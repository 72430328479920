import styled from 'styled-components';

const CardTitle = styled.h3`
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: start;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0px;
    position: sticky;
    top: 0;
    left: 0;

    &.no-sticky {
        position: static;
    }

    &.mb {
        margin-bottom: 30px;
    }

    &.card-info {
        width: 100%;
        justify-content: space-between;
    }

    &.gray {
        color: #757575;
        font-size: 14px;
    }
`;

export default CardTitle;
