import { ThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';
import { ReactChildrenProps } from '@/ui/interfaces/props';
import { DarkTheme, LightTheme } from '@/ui/styles/themes';
import { StoreState } from '@/ui/store/models';

const ThemeProviderLocal = function Content(
    props: ReactChildrenProps,
): JSX.Element {
    const darkTheme = useSelector((state: StoreState) => {
        return state.toggleTheme.darkTheme;
    });

    return (
        <ThemeProvider theme={darkTheme ? DarkTheme.colors : LightTheme.colors}>
            {props.children}
        </ThemeProvider>
    );
};

export default ThemeProviderLocal;
