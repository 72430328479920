import {
    HttpMethod,
    HttpRequest,
    HttpResponse,
    OrderEditHttpEndpoint,
} from '@/domain/models/services';
import { IHttpServicePort, IOrderEditRepository } from '@/infra/ports';

export class OrderEditRepositoryAdapter implements IOrderEditRepository {
    constructor(private readonly httpServicePort: IHttpServicePort) {}

    async getOrderPaymentContestationAsync<T>(
        orderPaymentId: string | number,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url:
                OrderEditHttpEndpoint.ORDER_CONTESTATION_BASE +
                '/' +
                orderPaymentId,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async updateOrderPaymentContestationAsync<T>(
        body: any,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: OrderEditHttpEndpoint.ORDER_CONTESTATION_CONTESTATION,
            method: HttpMethod.put,
            body: body,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getOrderPaymentContestationCalculateAsync<T>(
        body: any,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: OrderEditHttpEndpoint.ORDER_CONTESTATION_CALCULATE,
            method: HttpMethod.post,
            body: body,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getProductUnitaryValueAtOrderDate<T>(
        orderPaymentId: string | number,
        productId: any,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url:
                OrderEditHttpEndpoint.ORDER_CONTESTATION_BASE +
                '/' +
                orderPaymentId +
                '/products/prices/' +
                productId,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async declineOrderPaymentContestationAsync<T>(
        body: any,
        orderId: any,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url:
                OrderEditHttpEndpoint.ORDER_CONTESTATION_CONTESTATION +
                '/' +
                orderId +
                '/deny',
            method: HttpMethod.post,
            auth: true,
            body: body,
        });

        return await this.httpServicePort.request<T>(request);
    }
}
