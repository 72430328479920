export default class RefrigeratorHttpEndpoint {
    static API_VERSION = 'api/v1';

    static REFRIGERATOR_BASE = `${RefrigeratorHttpEndpoint.API_VERSION}/coolers`;
    static REFRIGERATOR_LIST = `${RefrigeratorHttpEndpoint.REFRIGERATOR_BASE}`;
    static REFRIGERATOR_LIST_STOCK = `${RefrigeratorHttpEndpoint.REFRIGERATOR_BASE}/stocks`;
    static REFRIGERATOR_DETAILED_REPORT_BASE = `${RefrigeratorHttpEndpoint.REFRIGERATOR_LIST_STOCK}/reports/detailed`;
    static REFRIGERATOR_STOCK_DETAILED_XLS = `${RefrigeratorHttpEndpoint.REFRIGERATOR_DETAILED_REPORT_BASE}/xls`;

    static REFRIGERATOR_PRODUCT_STOCK = `${RefrigeratorHttpEndpoint.REFRIGERATOR_LIST_STOCK}/products`;

    static PRODUCT_ALL = `${RefrigeratorHttpEndpoint.API_VERSION}/products`;
    static PRODUCT_BASE_BY_COOLER = `${RefrigeratorHttpEndpoint.API_VERSION}/coolers/`;
    static COOLER_AUTOCOMPLETE = `${RefrigeratorHttpEndpoint.API_VERSION}/coolers/autocomplete`;
    static USER_BASE = `${RefrigeratorHttpEndpoint.API_VERSION}/users`;
    static USER_GET_BY_TYPE = `${RefrigeratorHttpEndpoint.USER_BASE}/type/{0}`;
    static PRODUCT_BY_REFRIGERATOR_ID = `${RefrigeratorHttpEndpoint}`;
}
