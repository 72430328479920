import { useContext } from 'react';

import {
    IPromotionList,
    PromotionHttpEndpoint,
    PromotionTypeLabelMap,
    SearchCriteriaModel,
    SearchRequestModel,
    SearchResponseModel,
    UseCaseLabelMap,
} from '@/domain/models';
import { WindowHelper } from '@/ui/helpers';
import { RowActionProps } from '@/ui/interfaces/props/table';
import { IPromotionRepositoryPort, ISearchRepositoryPort } from '@/infra';
import { ColumnModel } from '@/presentation/models/table';
import { NotificationContext } from '@/main';
import {
    EditIcon,
    TogglePromotionIcon,
} from '@/ui/components/illustration/icons';
import { ITableServerSidePresenter } from '@/ui/components/table';

export default class PromotionTablePresenter
    implements ITableServerSidePresenter
{
    constructor(
        private readonly searchRepository: ISearchRepositoryPort,
        private readonly promotionRepository: IPromotionRepositoryPort,
    ) {}

    makeColumns(): ColumnModel<any>[] {
        return [
            new ColumnModel<any>('Código', 'id', {}),
            new ColumnModel<any>('Nome', 'name', {}),
            new ColumnModel<any>('Tipo', 'type', {})
                .withRender((value: string) => {
                    return <>{PromotionTypeLabelMap.get(value)}</>;
                })
                .withRenderTitleCustom(
                    ({ type }: IPromotionList) =>
                        PromotionTypeLabelMap.get(type) as string,
                ),
            new ColumnModel<any>('Tipo de uso', 'useCase', {}).withRender(
                (value: string) => {
                    return <>{UseCaseLabelMap.get(value)}</>;
                },
            ),
            new ColumnModel<any>('Desconto', 'discount', {}).withRender(
                (value: string) => {
                    return <>{value}</>;
                },
            ),
            new ColumnModel<any>('Inicio', 'dateBegin', {}),
            new ColumnModel<any>('Término', 'dateEnd', {}),
            new ColumnModel<any>('Categoria', 'categories', {}),
            new ColumnModel<any>('Cooler', 'coolers', {}),
            new ColumnModel<any>('Produto', 'products', {}),
        ];
    }

    makeSearchRequestModel(): SearchRequestModel {
        return new SearchRequestModel(
            PromotionHttpEndpoint.PROMOTION_BASE,
            'id',
            {
                isOrderByDesc: true,
                criterias: [
                    new SearchCriteriaModel('promotionName', {
                        value: '',
                    }),
                ],
            },
        );
    }

    makeLabelDisplayedResult(response: SearchResponseModel<any>): string {
        if (response === undefined) return '';
        if (response.page === undefined || response.page.total === 0) return '';

        return `Exibindo ${response.page.numberOfElements} promoções`;
    }

    async search<T>(
        searchModel: SearchRequestModel,
    ): Promise<SearchResponseModel<T>> {
        return await this.searchRepository.getDataAsync<any>(
            searchModel,
            PromotionHttpEndpoint.PROMOTION_BASE,
        );
    }

    makeRowActions(): RowActionProps<any>[] {
        const { successNotification } = useContext(NotificationContext);

        return [
            {
                icon: rowData => (
                    <TogglePromotionIcon rowData={rowData.status} />
                ),
                tooltip: 'Ativa/Desativar',
                enableClickOnRow: false,
                onClick: (event: any, rowData: any, refetchTable) => {
                    this.promotionRepository
                        .toggleActivatePromotion(
                            rowData?.id,
                            rowData.status === 'ACTIVE' ? false : true,
                        )
                        .then(() => refetchTable())
                        .then(() =>
                            successNotification(
                                rowData.status === 'ACTIVE'
                                    ? 'Promoção desativada com sucesso!'
                                    : 'Promoção ativada com sucesso!',
                            ),
                        );
                },
            },
            {
                icon: () => <EditIcon />,
                tooltip: 'Editar promoção',
                enableClickOnRow: false,
                onClick: (event: any, rowData: any) => {
                    WindowHelper.redirect(`/promotion/${rowData?.id}`);
                },
            },
        ];
    }
}
