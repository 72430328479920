import { SubmitHandler, useForm } from 'react-hook-form';
import { useContext, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
    AuthenticationHelper,
    DateHelper,
    PermissionHelper,
    RequestQueryMaker,
} from '@/domain/helpers';
import { IAutocomplete } from '@/domain/models';
import { OverviewFilterParams } from '@/presentation';
import { makeRefrigeratorListPresenter } from '@/main/factories';
import { IFilterDrawer } from '@/ui/interfaces';
import { AutoCompleteField, DatePickerField, Filter } from '@/ui/components';
import { FilterContext } from '@/ui/context/context-filter';
import 'dayjs/locale/pt-br';

export default function OverviewFilter({ filter, setFilter }: IFilterDrawer) {
    const queryClient = useQueryClient();

    const { getFilters, resetFilter, setResetFilter, updateFilters } =
        useContext(FilterContext);

    const filtersPage = getFilters();

    const token = AuthenticationHelper.getToken();
    const permissionHelper = new PermissionHelper(token.auth);
    const isAllowed =
        permissionHelper.isTakeAndGo() || permissionHelper.isDeveloper();

    const refrigeratorListPresenter = makeRefrigeratorListPresenter();

    const resetValues = {
        coolerId: filtersPage?.coolerId ? filtersPage?.coolerId : null,
        licensedId: filtersPage?.licensedId ? filtersPage?.licensedId : null,
        initialDate: filtersPage?.initialDate
            ? DateHelper.formatDateFromRequest(filtersPage?.initialDate?.id)
            : null,
        endDate: filtersPage?.endDate
            ? DateHelper.formatDateFromRequest(filtersPage?.endDate?.id)
            : null,
    };

    const {
        handleSubmit,
        control,
        setValue,
        watch,
        reset,
        formState: { isDirty, isValid },
    } = useForm<{
        coolerId: { id: string; label: string } | null;
        licensedId: { id: string; label: string } | null;
        initialDate: any;
        endDate: any;
    }>({
        mode: 'onChange',
        defaultValues: resetValues,
    });

    const disableSubmit = !isDirty || !isValid;
    const [licensedId] = watch(['licensedId']);

    const onSubmit: SubmitHandler<{
        coolerId: { id: string; label: string } | null;
        licensedId: { id: string; label: string } | null;
        initialDate: any;
        endDate: any;
    }> = data => submitHandler(data);

    const submitHandler = (data: any) => {
        const overviewFilterParams = new OverviewFilterParams(
            DateHelper.formatDate(data.initialDate),
            DateHelper.formatDate(data.endDate),
            data.coolerId?.id,
            data.licensedId?.id,
            data.coolerId?.label,
            data.licensedId?.label,
        );

        window.localStorage.setItem('licensedId', data.licensedId?.id);
        window.localStorage.setItem('licensedName', data.licensedId?.label);

        updateFilters({ ...filtersPage, ...overviewFilterParams });
        setFilter({ right: false });
        reset();
    };

    const handleLicensedChange = (value: any) => {
        setValue('coolerId', null);
        return setValue('licensedId', value, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
        });
    };

    const licensedAutoCompleteData = queryClient.getQueryData<
        Array<IAutocomplete>
    >('licensedAutoComplete');

    const {
        data: coolerAutoComplete,
        isLoading: isCoolerAutoCompleteLoading,
        isFetching: isCoolerAutoCompleteFetching,
    } = useQuery(['coolerAutoComplete', licensedId], () =>
        refrigeratorListPresenter.getCoolerFilterAutocompleteAsync(
            RequestQueryMaker({
                licensedId: licensedId?.id,
                active: '0',
            }),
        ),
    );

    useEffect(() => {
        reset(resetValues);
    }, [filtersPage]);

    useEffect(() => {
        if (resetFilter) {
            reset(resetValues);
            setResetFilter(false);
        }
    }, [resetFilter]);

    return (
        <Filter
            filter={filter}
            setFilter={setFilter}
            handleSubmit={handleSubmit as any}
            onSubmit={onSubmit}
            disabled={disableSubmit}
        >
            {isAllowed && (
                <AutoCompleteField
                    dataTestId="licensed-autocomplete"
                    control={control}
                    options={licensedAutoCompleteData as IAutocomplete[]}
                    name="licensedId"
                    label="Licenciado"
                    data-testid="licensed-autocomplete"
                    interceptOnChange={handleLicensedChange}
                />
            )}
            <AutoCompleteField
                dataTestId="cooler-autocomplete"
                control={control}
                options={coolerAutoComplete as IAutocomplete[]}
                isLoading={
                    isCoolerAutoCompleteLoading || isCoolerAutoCompleteFetching
                }
                isDisabled={
                    isAllowed &&
                    !licensedId === null &&
                    coolerAutoComplete!.length > 0
                }
                name="coolerId"
                label="Cooler"
            />
            <LocalizationProvider
                adapterLocale="pt-br"
                dateAdapter={AdapterDayjs}
            >
                <DatePickerField
                    dataTestId="initial-date-input"
                    control={control}
                    name="initialDate"
                    label="Inicio"
                />
                <DatePickerField
                    dataTestId="end-date-input"
                    control={control}
                    name="endDate"
                    label="Fim"
                />
            </LocalizationProvider>
        </Filter>
    );
}
