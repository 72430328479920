import {
    ProductListHttpEndpoint,
    SearchCriteriaModel,
    SearchRequestModel,
    SearchResponseModel,
} from '@/domain/models';
import { StringHelper } from '@/domain/helpers';
import { ISearchRepositoryPort } from '@/infra';
import { ColumnModel } from '@/presentation/models/table';
import { RowActionProps } from '@/ui/interfaces/props/table';
import { ITableServerSidePresenter } from '@/ui/components/table';
import { EditIcon } from '@/ui/components/illustration/icons';
import { WindowHelper } from '@/ui/helpers';

export default class ProductsTablePresenter
    implements ITableServerSidePresenter
{
    constructor(private readonly searchRepository: ISearchRepositoryPort) {}

    makeColumns(): ColumnModel<any>[] {
        return [
            new ColumnModel<any>('Produto', 'url', {}).withRender(
                (value: any) => {
                    return (
                        <img
                            width={25}
                            height={25}
                            src={value}
                            alt="imagem do produto"
                        />
                    );
                },
            ),
            new ColumnModel<any>('Código', 'code', {}),
            new ColumnModel<any>('Nome', 'name', {}),
            new ColumnModel<any>('Descrição', 'description', {}),
            new ColumnModel<any>(
                'Valor sugerido',
                'suggestedValue',
                {},
            ).withRender((value: any) => {
                return (
                    <>
                        {Intl.NumberFormat('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                        }).format(+value)}
                    </>
                );
            }),
            new ColumnModel<any>('Valor de tabela', 'costPrice', {}).withRender(
                (value: any) => {
                    return (
                        <>
                            {Intl.NumberFormat('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                            }).format(+value)}
                        </>
                    );
                },
            ),
            new ColumnModel<any>('Categoria', 'category', {}).withRender(
                (value: any) => {
                    return value.description;
                },
            ),
            new ColumnModel<any>('Fornecedor', 'provider', {}).withRender(
                (value: any) => {
                    return value.name;
                },
            ),
            new ColumnModel<any>('Público', 'general', {}).withRender(
                (value: any) => {
                    return value ? <>Sim</> : <>Não</>;
                },
            ),
        ];
    }

    makeSearchRequestModel(): SearchRequestModel {
        return new SearchRequestModel(
            ProductListHttpEndpoint.PRODUCT_BASE,
            'name',
            {
                isOrderByDesc: false,
                criterias: [
                    new SearchCriteriaModel('name', {
                        value: '',
                    }),
                ],
            },
        );
    }

    makeLabelDisplayedResult(response: SearchResponseModel<any>): string {
        if (response === undefined) return '';
        if (response.page === undefined || response.page.total === 0) return '';

        const textPlural = StringHelper.pluralize({
            count: response.page.numberOfElements,
        });
        return `Exibindo ${response.page.numberOfElements} produto${textPlural}`;
    }

    async search<T>(
        searchModel: SearchRequestModel,
    ): Promise<SearchResponseModel<T>> {
        return await this.searchRepository.getDataAsync<any>(
            searchModel,
            ProductListHttpEndpoint.PRODUCT_BASE,
        );
    }

    makeRowActions(): RowActionProps<any>[] {
        return [
            {
                icon: () => <EditIcon />,
                tooltip: 'Editar produto',
                enableClickOnRow: false,
                onClick: (event: any, rowData: any) => {
                    WindowHelper.redirect(`/product/${rowData?.id}`);
                },
            },
        ];
    }
}
