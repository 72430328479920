import { CircularProgress } from '@/ui/components';
import {
    RatingChartContainer,
    RatingColumn,
    RatingMiniCard,
    RatingPieChartTitle,
    RatingTotalPossibleRating,
} from '../styles';
import makeHomeOverviewPresenter from '@/main/factories/presenters/pages/home/home-overview-presenter-factory';
import { PieChartProps } from '@/presentation';
import RePieChart from '@/ui/components/recharts/pie-chart';
import { Row } from '@/ui/components/layout';
import { NoDataFound } from '../../components';

interface DesktopTopSection {
    ratingWhatCanImproveLoading: boolean;
    ratingData: any;
    ratingDataLoading: boolean;
    ratingWatCanImprove: PieChartProps;
}

export default function DesktopTopSection({
    ratingWhatCanImproveLoading,
    ratingData,
    ratingDataLoading,
    ratingWatCanImprove,
}: DesktopTopSection) {
    const homePresenter = makeHomeOverviewPresenter();

    return (
        <Row justify="space-evenly" margin="50px 0">
            <RatingColumn>
                <RatingMiniCard>
                    {ratingDataLoading ? (
                        <CircularProgress variant="indeterminate" />
                    ) : (
                        <>
                            <p data-testid="average-rating">Nota média</p>
                            <h1>
                                {!ratingData.averageRating ||
                                isNaN(ratingData.averageRating) ||
                                ratingData.averageRating === 0
                                    ? '-'
                                    : ratingData.averageRating}
                                <RatingTotalPossibleRating>
                                    {ratingData.averageRating &&
                                        Number(ratingData.averageRating) &&
                                        ratingData.averageRating !== 0 &&
                                        '/5'}
                                </RatingTotalPossibleRating>
                            </h1>
                        </>
                    )}
                </RatingMiniCard>
                <RatingMiniCard>
                    {ratingDataLoading ? (
                        <CircularProgress variant="indeterminate" />
                    ) : (
                        <>
                            <p data-testid="average-quantity">
                                Quantidade de avaliações
                            </p>
                            <h1>
                                {ratingData.ratingQuantity &&
                                    ratingData.ratingQuantity}
                            </h1>
                        </>
                    )}
                </RatingMiniCard>
            </RatingColumn>
            <RatingChartContainer>
                {ratingWhatCanImproveLoading ? (
                    <CircularProgress variant="indeterminate" />
                ) : (
                    <>
                        <NoDataFound
                            data={homePresenter.checkIfChartContainData(
                                ratingWatCanImprove,
                                'pieChart',
                            )}
                        >
                            {ratingWatCanImprove && (
                                <RePieChart
                                    pieChartProps={ratingWatCanImprove}
                                    isEvaluation
                                />
                            )}
                            <RatingPieChartTitle>
                                O que pode <br />
                                melhorar
                            </RatingPieChartTitle>
                        </NoDataFound>
                    </>
                )}
            </RatingChartContainer>
        </Row>
    );
}
