import { useContext, useState } from 'react';
import { useQueries } from 'react-query';

import {
    AuthenticationHelper,
    PermissionHelper,
    RequestQueryMaker,
} from '@/domain/helpers';
import makeHomeOverviewPresenter from '@/main/factories/presenters/pages/home/home-overview-presenter-factory';
import makeHomeRatingPresenter from '@/main/factories/presenters/pages/home/home-rating-presenter-factory';
import { FilterContext } from '@/ui/context/context-filter';
import { FilterType } from '@/ui/context/context-filter/type';
import { IUseRatingQueries } from '@/ui/interfaces';

export default function useRatingQueries(): IUseRatingQueries {
    const { getFiltersObject } = useContext(FilterContext);
    const pageFilters: FilterType<'home_rating'> = getFiltersObject();

    const getLicensedIdInSessionStorage =
        window.localStorage.getItem('licensedId');
    const roles = AuthenticationHelper.getToken().auth;
    const permissionHelper = new PermissionHelper(roles);

    const [isModalOpen, setIsModalOpen] = useState<boolean>(
        permissionHelper.isTakeAndGo() && !getLicensedIdInSessionStorage,
    );

    const ratingPresenter = makeHomeRatingPresenter();
    const homePresenter = makeHomeOverviewPresenter();

    const [
        { data: ratingData, isLoading: isRatingDataLoading },
        {
            data: ratingWhatCanImprove,
            isLoading: isRatingWhatCanImproveLoading,
        },
        {
            data: ratingAverageTicketByPublic,
            isLoading: isAverageTicketLoading,
        },
        { data: ratingTopUser, isLoading: isRatingTopUserLoading },
        { data: ratingUserStatistics, isLoading: isUserStatisticsLoading },
        {
            data: ratingByReviewQuantity,
            isLoading: isRatingByReviewQuantityLoading,
        },
        {
            data: ratingByRefrigerator,
            isLoading: isRatingByRefrigeratorLoading,
        },
    ] = useQueries([
        {
            queryKey: ['ratingData', pageFilters],
            queryFn: () =>
                ratingPresenter.getRatingAsync(
                    RequestQueryMaker(homePresenter.getParams(pageFilters)),
                ),
            enabled: !isModalOpen,
        },
        {
            queryKey: ['ratingWhatCanImprove', pageFilters],
            queryFn: () =>
                ratingPresenter.getRatingWhatCanImproveAsync(
                    RequestQueryMaker(homePresenter.getParams(pageFilters)),
                ),
            enabled: !isModalOpen,
        },
        {
            queryKey: ['ratingAverageTicketByPublic', pageFilters],
            queryFn: () =>
                ratingPresenter.getRatingAverageTicketByPublicAsync(
                    RequestQueryMaker(homePresenter.getParams(pageFilters)),
                ),
            enabled: !isModalOpen,
        },
        {
            queryKey: ['ratingTopUser', pageFilters],
            queryFn: () =>
                ratingPresenter.getRatingTopUsersAsync(
                    RequestQueryMaker(homePresenter.getParams(pageFilters)),
                ),
            enabled: !isModalOpen,
        },
        {
            queryKey: ['ratingUserStatistics', pageFilters],
            queryFn: () =>
                ratingPresenter.getUserStatisticsAsync(
                    RequestQueryMaker(homePresenter.getParams(pageFilters)),
                ),
            enabled: !isModalOpen,
        },
        {
            queryKey: ['ratingAverageRatingByReviewQuantity', pageFilters],
            queryFn: () =>
                ratingPresenter.getRatingAverageRatingByReviewQuantityAsync(
                    RequestQueryMaker(homePresenter.getParams(pageFilters)),
                ),
            enabled: !isModalOpen,
        },
        {
            queryKey: ['ratingByRefrigerator', pageFilters],
            queryFn: () =>
                ratingPresenter.getRatingAverageRatingByRefrigeratorAsync(
                    RequestQueryMaker(homePresenter.getParams(pageFilters)),
                ),
            enabled: !isModalOpen,
        },
    ]);

    return {
        isModalOpen,
        setIsModalOpen,
        ratingData,
        isRatingDataLoading,
        ratingWhatCanImprove,
        isRatingWhatCanImproveLoading,
        ratingAverageTicketByPublic,
        isAverageTicketLoading,
        ratingTopUser,
        isRatingTopUserLoading,
        ratingUserStatistics,
        isUserStatisticsLoading,
        ratingByReviewQuantity,
        isRatingByReviewQuantityLoading,
        ratingByRefrigerator,
        isRatingByRefrigeratorLoading,
    };
}
