export default class RequiredValueHelper {
    static isRequired = (value: any) => {
        return (
            (value != false || value == 0) &&
            value != undefined &&
            value != '' &&
            value.length != 0 &&
            value != 'Invalid Date'
        );
    };

    static isNotNegative = (value: number) => {
        return value >= 0;
    };

    static isGreaterThanZero = (value: number) => {
        return value > 0;
    };
}
