import { ProductStockTableHead } from '../../styles';

export default function StockProductTableHead(): JSX.Element {
    return (
        <ProductStockTableHead>
            <th>Produto</th>
            <th>Ult. Reposição</th>
            <th>Consumo</th>
            <th>Estoque atual</th>
            <th>Estoque mínimo</th>
            <th>Estoque esperado</th>
            <th className="center">% Estoque</th>
            <th>Valor médio</th>
            <th>Opções</th>
        </ProductStockTableHead>
    );
}
