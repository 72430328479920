import { useContext } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { ContextRoutePath } from '@/domain/models';
import { AuthenticationHelper, PermissionHelper } from '@/domain/helpers';
import { NotificationContext } from '../notification-provider';
import { BaseLayout } from '@/ui/components';

const ProtectedRoute = ({
    children,
}: {
    children: JSX.Element;
}): JSX.Element => {
    if (!AuthenticationHelper.isAuthenticated()) {
        return <Navigate to={ContextRoutePath.login} />;
    }

    const userRoles = AuthenticationHelper.hasToken()
        ? AuthenticationHelper.getToken().auth
        : '';
    const permissionHelper = new PermissionHelper(userRoles);
    const { errorNotification } = useContext(NotificationContext);

    const navigate = useNavigate();

    if (permissionHelper.isOnlyUser()) {
        AuthenticationHelper.removeToken();
        navigate(`/login`);
        errorNotification('Sem permissão para acessar!');
    }

    return <BaseLayout>{children}</BaseLayout>;
};

export default ProtectedRoute;
