import { useTheme } from 'styled-components';
import StyledSvgIcon from './svg-icon.styles';

export default function StockIcon() {
    const currentTheme = useTheme();

    return (
        <StyledSvgIcon
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.0837 5.66667V12.5C17.0837 13.9001 17.0837 14.6002 16.8112 15.135C16.5715 15.6054 16.189 15.9878 15.7186 16.2275C15.1839 16.5 14.4838 16.5 13.0837 16.5H6.91699C5.51686 16.5 4.8168 16.5 4.28202 16.2275C3.81161 15.9878 3.42916 15.6054 3.18948 15.135C2.91699 14.6002 2.91699 13.9001 2.91699 12.5V5.66667M3.00033 1.5H17.0003C17.467 1.5 17.7004 1.5 17.8787 1.59083C18.0355 1.67072 18.1629 1.79821 18.2428 1.95501C18.3337 2.13327 18.3337 2.36662 18.3337 2.83333V4.33333C18.3337 4.80004 18.3337 5.0334 18.2428 5.21166C18.1629 5.36846 18.0355 5.49594 17.8787 5.57584C17.7004 5.66667 17.467 5.66667 17.0003 5.66667H3.00033C2.53362 5.66667 2.30026 5.66667 2.122 5.57584C1.9652 5.49594 1.83771 5.36846 1.75782 5.21166C1.66699 5.0334 1.66699 4.80004 1.66699 4.33333V2.83333C1.66699 2.36662 1.66699 2.13327 1.75782 1.95501C1.83771 1.79821 1.9652 1.67072 2.122 1.59083C2.30026 1.5 2.53362 1.5 3.00033 1.5ZM8.00033 8.58333H12.0003C12.467 8.58333 12.7004 8.58333 12.8787 8.67416C13.0355 8.75406 13.1629 8.88154 13.2428 9.03834C13.3337 9.2166 13.3337 9.44996 13.3337 9.91667V10.5833C13.3337 11.05 13.3337 11.2834 13.2428 11.4617C13.1629 11.6185 13.0355 11.7459 12.8787 11.8258C12.7004 11.9167 12.467 11.9167 12.0003 11.9167H8.00033C7.53362 11.9167 7.30026 11.9167 7.122 11.8258C6.9652 11.7459 6.83771 11.6185 6.75782 11.4617C6.66699 11.2834 6.66699 11.05 6.66699 10.5833V9.91667C6.66699 9.44996 6.66699 9.2166 6.75782 9.03834C6.83771 8.88154 6.9652 8.75406 7.122 8.67416C7.30026 8.58333 7.53362 8.58333 8.00033 8.58333Z"
                stroke={currentTheme.tableIconColors}
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </StyledSvgIcon>
    );
}
