import { TextField } from '@mui/material';
import styled from 'styled-components';

export default styled(TextField)`
    width: 100%;
    color: ${props =>
        props.disabled ? props.theme.quaternary : props.theme.secondary};
    min-height: 55px;
    .MuiInputBase-root {
        flex: 1;
    }

    .Mui-disabled {
        fieldset {
            color: ${props => props.theme.quaternary} !important;
            border-color: ${props => props.theme.lines} !important;
        }
    }
`;
