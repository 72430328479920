import { IOrdinationButtonProps } from '@/ui/interfaces';
import { OrdinationArrowDown, OrdinationArrowUp } from '../icons';
import { OrdinationButtonStyled } from './styles';
import IOrdinationButtonPresenter from './ordination-button-presenter';
import { makeOrdinationButtonPresenter } from '@/main/factories/presenters/components/ordination-presenter-factory';

const OrdinationButton = ({
    columnName,
    ordination,
    setOrdination,
}: IOrdinationButtonProps) => {
    const ordinationButtonPresenter: IOrdinationButtonPresenter =
        makeOrdinationButtonPresenter();

    const showOrdinationArrowButton =
        ordinationButtonPresenter.showOrdinationArrowButton(
            columnName,
            ordination,
        );

    const handleOrdinationClick = () => {
        if (
            ordination.orderColumn == false &&
            ordination.descOrderColumn == false
        ) {
            setOrdination &&
                setOrdination({
                    show: {
                        ...ordination.show,
                    },
                    orderColumn: true,
                    descOrderColumn: false,
                    column: columnName,
                });
        } else if (
            ordination.orderColumn == true &&
            ordination.column != columnName
        ) {
            setOrdination &&
                setOrdination({
                    show: {
                        ...ordination.show,
                    },
                    orderColumn: true,
                    descOrderColumn: false,
                    column: columnName,
                });
        } else if (
            ordination.orderColumn == true &&
            ordination.column == columnName
        ) {
            setOrdination &&
                setOrdination({
                    show: {
                        ...ordination.show,
                    },
                    orderColumn: false,
                    descOrderColumn: true,
                    column: columnName,
                });
        } else if (ordination.descOrderColumn == true) {
            setOrdination &&
                setOrdination({
                    show: {
                        ...ordination.show,
                    },
                    orderColumn: false,
                    descOrderColumn: false,
                    column: columnName,
                });
        }
    };

    return showOrdinationArrowButton ? (
        <OrdinationButtonStyled onClick={handleOrdinationClick}>
            {Boolean(!ordination.descOrderColumn) && <OrdinationArrowUp />}
            {Boolean(ordination.descOrderColumn) && <OrdinationArrowDown />}
        </OrdinationButtonStyled>
    ) : (
        <></>
    );
};

export default OrdinationButton;
