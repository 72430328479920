import styled from 'styled-components';

const RatingChartContainer = styled.div`
    height: 450px;
    width: 620px;
    padding: 0px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 800;
`;

export default RatingChartContainer;
