import { AutoCompleteField, InputTextField } from '@/ui/components';
import { SubmitHandler, useForm } from 'react-hook-form';
import { IOrderEdit } from '@/domain/models';
import OrderConfirmButtons from '../order-confirm-buttons';
import IContestationEvaluation from '@/domain/models/pages/orders/edit/contestation-calculate/contestation-evaluation';
import { Column, Grid } from '@/ui/components/layout';

interface IOrderEvaluationProps {
    handleApplyOrderEdit: (valuationData: IContestationEvaluation) => any;
    initialOrderData: IOrderEdit;
    orderData: IOrderEdit;
    declineOrderContestation: (reason: string) => void;
}

export default function OrderEvaluation(
    props: IOrderEvaluationProps,
): JSX.Element {
    const {
        handleApplyOrderEdit,
        initialOrderData,
        orderData,
        declineOrderContestation,
    } = props;

    const {
        handleSubmit,
        control,
        formState: { isValid },
    } = useForm<IContestationEvaluation>({
        mode: 'onChange',
        defaultValues: {
            contestationRootCause: { label: '', id: '' },
            additionalInfo: '',
        },
    });

    const onSubmit: SubmitHandler<IContestationEvaluation> = data =>
        handleApplyOrderEdit(data);

    const rootCauseOptions = [
        {
            label: 'Erro na API ou APP',
            id: 'API_APP',
        },
        {
            label: 'Erro na imagem ou funcionamento do cooler',
            id: 'COOLER',
        },
        {
            label: 'Erro de reconhecimento ou contagem da IA, apesar do mix correto',
            id: 'IA',
        },
        {
            label: 'Mix configurado incorretamente',
            id: 'MIX',
        },
        {
            label: 'Erro na revisão manual',
            id: 'MANUAL_REVISION',
        },
        {
            label: 'Problema na qualidade do produto',
            id: 'PRODUCT_QUALITY',
        },
    ];

    function areNotTheSameProducts() {
        if (initialOrderData.products.length !== orderData.products.length) {
            return true;
        }

        if (
            initialOrderData.products[0] === null ||
            orderData.products[0] === null
        ) {
            return true;
        }

        const initialOrderProducts = [...initialOrderData.products]
            .sort((a, b) => a.productId - b.productId)
            .map(product => ({
                productId: Number(product.productId),
                quantity: Number(product.quantity),
            }));

        const orderProducts = [...orderData.products]
            .sort((a, b) => a.productId - b.productId)
            .filter(product => !product.removed)
            .map(product => ({
                productId: Number(product.productId),
                quantity: Number(product.quantity),
            }));

        const initialOrderProductsString = JSON.stringify(initialOrderProducts);
        const orderProductsString = JSON.stringify(orderProducts);

        return initialOrderProductsString !== orderProductsString;
    }

    return (
        <Column as="form" onSubmit={handleSubmit(onSubmit)} gap={6}>
            <h5>Avaliação</h5>
            <Grid columns={3}>
                <AutoCompleteField
                    dataTestId="evaluation_root_cause"
                    control={control}
                    options={rootCauseOptions}
                    name="contestationRootCause"
                    label="Causa raiz da contestação"
                    rules={{
                        required: 'Causa raiz da contestação é obrigatório',
                    }}
                />
            </Grid>
            <InputTextField
                dataTestId="evaluation_contestation_observation"
                control={control}
                name="contestationObservation"
                label="Observações"
                rules={{
                    required: 'O campo observações é obrigatório',
                }}
                type="textarea"
            />
            <OrderConfirmButtons
                declineOrderContestation={declineOrderContestation}
                isEnable={areNotTheSameProducts() && isValid}
            />
        </Column>
    );
}
