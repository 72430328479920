import styled from 'styled-components';
import { ContainerRoot as Root } from '../common/styles';

export const ContainerRoot = styled(Root)`
    tr:hover .row-action {
        visibility: visible;
    }

    table.is-loading-true {
        background-color: ${props => `${props.theme.bodyBg}`};
        opacity: 0.5;
    }

    table {
        border-spacing: 0;
        @media (min-width: 650px) {
            table-layout: fixed;
        }

        tr {
            border-color: ${props => props.theme.quaternary} !important;
            border: none;
            width: 100%;
        }

        tr:nth-child(odd) {
            background-color: ${props => props.theme.tableNthChild};
            color: #fff;
            z-index: 55;
            border: none !important;
        }

        th {
            border-top: 0px !important;
            background-color: ${props => props.theme.bodyBg} !important;
            color: ${props => props.theme.quaternary} !important;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 140%;
            text-align: start;
            position: relative;
            padding: 20px 10px;
        }

        td {
            margin-right: 15px;
            color: ${props => props.theme.tableText} !important;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: ${props => props.theme.tableText};
            text-align: start;
            padding: 15px 0px;
            position: relative;
            border-color: ${props => props.theme.tableBorder} !important;
            border: none;
            max-height: 100px;
        }

        .table-cell-action-thead {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 140%;
            color: ${props => props.theme.quaternary} !important;
            text-align: start;
            padding-bottom: 15px;
        }

        .table-cell-action-body {
            padding-top: 4px !important;
            padding-bottom: 4px !important;
        }

        .material-table-body {
            td {
                border: none;
                border-color: transparent;
                box-sizing: border-box;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: ${props => props.theme.tableText} !important;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;
                color: ${props => props.theme.tableText};
                text-align: start;
                padding: 20px 10px;
                position: relative;
                border: none !important;
                max-height: 100px !important;

                @media (max-width: 650px) {
                    padding: 10px 5px;
                    font-size: 13px;
                }
            }
        }
    }
`;
