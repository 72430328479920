import { LegendItem, LegendList } from './styles';

export default function ChartLegend({ payload }: any): JSX.Element {
    return (
        <LegendList as="ul">
            {payload.map((entry: any, index: any) => (
                <LegendItem
                    as="li"
                    elementColor={entry.color}
                    key={`item-${index} entry-${entry.value}`}
                >
                    <div></div>
                    {entry.value}
                </LegendItem>
            ))}
        </LegendList>
    );
}
