import StyledSvgIcon from './svg-icon.styles';

export default function WebcamIcon(): JSX.Element {
    return (
        <StyledSvgIcon
            width="20"
            height="21"
            viewBox="0 0 20 21"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.99992 15.5C13.6818 15.5 16.6666 12.5153 16.6666 8.83335C16.6666 5.15146 13.6818 2.16669 9.99992 2.16669C6.31802 2.16669 3.33325 5.15146 3.33325 8.83335C3.33325 12.5153 6.31802 15.5 9.99992 15.5ZM9.99992 15.5V18.8334M9.99992 18.8334H5.83325M9.99992 18.8334H14.1666M12.4999 8.83335C12.4999 10.2141 11.3806 11.3334 9.99992 11.3334C8.61921 11.3334 7.49992 10.2141 7.49992 8.83335C7.49992 7.45264 8.61921 6.33335 9.99992 6.33335C11.3806 6.33335 12.4999 7.45264 12.4999 8.83335Z"
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </StyledSvgIcon>
    );
}
