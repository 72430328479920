import styled from 'styled-components';

interface IDefaultListProps {
    open: boolean;
}

const DefaultList = styled.ul<IDefaultListProps>`
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: auto;
    overflow-x: hidden;

    .MuiSvgIcon-colorPrimary {
        transition: background-color 400ms;
        background-color: ${props => `${props.theme.primary}`};
        color: ${props => `${props.theme.primary}`};
    }
`;

export default DefaultList;
