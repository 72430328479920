import HttpEndpoint from '../http-endpoint';

export default class RatingHttpEndpoint {
    static RATING_BASE = `${HttpEndpoint.API_VERSION}/dashboards/evaluations`;
    static RATING_USERS_BASE = `${HttpEndpoint.API_VERSION}/dashboards/users`;
    static RATING_AVERAGE_AND_TOTAL_RATINGS = `${RatingHttpEndpoint.RATING_BASE}/average-and-total-ratings/`;
    static RATING_IMPROVEMENTS_POINTS_SUGGESTIONS = `${RatingHttpEndpoint.RATING_BASE}/improvements-points-suggestions`;
    static RATING_AVERAGE_AND_QUANTITY_BY_COOLER = `${RatingHttpEndpoint.RATING_BASE}/ratings-average-and-quantity-by-cooler`;
    static RATING_AVERAGE_AND_QUANTITY_BY_DATE_LAST_SIX_MONTHS = `${RatingHttpEndpoint.RATING_BASE}/ratings-average-and-quantity-by-date-last-six-months`;
    static RATING_AVERAGE_TICKET_BY_PUBLIC = `${RatingHttpEndpoint.RATING_USERS_BASE}/average-ticket-by-gender`;
    static RATING_TOP_USERS = `${RatingHttpEndpoint.RATING_USERS_BASE}/top-five`;
    static USER_STATISTICS = `${RatingHttpEndpoint.RATING_USERS_BASE}/users-activities-quantity-by-classification`;
}
