import styled from 'styled-components';
import { keyframes } from 'styled-components';

interface StatusProps {
    isOnline?: boolean;
    hasProblem?: boolean;
}

export const Status = styled.span<StatusProps>`
    &.online {
        color: ${props => props.theme.orderStatusLabel.success.textColor};
        stroke: ${props => props.theme.secondary};
    }

    &.offline {
        color: ${props => props.theme.orderStatusLabel.problems.textColor};
        stroke: ${props => props.theme.orderStatusLabel.problems.textColor};
    }

    svg {
        cursor: default;
    }
    &.camera {
        svg {
            cursor: pointer;
        }
    }
`;

const fadeIn = keyframes`
    0%{background-position:0% 100%}
   100%{background-position:100% 0%}
`;

export const CellLoading = styled.td`
    &.default-loading {
        min-height: 70px;
        background: linear-gradient(
            90deg,
            #d4d4d4 2%,
            #757575 48%,
            #d4d4d4 100%
        );
        background-size: 300% 300%;
        -webkit-animation: ${fadeIn} 0.8s ease infinite;
        -moz-animation: ${fadeIn} 0.8s ease infinite;
        -o-animation: ${fadeIn} 0.8s ease infinite;
        animation: ${fadeIn} 0.8s ease infinite;
    }

    &.dark-default-loading {
        min-height: 50px;
        background: linear-gradient(
            270deg,
            rgba(32, 28, 28, 1) 20%,
            rgba(0, 0, 0, 1) 48%,
            rgba(32, 28, 28, 1) 76%
        );
        background-size: 300% 300%;
        -webkit-animation: ${fadeIn} 0.8s ease infinite;
        -moz-animation: ${fadeIn} 0.8s ease infinite;
        -o-animation: ${fadeIn} 0.8s ease infinite;
        animation: ${fadeIn} 0.8s ease infinite;
    }
`;

export const Capitalized = styled.span`
    text-transform: capitalize;
`;
