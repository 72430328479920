import styled from 'styled-components';

export const CouponExpireDataAndType = styled.p`
    color: ${props => props.theme.secondary};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
`;
