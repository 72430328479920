import styled from 'styled-components';
import { Link as LinkBase } from 'react-router-dom';

export const Link = styled(LinkBase)`
    text-decoration: none;
    &:active,
    &:visited,
    &:link {
        color: inherit;
    }

    &:hover {
        text-decoration: underline;
    }
`;
