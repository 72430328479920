import { SnackbarProvider } from 'notistack';
import styled from 'styled-components';

export const Container = styled(SnackbarProvider)`
    &&.SnackbarContent-root {
        color: #fff;
        font-size: 16px;
        display: flex;
        align-items: center;
        height: 48px;

        .SnackbarItem-message span {
            padding-right: 10px;
        }

        .notistack-snackbar-close {
            color: #fff;
        }
    }

    &&.SnackbarItem-variantInfo {
        background-color: ${props => props.theme.notification.info};
    }

    &&.SnackbarItem-variantSuccess {
        background-color: ${props => props.theme.notification.success};
    }

    &&.SnackbarItem-variantWarning {
        background-color: ${props => props.theme.notification.warning};
    }

    &&.SnackbarItem-variantError {
        background-color: ${props => props.theme.notification.error};
    }
`;
