import { NumberFormatBase } from 'react-number-format';
import { useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';
import defaultInputStyles from '../input-styles';
import styledTextField from '../styled-text-field';

interface MonetaryTextFieldProps {
    setValue: (value: number) => void;
    readOnly?: boolean;
    label: string;
    name: string;
    defaultValue?: number | null;
    placeholder?: string;
    size?: 'small' | 'normal';
    error?:
        | FieldError
        | {
              message: string;
          };
    dataTestId?: string;
}

export default function MonetaryTextField({
    setValue: onChange,
    defaultValue,
    readOnly = false,
    size,
    placeholder,
    label,
    name,
    error,
    dataTestId,
}: MonetaryTextFieldProps) {
    const { inputStyles } = defaultInputStyles(readOnly);
    const [value, setValue] = useState<string | number>('');

    const handleChange = (e: any, onChange: any) => {
        setValue(parseFloat(e.value) * 100);
        if (onChange) {
            onChange(e.floatValue / 100);
        }
    };

    const currencyFormatter = (formattedValue: any) => {
        if (!Number(formattedValue)) {
            return 'R$ 0,00';
        }

        const br: Intl.NumberFormatOptions = {
            style: 'currency',
            currency: 'BRL',
        };
        return new Intl.NumberFormat('pt-BR', br).format(formattedValue / 100);
    };

    const keyDown = (e: any) => {
        if (e.code === 'Backspace' && !value) {
            e.preventDefault();
        }

        if (e.code === 'Backspace' && Number(value) < 1000) {
            setValue(0);
        }
    };

    useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue * 100 * 100);
        }
        if (defaultValue === 0) {
            setValue('');
        }
    }, [defaultValue]);

    return (
        <NumberFormatBase
            value={Number(value) / 100}
            onValueChange={(e: any) => handleChange(e, onChange)}
            prefix="R$ "
            valueIsNumericString
            format={currencyFormatter}
            onKeyDown={keyDown}
            label={label}
            readOnly={readOnly}
            placeholder={placeholder}
            disabled={readOnly}
            name={name}
            className={size}
            error={!!error}
            helperText={error ? error?.message : null}
            sx={inputStyles}
            customInput={styledTextField}
            data-testid={dataTestId}
        />
    );
}
