import {
    Controller,
    ControllerRenderProps,
    FieldError,
    FieldValues,
} from 'react-hook-form';
import { InputTextFieldProps } from '@/ui/interfaces';
import defaultInputStyles from '../input-styles';
import useInputTextFieldPresenter from './input-text-field-presenter';
import StyledTextField from '../styled-text-field';
import { Row } from '../../layout';
import { HelperText } from './style';

const InputTextField = ({
    label,
    name,
    defaultValue,
    control,
    rules,
    type,
    readOnly = false,
    min,
    shrink,
    placeholder,
    dataTestId,
    maxLength,
    max,
    isMonetary = false,
    size = 'normal',
    icon,
    iconPosition = 'end',
    characterCount = false,
}: InputTextFieldProps) => {
    const { inputStyles } = defaultInputStyles(readOnly);
    const { inputMode, pattern } = useInputTextFieldPresenter({
        type,
        isMonetary,
    });

    const showIcon = () => {
        if (icon) {
            if (iconPosition === 'end') {
                return { endAdornment: icon };
            }
            return { startAdornment: icon };
        }
        return {};
    };

    const HandleHelperText = ({
        error,
        field,
    }: {
        error?: FieldError;
        field: ControllerRenderProps<FieldValues, string>;
    }) => (
        <Row justify="space-between">
            <span className="body-extra-small">
                {error ? error?.message : null}
                {characterCount &&
                    field.value &&
                    field.value?.length === maxLength && (
                        <HelperText>Limite de caracteres atingido</HelperText>
                    )}
            </span>
            {characterCount && (
                <HelperText className="body-extra-small">
                    {field.value ? field.value?.length : '0'}/{maxLength}
                </HelperText>
            )}
        </Row>
    );

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue ? defaultValue : ''}
            rules={rules}
            render={({ field, fieldState: { error } }) => {
                return (
                    <StyledTextField
                        {...field}
                        disabled={readOnly}
                        data-testid={dataTestId}
                        InputLabelProps={{ shrink: shrink }}
                        inputProps={{
                            readOnly: readOnly,
                            min,
                            max,
                            maxLength: maxLength
                                ? maxLength
                                : type != 'textarea' && 250,
                            inputMode,
                            pattern,
                        }}
                        sx={inputStyles}
                        className={size}
                        label={label}
                        type={type ? type : 'text'}
                        multiline={type === 'textarea' ? true : false}
                        minRows={type === 'textarea' ? 8 : undefined}
                        maxRows={type === 'textarea' ? 8 : undefined}
                        placeholder={placeholder}
                        error={!!error}
                        helperText={
                            <HandleHelperText error={error} field={field} />
                        }
                        required={!!rules?.required}
                        InputProps={{
                            ...showIcon(),
                        }}
                    />
                );
            }}
        />
    );
};

export default InputTextField;
