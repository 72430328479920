import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ContextType, TableIcons } from '@/domain/models';
import makeHomeOverviewPresenter from '@/main/factories/presenters/pages/home/home-overview-presenter-factory';
import { NavigationAppState, StoreState } from '@/ui/store/models';
import { store } from '@/ui/store/config';
import selectNavigationAction from '@/ui/store/modules/app/navigation/actions';
import { CircularProgress, OverviewCardContainer } from '@/ui/components';
import { FilterContext } from '@/ui/context/context-filter';
import { FilterType } from '@/ui/context/context-filter/type';
import { useOverviewQueries } from '@/ui/hooks';
import FilterLicensedModal from '../licensed-modal-filter';
import { MainCards, OverviewCharts } from './componentes';
import Default from '../../default';

const Overview = (): JSX.Element => {
    const homePresenter = makeHomeOverviewPresenter();

    const { setActivePage, getFiltersObject } = useContext(FilterContext);
    const pageFilters: FilterType<'home_overview'> = getFiltersObject();

    const isDarkTheme = useSelector((store: StoreState) => {
        return store.toggleTheme.darkTheme;
    });

    const {
        isModalOpen,
        setIsModalOpen,
        overviewData,
        isOverviewDataLoading,
        isOverviewDataFetching,
        isAllowed,
    } = useOverviewQueries();

    useEffect(() => {
        setActivePage(ContextType.home_overview);
        store.dispatch(
            selectNavigationAction(
                new NavigationAppState(
                    ContextType.home_overview,
                    ContextType.home,
                    TableIcons.HOME,
                ),
            ),
        );
    }, []);

    return (
        <Default contextType={ContextType.home_overview}>
            <FilterLicensedModal
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
            />
            {isModalOpen ? (
                <CircularProgress variant="indeterminate" />
            ) : (
                <OverviewCardContainer>
                    <MainCards
                        data={overviewData}
                        isLoading={
                            isOverviewDataLoading || isOverviewDataFetching
                        }
                        isDarkTheme={isDarkTheme}
                    />
                    <OverviewCharts
                        isAllowed={isAllowed}
                        filterParams={pageFilters}
                        homePresenter={homePresenter}
                        isDarkTheme={isDarkTheme}
                    />
                </OverviewCardContainer>
            )}
        </Default>
    );
};

export default Overview;
