import { SubmitHandler, useForm } from 'react-hook-form';
import { useContext, useEffect } from 'react';
import { Params, useNavigate, useParams } from 'react-router-dom';
import { ContextType, IProductsMixForm, TableIcons } from '@/domain/models';
import makeProductsPresenter from '@/main/factories/presenters/pages/products/product-presenter-factory';
import { NotificationContext } from '@/main';
import { InputTextField } from '@/ui/components';
import { store } from '@/ui/store/config';
import selectNavigationAction from '@/ui/store/modules/app/navigation/actions';
import Default from '../../default';
import { Column, Grid } from '@/ui/components/layout';
import { NavigationAppState } from '@/ui/store/models';

export default function ProductMixEdit(): JSX.Element {
    const { mixCode }: Readonly<Params<string>> = useParams();
    const navigate = useNavigate();

    const {
        handleSubmit,
        control,
        reset,
        formState: { isDirty, isValid },
    } = useForm<IProductsMixForm>({
        mode: 'onChange',
        defaultValues: {
            mix: '',
            name: '',
            description: '',
        },
    });

    const productsPresenter = makeProductsPresenter();

    const { successNotification, errorNotification, warningNotification } =
        useContext(NotificationContext);

    const isButtonDisabled = !isDirty || !isValid;

    const onSubmit: SubmitHandler<IProductsMixForm> = data => {
        mixCode
            ? productsPresenter
                  .updateMix(data, mixCode)
                  .then(() =>
                      successNotification('Mix atualizado com sucesso!!'),
                  )
                  .catch(err => errorNotification(err.message))
            : productsPresenter
                  .createMix(data, warningNotification)
                  .then(res => {
                      successNotification('Mix criado com sucesso!!');
                      setTimeout(() => {
                          navigate(`/product/mix/${res.data.mix}`, {
                              state: { from: 'save' },
                          });
                      }, 2700);
                  })
                  .catch(err => errorNotification(err.message));
    };

    useEffect(() => {
        if (mixCode) {
            productsPresenter.getMixById(mixCode).then(data => {
                store.dispatch(
                    selectNavigationAction(
                        new NavigationAppState(
                            ContextType.product_mix_edit,
                            ContextType.product_mix,
                            TableIcons.PRODUCTS,
                            data?.name,
                        ),
                    ),
                );

                reset({
                    mix: data?.mix,
                    name: data?.name,
                    description: data.description,
                });
            });

            return;
        }

        store.dispatch(
            selectNavigationAction(
                new NavigationAppState(
                    ContextType.product_mix_save,
                    ContextType.product_mix,
                    TableIcons.PRODUCTS,
                ),
            ),
        );
    }, []);

    return (
        <Default
            contextType={ContextType.product_save}
            isSubmitDisabled={isButtonDisabled}
        >
            <Column
                as="form"
                onSubmit={handleSubmit(onSubmit)}
                id="product-mix-form"
            >
                <h5>Dados do mix</h5>
                <Grid columns={4} gap={6} responsiveType="input">
                    <InputTextField
                        control={control}
                        name="mix"
                        label="Código"
                        readOnly={mixCode ? true : false}
                        rules={{ required: 'Código do mix é obrigatório' }}
                    />
                    <InputTextField
                        control={control}
                        name="name"
                        label="Nome"
                        rules={{ required: 'Nome do mix é obrigatório' }}
                    />
                    <InputTextField
                        control={control}
                        name="description"
                        label="Descrição"
                        rules={{
                            required: 'Descrição do mix é obrigatória',
                        }}
                    />
                </Grid>
            </Column>
        </Default>
    );
}
