export default class BackdropAppState {
    open: boolean;
    loadingVisibility: boolean;
    closeInTouch: boolean;

    constructor({
        open = false,
        loadingVisibility = true,
        closeInTouch = false,
    }) {
        this.open = open;
        this.loadingVisibility = loadingVisibility;
        this.closeInTouch = closeInTouch;
    }
}
