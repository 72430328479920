import { Close } from '@mui/icons-material';
import { Image, ImagePreviewContainer, RemoveImageButton } from './styles';

interface ImagePreviewProps {
    imageSrc: string;
    handleRemoveImage: any;
    width?: number;
    height?: number;
}

export default function ImagePreview(props: ImagePreviewProps): JSX.Element {
    const { imageSrc, handleRemoveImage, width, height } = props;

    return (
        <ImagePreviewContainer>
            <Image
                width={width && width}
                height={height && height}
                src={imageSrc}
            />
            {imageSrc && (
                <RemoveImageButton
                    type="button"
                    onClick={() => handleRemoveImage()}
                >
                    <Close />
                </RemoveImageButton>
            )}
        </ImagePreviewContainer>
    );
}
