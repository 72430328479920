import { HttpRequest, HttpResponse, HttpServerError } from '@/domain/models';
import { IHttpServicePort } from '@/infra/ports';
import TelemetryAxiosHelper from './helpers/telemetry-axios-helper';
import { AxiosError } from 'axios';
import { HttpErrorHelper } from './helpers';

export default class AxiosTelemetryServiceAdapter implements IHttpServicePort {
    async request<T>(data: HttpRequest): Promise<HttpResponse<T>> {
        return TelemetryAxiosHelper.getInstance()
            .request({
                url: data.url,
                method: data.method,
                data: data.body,
            })
            .then(response => new HttpResponse(true, response.data as T))
            .catch((error: AxiosError<HttpServerError | T>) => {
                return new HttpResponse<T>(
                    false,
                    error?.response?.data as T,
                    HttpErrorHelper.normalizeError(
                        error as AxiosError<HttpServerError>,
                        data.notify,
                    ),
                    error?.response?.status,
                );
            });
    }
}
