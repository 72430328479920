import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const StyledNavLink = styled(NavLink)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: ${props => props.theme.quaternary};
    text-decoration: none;
    margin-top: 27px;
    margin-bottom: 27px;
    margin-left: 24px;
    margin-right: 24px;
    transition: color 700ms;

    .selected {
        color: ${props => props.theme.secondary};
        transition: color 700ms;
    }
`;

export default StyledNavLink;
