import { ContextType, TableIcons } from '@/domain/models';

type TContextType = `${ContextType}`;
type TTableIcons = `${TableIcons}`;
export default class NavigationAppState {
    constructor(
        public activePage: TContextType,
        public activeContextType: TContextType,
        public activeMenu: TTableIcons,
        public pageTitle: string | null = null,
    ) {}
}
