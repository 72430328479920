import {
    CameraOrLockProblemOptions,
    IAutocomplete,
    IRefrigeratorStatusFilterForm,
    IRefrigeratorStatusListFilterParams,
    RefrigeratorActiveOptions,
} from '@/domain/models';
import { FilterContext } from '@/ui/context/context-filter';
import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';

export default function useRefrigeratorFilter(
    setFilter: Dispatch<SetStateAction<{ right: boolean }>>,
) {
    const queryClient = useQueryClient();
    const { getFilters, resetFilter, setResetFilter, updateFilters } =
        useContext(FilterContext);
    const filtersPage = getFilters();

    const licensedAutoCompleteData =
        queryClient.getQueryData<Array<IAutocomplete>>(
            'licensedAutoComplete',
        ) || [];

    const resetValues: IRefrigeratorStatusFilterForm = {
        licensedName:
            filtersPage?.licensedName && licensedAutoCompleteData
                ? licensedAutoCompleteData.find(
                      item => item.label === filtersPage?.licensedName?.label,
                  )!
                : null,
        cameraProblem: filtersPage?.cameraProblem
            ? CameraOrLockProblemOptions.find(
                  item => item.id === filtersPage?.cameraProblem?.id,
              )!
            : null,
        lockProblem: filtersPage?.lockProblem
            ? CameraOrLockProblemOptions.find(
                  item => item.id === filtersPage?.lockProblem?.id,
              )!
            : null,
        active: filtersPage?.active
            ? RefrigeratorActiveOptions.find(
                  item => item.id === filtersPage?.active?.id,
              )!
            : null,
        version: filtersPage?.version ? filtersPage?.version.id : '',
    };

    const statusForm = useForm<IRefrigeratorStatusFilterForm>({
        mode: 'all',
        defaultValues: resetValues,
    });

    const onSubmit: SubmitHandler<IRefrigeratorStatusFilterForm> = data => {
        const refrigeratorStatusFilter: IRefrigeratorStatusListFilterParams = {
            ...filtersPage,
            licensedName: data.licensedName
                ? {
                      id: data.licensedName.label.replace('&', '%26'),
                      label: data.licensedName.label,
                      name: 'licensedName',
                  }
                : null,
            cameraProblem: data.cameraProblem
                ? {
                      id: data.cameraProblem.id,
                      label: `Status da câmera: ${data.cameraProblem.label}`,
                      name: 'cameraProblem',
                  }
                : null,
            lockProblem: data.lockProblem
                ? {
                      id: data.lockProblem.id,
                      label: `Status da porta: ${data.lockProblem.label}`,
                      name: 'lockProblem',
                  }
                : null,
            active: data.active
                ? {
                      id: data.active.id,
                      label: data.active.label,
                      name: 'active',
                  }
                : null,

            version: data.version
                ? {
                      id: data.version.replaceAll('.', '-'),
                      label: `Versão: ${data.version}`,
                      name: 'version',
                  }
                : null,
        };

        updateFilters(refrigeratorStatusFilter);
        setFilter({ right: false });
        statusForm.reset();
    };

    useEffect(() => {
        statusForm.reset(resetValues);
    }, [filtersPage, licensedAutoCompleteData]);

    useEffect(() => {
        if (resetFilter) {
            statusForm.reset(resetValues);
            setResetFilter(false);
        }
    }, [resetFilter]);

    return {
        statusForm,
        onSubmit,
        licensedAutoCompleteData,
    };
}
