export default class PaginationModel {
    from: number;
    size?: number;
    total: number;
    totalPages: number;
    number: number;
    numberOfElements: number;

    constructor({
        from = 0,
        size = 0,
        total = 0,
        totalPages = 0,
        number = 0,
        numberOfElements = 0,
    }) {
        this.from = from;
        this.size = size;
        this.total = total;
        this.totalPages = totalPages;
        this.number = number;
        this.numberOfElements = numberOfElements;
    }

    static makeByTable(
        pageIndex: number,
        rowsPerPage: number,
    ): PaginationModel {
        return new PaginationModel({
            from: pageIndex * rowsPerPage,
            size: rowsPerPage,
            total: 0,
            number: 0,
        });
    }
}
