import { NumberHelper } from '@/domain/helpers';
import { IContestationOrderProduct } from '@/domain/models';
import {
    CircularProgress,
    EditIcon,
    TrashIcon,
    VerifyIcon,
} from '@/ui/components';
import {
    CustomInput,
    DeleteButton,
} from '@/ui/pages/refrigerator/list/forms/styles';
import { Row } from '@/ui/components/layout';

interface IOrderEditTableRowProps {
    orderProduct: IContestationOrderProduct;
    isEditing: boolean | string | number;
    orderProductQuantity: number | string;
    setOrderProductQuantity: (value: number | string) => void;
    handleConfirmEdit: (productId: number) => any;
    handleEnableEdit: (productId: number) => any;
    handleOpenRemoveModal: (
        orderId: number,
        productName: string,
        productId: number,
    ) => void;
    isLoadingCalculate: boolean;
    orderId: any;
}

export default function OrderEditTableRow(
    props: IOrderEditTableRowProps,
): JSX.Element {
    const {
        orderProduct,
        isEditing,
        orderProductQuantity,
        setOrderProductQuantity,
        handleConfirmEdit,
        handleEnableEdit,
        handleOpenRemoveModal,
        isLoadingCalculate,
        orderId,
    } = props;

    const editButton = (productId: any) => {
        return isEditing == productId ? (
            <DeleteButton
                data-testid="confirm_product_edit"
                onClick={() => handleConfirmEdit(productId)}
            >
                <VerifyIcon />
            </DeleteButton>
        ) : (
            <DeleteButton
                data-testid="enable_product_edit_button"
                onClick={() => handleEnableEdit(productId)}
                disabled={orderProduct.removed}
                data-tooltip-id="edit"
                data-tooltip-content="Editar produto"
            >
                <EditIcon />
            </DeleteButton>
        );
    };

    const handleEditQuantity = (e: any) => {
        const value = e.target.value;
        if (
            value === 'e' ||
            value === 'E' ||
            value === '-' ||
            value === '+' ||
            value === '.' ||
            value === ','
        ) {
            e.preventDefault();
        }
        const num = Number(value);
        if (isNaN(num)) {
            return;
        }
        if (+value < 0 || value === 0) {
            setOrderProductQuantity(1);
        } else if (+value > 999) {
            e.preventDefault();
        } else {
            setOrderProductQuantity(value);
        }
    };

    const renderProductQuantity = () => {
        if (isEditing == orderProduct.productId) {
            return (
                <CustomInput
                    onKeyDown={e => {
                        if (
                            e.key === 'e' ||
                            e.key === 'E' ||
                            e.key === '-' ||
                            e.key === '+' ||
                            e.key === '.' ||
                            e.key === ','
                        ) {
                            e.preventDefault();
                        }
                    }}
                    data-testid="edit_product_quantity_input"
                    type="number"
                    value={orderProductQuantity}
                    onChange={e => handleEditQuantity(e)}
                    step="1"
                />
            );
        } else if (
            'oldQuantity' in orderProduct &&
            orderProduct.oldQuantity != null &&
            orderProduct.oldQuantity != orderProduct.quantity
        ) {
            return (
                <td>
                    <span style={{ textDecoration: 'line-through' }}>
                        {orderProduct.oldQuantity!}
                    </span>{' '}
                    {orderProduct.quantity}
                </td>
            );
        } else {
            return <td>{orderProduct.quantity}</td>;
        }
    };

    const renderProductTotalValue = () => {
        if (
            'oldTotalValue' in orderProduct &&
            orderProduct.oldTotalValue != null &&
            orderProduct.oldTotalValue != orderProduct.totalValue
        ) {
            return (
                <td>
                    <span style={{ textDecoration: 'line-through' }}>
                        {NumberHelper.makeMoney(orderProduct.oldTotalValue!)}
                    </span>{' '}
                    {NumberHelper.makeMoney(orderProduct.totalValue)}
                </td>
            );
        } else {
            return <td>{NumberHelper.makeMoney(orderProduct.totalValue)}</td>;
        }
    };

    return (
        <>
            <tr
                className={orderProduct.removed ? 'removed' : ''}
                key={orderProduct.productId}
                data-testid="product_row"
            >
                <td>{orderProduct.name}</td>
                <td>
                    {isLoadingCalculate ? (
                        <Row align="center">
                            <CircularProgress
                                size={20}
                                variant="indeterminate"
                            />
                        </Row>
                    ) : (
                        renderProductQuantity()
                    )}
                </td>
                <td>{NumberHelper.makeMoney(orderProduct.unitaryValue)}</td>
                {isLoadingCalculate ? (
                    <td>
                        <CircularProgress size={20} variant="indeterminate" />
                    </td>
                ) : (
                    renderProductTotalValue()
                )}
                <td className="align-end icon-container">
                    {editButton(orderProduct.productId)}
                    <DeleteButton
                        data-tooltip-content="Excluir produto"
                        data-tooltip-id="exclude"
                        data-testid="delete_product_button"
                        disabled={orderProduct.removed}
                        onClick={() =>
                            handleOpenRemoveModal(
                                orderId,
                                orderProduct.name,
                                orderProduct.productId,
                            )
                        }
                    >
                        <TrashIcon />
                    </DeleteButton>
                </td>
            </tr>
        </>
    );
}
