import { Button, Modal } from '@/ui/components';

export default function ConfirmDeleteModal({
    isOpen,
    close,
    productName,
    deleteProduct,
}: {
    isOpen: boolean;
    close: () => void;
    productName: string;
    productId: number;
    deleteProduct: () => void;
}) {
    return (
        <Modal
            title="Remover produto"
            isOpen={isOpen}
            onClose={close}
            okButton={
                <Button className="base" text="Cancelar" onClick={close} />
            }
            cancelButton={<Button text="Remover" onClick={deleteProduct} />}
        >
            <div>
                Tem certeza que deseja remover o produto{' '}
                <strong>{productName}</strong>?
            </div>
        </Modal>
    );
}
