import { Grid } from '@/ui/components/layout';
import IInformationInputProps from './information-inputs-props';
import {
    AutoCompleteField,
    DatePickerField,
    InputTextField,
} from '@/ui/components';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export default function RefrigeratorData({
    control,
    watch,
    licensedAutoComplete,
    isLicensedLoading,
    isAllowedToEdit,
}: IInformationInputProps) {
    const [beginDate, endDate] = watch(['beginDate', 'endDate']);

    return (
        <>
            <h5>Dados do cooler</h5>
            <Grid
                columns={4}
                gap={{
                    row: 6,
                    column: 4,
                }}
            >
                <InputTextField
                    control={control}
                    name="code"
                    label="Código"
                    readOnly={!isAllowedToEdit}
                    rules={{
                        required: 'Código é obrigatório',
                    }}
                />
                <InputTextField
                    control={control}
                    name="device"
                    label="Dispositivo"
                    readOnly={!isAllowedToEdit}
                    rules={{
                        required: 'Dispositivo é obrigatório',
                    }}
                />
                <InputTextField
                    control={control}
                    name="location"
                    label="Localização"
                    rules={{
                        required: 'Localização é obrigatório',
                    }}
                />
                {isAllowedToEdit && (
                    <AutoCompleteField
                        control={control}
                        name="licensedId"
                        label="Licenciado"
                        options={licensedAutoComplete}
                        isLoading={isLicensedLoading}
                        rules={{
                            required: 'Licenciado é obrigatório',
                        }}
                    />
                )}
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="pt-br"
                >
                    <DatePickerField
                        control={control}
                        name="beginDate"
                        label="Período de vigência - Início"
                        readOnly={!isAllowedToEdit}
                        rules={{
                            required:
                                'Período de vigência - Início é obrigatório',
                            deps: ['endDate'],
                            validate: {
                                isLessThan: (value: any) => {
                                    if (!value || !endDate) return true;
                                    return (
                                        endDate > value ||
                                        'Data de início deve ser menor que a data de término'
                                    );
                                },
                            },
                        }}
                    />
                    <DatePickerField
                        control={control}
                        name="endDate"
                        label="Período de vigência - Fim"
                        readOnly={!isAllowedToEdit}
                        rules={{
                            required: 'Período de vigência - Fim é obrigatório',
                            deps: ['beginDate'],
                            validate: {
                                isGreaterThan: (value: any) => {
                                    if (!value || !beginDate) return true;
                                    return (
                                        beginDate < value ||
                                        'Data de término deve ser maior que a data de início'
                                    );
                                },
                            },
                        }}
                    />
                </LocalizationProvider>
            </Grid>
        </>
    );
}
