enum roles {
    ADM_FINANCIAL = 'ADM_FINANCIAL',
    ADMINISTRATIVE = 'ADMINISTRATIVE',
    ANTI_FRAUD = 'ANTI_FRAUD',
    DEVELOPER = 'DEVELOPER',
    FINANCIAL = 'FINANCIAL',
    FRANCHISEE = 'FRANCHISEE',
    FRANCHISEE_EARLY_ADOPTER = 'FRANCHISEE_EARLY_ADOPTER',
    FRANCHISEE_SUCCESS = 'FRANCHISEE_SUCCESS',
    OPERATIONAL = 'OPERATIONAL',
    PROMOTER = 'PROMOTER',
    REPOSITOR = 'REPOSITOR',
    REVIEWER = 'REVIEWER',
    TAKEANDGO = 'TAKEANDGO',
    USER = 'USER',
    PARTNER = 'PARTNER',
}

const UserRoles = new Map<string, string>([
    [roles.ADM_FINANCIAL, 'Administrativo financeiro'],
    [roles.ADMINISTRATIVE, 'Administrativo'],
    [roles.ANTI_FRAUD, 'Anti fraude'],
    [roles.DEVELOPER, 'Desenvolvedor'],
    [roles.FINANCIAL, 'Financeiro'],
    [roles.FRANCHISEE, 'Licenciado'],
    [roles.FRANCHISEE_EARLY_ADOPTER, 'Licenciado tester'],
    [roles.FRANCHISEE_SUCCESS, 'Sucesso do licenciado'],
    [roles.OPERATIONAL, 'Operação'],
    [roles.PROMOTER, 'Promotor'],
    [roles.REPOSITOR, 'Repositor'],
    [roles.REVIEWER, 'Revisor'],
    [roles.TAKEANDGO, 'Take'],
    [roles.USER, 'Usuário'],
    [roles.PARTNER, 'Parceiro'],
]);

export default UserRoles;
