import { useQueries } from 'react-query';
import { CardChart } from '@/ui/components';
import { LineBarChart } from '@/ui/components/recharts';
import { RequestQueryMaker } from '@/domain/helpers';
import RePieChart from '@/ui/components/recharts/pie-chart';
import RowsProps from './row-props';
import { NoDataFound } from '@/ui/pages/home/components';
import { ILineBarChart } from '@/domain/models';

export default function FirstRow({
    homePresenter,
    isDarkTheme,
    filterParams,
}: RowsProps) {
    const [
        {
            data: grossValueVsNetValue,
            isLoading: isGrossValueVsNetValueLoading,
            isFetching: isGrossValueVsNetValueFetching,
        },
        {
            data: grossTotalSales,
            isLoading: isGrossTotalSalesLoading,
            isFetching: isGrossTotalSalesFetching,
        },
    ] = useQueries([
        {
            queryKey: ['grossValueVsNetValue', filterParams],
            queryFn: () =>
                homePresenter.getOverviewGrossValueVsNetValueAsync(
                    RequestQueryMaker(homePresenter.getParams(filterParams)),
                ),
        },
        {
            queryKey: ['grossTotalSales', filterParams],
            queryFn: () =>
                homePresenter.getOverviewTotalGrossSalesAsync(
                    RequestQueryMaker(homePresenter.getParams(filterParams)),
                ),
        },
    ]);

    const isGrossValueVsNeValuetLoadingOrFetching =
        isGrossValueVsNetValueLoading || isGrossValueVsNetValueFetching;
    const isGrossTotalSalesLoadingOrFetching =
        isGrossTotalSalesLoading || isGrossTotalSalesFetching;

    return (
        <>
            <CardChart
                title="Produtos consumidos vs faturamento bruto"
                isDarkTheme={isDarkTheme}
                isLoading={
                    isGrossValueVsNetValueLoading ||
                    isGrossValueVsNetValueFetching
                }
                position="2-1"
            >
                <NoDataFound
                    data={
                        grossValueVsNetValue && grossValueVsNetValue.length > 0
                    }
                    isLoading={isGrossValueVsNeValuetLoadingOrFetching}
                >
                    <LineBarChart
                        lineBarChartData={
                            grossValueVsNetValue as ILineBarChart[]
                        }
                    />
                </NoDataFound>
            </CardChart>
            <CardChart
                title="Detalhe faturamento bruto"
                isDarkTheme={isDarkTheme}
                isLoading={
                    isGrossTotalSalesLoading || isGrossTotalSalesFetching
                }
                position="2-2"
            >
                <NoDataFound
                    data={homePresenter.checkIfChartContainData(
                        grossTotalSales!,
                        'pieChart',
                    )}
                    isLoading={isGrossTotalSalesLoadingOrFetching}
                >
                    <RePieChart pieChartProps={grossTotalSales} />
                </NoDataFound>
            </CardChart>
        </>
    );
}
