import { ContextType } from '@/domain/models';
import { SubmitButton } from '@/ui/components';
import { Row } from '@/ui/components/layout';
import { useBreakpoints } from '@/ui/helpers';
import { StoreState } from '@/ui/store/models';
import { ChevronLeft } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

interface ISubHeaderSubmitButtonsProps {
    form: string;
    isSubmitDisabled?: boolean;
    showOnlyReturnButton?: boolean;
}

export default function SubHeaderSubmitButtons({
    form,
    isSubmitDisabled,
    showOnlyReturnButton = false,
}: ISubHeaderSubmitButtonsProps) {
    const { isXs } = useBreakpoints();

    const navigate = useNavigate();

    const location = useLocation();

    const activePage = useSelector(
        (store: StoreState) => store.navigation.activePage,
    );

    const theme = useTheme();

    const isCreatingOrEditing =
        activePage.includes('save') || activePage.includes('edit');

    const navigateToActiveContext = () => {
        if (
            activePage == ContextType.refrigerator_save ||
            activePage == 'refrigerator_edit_product' ||
            activePage == 'refrigerator_edit'
        ) {
            navigate('/refrigerator/list');
            return;
        }

        if (location.state && location.state.from === 'save') {
            navigate(-3);
            return;
        }

        if (isCreatingOrEditing) {
            navigate(-2);
            return;
        }

        return navigate(-1);
    };

    return (
        <Row
            justify={isXs ? 'space-between' : 'flex-end'}
            width="90vw"
            align="center"
        >
            {isXs && (
                <ChevronLeft
                    scale={20}
                    onClick={navigateToActiveContext}
                    cursor="pointer"
                    sx={{ color: theme.secondary }}
                />
            )}
            {!showOnlyReturnButton && (
                <SubmitButton
                    text="Salvar"
                    disabled={isSubmitDisabled as boolean}
                    form={form}
                />
            )}
        </Row>
    );
}
