import { Route, Routes } from 'react-router-dom';
import { ContextRoutePath } from '@/domain/models';
import { AuthenticationHelper, PermissionHelper } from '@/domain/helpers';
import {
    RefrigeratorStock,
    Overview,
    Rating,
    RefrigeratorList,
    OrderList,
    PromotionList,
    PromotionSave,
    UserList,
    UserSave,
    Login,
    ProductsList,
    ProductsEdit,
    ProductsMixList,
    ProductMixEdit,
    ProductsCategoryList,
    ProductsCategoryEdit,
    OrderEdit,
    OpeningAttempts,
    LicensedList,
    NewPassword,
    PasswordRecovery,
    Financial,
    RefrigeratorForm,
    RefrigeratorStatusList,
    RefrigeratorStatusDashboard,
} from '@/ui/pages/';
import DashboardMock from '@/ui/pages/dashboard-mock';
import LicensedForm from '@/ui/pages/licenseds/licensed-form';
import ProtectedRoute from './protected-route';
import { BannerList, BannerManagement } from '@/ui/pages/banner';

export default function RouteProvider(): JSX.Element {
    const userRoles = AuthenticationHelper.getToken().auth || '';
    const permissionHelper = new PermissionHelper(userRoles);

    return (
        <Routes>
            <Route
                path={ContextRoutePath.home_overview}
                element={
                    <ProtectedRoute>
                        {permissionHelper.isCommercial() ? (
                            <DashboardMock />
                        ) : (
                            <Overview />
                        )}
                    </ProtectedRoute>
                }
            />
            <Route
                path={ContextRoutePath.home_rating}
                element={
                    <ProtectedRoute>
                        <Rating />
                    </ProtectedRoute>
                }
            />
            <Route
                path={ContextRoutePath.refrigerator_status_dashboard}
                element={
                    <ProtectedRoute>
                        <RefrigeratorStatusDashboard />
                    </ProtectedRoute>
                }
            />
            <Route
                path={ContextRoutePath.refrigerator_status_list}
                element={
                    <ProtectedRoute>
                        <RefrigeratorStatusList />
                    </ProtectedRoute>
                }
            />
            <Route
                path={ContextRoutePath.refrigerator_stock}
                element={
                    <ProtectedRoute>
                        <RefrigeratorStock />
                    </ProtectedRoute>
                }
            />
            <Route
                path={ContextRoutePath.refrigerator_list}
                element={
                    <ProtectedRoute>
                        <RefrigeratorList />
                    </ProtectedRoute>
                }
            />
            <Route
                path={ContextRoutePath.refrigerator_save}
                element={
                    <ProtectedRoute>
                        <RefrigeratorForm />
                    </ProtectedRoute>
                }
            />
            <Route
                path={ContextRoutePath.refrigerator_edit}
                element={
                    <ProtectedRoute>
                        <RefrigeratorForm />
                    </ProtectedRoute>
                }
            />
            <Route
                path={ContextRoutePath.order}
                element={
                    <ProtectedRoute>
                        <OrderList />
                    </ProtectedRoute>
                }
            />
            <Route
                path={ContextRoutePath.promotion_list}
                element={
                    <ProtectedRoute>
                        <PromotionList />
                    </ProtectedRoute>
                }
            />
            <Route
                path={ContextRoutePath.promotion_save}
                element={
                    <ProtectedRoute>
                        <PromotionSave />
                    </ProtectedRoute>
                }
            />
            <Route
                path={ContextRoutePath.promotion_edit}
                element={
                    <ProtectedRoute>
                        <PromotionSave />
                    </ProtectedRoute>
                }
            />
            <Route
                path={ContextRoutePath.user}
                element={
                    <ProtectedRoute>
                        <UserList />
                    </ProtectedRoute>
                }
            />
            <Route
                path={ContextRoutePath.user_save}
                element={
                    <ProtectedRoute>
                        <UserSave />
                    </ProtectedRoute>
                }
            />
            <Route
                path={ContextRoutePath.user_edit}
                element={
                    <ProtectedRoute>
                        <UserSave />
                    </ProtectedRoute>
                }
            />
            <Route
                path={ContextRoutePath.financial}
                element={
                    <ProtectedRoute>
                        <Financial />
                    </ProtectedRoute>
                }
            />
            <Route
                path={ContextRoutePath.product_list}
                element={
                    <ProtectedRoute>
                        <ProductsList />
                    </ProtectedRoute>
                }
            />
            <Route
                path={ContextRoutePath.product_save}
                element={
                    <ProtectedRoute>
                        <ProductsEdit />
                    </ProtectedRoute>
                }
            />
            <Route
                path={ContextRoutePath.product_edit}
                element={
                    <ProtectedRoute>
                        <ProductsEdit />
                    </ProtectedRoute>
                }
            />
            <Route
                path={ContextRoutePath.product_mix}
                element={
                    <ProtectedRoute>
                        <ProductsMixList />
                    </ProtectedRoute>
                }
            />
            <Route
                path={ContextRoutePath.product_mix_save}
                element={
                    <ProtectedRoute>
                        <ProductMixEdit />
                    </ProtectedRoute>
                }
            />
            <Route
                path={ContextRoutePath.product_mix_edit}
                element={
                    <ProtectedRoute>
                        <ProductMixEdit />
                    </ProtectedRoute>
                }
            />
            <Route
                path={ContextRoutePath.product_category}
                element={
                    <ProtectedRoute>
                        <ProductsCategoryList />
                    </ProtectedRoute>
                }
            />
            <Route
                path={ContextRoutePath.product_category_save}
                element={
                    <ProtectedRoute>
                        <ProductsCategoryEdit />
                    </ProtectedRoute>
                }
            />
            <Route
                path={ContextRoutePath.product_category_edit}
                element={
                    <ProtectedRoute>
                        <ProductsCategoryEdit />
                    </ProtectedRoute>
                }
            />
            <Route
                path={ContextRoutePath.order_edit}
                element={
                    <ProtectedRoute>
                        <OrderEdit />
                    </ProtectedRoute>
                }
            />
            <Route
                path={ContextRoutePath.monitoring_opening_attempts}
                element={
                    <ProtectedRoute>
                        <OpeningAttempts />
                    </ProtectedRoute>
                }
            />
            <Route
                path={ContextRoutePath.licensed_list}
                element={
                    <ProtectedRoute>
                        <LicensedList />
                    </ProtectedRoute>
                }
            />
            <Route
                path={ContextRoutePath.licensed_save}
                element={
                    <ProtectedRoute>
                        <LicensedForm />
                    </ProtectedRoute>
                }
            />
            <Route
                path={ContextRoutePath.licensed_edit}
                element={
                    <ProtectedRoute>
                        <LicensedForm />
                    </ProtectedRoute>
                }
            />
            <Route path={ContextRoutePath.login} element={<Login />} />
            <Route
                path={ContextRoutePath.login_with_token}
                element={<Login />}
            />
            <Route
                path={ContextRoutePath.password_recovery}
                element={<PasswordRecovery />}
            />
            <Route
                path={ContextRoutePath.password_recovery_token}
                element={<NewPassword />}
            />
            <Route
                path={ContextRoutePath.banner_save}
                element={
                    <ProtectedRoute>
                        <BannerManagement />
                    </ProtectedRoute>
                }
            />
            <Route
                path={ContextRoutePath.banner_edit}
                element={
                    <ProtectedRoute>
                        <BannerManagement />
                    </ProtectedRoute>
                }
            />
            <Route
                path={ContextRoutePath.banner_list}
                element={
                    <ProtectedRoute>
                        <BannerList />
                    </ProtectedRoute>
                }
            />
        </Routes>
    );
}
