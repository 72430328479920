interface IDesktopCardSection {
    trTitle: string;
    trDescription?: string;
    value?: number;
    grossValue?: number;
    netValue?: number;
    className?: string;
    onClick?: () => void;
    dataTestId?: string;
}

export default function DesktopTr({
    trTitle,
    trDescription,
    value,
    grossValue,
    netValue,
    className,
    onClick,
    dataTestId,
}: IDesktopCardSection): JSX.Element {
    return (
        <tr
            data-test-id={dataTestId}
            className={`content-row ${className}`}
            onClick={onClick}
        >
            <td className="bold financial-padding fixed-overflow">{trTitle}</td>
            <td className="fixed-overflow">{trDescription && trDescription}</td>
            {grossValue || grossValue == 0 ? (
                <>
                    <td className="fixed-overflow">
                        {Intl.NumberFormat('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                        }).format(grossValue!)}
                    </td>
                    <td className="bold fixed-overflow">
                        {Intl.NumberFormat('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                        }).format(netValue!)}
                    </td>
                </>
            ) : (
                <td className="bold fixed-overflow">
                    {Intl.NumberFormat('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                    }).format(+value!)}
                </td>
            )}
        </tr>
    );
}
