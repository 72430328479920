import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContextPaginationFilters, ContextType } from '@/domain/models';
import {
    AuthenticationHelper,
    PermissionHelper,
    RequestQueryMaker,
} from '@/domain/helpers';
import { makeRefrigeratorStockPresenter } from '@/main/factories';
import { NotificationContext } from '@/main';
import makeOrderListPresenter from '@/main/factories/presenters/pages/order/order-list-presenter-factory';
import { store } from '@/ui/store/config';
import { sidebarOpenAction } from '@/ui/store/modules';
import { useBreakpoints } from '@/ui/helpers';
import { OrderColumnFilter, RefrigeratorStatusColumnsFilter } from '@/ui/pages';
import {
    CoolerToggleButtonGroup,
    ToggleButton,
    FilterButton,
    Divider,
} from './styles';
import ExportBox from '../../../components/illustration/export-box';
import makeFinancialPresenter from '@/main/factories/presenters/pages/financial/finantial-presenter-factory';
import { Button } from '../../../components/input';
import { FilterContext } from '@/ui/context/context-filter';
import makeHomeRatingPresenter from '@/main/factories/presenters/pages/home/home-rating-presenter-factory';
import {
    BoxIcon,
    ColumnIcon,
    CoolerIcon,
    FilterIcon,
    GridIcon,
    ListIcon,
    SubHeaderSubmitButtons,
    SubmitButton,
} from '@/ui/components';
import { SelectedTableRowsContext } from '@/ui/context/selected-table-rows';
import Roles from '@/domain/models/helpers/roles-enum';
import { Row } from '@/ui/components/layout';
import { ChevronLeft } from '@mui/icons-material';
import { useTheme } from 'styled-components';

interface IGetFilterButtons {
    activePage: string;
    setFiltersColumn: React.Dispatch<React.SetStateAction<any>>;
    setTableColumnsFilter: React.Dispatch<React.SetStateAction<any>>;
    tableColumnsFilter: ISidebarState;
    setIsHoveringButton: React.Dispatch<React.SetStateAction<any>>;
    isHoveringButton: boolean;
    isSubmitDisabled?: boolean | boolean[];
    hiddenButtons?: boolean;
}

type ISidebarState = {
    right: boolean;
};

const getFilterButtons = ({
    activePage,
    setFiltersColumn,
    setTableColumnsFilter,
    tableColumnsFilter,
    setIsHoveringButton,
    isHoveringButton,
    isSubmitDisabled = false,
    hiddenButtons,
}: IGetFilterButtons) => {
    const navigate = useNavigate();

    const { isXs } = useBreakpoints();

    const roles = AuthenticationHelper.getToken().auth;
    const permissionHelper = new PermissionHelper(roles || '');

    const { successNotification, errorNotification } =
        useContext(NotificationContext);
    const { getFiltersObject, updateFilters } = useContext(FilterContext);
    const { selectedRows } = useContext(SelectedTableRowsContext);

    const ratingPresenter = makeHomeRatingPresenter();

    const orderPresenter = makeOrderListPresenter();

    const refrigeratorStockPresenter = makeRefrigeratorStockPresenter();

    const financialPresenter = makeFinancialPresenter();

    const filterParams = getFiltersObject();

    const theme = useTheme();

    const createPromotion = () => {
        navigate('/promotion/save');
    };

    const createProduct = () => {
        navigate('/product/save');
    };

    const createProductMix = () => {
        navigate('/product/mix/save');
    };

    const createProductCategory = () => {
        navigate('/product/category/save');
    };

    const filtersQuery = (): string => {
        const filters: {
            [key: string]: string | number | boolean;
        } = {};

        for (const key in filterParams) {
            if (!ContextPaginationFilters.get(key)) {
                filters[key] = filterParams[key];
            }
        }

        return RequestQueryMaker(filters);
    };

    const ratingOptions = [
        {
            name: 'Avaliações detalhadas',
            handleExport: () => {
                ratingPresenter
                    .getExportDetailedRatingAsync(filtersQuery())
                    .then(response => {
                        successNotification(response as string);
                    })
                    .catch(error => {
                        errorNotification(error.message);
                    });
            },
        },
    ];

    const isCreatingOrEditing =
        activePage.includes('save') || activePage.includes('edit');

    const navigateToActiveContext = () => {
        if (
            activePage == ContextType.refrigerator_save ||
            activePage == 'refrigerator_edit_product' ||
            activePage == 'refrigerator_edit'
        ) {
            navigate('/refrigerator/list');
            return;
        }

        if (location.state && location.state.from === 'save') {
            navigate(-3);
            return;
        }

        if (isCreatingOrEditing) {
            navigate(-2);
            return;
        }

        return navigate(-1);
    };

    const orderExportOptions = [
        {
            name: 'Planilha XLS',
            handleExport: () => {
                successNotification(
                    'Seu relatório está sendo gerado, e será enviado para seu email!',
                    6000,
                );
                orderPresenter.getSimpleReportXls(
                    filtersQuery(),
                    successNotification,
                );
            },
        },
        {
            name: 'Planilha XLS detalhada',
            handleExport: () => {
                successNotification(
                    'Seu relatório está sendo gerado, e será enviado para seu email!',
                );
                orderPresenter.getDetailedReportXls(
                    filtersQuery(),
                    successNotification,
                );
            },
        },
        {
            name: 'Planilha PDF',
            handleExport: () => {
                successNotification(
                    'Seu relatório está sendo gerado, e será enviado para seu email!',
                );
                orderPresenter.getSimpleReportPdf(
                    filtersQuery(),
                    successNotification,
                );
            },
        },
        {
            name: 'XML NF',
            handleExport: () => {
                store.dispatch(
                    sidebarOpenAction({
                        sidebarName: 'order_xml_nf',
                        sidebarState: { right: true },
                        elementId: '0',
                        elementData: {},
                    }),
                );
            },
        },
    ];

    const orderExportOptionsTake = [
        {
            name: 'Planilha XLS',
            handleExport: () => {
                successNotification(
                    'Seu relatório está sendo gerado, e será enviado para seu email!',
                    6000,
                );
                orderPresenter.getSimpleReportXls(
                    filtersQuery(),
                    successNotification,
                );
            },
        },
        {
            name: 'Planilha XLS detalhada',
            handleExport: () => {
                successNotification(
                    'Seu relatório está sendo gerado, e será enviado para seu email!',
                );
                orderPresenter.getDetailedReportXls(
                    filtersQuery(),
                    successNotification,
                );
            },
        },
        {
            name: 'Planilha PDF',
            handleExport: () => {
                successNotification(
                    'Seu relatório está sendo gerado, e será enviado para seu email!',
                );
                orderPresenter.getSimpleReportPdf(
                    filtersQuery(),
                    successNotification,
                );
            },
        },
        {
            name: 'XML NF',
            handleExport: () => {
                store.dispatch(
                    sidebarOpenAction({
                        sidebarName: 'order_xml_nf',
                        sidebarState: { right: true },
                        elementId: '0',
                        elementData: {},
                    }),
                );
            },
        },
        {
            name: 'Relatório de Inadimplência',

            handleExport: () => {
                store.dispatch(
                    sidebarOpenAction({
                        sidebarName: 'order_deadbeat_report',
                        sidebarState: { right: true },
                        elementId: '0',
                        elementData: {},
                    }),
                );
            },
        },
    ];

    const refrigeratorStockOptions = [
        {
            name: 'Planilha XLS detalhada',
            handleExport: () => {
                refrigeratorStockPresenter.getRefrigeratorDetailedXlsAsync(
                    filtersQuery(),
                    successNotification,
                    errorNotification,
                );
            },
        },
    ];

    const financialExportOptions = [
        {
            name: 'Relatório',
            handleExport: () => {
                financialPresenter
                    .exportFinancialReportAsync(filtersQuery())
                    .then(response => {
                        successNotification(response as string);
                    })
                    .catch(error => {
                        errorNotification(error.message);
                    });
            },
            disabled: (isSubmitDisabled as boolean[])[0],
        },
        {
            name: 'Repasse condomínio',
            handleExport: () => {
                financialPresenter
                    .exportCondominiumTransferReportAsync(filtersQuery())
                    .then(response => {
                        successNotification(response as string);
                    })
                    .catch(error => {
                        errorNotification(error.message);
                    });
            },
            disabled: (isSubmitDisabled as boolean[])[1],
        },
    ];

    const ChangeCoolersViewButtons = () => {
        const handleUpdateFilters = (activeView: string) => {
            updateFilters(
                {
                    ...filterParams,
                    activeView: {
                        id: activeView,
                        name: 'activeView',
                        label: activeView,
                    },
                    limit: {
                        id: activeView === 'stock-by-cooler' ? 10 : 25,
                        name: 'limit',
                        label: activeView === 'stock-by-cooler' ? 10 : 25,
                    },
                },
                true,
            );
        };

        return (
            <CoolerToggleButtonGroup value={activePage}>
                <ToggleButton
                    title="Estoque por cooler"
                    value={ContextType.refrigerator_stock_by_cooler}
                    onClick={() => handleUpdateFilters('stock-by-cooler')}
                >
                    <CoolerIcon />
                </ToggleButton>
                <span>|</span>
                <ToggleButton
                    title="Estoque por produto"
                    value={ContextType.refrigerator_stock_by_product}
                    onClick={() => handleUpdateFilters('stock-by-product')}
                >
                    <BoxIcon />
                </ToggleButton>
            </CoolerToggleButtonGroup>
        );
    };

    const ChangeCoolersStatusViewButtons = () => {
        return (
            <CoolerToggleButtonGroup value={activePage}>
                <ToggleButton
                    title="Dashboard"
                    value={ContextType.refrigerator_status_dashboard}
                    disabled={
                        activePage === ContextType.refrigerator_status_dashboard
                    }
                    onClick={() => navigate('../refrigerator/status/dashboard')}
                >
                    <GridIcon />
                </ToggleButton>
                <span>|</span>
                <ToggleButton
                    title="Lista"
                    value={ContextType.refrigerator_status_list}
                    disabled={
                        activePage === ContextType.refrigerator_status_list
                    }
                    onClick={() => navigate('../refrigerator/status/list')}
                >
                    <ListIcon />
                </ToggleButton>
            </CoolerToggleButtonGroup>
        );
    };

    if (hiddenButtons) {
        return <></>;
    }

    const isAllowed =
        permissionHelper.isTakeAndGo() || permissionHelper.isDeveloper();

    const handleRefundButtonClick = () =>
        store.dispatch(
            sidebarOpenAction({
                sidebarName: 'order_refund',
                sidebarState: { right: true },
                elementId: '0',
                elementData: selectedRows,
            }),
        );

    switch (activePage) {
        case ContextType.home_overview:
            return (
                <FilterButton onClick={() => setFiltersColumn({ right: true })}>
                    {!isXs && 'Filtrar'}
                    <FilterIcon />
                </FilterButton>
            );
        case ContextType.home_rating:
            return (
                <>
                    <FilterButton
                        data-testid="filter-button-open"
                        onClick={() => setFiltersColumn({ right: true })}
                    >
                        {!isXs && 'Filtrar'}
                        <FilterIcon />
                    </FilterButton>
                    <ExportBox options={ratingOptions} />
                </>
            );
        case ContextType.order:
            return (
                <>
                    {isAllowed && (
                        <>
                            <Button
                                text={`Ressarcir (${selectedRows.length})`}
                                disabled={selectedRows.length === 0}
                                onClick={handleRefundButtonClick}
                            />
                            <Divider
                                orientation="vertical"
                                flexItem
                                variant="middle"
                            />
                        </>
                    )}
                    <OrderColumnFilter
                        filter={tableColumnsFilter}
                        setFilter={setTableColumnsFilter}
                    />
                    <FilterButton
                        onMouseEnter={() => setIsHoveringButton(true)}
                        onMouseLeave={() => setIsHoveringButton(false)}
                        onClick={() => setTableColumnsFilter({ right: true })}
                    >
                        {!isXs && 'Colunas'}
                        <ColumnIcon isHovering={isHoveringButton} />
                    </FilterButton>
                    <FilterButton
                        onClick={() => setFiltersColumn({ right: true })}
                    >
                        {!isXs && 'Filtrar'}
                        <FilterIcon />
                    </FilterButton>
                    <ExportBox
                        options={
                            permissionHelper.isTakeAndGo()
                                ? orderExportOptionsTake
                                : orderExportOptions
                        }
                    />
                </>
            );
        case ContextType.refrigerator_list:
            return (
                <>
                    {isAllowed && (
                        <>
                            <Button
                                text="Novo cooler"
                                onClick={() => navigate('./save')}
                            />
                            <Divider
                                orientation="vertical"
                                flexItem
                                variant="middle"
                            />
                        </>
                    )}
                    <FilterButton
                        onClick={() => setFiltersColumn({ right: true })}
                    >
                        {!isXs && 'Filtrar'}
                        <FilterIcon />
                    </FilterButton>
                </>
            );
        case ContextType.refrigerator_edit:
            return (
                <SubHeaderSubmitButtons
                    form="refrigerator-form"
                    isSubmitDisabled={isSubmitDisabled as boolean}
                />
            );
        case ContextType.refrigerator_edit_product:
            return (
                <SubHeaderSubmitButtons
                    form="refrigerator-form"
                    isSubmitDisabled={isSubmitDisabled as boolean}
                />
            );
        case ContextType.refrigerator_save:
            return (
                <SubHeaderSubmitButtons
                    form="refrigerator-form"
                    isSubmitDisabled={isSubmitDisabled as boolean}
                />
            );
        case ContextType.refrigerator_status_list:
            return (
                <>
                    <RefrigeratorStatusColumnsFilter
                        filter={tableColumnsFilter}
                        setFilter={setTableColumnsFilter}
                    />
                    <FilterButton
                        onMouseEnter={() => setIsHoveringButton(true)}
                        onMouseLeave={() => setIsHoveringButton(false)}
                        onClick={() => setTableColumnsFilter({ right: true })}
                    >
                        {!isXs && 'Colunas'}
                        <ColumnIcon isHovering={isHoveringButton} />
                    </FilterButton>
                    <FilterButton
                        onClick={() => setFiltersColumn({ right: true })}
                    >
                        {!isXs && 'Filtrar'}
                        <FilterIcon />
                    </FilterButton>
                    <ChangeCoolersStatusViewButtons />
                </>
            );
        case ContextType.refrigerator_status_dashboard:
            return (
                <>
                    <FilterButton
                        onClick={() => setFiltersColumn({ right: true })}
                    >
                        {!isXs && 'Filtrar'}
                        <FilterIcon />
                    </FilterButton>
                    <ChangeCoolersStatusViewButtons />
                </>
            );
        case ContextType.refrigerator_stock_by_cooler:
            return (
                <>
                    <FilterButton
                        onClick={() => setFiltersColumn({ right: true })}
                    >
                        {!isXs && 'Filtrar'}
                        <FilterIcon />
                    </FilterButton>
                    <ExportBox options={refrigeratorStockOptions} />
                    <ChangeCoolersViewButtons />
                </>
            );
        case ContextType.refrigerator_stock_by_product:
            return <ChangeCoolersViewButtons />;
        case ContextType.product_list:
            return <Button onClick={createProduct} text="Novo produto" />;
        case ContextType.product_mix:
            return <Button onClick={createProductMix} text="Novo mix" />;
        case ContextType.product_category:
            return (
                <Button onClick={createProductCategory} text="Nova categoria" />
            );
        case ContextType.product_mix_save:
            return (
                <SubHeaderSubmitButtons
                    form="product-mix-form"
                    isSubmitDisabled={isSubmitDisabled as boolean}
                />
            );
        case ContextType.product_mix_edit:
            return (
                <SubHeaderSubmitButtons
                    form="product-mix-form"
                    isSubmitDisabled={isSubmitDisabled as boolean}
                />
            );
        case ContextType.product_category_save:
            return (
                <SubHeaderSubmitButtons
                    form="product-category-form"
                    isSubmitDisabled={isSubmitDisabled as boolean}
                />
            );
        case ContextType.product_category_edit:
            return (
                <SubHeaderSubmitButtons
                    form="product-category-form"
                    isSubmitDisabled={isSubmitDisabled as boolean}
                />
            );
        case ContextType.product_save:
            return (
                <SubHeaderSubmitButtons
                    form="product-form"
                    isSubmitDisabled={isSubmitDisabled as boolean}
                />
            );
        case ContextType.product_edit:
            return (
                <SubHeaderSubmitButtons
                    form="product-form"
                    isSubmitDisabled={isSubmitDisabled as boolean}
                />
            );
        case ContextType.promotion_list:
            return (
                <>
                    <Button onClick={createPromotion} text="Criar promoção" />
                    <Divider orientation="vertical" flexItem variant="middle" />
                    <FilterButton
                        onClick={() => setFiltersColumn({ right: true })}
                    >
                        {!isXs && 'Filtrar'}
                        <FilterIcon />
                    </FilterButton>
                </>
            );
        case ContextType.promotion_edit:
            return (
                <SubHeaderSubmitButtons
                    form="promotion-form"
                    isSubmitDisabled={isSubmitDisabled as boolean}
                />
            );
        case ContextType.promotion_save:
            return (
                <SubHeaderSubmitButtons
                    form="promotion-form"
                    isSubmitDisabled={isSubmitDisabled as boolean}
                />
            );
        case ContextType.user:
            return (
                <>
                    {isAllowed && (
                        <>
                            <Button
                                text="Adicionar"
                                onClick={() => navigate('/user/save')}
                            />
                            <Divider
                                orientation="vertical"
                                flexItem
                                variant="middle"
                            />
                        </>
                    )}
                    <FilterButton
                        onClick={() => setFiltersColumn({ right: true })}
                    >
                        {!isXs && 'Filtrar'}
                        <FilterIcon />
                    </FilterButton>
                </>
            );
        case ContextType.user_edit:
            return (
                <SubHeaderSubmitButtons
                    form="user-form"
                    isSubmitDisabled={isSubmitDisabled as boolean}
                />
            );
        case ContextType.user_save:
            return (
                <SubHeaderSubmitButtons
                    form="user-form"
                    isSubmitDisabled={isSubmitDisabled as boolean}
                />
            );
        case ContextType.financial:
            return (
                <>
                    {permissionHelper.hasAnyRole([Roles.ADM_FINANCIAL]) &&
                        filterParams.licensedId && (
                            <>
                                <Button
                                    dataTestId="create-negotiation"
                                    text="Criar negociação"
                                    onClick={() => {
                                        store.dispatch(
                                            sidebarOpenAction({
                                                sidebarName:
                                                    'financial_negotiation_modal',
                                                sidebarState: { right: true },
                                                elementId:
                                                    filterParams.licensedId,
                                                elementData: {},
                                            }),
                                        );
                                    }}
                                />
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    variant="middle"
                                />
                            </>
                        )}
                    <FilterButton
                        onClick={() => setFiltersColumn({ right: true })}
                    >
                        {!isXs && 'Filtrar'}
                        <FilterIcon />
                    </FilterButton>
                    {permissionHelper.isTakeAndGo() && (
                        <ExportBox options={financialExportOptions} />
                    )}
                </>
            );
        case ContextType.monitoring_opening_attempts:
            return (
                <FilterButton onClick={() => setFiltersColumn({ right: true })}>
                    {!isXs && 'Filtrar'}
                    <FilterIcon />
                </FilterButton>
            );
        case ContextType.licensed_list:
            return (
                <>
                    <Button
                        text="Adicionar"
                        onClick={() => navigate('./save')}
                    />
                    <Divider orientation="vertical" flexItem variant="middle" />
                    <FilterButton
                        onClick={() => setFiltersColumn({ right: true })}
                    >
                        {!isXs && 'Filtrar'}
                        <FilterIcon />
                    </FilterButton>
                </>
            );
        case ContextType.licensed_edit:
            return (
                <SubHeaderSubmitButtons
                    form="licensed-form"
                    isSubmitDisabled={isSubmitDisabled as boolean}
                />
            );
        case ContextType.licensed_save:
            return (
                <SubHeaderSubmitButtons
                    form="licensed-form"
                    isSubmitDisabled={isSubmitDisabled as boolean}
                />
            );
        case ContextType.order_edit:
            return (
                <SubHeaderSubmitButtons
                    form="banner-form"
                    isSubmitDisabled={isSubmitDisabled as boolean}
                    showOnlyReturnButton
                />
            );
        case ContextType.banner_save:
            return (
                <>
                    {!isXs && (
                        <SubmitButton
                            text="Salvar"
                            disabled={isSubmitDisabled as boolean}
                            form="banner-form"
                        />
                    )}
                </>
            );
        case ContextType.banner_list:
            return (
                <>
                    <Button
                        text="Novo banner"
                        onClick={() => navigate('./save')}
                    />
                    <Divider orientation="vertical" flexItem variant="middle" />
                    <FilterButton
                        onClick={() => setFiltersColumn({ right: true })}
                    >
                        {!isXs && 'Filtrar'}
                        <FilterIcon />
                    </FilterButton>
                </>
            );
        case ContextType.banner_edit:
            return (
                <>
                    {!isXs && (
                        <SubmitButton
                            text="Salvar"
                            disabled={isSubmitDisabled as boolean}
                            form="banner-form"
                        />
                    )}
                </>
            );
        default:
            break;
    }
};

export default getFilterButtons;
