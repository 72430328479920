import { Column } from 'react-table';
import { ColumnModel } from '@/presentation/models/table';

export default class TableColumnHelper {
    static makeModelToColumn(value: ColumnModel<any>[]): Column<any>[] {
        const columns = value.map(column => {
            if (column != undefined) {
                return {
                    Header: column.name,
                    accessor: column.property,
                    disableSortBy: !column.sorting,
                    Cell: v => column.renderComponent(v.value, v.row.original),
                    disableResizing: false,
                    align: column.align,
                    renderTitleCustom: column.renderTitleCustom,
                    width: column.width,
                } as Column;
            }
        });

        return columns as Column<any>[];
    }
}
