import IInformationInputProps from './information-inputs-props';
import { Grid } from '@/ui/components/layout';
import {
    AutoCompleteField,
    InputTextField,
    MaskedInputTextField,
} from '@/ui/components';
import { State } from '@/domain/models';

export default function RefrigeratorAddress({
    control,
}: IInformationInputProps) {
    return (
        <>
            <h5>Endereço do cooler</h5>
            <Grid
                columns={4}
                gap={{
                    row: 6,
                    column: 4,
                }}
            >
                <InputTextField
                    control={control}
                    name="latitude"
                    label="Latitude"
                    type="number"
                />
                <InputTextField
                    control={control}
                    name="longitude"
                    label="Longitude"
                    type="number"
                />
                <MaskedInputTextField
                    mask="99999-999"
                    control={control}
                    name="cep"
                    label="CEP"
                    rules={{
                        required: 'CEP é obrigatório',
                    }}
                />
                <InputTextField
                    control={control}
                    name="addressStreet"
                    label="Endereço"
                    rules={{
                        required: 'Endereço é obrigatório',
                    }}
                />

                <InputTextField
                    control={control}
                    name="number"
                    label="Número"
                    rules={{
                        required: 'Número é obrigatório',
                    }}
                />
                <InputTextField
                    control={control}
                    name="complement"
                    label="Complemento"
                />
                <InputTextField
                    control={control}
                    name="neighborhood"
                    label="Bairro"
                    rules={{
                        required: 'Bairro é obrigatório',
                    }}
                />
                <AutoCompleteField
                    control={control}
                    options={State.getStates()}
                    name="state"
                    label="Estado"
                    rules={{
                        required: 'Estado é obrigatório',
                    }}
                />
                <InputTextField
                    control={control}
                    name="city"
                    label="Cidade"
                    rules={{
                        required: 'Cidade é obrigatório',
                    }}
                />
            </Grid>
        </>
    );
}
