import { Controller } from 'react-hook-form';
import MaskedInput from 'react-input-mask';
import { IMaskedInputTextField } from '@/ui/interfaces';
import defaultInputStyles from '../input-styles';
import StyledTextField from '../styled-text-field';

const MaskedInputTextField = ({
    label,
    control,
    name,
    mask,
    defaultValue,
    rules,
    dataTestId,
    shrink = false,
    icon,
    iconPosition = 'end',
}: IMaskedInputTextField) => {
    const { inputStyles } = defaultInputStyles();
    const showIcon = () => {
        if (icon) {
            if (iconPosition === 'end') {
                return { endAdornment: icon };
            }
            return { startAdornment: icon };
        }
        return {};
    };

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue && defaultValue}
            rules={rules}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <MaskedInput
                    name={name}
                    value={value}
                    onChange={onChange}
                    mask={mask}
                    defaultValue={defaultValue && defaultValue}
                    maskPlaceholder={null}
                >
                    <StyledTextField
                        name={name}
                        data-testid={dataTestId}
                        defaultValue={defaultValue && defaultValue}
                        inputProps={{
                            autocomplete: 'off',
                            shrink: shrink,
                        }}
                        sx={inputStyles}
                        label={label}
                        required={!!rules?.required}
                        error={!!error}
                        helperText={error ? error.message : null}
                        InputProps={{
                            ...showIcon(),
                        }}
                    />
                </MaskedInput>
            )}
        />
    );
};

export default MaskedInputTextField;
