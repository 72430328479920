import { IRefrigeratorStatusList } from '@/domain/models';
import { Row } from '@/ui/components/layout';

export default function CameraProblemModal({
    refrigerator,
}: {
    refrigerator: IRefrigeratorStatusList;
}) {
    const handleCameraProblem = (cameraProblem: string) => {
        switch (cameraProblem) {
            case 'blurry_image':
                return 'Imagem embaçada';
            case 'cant_get_image':
                return 'Não consegue capturar imagem';
            case 'cant_connect':
                return 'Não consegue conectar';
            default:
                return '';
        }
    };

    return (
        <>
            {refrigerator.cameraOneProblem && (
                <Row>
                    <span className="subtitle">Câmera 1:</span>
                    {handleCameraProblem(refrigerator.cameraOneProblem)}
                </Row>
            )}
            {refrigerator.cameraTwoProblem && (
                <Row>
                    <span className="subtitle">Câmera 2:</span>
                    {handleCameraProblem(refrigerator.cameraTwoProblem)}
                </Row>
            )}
            {refrigerator.cameraThreeProblem && (
                <Row>
                    <span className="subtitle">Câmera 3:</span>
                    {handleCameraProblem(refrigerator.cameraThreeProblem)}
                </Row>
            )}
            {refrigerator.cameraFourProblem && (
                <Row>
                    <span className="subtitle">Câmera 4:</span>
                    {handleCameraProblem(refrigerator.cameraFourProblem)}
                </Row>
            )}
        </>
    );
}
