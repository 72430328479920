import styled from 'styled-components';

const Table = styled.table`
    width: 100%;
    height: 100%;
    margin-top: 16px;
    border-collapse: collapse;

    &.xs {
        @media (max-width: 650px) {
            table-layout: fixed;
            width: unset;
        }
    }

    &.m-0 {
        margin: 0;
    }

    &.mt {
        margin-top: 24px;
    }

    tr {
        &.financial-total {
            padding: 22px 16px;
            background: ${props => props.theme.financialReport.financialTotal};
        }

        &.financial-to-receive {
            border: none;
            padding: 22px 16px;
            background: ${props =>
                props.theme.financialReport.financialToReceive};
        }

        &.condominium-transfer {
            padding: 22px 16px;
            background: ${props =>
                props.theme.financialReport.condominiumTransfer};
        }

        th {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 140%;
            color: ${props => props.theme.quaternary};
            text-align: start;
            padding-right: 24px;
            padding-bottom: 15px;
            max-height: 50px;

            &.status-label {
                text-align: center !important;
            }

            &.placeholder-label {
                color: ${props => props.theme.primary};
            }

            &.financial-padding {
                padding-left: 16px;
            }

            @media (max-width: 650px) {
                white-space: nowrap;
            }
        }

        td {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: ${props => props.theme.tableText};
            text-align: start;
            padding: 20px 16px 20px 0;
            position: relative;

            &.fixed-overflow {
                overflow: hidden;
            }

            &.color {
                color: ${props => props.theme.secondary};
            }

            &.product-code {
                color: ${props => props.theme.secondary};
            }

            &.bold {
                font-weight: 700;
                color: ${props => props.theme.secondary};

                @media (max-width: 650px) {
                    font-weight: 400;
                }
            }

            &.financial-padding {
                padding-left: 16px;
            }

            @media (max-width: 650px) {
                margin-top: 0px;
                margin-right: 5px;
                padding-left: 5px;
                white-space: nowrap;
            }
        }

        &.content-row {
            border-top: 1px solid ${props => props.theme.tableBorder};
            border-bottom: 1px solid ${props => props.theme.tableBorder};
            cursor: pointer;
        }

        &.table-head {
            margin-top: 48px;
            margin-bottom: 25px;
        }
    }
`;

export default Table;
