import { IRefrigeratorFilterParams } from '@/domain/models';

const refrigeratorStockDefaultValues: IRefrigeratorFilterParams = {
    code: null,
    device: null,
    location: null,
    situation: null,
    status: null,
    sortDirectionStockPercentage: null,
    licensedId: null,
    activeView: {
        id: 'stock-by-cooler',
        name: 'activeView',
        label: 'stock-by-cooler',
    },
    page: {
        id: '0',
        name: 'page',
        label: '0',
    },
    limit: {
        id: 10,
        name: 'limit',
        label: '10',
    },
    column: {
        id: 'code',
        name: 'column',
        label: 'code',
    },
    sort: {
        id: 'DESC',
        name: 'sort',
        label: 'DESC',
    },
};

export default refrigeratorStockDefaultValues;
