import LikeIcon from './like-icon';
import DeslikeIcon from './deslike-icon';

export default function TogglePromotionIcon(status: any): JSX.Element {
    return status.rowData == 'ACTIVE' ? (
        <LikeIcon status={status.rowData} />
    ) : (
        <DeslikeIcon />
    );
}
