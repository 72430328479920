import StyledSvgIcon from './svg-icon.styles';

export default function AverageTicket() {
    return (
        <StyledSvgIcon
            width="20"
            height="19"
            viewBox="0 0 20 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.41662 14.167H14.441C15.2326 14.167 15.6284 14.167 15.951 14.024C16.2354 13.8979 16.4789 13.6947 16.6538 13.4375C16.8522 13.1456 16.923 12.7562 17.0646 11.9774L18.1903 5.78625C18.2394 5.5163 18.2639 5.38133 18.226 5.27594C18.1927 5.18348 18.1278 5.10575 18.0428 5.05648C17.9459 5.00033 17.8087 5.00033 17.5344 5.00033H4.16662M1.6665 1.66699H2.76351C2.96575 1.66699 3.06687 1.66699 3.14865 1.70393C3.22073 1.73649 3.28206 1.78889 3.32546 1.85502C3.3747 1.93004 3.39047 2.02992 3.42202 2.22968L5.74432 16.9376C5.77587 17.1374 5.79164 17.2373 5.84088 17.3123C5.88428 17.3784 5.94561 17.4308 6.01769 17.4634C6.09947 17.5003 6.20059 17.5003 6.40283 17.5003H15.8332"
                stroke="#00A6DB"
                fill="transparent"
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </StyledSvgIcon>
    );
}
