import { ReactNode, createContext, useMemo, useState } from 'react';

interface ISelectedTableRowsContext {
    handleSetInitialState: (rows: any[], keyField: string) => void;
    setSelectedRows: (rows: any[]) => void;
    selectedRows: any[];
    isAllSelected: boolean;
    handleAddAllRows: () => void;
    handleRemoveAllRows: () => void;
    handleAddRow: (row: any) => void;
    handleRemoveRow: (row: any) => void;
}

interface ISelectedTableRowsProvider {
    children: ReactNode;
}

export const SelectedTableRowsContext = createContext(
    {} as ISelectedTableRowsContext,
);

export function SelectedTableRowsProvider({
    children,
}: ISelectedTableRowsProvider) {
    const [initialState, setInitialState] = useState<{
        rows: any[];
        keyField: string;
    }>({
        rows: [],
        keyField: '',
    });
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const { rows, keyField } = initialState;

    const handleSetInitialState = (rows: any[], keyField: string) => {
        setInitialState({ rows, keyField });
    };

    const handleAddAllRows = () => {
        const newRows = rows.filter(
            row =>
                !selectedRows.find(
                    selectedRow => selectedRow[keyField] === row[keyField],
                ),
        );

        setSelectedRows([...selectedRows, ...newRows]);
    };

    const handleRemoveAllRows = () => {
        setSelectedRows([]);
    };

    const handleAddRow = (row: any) => {
        setSelectedRows([...selectedRows, row]);
    };

    const handleRemoveRow = (row: any) => {
        setSelectedRows(
            selectedRows.filter(
                selectedRow => selectedRow[keyField] !== row[keyField],
            ),
        );
    };

    const isAllSelected = useMemo(() => {
        return (
            selectedRows.length > 0 &&
            rows.length > 0 &&
            selectedRows.length === rows.length
        );
    }, [selectedRows, rows]);

    return (
        <SelectedTableRowsContext.Provider
            value={{
                handleSetInitialState,
                setSelectedRows,
                selectedRows,
                isAllSelected,
                handleAddAllRows,
                handleRemoveAllRows,
                handleAddRow,
                handleRemoveRow,
            }}
        >
            {children}
        </SelectedTableRowsContext.Provider>
    );
}
