export default function OrderEditTableHead(): JSX.Element {
    return (
        <thead>
            <th>Item</th>
            <th>Quantidade</th>
            <th>Valor unitário</th>
            <th>Valor total</th>
            <th></th>
        </thead>
    );
}
