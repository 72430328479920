import { useTheme } from 'styled-components';

export const checkboxStyles = (readOnly: boolean, editCoolers?: boolean) => {
    const theme = useTheme();
    if (editCoolers) {
        return {
            color: readOnly ? theme.lines : theme.secondary,
            paddingLeft: 1.6,
            fontSize: '14px',
            '& .Mui-checked': {
                color: '#ffdd3f !important',
            },
            '.MuiFormControlLabel-label': {
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                overflow: 'hidden',
                '-webkit-line-clamp': '2',
                '-webkit-box-orient': 'vertical',
            },
            '.MuiFormControlLabel-label.Mui-disabled': {
                color: theme.lines,
            },
        };
    }

    return {
        color: readOnly ? theme.lines : theme.quaternary,
        paddingLeft: 1.6,
        fontSize: '14px',
        '& .Mui-checked': {
            color: '#ffdd3f !important',
        },
    };
};
