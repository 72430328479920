import styled from 'styled-components';

export const SubmitInput = styled.input`
    padding: 14px 16px;
    background: #fbcc0a;
    border-radius: 8px;
    border: none;
    font-size: 14px;
    cursor: pointer;
    line-height: 140%;
    box-shadow: ${props => props.theme.cardBoxShadow};
    white-space: nowrap;

    &:hover {
        background: #b78c00;
    }

    &:disabled {
        cursor: not-allowed;
    }
`;
