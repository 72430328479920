import { UserState } from '@/ui/store/models';
import { Types, UserAppAction } from './types';

export default function user(
    state = new UserState('', '', '', '', '', '', 0),
    action: UserAppAction,
): UserState {
    switch (action.type) {
        case Types.STORE_USER_DATA:
            return action.payload.data;
        default:
            return state;
    }
}
