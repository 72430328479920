export default class WindowHelper {
    static openNewTab(url: string): void {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    }

    static redirect(href: string): void {
        window.location.href = href;
    }
}
