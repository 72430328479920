import {
    Button,
    ControlledMonetaryTextField,
    InputTextField,
    Modal,
    RadioGroupInput,
} from '@/ui/components';
import { Column, Grid, Row } from '@/ui/components/layout';
import useWalletBallancePresenter from './use-wallet-ballance-presenter';
import { useState } from 'react';

export default function WalletBalance({
    userId,
    walletBalance,
}: {
    userId: string;
    walletBalance: number;
}) {
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

    const {
        walletForm,
        onSubmit,
        radios,
        calculateNewBalance,
        isFutureValueNegative,
        isUpdatingWallet,
    } = useWalletBallancePresenter(userId, walletBalance);

    const {
        control,
        watch,
        formState: { isDirty, isValid, isSubmitting },
    } = walletForm;

    const [isCredit, value] = watch(['isCredit', 'value']);

    const isDisabled = !isValid || !isDirty || isSubmitting || isUpdatingWallet;

    const handleConfirmButton = () => {
        setIsConfirmModalOpen(false);
        return onSubmit();
    };

    return (
        <Column gap={6}>
            <h5>Wallet</h5>
            <Grid
                columns={4}
                gap={{
                    row: 6,
                    column: 4,
                }}
            >
                <RadioGroupInput
                    required
                    control={control}
                    radios={radios}
                    name="isCredit"
                    flexDirection="row"
                    paddingLeft="2px"
                />
                <ControlledMonetaryTextField
                    label="Valor"
                    name="value"
                    control={control}
                    rules={{
                        required: 'Campo obrigatório',
                        validate: {
                            isFutureValueNegative,
                        },
                    }}
                    dataTestId="wallet-value-input"
                />
            </Grid>
            <Grid columns={4}>
                <Row gap={2}>
                    Saldo atual:
                    <span data-testid="wallet-balance">
                        {walletBalance.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                        })}
                    </span>
                </Row>
                <Row gap={2}>
                    Novo saldo:
                    <span data-testid="wallet-new-balance">
                        {calculateNewBalance()}
                    </span>
                </Row>
            </Grid>
            <Grid columns={2}>
                <InputTextField
                    label="Descrição"
                    name="description"
                    control={control}
                    rules={{
                        required: 'Campo obrigatório',
                    }}
                    type="textarea"
                    dataTestId="wallet-description-input"
                />
            </Grid>
            <Grid columns={2}>
                <Row align="center" justify="end">
                    <Button
                        text={isCredit === 'add' ? 'Adicionar' : 'Remover'}
                        onClick={() => setIsConfirmModalOpen(true)}
                        disabled={isDisabled}
                        type="button"
                        dataTestId="wallet-submit-button"
                    />
                </Row>
            </Grid>
            <Modal
                isOpen={isConfirmModalOpen}
                onClose={() => setIsConfirmModalOpen(false)}
                title="Atualizar saldo"
                cancelButton={
                    <Button
                        text="Cancelar"
                        onClick={() => setIsConfirmModalOpen(false)}
                        className="outlined"
                        type="button"
                        dataTestId="cancel-button"
                    />
                }
                okButton={
                    <Button
                        text="Confirmar"
                        onClick={handleConfirmButton}
                        type="button"
                        dataTestId="confirm-button"
                        disabled={isDisabled}
                    />
                }
                content={
                    <p>
                        Tem certeza que deseja{' '}
                        {isCredit === 'add' ? 'adicionar' : 'remover'}{' '}
                        <strong>
                            {value.toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                            })}
                        </strong>{' '}
                        {isCredit === 'add' ? 'ao ' : 'do '}
                        saldo?
                    </p>
                }
            />
        </Column>
    );
}
