import { ContextType } from './context-type';

const ContextSearchPlaceholder = new Map<string, string>([
    [ContextType.none, ''],
    [ContextType.order, 'Pesquisar por pedido'],
    [ContextType.refrigerator_stock_by_cooler, 'Pesquisar por localização'],
    [ContextType.refrigerator_stock_by_product, 'Pesquisar por nome'],
    [ContextType.refrigerator_list, 'Pesquisar por localização'],
    [ContextType.refrigerator_status_list, 'Pesquisar por localização'],
    [ContextType.refrigerator_status_dashboard, 'Pesquisar por localização'],
    [ContextType.promotion_list, 'Pesquisar por nome'],
    [ContextType.user, 'Pesquisar por nome'],
    [ContextType.product_list, 'Pesquisar por nome'],
    [ContextType.product_mix, 'Pesquisar por nome'],
    [ContextType.product_category, 'Pesquisar por descrição'],
    [ContextType.monitoring_opening_attempts, 'Pesquisar por usuário'],
    [ContextType.licensed_list, 'Pesquisar por nome'],
]);

export default ContextSearchPlaceholder;
