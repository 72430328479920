import styled from 'styled-components';
import {
    ToggleButtonGroup,
    ToggleButton as ToggleButtonBase,
    Divider as DividerBase,
} from '@mui/material';
import { Row } from '../../../components/layout';

interface ITitleContainer {
    menu?: any;
}

export const TitleContainer = styled(Row)<ITitleContainer>`
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    @media (max-width: 650px) {
        margin-bottom: 20px;
        margin-top: ${props => {
            if (
                props.menu === 'financial' ||
                props.menu === 'order' ||
                props.menu === 'promotion' ||
                props.menu === 'user'
            )
                return '40px';
            else if (
                props.menu === 'refrigerator_edit_product' ||
                props.menu === 'refrigerator_edit' ||
                props.menu === 'promotion_edit' ||
                props.menu === 'promotion_save' ||
                props.menu === 'user_edit'
            )
                return 'none';
            else return '120px';
        }};
    }
`;

type Props = React.HTMLAttributes<HTMLButtonElement>;

export const FilterButton = styled.button<Props>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px 16px;
    gap: 8px;
    background: ${props => props.theme.cardBg};
    border-radius: 8px;
    border: none;
    color: ${props => props.theme.secondary};
    font-size: 14px;
    cursor: pointer;
    box-shadow: ${props => props.theme.cardBoxShadow};

    &:disabled {
        cursor: not-allowed;
    }

    &:hover {
        color: ${props => props.theme.secondary};
        background: ${props => props.theme.filterButtonHover};

        svg {
            color: ${props => props.theme.filterButtonHover.background};
        }
    }

    @media (max-width: 650px) {
        font-size: 12px;
        margin-left: 4px;
        margin-right: 4px;
        &.xs {
            padding: 2px 8px;
            height: 40px;
        }
    }
`;

export const ToggleButton = styled(ToggleButtonBase)`
    padding: 0 !important;
    border: none !important;
    background-color: transparent !important;
    svg {
        fill: none;
        background-color: transparent;
        stroke: ${props => props.theme.quaternary} !important;
    }

    &.Mui-selected {
        svg {
            stroke: ${props => props.theme.secondary} !important;
            fill: none;
            background-color: transparent;
        }
    }
`;

export const CoolerToggleButtonGroup = styled(ToggleButtonGroup)`
    background-color: ${({ theme }) => theme.cardBg} !important;
    padding: 14px 16px;
    border-radius: 8px !important;
    align-items: center;
    gap: 16px;
    color: ${({ theme }) => theme.quaternary} !important;
`;

export const Divider = styled(DividerBase)`
    background-color: ${({ theme }) => theme.lines};
    margin-left: 0 !important;
`;
