import { IFilters } from '../context-filter/type';
import financialDefaultValues from './financial';
import homeOverviewDefaultValues from './home-overview';
import homeRatingDefaultValues from './home-rating';
import licensedDefaultValue from './licensed';
import monitoringOpeningAttemptsDefaultValues from './monitorin-oppening-attempts';
import orderDefaultValues from './order';
import productDefaultValues from './product-list';
import promotionDefaultValues from './promotion';
import refrigeratorListDefaultValues from './refrigerator-list';
import refrigeratorStockDefaultValues from './refrigerator-stock';
import refrigeratorStatusListDefaultValues from './refrigerator-status-list';
import refrigeratorStatusDashboardDefaultValues from './refrigerator-status-dashboard';
import userDefaultValues from './user';
import bannerDefaultValues from './banner-list';

const defaultFilterValues: IFilters = {
    refrigerator_list: refrigeratorListDefaultValues,
    refrigerator_stock: refrigeratorStockDefaultValues,
    refrigerator_status_list: refrigeratorStatusListDefaultValues,
    refrigerator_status_dashboard: refrigeratorStatusDashboardDefaultValues,
    home_overview: homeOverviewDefaultValues,
    home_rating: homeRatingDefaultValues,
    financial: financialDefaultValues,
    order: orderDefaultValues,
    promotion: promotionDefaultValues,
    user: userDefaultValues,
    monitoring_opening_attempts: monitoringOpeningAttemptsDefaultValues,
    licensed: licensedDefaultValue,
    product_list: productDefaultValues,
    product_category: productDefaultValues,
    product_mix: productDefaultValues,
    banner_list: bannerDefaultValues,
};

export default defaultFilterValues;
