import StyledSvgIcon from './svg-icon.styles';

export default function CoolerIcon() {
    return (
        <StyledSvgIcon
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill={'none'}
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="cooler">
                <path
                    id="Icon"
                    d="M4 10V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.11984 22 8.8 22H15.2C16.8802 22 17.7202 22 18.362 21.673C18.9265 21.3854 19.3854 20.9265 19.673 20.362C20 19.7202 20 18.8802 20 17.2V10M4 10V6.8C4 5.11984 4 4.27976 4.32698 3.63803C4.6146 3.07354 5.07354 2.6146 5.63803 2.32698C6.27976 2 7.11984 2 8.8 2H15.2C16.8802 2 17.7202 2 18.362 2.32698C18.9265 2.6146 19.3854 3.07354 19.673 3.63803C20 4.27976 20 5.11984 20 6.8V10M4 10H20"
                    strokeWidth="1.75"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill={'none'}
                />
                <path
                    id="Vector 1"
                    d="M17 13H14"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill={'none'}
                />
            </g>
        </StyledSvgIcon>
    );
}
