import { SelectedRowsAppState } from '@/ui/store/models';
import { SelectedRowsAppAction, Types } from './types';

export default function selectedRows(
    state = new SelectedRowsAppState([]),
    action: SelectedRowsAppAction,
): SelectedRowsAppState {
    switch (action.type) {
        case Types.SELECTED_ROWS:
            return action.payload.data;
        default:
            return state;
    }
}
