import styled from 'styled-components';

const DashboardTable = styled.table`
    width: 100%;
    margin-top: 70px;

    th {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
        color: ${props => props.theme.chartLegend};
        text-align: start;

        &.align-end {
            text-align: end;
        }
    }

    td {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        text-align: start;
        line-height: 48px;

        &.gray {
            color: ${props => props.theme.chartLegend};
        }

        &.align-end {
            text-align: end;
        }

        &.white {
            color: '#FFFFFF';
        }
    }

    @media (max-width: 650px) {
        margin-top: 34px;

        td {
            font-size: 12.5px;
        }
    }
`;

export default DashboardTable;
