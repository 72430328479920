import {
    HttpMethod,
    HttpRequest,
    HttpResponse,
    RatingHttpEndpoint,
} from '@/domain/models/services';
import { IHttpServicePort } from '@/infra/ports';
import IDashboardRatingRepositoryPort from '@/infra/ports/repositories/dashboard-rating-repository';

export class DashboardRatingRepositoryAdapter
    implements IDashboardRatingRepositoryPort
{
    constructor(private readonly httpServicePort: IHttpServicePort) {}

    async getAverageAndTotalRatingsAsync<T>(
        params?: string | undefined,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: RatingHttpEndpoint.RATING_AVERAGE_AND_TOTAL_RATINGS + params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getImprovementsPointsSuggestionsAsync<T>(
        params?: string | undefined,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url:
                RatingHttpEndpoint.RATING_IMPROVEMENTS_POINTS_SUGGESTIONS +
                params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getRatingsAverageQuantityByCoolerAsync<T>(
        params?: string | undefined,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url:
                RatingHttpEndpoint.RATING_AVERAGE_AND_QUANTITY_BY_COOLER +
                params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getRatingsAverageQuantityByDateAsync<T>(
        params?: string | undefined,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url:
                RatingHttpEndpoint.RATING_AVERAGE_AND_QUANTITY_BY_DATE_LAST_SIX_MONTHS +
                params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getRatingAverageTickerByPublicAsync<T>(
        params?: string | undefined,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: '/api/v1/dashboards/users/average-ticket-by-gender' + params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getRatingTopUsersAsync<T>(
        params?: string | undefined,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: '/api/v1/dashboards/users/top-five' + params,
            method: HttpMethod.get,
            auth: true,
        });
        return await this.httpServicePort.request<T>(request);
    }

    async getUserStatisticsAsync<T>(
        params?: string | undefined,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url:
                '/api/v1/dashboards/users/users-activities-quantity-by-classification' +
                params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getExportDetailedRatingAsync<T>(
        params?: string | undefined,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: '/api/v1/dashboards/evaluations/export/xls' + params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async hasExportDetailedRatingInProgress<T>(): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: '/api/v1/dashboards/evaluations/export/in-progress',
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }
}
