import styled from 'styled-components';
import { Tabs } from '@mui/material';

export const DefaultMuiTabs = styled(Tabs)`
    .MuiTabs-indicator {
        display: flex;
        justify-content: center;
        background-color: transparent;
        position: relative;
    }

    .MuiTabs-indicator::after {
        content: '';
        position: absolute;
        width: 24px;
        height: 2px;
        background-color: ${props => `${props.theme.tertiary}`};
        border-radius: 5px 5px 0px 0px;
    }

    .selected {
        color: ${props => `${props.theme.secondary}!important`};
    }
`;
