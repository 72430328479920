import { useContext, useEffect } from 'react';
import { ContextType, TableIcons } from '@/domain/models';
import makePromotionTablePresenter from '@/main/factories/presenters/pages/promotion/promotion-table-presenter-factory';
import { TableServerSide } from '@/ui/components/table';
import { store } from '@/ui/store/config';
import { FilterContext } from '@/ui/context/context-filter';
import selectNavigationAction from '@/ui/store/modules/app/navigation/actions';
import Default from '../default';
import { NavigationAppState } from '@/ui/store/models';

export default function PromotionList(): JSX.Element {
    const { setActivePage } = useContext(FilterContext);
    const promotionTablePresenter = makePromotionTablePresenter();

    useEffect(() => {
        setActivePage(ContextType.promotion_list);
        store.dispatch(
            selectNavigationAction(
                new NavigationAppState(
                    ContextType.promotion_list,
                    ContextType.promotion_list,
                    TableIcons.PROMOTION,
                ),
            ),
        );
    }, []);

    return (
        <Default contextType={ContextType.promotion_list}>
            <TableServerSide
                presenter={promotionTablePresenter}
                rowProps={{
                    enableRowActions: true,
                    identityPropertyName: 'id',
                    rowActions: promotionTablePresenter.makeRowActions(),
                }}
                paginationProps={{
                    enablePagination: true,
                    pageIndex: 0,
                    rowsPerPage: 25,
                    rowsPerPageOptions: [25, 50, 75, 100],
                }}
            />
        </Default>
    );
}
