import { useContext, useEffect } from 'react';
import { ContextType, TableIcons } from '@/domain/models';
import { makeUserTablePresenter } from '@/main/factories/presenters/pages';
import selectNavigationAction from '@/ui/store/modules/app/navigation/actions';
import { FilterContext } from '@/ui/context/context-filter';
import { store } from '@/ui/store/config';
import { TableServerSide } from '@/ui/components';
import Default from '../default';
import { ChangeActiveModal, ResetUserPasswordModal } from './components';
import { NavigationAppState } from '@/ui/store/models';

export default function UserList() {
    const { setActivePage } = useContext(FilterContext);

    const userTablePresenter = makeUserTablePresenter();

    useEffect(() => {
        setActivePage(ContextType.user);
        store.dispatch(
            selectNavigationAction(
                new NavigationAppState(
                    ContextType.user,
                    ContextType.user,
                    TableIcons.USER,
                ),
            ),
        );
    }, []);

    return (
        <Default contextType={ContextType.user}>
            <ChangeActiveModal />
            <ResetUserPasswordModal />
            <TableServerSide
                presenter={userTablePresenter}
                rowProps={{
                    enableRowActions: true,
                    identityPropertyName: 'id',
                    rowActions: userTablePresenter.makeRowActions(),
                    moreActionsMenu: userTablePresenter.moreActionsMenu,
                }}
                paginationProps={{
                    enablePagination: true,
                    pageIndex: 0,
                    rowsPerPage: 25,
                    rowsPerPageOptions: [25, 50, 75, 100],
                }}
            />
        </Default>
    );
}
