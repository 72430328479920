enum Roles {
    ADM_FINANCIAL = 'ADM_FINANCIAL',
    ADMINISTRATIVE = 'ADMINISTRATIVE',
    ANTI_FRAUD = 'ANTI_FRAUD',
    COMMERCIAL = 'COMMERCIAL',
    DEVELOPER = 'DEVELOPER',
    FINANCIAL = 'FINANCIAL',
    FRANCHISEE = 'FRANCHISEE',
    FRANCHISEE_EARLY_ADOPTER = 'FRANCHISEE_EARLY_ADOPTER',
    FRANCHISEE_SUCCESS = 'FRANCHISEE_SUCCESS',
    OPERATIONAL = 'OPERATIONAL',
    PROMOTER = 'PROMOTER',
    REPOSITOR = 'REPOSITOR',
    REVIEWER = 'REVIEWER',
    TAKEANDGO = 'TAKEANDGO',
    USER = 'USER',
    PARTNER = 'PARTNER',
}

export default Roles;
