/* eslint-disable */
import { useEffect, useState } from 'react';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { ContextType, IProductDetailData, TableIcons } from '@/domain/models';
import { makeRefrigeratorStockPresenter } from '@/main/factories';
import { CircularProgress, Pagination } from '@/ui/components';
import {
    NoResultFountTd,
    ProductStockTable,
    ProductStockTableBody,
    TableContainerStockProduct,
} from '../styles';
import {
    StockProductDetailRow,
    StockProductRow,
    StockProductTableHead,
} from './stock-product';
import StockProductDetailTableHead from './stock-product/stock-product-detail-table-row';
import { useQuery } from 'react-query';
import EditValueModal from './edit-value-modal';
import ConfirmValueModal from './confirm-value-modal';
import { Row } from '@/ui/components/layout';
import { NavigationAppState } from '@/ui/store/models';
import selectNavigationAction from '@/ui/store/modules/app/navigation/actions';
import { store } from '@/ui/store/config';

interface colapseState {
    isOpen: boolean;
    activeProductColapse: string;
}

export default function RefrigeratorStockProduct({
    data,
    loading,
    pageFilters,
    handleChangePage,
    handleChangeRowsPerPage,
    refetchData,
    setActivePage,
}: any): JSX.Element {
    const [showProductDetail, setShowProductDetail] = useState<colapseState>({
        isOpen: false,
        activeProductColapse: '',
    });
    const [isEditValueModalOpen, setIsEditValueModalOpen] = useState(false);
    const [isConfirmValueModalOpen, setIsConfirmValueModalOpen] =
        useState(false);
    const [editValueModalData, setEditValueModalData] = useState<any>({});
    const [confirmValueModalPayload, setConfirmValueModalPayload] =
        useState<any>({});
    const [parent, enableAnimations] = useAutoAnimate({
        duration: 300,
    });

    const refrigeratorStockPresenter = makeRefrigeratorStockPresenter();

    const { data: productDetailData, isLoading: productDetailLoading } =
        useQuery(
            [
                'refrigeratorStockProduct',
                showProductDetail.activeProductColapse,
            ],
            () =>
                refrigeratorStockPresenter.getRefrigeratorProductStockByProductIdkAsync(
                    showProductDetail.activeProductColapse,
                ),
            {
                enabled: showProductDetail.activeProductColapse != '',
            },
        );

    const handleColapse = (productName: string) => {
        if (
            showProductDetail.isOpen === true &&
            showProductDetail.activeProductColapse != productName
        ) {
            setShowProductDetail({
                isOpen: showProductDetail.isOpen,
                activeProductColapse: productName,
            });
        } else if (
            showProductDetail.isOpen === true &&
            showProductDetail.activeProductColapse == productName
        ) {
            setShowProductDetail({
                isOpen: !showProductDetail.isOpen,
                activeProductColapse: '',
            });
        } else {
            setShowProductDetail({
                isOpen: !showProductDetail.isOpen,
                activeProductColapse: productName,
            });
        }
    };

    useEffect(() => {
        setActivePage(ContextType.refrigerator_stock);
        store.dispatch(
            selectNavigationAction(
                new NavigationAppState(
                    ContextType.refrigerator_stock_by_product,
                    ContextType.refrigerator_stock,
                    TableIcons.REFRIGERATOR,
                ),
            ),
        );
    }, []);

    useEffect(() => {
        if (loading) {
            enableAnimations(false);
        }
        if (showProductDetail.isOpen === true) {
            enableAnimations(true);
        } else {
            enableAnimations(false);
        }
    }, [showProductDetail.isOpen, loading]);

    const handleEditValueModal = (product: any, refrigeratorCode?: any) => {
        setEditValueModalData({
            product,
            refrigeratorCode,
        });
        setIsEditValueModalOpen(true);
    };
    6;

    const handleConfirmValueModal = (payload: any) => {
        setConfirmValueModalPayload(payload);
        setIsConfirmValueModalOpen(true);
    };

    return (
        <>
            <Row align="end" justify="end">
                {data && (
                    <Pagination
                        count={data.totalElements}
                        page={+pageFilters.page}
                        onPageChange={handleChangePage}
                        rowsPerPage={pageFilters.limit as number}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
            </Row>
            <TableContainerStockProduct>
                <ProductStockTable>
                    <col width="25%" />
                    {Array.from(Array(8).keys()).map(i => (
                        <col key={'columns' + i} width="10%" />
                    ))}
                    <StockProductTableHead />
                    {!loading && data ? (
                        <ProductStockTableBody ref={parent}>
                            {data &&
                                Array.isArray(data.stockData) &&
                                data.stockData.map(
                                    (product: any, index: number) => {
                                        return (
                                            <>
                                                <StockProductRow
                                                    key={
                                                        product.productId +
                                                        index
                                                    }
                                                    handleColapse={
                                                        handleColapse
                                                    }
                                                    product={product}
                                                    showProductDetail={
                                                        showProductDetail
                                                    }
                                                    handleModalOpen={
                                                        handleEditValueModal
                                                    }
                                                />
                                                {showProductDetail.activeProductColapse ==
                                                    product.productId &&
                                                    showProductDetail.isOpen && (
                                                        <tr
                                                            key={
                                                                index +
                                                                product.productId
                                                            }
                                                        >
                                                            {!productDetailLoading &&
                                                                productDetailData?.length! ==
                                                                    0 && (
                                                                    <NoResultFountTd
                                                                        colSpan={
                                                                            5
                                                                        }
                                                                    >
                                                                        Nenhum
                                                                        resultado
                                                                        encontrado!
                                                                    </NoResultFountTd>
                                                                )}
                                                            {productDetailLoading && (
                                                                <NoResultFountTd
                                                                    colSpan={5}
                                                                >
                                                                    <CircularProgress variant="indeterminate" />
                                                                </NoResultFountTd>
                                                            )}
                                                        </tr>
                                                    )}
                                                {showProductDetail.activeProductColapse ==
                                                    product.productId &&
                                                    showProductDetail.isOpen &&
                                                    !productDetailLoading &&
                                                    productDetailData?.length! >
                                                        0 && (
                                                        <StockProductDetailTableHead />
                                                    )}
                                                {showProductDetail.activeProductColapse ==
                                                    product.productId &&
                                                    showProductDetail.isOpen &&
                                                    !productDetailLoading &&
                                                    productDetailData?.length! >
                                                        0 &&
                                                    productDetailData!.map(
                                                        (
                                                            productDetail: IProductDetailData,
                                                            index: number,
                                                        ) => (
                                                            <StockProductDetailRow
                                                                key={
                                                                    index +
                                                                    productDetail.refrigeratorCode
                                                                }
                                                                productDetail={
                                                                    productDetail
                                                                }
                                                                productId={
                                                                    product.productId
                                                                }
                                                                productName={
                                                                    product.productName
                                                                }
                                                                confirmModal={
                                                                    handleConfirmValueModal
                                                                }
                                                            />
                                                        ),
                                                    )}
                                            </>
                                        );
                                    },
                                )}
                        </ProductStockTableBody>
                    ) : (
                        <tr>
                            <td colSpan={9}>
                                <CircularProgress variant="indeterminate" />
                            </td>
                        </tr>
                    )}
                </ProductStockTable>
            </TableContainerStockProduct>
            {isEditValueModalOpen && (
                <EditValueModal
                    isOpen={isEditValueModalOpen}
                    setIsOpen={setIsEditValueModalOpen}
                    product={editValueModalData.product}
                    refrigeratorCode={editValueModalData.refrigeratorCode}
                    confirmModal={handleConfirmValueModal}
                />
            )}
            {isConfirmValueModalOpen && (
                <ConfirmValueModal
                    isOpen={isConfirmValueModalOpen}
                    setIsOpen={setIsConfirmValueModalOpen}
                    payload={confirmValueModalPayload}
                    refetchData={refetchData}
                />
            )}
        </>
    );
}
