import StyledSvgIcon from './svg-icon.styles';

export default function EmailInputIcon() {
    return (
        <StyledSvgIcon
            width="18"
            height="17"
            viewBox="0 0 18 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.5 15.6667C3.44649 13.6021 6.08918 12.3333 9 12.3333C11.9108 12.3333 14.5535 13.6021 16.5 15.6667M12.75 5.25C12.75 7.32107 11.0711 9 9 9C6.92893 9 5.25 7.32107 5.25 5.25C5.25 3.17893 6.92893 1.5 9 1.5C11.0711 1.5 12.75 3.17893 12.75 5.25Z"
                stroke="#0C0407"
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
                color="#ffff"
            />
        </StyledSvgIcon>
    );
}
