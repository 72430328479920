import {
    ContextType,
    IRefrigeratorStatusIndicators,
    IRefrigeratorStatusList,
    IRefrigeratorStatusListResponse,
    TableIcons,
} from '@/domain/models';
import Default from '../../../default';
import { useContext, useEffect, useState } from 'react';
import { store } from '@/ui/store/config';
import { FilterContext } from '@/ui/context/context-filter';
import selectNavigationAction from '@/ui/store/modules/app/navigation/actions';
import { NavigationAppState, StoreState } from '@/ui/store/models';
import { CameraProblemModal, IndicatorsCards } from '../components';
import { useSelector } from 'react-redux';
import makeRefrigeratorStatusPresenter from '@/main/factories/presenters/pages/refrigerator/refrigerator-status-presenter-factory';
import DashboardCard from './dashboard-cards';
import { Column, Grid } from '@/ui/components/layout';
import { CardContainer, CircularProgress, Modal } from '@/ui/components';
import { useInView } from 'react-intersection-observer';
import { useBreakpoints } from '@/ui/helpers';

export default function RefrigeratorStatusDashboard() {
    const [isModalOpen, setIsModalOpen] = useState({
        open: false,
        refrigerator: {} as IRefrigeratorStatusList,
    });

    const {
        setActivePage,
        getFilters,
        getFiltersObject,
        setShouldReRenderTable,
    } = useContext(FilterContext);
    const pageFilters = getFilters();

    const isDarkTheme = useSelector(
        (state: StoreState) => state.toggleTheme.darkTheme,
    );

    useEffect(() => {
        setActivePage(ContextType.refrigerator_status_dashboard);
        store.dispatch(
            selectNavigationAction(
                new NavigationAppState(
                    ContextType.refrigerator_status_dashboard,
                    ContextType.refrigerator_status,
                    TableIcons.REFRIGERATOR,
                ),
            ),
        );
    }, []);

    const { getStatusList } = makeRefrigeratorStatusPresenter();

    const {
        data: statusListResponse,
        isSuccess: isStatusListSuccess,
        isLoading: isStatusListLoading,
        isRefetching: isStatusListRefetching,
        hasNextPage: isStatusListHasNextPage,
        fetchNextPage: fetchNextStatusListPage,
        isFetchingNextPage: isStatusListFetchingNextPage,
        isFetching: isStatusListFetching,
        refetch: refetchStatusList,
    } = getStatusList(getFiltersObject, setShouldReRenderTable);
    const statusListPages = isStatusListSuccess ? statusListResponse.pages : [];
    const indicatorsData: IRefrigeratorStatusIndicators =
        Array.isArray(statusListResponse?.pages) &&
        statusListResponse.pages.length > 0 &&
        statusListResponse.pages[0].data.coolerStatusConsolidated
            ? (
                  statusListResponse.pages[0]
                      .data as IRefrigeratorStatusListResponse
              ).coolerStatusConsolidated
            : {
                  countCameras: null,
                  countLock: null,
                  countOfflineCoolers: null,
                  countCameraProblems: null,
                  countCoolers: null,
                  countLockProblems: null,
                  countOnlineCoolers: null,
              };

    const { ref, inView } = useInView({
        skip:
            !isStatusListHasNextPage ||
            isStatusListFetchingNextPage ||
            isStatusListFetching,
    });

    useEffect(() => {
        if (
            inView &&
            isStatusListHasNextPage &&
            !isStatusListFetching &&
            !isStatusListFetchingNextPage
        ) {
            fetchNextStatusListPage();
        }
    }, [inView]);

    useEffect(() => {
        refetchStatusList();
    }, [pageFilters]);

    const loadingClass =
        isStatusListLoading && !isDarkTheme
            ? 'default-loading'
            : isStatusListLoading && isDarkTheme
            ? 'dark-default-loading'
            : '';

    const fetchingClass =
        isStatusListFetching && !isDarkTheme
            ? 'default-loading'
            : isStatusListFetching && isDarkTheme
            ? 'dark-default-loading'
            : '';

    const { isXs } = useBreakpoints();

    const handleCameraClick = (refrigerator: IRefrigeratorStatusList) => {
        setIsModalOpen({ open: true, refrigerator });
    };

    return (
        <Default
            contextType={ContextType.refrigerator_status_dashboard}
            height="100%"
        >
            {(isStatusListLoading || isStatusListRefetching) &&
            !isStatusListFetchingNextPage ? (
                <Column justify="center" align="center" height="100%">
                    <CircularProgress variant="indeterminate" />
                </Column>
            ) : (
                <>
                    <IndicatorsCards
                        isDarkTheme={isDarkTheme}
                        indicators={indicatorsData}
                        isLoading={isStatusListLoading}
                    />
                    <Grid
                        columns="repeat(auto-fit, minmax(400px, 1fr))"
                        gap={2}
                        responsiveType="input"
                        overflow="auto"
                        maxHeight="calc(100vh - 300px)"
                        minHeight={isXs ? '100%' : 'auto'}
                    >
                        {statusListPages.length > 0 &&
                            statusListPages.map(page => {
                                const data =
                                    page.data as IRefrigeratorStatusListResponse;

                                return data.coolerStatusList.map(
                                    (refrigerator, index) => (
                                        <DashboardCard
                                            key={
                                                refrigerator.serial +
                                                refrigerator.locality +
                                                index
                                            }
                                            refrigerator={refrigerator}
                                            isLoading={loadingClass}
                                            reference={
                                                index ===
                                                data.coolerStatusList.length - 1
                                                    ? (ref as any)
                                                    : null
                                            }
                                            onCameraClick={handleCameraClick}
                                        />
                                    ),
                                );
                            })}
                        {isStatusListFetching &&
                            Array.from(Array(2).keys()).map((_, index) => (
                                <CardContainer
                                    key={'loading-card' + index}
                                    className={fetchingClass}
                                    padding={5}
                                    height="100px"
                                />
                            ))}
                    </Grid>
                </>
            )}
            <Modal
                isOpen={isModalOpen.open}
                onClose={() =>
                    setIsModalOpen({
                        open: false,
                        refrigerator: {} as IRefrigeratorStatusList,
                    })
                }
                title="Problema da câmera"
                content={
                    <CameraProblemModal
                        refrigerator={isModalOpen.refrigerator}
                    />
                }
            />
        </Default>
    );
}
