import { useSelector } from 'react-redux';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useContext, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import 'dayjs/locale/pt-br';
import { IAutocomplete, IRefrigeratorFilterParams } from '@/domain/models';
import { AuthenticationHelper, PermissionHelper } from '@/domain/helpers';
import { RefrigeratorFilterParams } from '@/presentation';
import { makeRefrigeratorListPresenter } from '@/main/factories';
import { StoreState } from '@/ui/store/models';
import { IFilterDrawer } from '@/ui/interfaces';
import { InputTextField, Filter, AutoCompleteField } from '@/ui/components';
import { FilterContext } from '@/ui/context/context-filter';

export default function RefrigeratorFilter({
    filter,
    setFilter,
}: IFilterDrawer) {
    const queryClient = useQueryClient();
    const { getFilters, resetFilter, setResetFilter, updateFilters } =
        useContext(FilterContext);
    const filtersPage = getFilters();

    const resetValues = {
        code: filtersPage?.code ? filtersPage?.code.id : '',
        device: filtersPage?.device ? filtersPage?.device.id : '',
        location: filtersPage?.location ? filtersPage?.location.id : '',
        situation: filtersPage?.situation ? filtersPage?.situation : null,
        status: filtersPage?.status ? filtersPage?.status : null,
        sortDirectionStockPercentage: filtersPage?.sortDirectionStockPercentage
            ? filtersPage?.sortDirectionStockPercentage
            : null,
        licensedId: filtersPage?.licensedId ? filtersPage?.licensedId : null,
    };

    const {
        handleSubmit,
        control,
        reset,
        formState: { isDirty },
    } = useForm<IRefrigeratorFilterParams>({
        mode: 'onChange',
        defaultValues: resetValues,
    });

    const onSubmit: SubmitHandler<IRefrigeratorFilterParams> = data =>
        submitHandler(data);

    const activeMenu = useSelector(
        (store: StoreState) => store.navigation.activePage,
    );

    const roles = AuthenticationHelper.getToken().auth;
    const permissionHelper = new PermissionHelper(roles);
    const refrigeratorListPresenter = makeRefrigeratorListPresenter();

    const situationOptions = refrigeratorListPresenter.situationOptions;
    const statusOptions = refrigeratorListPresenter.statusOptions;

    const submitHandler = (data: any) => {
        const newFilters = new RefrigeratorFilterParams(
            data.code ? data.code : '',
            data.device ? data.device : '',
            data.location ? data.location : '',
            data.situation ? data.situation.id : null,
            data.status ? data.status.id : null,
            data.sortDirectionStockPercentage
                ? data.sortDirectionStockPercentage.id
                : null,
            data.licensedId ? data.licensedId.id : '',
            data.licensedId ? data.licensedId.label : '',
        );

        updateFilters({ ...filtersPage, ...newFilters }, true);
        setFilter({ right: false });
        reset();
    };

    const isAllowed =
        permissionHelper.isTakeAndGo() || permissionHelper.isDeveloper();

    const licensedAutoCompleteData = queryClient.getQueryData<
        Array<IAutocomplete>
    >('licensedAutoComplete');

    useEffect(() => {
        reset(resetValues);
    }, [filtersPage]);

    useEffect(() => {
        if (resetFilter) {
            reset(resetValues);
            setResetFilter(false);
        }
    }, [resetFilter]);

    return (
        <Filter
            filter={filter}
            setFilter={setFilter}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            disabled={!isDirty}
        >
            {isAllowed && (
                <AutoCompleteField
                    control={control}
                    options={licensedAutoCompleteData as IAutocomplete[]}
                    name="licensedId"
                    label="Licenciado"
                    dataTestId="licensed-autocomplete"
                />
            )}
            <AutoCompleteField
                dataTestId="status-select"
                control={control}
                options={statusOptions}
                name="status"
                label="Status"
            />
            {Boolean(activeMenu == 'refrigerator_stock') && (
                <AutoCompleteField
                    dataTestId="stock-sort-select"
                    control={control}
                    options={[
                        { label: 'Crescente', id: 'ASC' },
                        { label: 'Decrescente', id: 'DESC' },
                    ]}
                    name="sortDirectionStockPercentage"
                    label="Ordenação estoque total"
                />
            )}
            <AutoCompleteField
                dataTestId="cooler-situation-select"
                control={control}
                options={situationOptions}
                name="situation"
                label="Situação"
            />
            <InputTextField
                dataTestId="cooler-code-select"
                control={control}
                name="code"
                label="Código"
            />
            <InputTextField
                dataTestId="cooler-device-select"
                control={control}
                name="device"
                label="Dispositivo"
            />
            <InputTextField
                dataTestId="cooler-location-select"
                control={control}
                name="location"
                label="Localização"
            />
        </Filter>
    );
}
