import { Provider } from 'react-redux';
import moment from 'moment';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { ReactGAImplementation } from 'react-ga4';
import { ThemeProvider } from '@mui/material';
import { RouteProvider } from '@/main/routes';
import { GlobalStyle } from '@/ui/styles/common';
import { persistedStore, store } from '@/ui/store/config';
import LanguageProvider from '@/ui/styles/common/mui-language';
import ThemeProviderLocal from '@/ui/styles/themes/theme-provider';
import { NotificationProvider } from './notification-provider/notification-provider';
import { FilterProvider } from '@/ui/context/context-filter';
import 'react-toastify/dist/ReactToastify.css';
import '@/ui/styles/common/fonts.css';
import { SelectedTableRowsProvider } from '@/ui/context/selected-table-rows';
import { useEffect } from 'react';

moment.locale('pt-BR');

const App = function App(): JSX.Element {
    const {
        REACT_APP_GA_TRACKING_ID: gaTrackingId,
        REACT_APP_CLARITY_ID: clarityId,
    } = process.env;

    const ReactGA = new ReactGAImplementation();

    ReactGA.initialize([
        {
            trackingId: gaTrackingId as string,
        },
    ]);

    ReactGA.send({
        hitType: 'pageview',
        page: '/my-path',
        title: 'Custom Title',
    });

    ReactGA.event({
        category: 'your category',
        action: 'your action',
        label: 'your label',
        value: 99,
        nonInteraction: true,
        transport: 'xhr',
    });

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                keepPreviousData: true,
            },
        },
    });

    useEffect(() => {
        if (clarityId) {
            (function (
                c: any,
                l: any,
                a: any,
                r: any,
                i: any,
                t?: any,
                y?: any,
            ) {
                c[a] =
                    c[a] ||
                    function () {
                        /*eslint-disable-next-line*/
                        (c[a].q = c[a].q || []).push(arguments);
                    };
                t = l.createElement(r);
                t.async = 1;
                t.src = 'https://www.clarity.ms/tag/' + i;
                y = l.getElementsByTagName(r)[0];
                y.parentNode.insertBefore(t, y);
            })(window, document, 'clarity', 'script', clarityId);
        }
    }, [clarityId]);

    return (
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <NotificationProvider>
                    <PersistGate persistor={persistedStore}>
                        <ThemeProviderLocal>
                            <ThemeProvider theme={LanguageProvider}>
                                <BrowserRouter>
                                    <FilterProvider>
                                        <SelectedTableRowsProvider>
                                            <GlobalStyle />
                                            <RouteProvider />
                                        </SelectedTableRowsProvider>
                                    </FilterProvider>
                                </BrowserRouter>
                            </ThemeProvider>
                        </ThemeProviderLocal>
                    </PersistGate>
                </NotificationProvider>
            </QueryClientProvider>
        </Provider>
    );
};

export default App;
