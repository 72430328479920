import StyledSvgIcon from './svg-icon.styles';

export default function ThermometerIcon(): JSX.Element {
    return (
        <StyledSvgIcon
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.25 2.25C7.25 1.55964 6.69036 1 6 1C5.30964 1 4.75 1.55964 4.75 2.25V6.87889C4.14701 7.28252 3.75 7.9699 3.75 8.75C3.75 9.99264 4.75736 11 6 11C7.24264 11 8.25 9.99264 8.25 8.75C8.25 7.9699 7.85299 7.28252 7.25 6.87889V2.25Z"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6 9.25C6.27614 9.25 6.5 9.02614 6.5 8.75C6.5 8.47386 6.27614 8.25 6 8.25C5.72386 8.25 5.5 8.47386 5.5 8.75C5.5 9.02614 5.72386 9.25 6 9.25Z"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </StyledSvgIcon>
    );
}
