export default class DashboardHttpEndpoint {
    static API_VERSION = 'api/v1';
    static DASHBOARD_BASE = `${DashboardHttpEndpoint.API_VERSION}/dashboards`;
    static DASHBOARD_RATING = `${DashboardHttpEndpoint.API_VERSION}/rating`;
    static DASHBOARD_PATH_GET_BY_TYPE = `${DashboardHttpEndpoint.DASHBOARD_BASE}/type/{0}`;
    static DASHBOARD_RATING_PATH_GET_BY_TYPE = `${DashboardHttpEndpoint.DASHBOARD_RATING}/type/{0}`;
    static DASHBOARD_PRODUCTS_REPORTS = `${this.API_VERSION}/products-reports`;
    static TOP_SELLING_PRODUCTS_REPORTS = `${this.DASHBOARD_PRODUCTS_REPORTS}/top-selling`;
    static TOP_SELLING_PRODUCTS_REPORT_IN_PROGRESS = `${this.API_VERSION}/report/TOP_SELLING_PRODUCTS_XLS/in-progress`;
}
