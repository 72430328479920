import {
    ContextType,
    IRefrigeratorEditInputs,
    TableIcons,
} from '@/domain/models';
import Default from '../../default';
import { CircularProgress, Tabs } from '@/ui/components';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRefrigeratorListQueries } from '@/ui/hooks';
import { Column } from '@/ui/components/layout';
import {
    ConfirmModalSave,
    ConfirmTabChangeModal,
    RefrigeratorAddress,
    RefrigeratorContactData,
    RefrigeratorData,
    RefrigeratorParameters,
    RefrigeratorPdvData,
    RefrigeratorProductsForm,
    RefrigeratorSimData,
} from './components';
import { store } from '@/ui/store/config';
import selectNavigationAction from '@/ui/store/modules/app/navigation/actions';
import { NavigationAppState } from '@/ui/store/models';
import { AuthenticationHelper, PermissionHelper } from '@/domain/helpers';
import { DevTool } from '@hookform/devtools';

export default function RefrigeratorForm() {
    const { refrigeratorId } = useParams();
    const isEditing = useMemo(() => !!refrigeratorId, [refrigeratorId]);
    const [tabValue, setTabValue] = useState<number>(0);
    const [isConfirmModalTabChange, setIsConfirmModalTabChange] =
        useState<boolean>(false);
    const [isConfirmModalSave, setIsConfirmModalSave] = useState<{
        isOpen: boolean;
        type?: 'mix' | 'processing';
    }>({
        isOpen: false,
    });

    const {
        reset,
        tabs,
        informationForm,
        isDisabled,
        isDirty,
        isLoading,
        licensedAutoComplete,
        isLicensedLoading,
        refrigeratorProductsQuery,
        allProductsQuery,
        addRefrigeratorProduct,
        updateProduct,
        deleteProduct,
        updateRefrigerator,
        createRefrigerator,
        refrigeratorData,
    } = useRefrigeratorListQueries(tabValue, refrigeratorId);

    const { control, watch, handleSubmit } = informationForm;

    useEffect(() => {
        !isEditing &&
            store.dispatch(
                selectNavigationAction(
                    new NavigationAppState(
                        ContextType.refrigerator_save,
                        ContextType.refrigerator_list,
                        TableIcons.REFRIGERATOR,
                    ),
                ),
            );
    }, [isEditing]);

    const handleSave = (data: IRefrigeratorEditInputs) => {
        if (isConfirmModalSave.isOpen) {
            setIsConfirmModalSave({ isOpen: false });
        }

        if (isEditing) {
            return updateRefrigerator(data);
        }

        return createRefrigerator(data);
    };

    const onRefrigeratorFormSubmit = (data: IRefrigeratorEditInputs) => {
        if (isEditing) {
            if (
                (refrigeratorData as IRefrigeratorEditInputs).processing.id !==
                data.processing.id
            ) {
                return setIsConfirmModalSave({
                    isOpen: true,
                    type: 'processing',
                });
            }

            if (
                (refrigeratorData as IRefrigeratorEditInputs).mix.id !==
                data.mix.id
            ) {
                return setIsConfirmModalSave({
                    isOpen: true,
                    type: 'mix',
                });
            }
        }

        handleSave(data);
    };

    const { auth: roles, licensed_type: licensedType } =
        AuthenticationHelper.getToken();
    const permissionHelper = new PermissionHelper(roles);
    const isPartner = permissionHelper.isPartner();

    const isAllowedToEdit = permissionHelper.isTakeAndGo();

    const components = [
        RefrigeratorData,
        RefrigeratorAddress,
        RefrigeratorPdvData,
        RefrigeratorParameters,
        RefrigeratorSimData,
        RefrigeratorContactData,
    ];

    const handleTabChange = (_: any, newValue: number) => {
        if (isDirty) {
            return setIsConfirmModalTabChange(true);
        }

        setTabValue(newValue);
    };

    const confirmChange = () => {
        setTabValue(1);
        setIsConfirmModalTabChange(false);
        reset();
    };

    return (
        <Default
            contextType={ContextType.refrigerator_save}
            isSubmitDisabled={isDisabled}
        >
            <Tabs
                value={tabValue}
                setValue={setTabValue}
                tabs={tabs}
                handleTabChange={handleTabChange}
            >
                {tabValue === 0 && (
                    <Column
                        gap={2}
                        overflow="auto"
                        as="form"
                        id="refrigerator-form"
                        onSubmit={handleSubmit(onRefrigeratorFormSubmit)}
                    >
                        {isLoading ? (
                            <CircularProgress variant="indeterminate" />
                        ) : (
                            components.map((Component, index) => (
                                <Component
                                    key={Component.name + index}
                                    control={control}
                                    watch={watch}
                                    licensedAutoComplete={licensedAutoComplete}
                                    isLicensedLoading={isLicensedLoading}
                                    isAllowedToEdit={isAllowedToEdit}
                                    isPartner={isPartner}
                                    isEditing={isEditing}
                                    licensedType={licensedType}
                                />
                            ))
                        )}
                        <DevTool control={control} placement="top-right" />
                    </Column>
                )}
                {tabValue === 1 && (
                    <RefrigeratorProductsForm
                        refrigeratorProductsQuery={refrigeratorProductsQuery}
                        allProductsQuery={allProductsQuery}
                        addRefrigeratorProduct={addRefrigeratorProduct}
                        updateProduct={updateProduct}
                        deleteProduct={deleteProduct}
                    />
                )}
            </Tabs>
            {isConfirmModalTabChange && (
                <ConfirmTabChangeModal
                    isOpen={isConfirmModalTabChange}
                    onClose={() => setIsConfirmModalTabChange(false)}
                    confirmChange={confirmChange}
                />
            )}
            {isConfirmModalSave.isOpen && isConfirmModalSave.type && (
                <ConfirmModalSave
                    isOpen={isConfirmModalSave.isOpen}
                    onClose={() => setIsConfirmModalSave({ isOpen: false })}
                    type={isConfirmModalSave.type}
                    confirmSave={handleSubmit(handleSave)}
                />
            )}
        </Default>
    );
}
