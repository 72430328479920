import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { TableIcons } from '@/domain/models';
import { StoreState } from '@/ui/store/models';
import StyledSvgIcon from './svg-icon.styles';

export default function RefrigeratorIcon() {
    const currentTheme = useTheme();
    const activeMenu = useSelector(
        (currentStore: StoreState) => currentStore.navigation.activeMenu,
    );

    return (
        <StyledSvgIcon
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.33331 8.33342V14.3334C3.33331 15.7335 3.33331 16.4336 3.6058 16.9684C3.84548 17.4388 4.22793 17.8212 4.69834 18.0609C5.23312 18.3334 5.93318 18.3334 7.33331 18.3334H12.6666C14.0668 18.3334 14.7668 18.3334 15.3016 18.0609C15.772 17.8212 16.1545 17.4388 16.3942 16.9684C16.6666 16.4336 16.6666 15.7335 16.6666 14.3334V8.33342M3.33331 8.33342V5.66675C3.33331 4.26662 3.33331 3.56655 3.6058 3.03177C3.84548 2.56137 4.22793 2.17892 4.69834 1.93923C5.23312 1.66675 5.93318 1.66675 7.33331 1.66675H12.6666C14.0668 1.66675 14.7668 1.66675 15.3016 1.93923C15.772 2.17892 16.1545 2.56137 16.3942 3.03177C16.6666 3.56655 16.6666 4.26662 16.6666 5.66675V8.33342M3.33331 8.33342H16.6666"
                stroke={
                    activeMenu === TableIcons.REFRIGERATOR
                        ? currentTheme.secondary
                        : currentTheme.quaternary
                }
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.1667 10.8333H11.6667"
                stroke={
                    activeMenu === TableIcons.REFRIGERATOR
                        ? currentTheme.secondary
                        : currentTheme.quaternary
                }
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </StyledSvgIcon>
    );
}
