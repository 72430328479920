import {
    IFinishOrderReview,
    IOrderList,
    IOrderReviewItems,
} from '@/domain/models';
import makeOrderListPresenter from '@/main/factories/presenters/pages/order/order-list-presenter-factory';
import {
    Button,
    CircularProgress,
    Modal,
    defaultInputStyles,
} from '@/ui/components';
import { StoreState } from '@/ui/store/models';
import { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import ImageCarousel from '../order-image-modal/caorulsel';
import { Column, Row } from '@/ui/components/layout';
import { useFieldArray, useForm } from 'react-hook-form';
import { TextField } from '@mui/material';
import { DevTool } from '@hookform/devtools';
import { NotificationContext } from '@/main';
import { AuthenticationHelper } from '@/domain/helpers';
import { store } from '@/ui/store/config';
import { sidebarOpenAction } from '@/ui/store/modules';

interface IManualOrderReviewProps {
    rerenderTable: () => void;
}

export default function ManualOrderReview({
    rerenderTable,
}: IManualOrderReviewProps) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleClose = () => {
        store.dispatch(
            sidebarOpenAction({
                sidebarName: '',
                sidebarState: { right: false },
                elementId: '',
                elementData: '',
            }),
        );
        setIsModalOpen(false);
    };
    const { successNotification, errorNotification, warningNotification } =
        useContext(NotificationContext);
    const orderListPresenter = makeOrderListPresenter();
    const modal = useSelector((store: StoreState) => store.sidebar);

    const { inputStyles } = defaultInputStyles();

    const { register, control, handleSubmit } = useForm<IFinishOrderReview>();
    const { fields, replace } = useFieldArray({
        name: 'items',
        control,
    });

    useEffect(() => {
        if (modal.sidebarName === 'manual_order_review' && modal.elementData) {
            setIsModalOpen(modal.sidebarState.right);
        }
    }, [modal]);

    const orderId = modal.elementId;

    const { data, isLoading } = useQuery(
        ['order-review', orderId],
        () => orderListPresenter.getOrderReview(orderId as string),
        {
            enabled: isModalOpen && !!orderId,
            onSuccess: data => {
                if (!data || !data?.aiGuesses || !data?.refrigeratorProducts) {
                    return errorNotification('Erro ao carregar pedido');
                }

                const { aiGuesses, refrigeratorProducts } = data;

                data?.refrigeratorProducts.forEach(item => {
                    item.consumedQuantity = 0;
                });

                const itemsMap = new Map<string, IOrderReviewItems>(
                    aiGuesses[0].items.map(item => [item.code, item]),
                );
                const productsMap = new Map<string, IOrderReviewItems>(
                    refrigeratorProducts.map(item => [item.code, item]),
                );

                const mergedItems = new Map<string, IOrderReviewItems>([
                    ...itemsMap,
                    ...productsMap,
                ]);

                const items: IOrderReviewItems[] = Array.from(
                    mergedItems.values(),
                );

                replace(items);
            },
        },
    );

    const { mutateAsync, isLoading: isFinishOrderReviewLoading } = useMutation(
        (data: IFinishOrderReview) =>
            orderListPresenter.finishOrderReview(orderId as string, data),
        {
            onSuccess: res => {
                if (!res.success) {
                    return errorNotification(
                        res.message || 'Erro ao revisar pedido',
                    );
                }

                rerenderTable();
                successNotification(
                    res.data.message || 'Pedido revisado com sucesso',
                );
                handleClose();
            },
        },
    );

    const submit = (data: IFinishOrderReview) => {
        if (data.items.find(item => item.consumedQuantity! < 0)) {
            return warningNotification(
                'Quantidade consumida não pode ser menor que zero',
            );
        }

        mutateAsync(data);
    };

    const orderData: IOrderList = modal.elementData;
    const location = orderData?.orderRefrigeratorResponse
        ? orderData.orderRefrigeratorResponse.pointOfSale
        : '';

    const getOrderType = {
        CONSUME: 'Pedido de Consumo',
        REPOSITION: 'Pedido de Reposição',
    };

    const licensedId = AuthenticationHelper.getToken()
        ? AuthenticationHelper.getToken().licensed_id
        : '';

    let canReview = true;

    if (
        isModalOpen &&
        orderData.replacementInform &&
        orderData.franchiseeId !== Number(licensedId)
    ) {
        canReview = false;
    }

    return (
        <Modal
            isOpen={isModalOpen}
            onClose={handleClose}
            aria-labelledby="manual_order_review"
            aria-describedby="manual_order_review"
            data-testid="manual_order_review_modal"
            title={`#${orderId} - ${location} - ${
                getOrderType[orderData?.orderType as keyof typeof getOrderType]
            }`}
            okButton={<></>}
            cancelButton={<></>}
        >
            {isLoading && (
                <Row align="center" justify="center">
                    <CircularProgress variant="indeterminate" />
                </Row>
            )}
            {data && (
                <Row width="60vw" overflow="auto" gap={4} justify="center">
                    {data?.orderMediasResponse?.medias?.length > 0 ? (
                        <Row
                            grow={canReview ? 5 : undefined}
                            align="center"
                            justify="center"
                            width={canReview ? 'auto' : '75%'}
                        >
                            <ImageCarousel
                                grow={5}
                                media={data.orderMediasResponse.medias}
                            />
                        </Row>
                    ) : (
                        <Column grow={5} align="center" justify="center">
                            Nenhuma imagem encontrada.
                        </Column>
                    )}
                    {canReview && (
                        <Column
                            maxwidth="200px"
                            gap={4}
                            maxHeight="500px"
                            overflow="auto"
                            padding={{
                                top: 4,
                                bottom: 4,
                            }}
                            as="form"
                            onSubmit={handleSubmit(submit)}
                        >
                            {fields.map((field, index) => (
                                <TextField
                                    sx={inputStyles}
                                    key={field.id}
                                    type="number"
                                    {...register(
                                        `items.${index}.consumedQuantity`,
                                    )}
                                    label={field.name}
                                />
                            ))}
                            {fields.length > 0 && (
                                <Button
                                    disabled={isFinishOrderReviewLoading}
                                    text="Finalizar revisão"
                                    type="submit"
                                />
                            )}
                            <DevTool control={control} />
                        </Column>
                    )}
                </Row>
            )}
        </Modal>
    );
}
