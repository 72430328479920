import React from 'react';
import { StyledNavLink } from './sidebar-menu-styles';

const HeaderRefNavLink = React.forwardRef<any, any>((props, ref) => {
    let className = '';

    if (props.isActive) {
        className = 'selected';
    }

    return (
        <StyledNavLink
            ref={ref}
            to={props.to}
            onClick={props.onClick}
            className={className}
            state={props.location}
        >
            {props.children}
        </StyledNavLink>
    );
});

HeaderRefNavLink.displayName = 'RefNavLink';

export default HeaderRefNavLink;
