import { OrdinationButton } from '../illustration';
import { ITableHeadItem } from '@/ui/interfaces';

export default function TableHeadItem({
    setOrdination,
    ordination,
    columnName,
    columnLabel,
    className,
    hasOrdination,
}: ITableHeadItem) {
    return (
        <th
            className={className ? className : ''}
            onMouseLeave={() =>
                setOrdination &&
                setOrdination({
                    ...ordination,
                    show: {
                        showing: '',
                        showColumnName: '',
                    },
                })
            }
            onMouseEnter={() =>
                setOrdination &&
                setOrdination({
                    ...ordination,
                    show: {
                        showing: 'show',
                        showColumnName: columnName,
                    },
                })
            }
            style={{ position: 'relative' }}
        >
            {columnLabel}
            {Boolean(hasOrdination) && (
                <OrdinationButton
                    setOrdination={setOrdination}
                    ordination={ordination}
                    columnName={columnName}
                />
            )}
        </th>
    );
}
