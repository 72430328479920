import StyledSvgIcon from './svg-icon.styles';

interface RatingUserIconProps {
    index: number;
}

export default function RatingUserIcon({ index }: RatingUserIconProps) {
    const ContextColorMap = new Map<number, string>([
        [0, '#FF5630'],
        [1, '#FFAB00'],
        [2, '#36B37E'],
        [3, '#00A6DB'],
    ]);

    return (
        <StyledSvgIcon
            width="16"
            height="18"
            viewBox="0 0 16 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.6673 16.5C14.6673 15.337 14.6673 14.7555 14.5238 14.2824C14.2006 13.217 13.3669 12.3834 12.3016 12.0602C11.8284 11.9167 11.247 11.9167 10.084 11.9167H5.91732C4.75435 11.9167 4.17286 11.9167 3.6997 12.0602C2.63436 12.3834 1.80068 13.217 1.47752 14.2824C1.33398 14.7555 1.33398 15.337 1.33398 16.5M11.7507 5.25C11.7507 7.32107 10.0717 9 8.00065 9C5.92958 9 4.25065 7.32107 4.25065 5.25C4.25065 3.17893 5.92958 1.5 8.00065 1.5C10.0717 1.5 11.7507 3.17893 11.7507 5.25Z"
                fill="transparent"
                stroke={ContextColorMap.get(index)}
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </StyledSvgIcon>
    );
}
