import UserClassificationType from './user-classification.type';

const ContextUserClassificationTitle = new Map<string, string>([
    [UserClassificationType.inactive_users, 'Inativos'],
    [UserClassificationType.sleeping_users, 'Adormecidos'],
    [UserClassificationType.active_users, 'Ativos'],
    [UserClassificationType.new_users, 'Novos'],
]);

export default ContextUserClassificationTitle;
