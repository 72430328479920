import styled from 'styled-components';

interface TableColoredLabelProps {
    isActive: string;
}

const TableColoredLabel = styled.div<TableColoredLabelProps>`
    padding: 5px 2px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    color: ${props => (props.isActive === 'ACTIVE' ? '#36b37e' : '#FF5630')};
    border: 2px solid
        ${props =>
            props.isActive === 'ACTIVE'
                ? props.theme.orderStatusLabel.success.borderColor
                : props.theme.orderStatusLabel.problems.borderColor};
    border-radius: 10px;
    text-align: center;
    overflow: hidden;
    white-space: normal;
    font-size: 12px;

    @media (max-width: 650px) {
        min-width: 90px;
    }

    &.success {
        color: ${props => props.theme.orderStatusLabel.success.textColor};
        border: 2px solid
            ${props => props.theme.orderStatusLabel.success.borderColor};

        @media (max-width: 650px) {
            padding: 0px;
            max-width: 40px !important;
            font-size: 12px;
        }
    }

    &.problems {
        color: ${props => props.theme.orderStatusLabel.problems.textColor};
        border: 2px solid
            ${props => props.theme.orderStatusLabel.problems.borderColor};

        @media (max-width: 650px) {
            padding: 0px;
            font-size: 12px;
            max-width: 70px;
        }
    }

    &.processing {
        color: ${props => props.theme.orderStatusLabel.processing.textColor};
        border: 2px solid
            ${props => props.theme.orderStatusLabel.processing.borderColor};

        @media (max-width: 650px) {
            padding: 0px;
            max-width: 70px;
            font-size: 12px;
        }
    }

    &.canceled {
        color: ${props => props.theme.orderStatusLabel.canceled.textColor};
        border: 2px solid
            ${props => props.theme.orderStatusLabel.canceled.borderColor};

        @media (max-width: 650px) {
            padding: 0px;
            max-width: 70px;
            font-size: 12px;
        }
    }

    &.reposition {
        color: ${props => props.theme.orderStatusLabel.reposition.textColor};
        border: 2px solid
            ${props => props.theme.orderStatusLabel.reposition.borderColor};

        @media (max-width: 650px) {
            padding: 0px;
            font-size: 12px;
            max-width: 70px;
        }
    }

    &.contestation {
        color: ${props => props.theme.orderStatusLabel.contestation.textColor};
        border: 2px solid
            ${props => props.theme.orderStatusLabel.contestation.borderColor};

        @media (max-width: 650px) {
            padding: 0px;
            font-size: 12px;
            max-width: 70px;
        }
    }

    &.contestation-approved {
        color: ${props =>
            props.theme.orderStatusLabel.contestationApproved.textColor};
        border: 2px solid
            ${props =>
                props.theme.orderStatusLabel.contestationApproved.borderColor};

        @media (max-width: 650px) {
            padding: 0px;
            font-size: 12px;
            max-width: 70px;
        }
    }

    &.contestation-denied {
        color: ${props =>
            props.theme.orderStatusLabel.contestationDenied.textColor};
        border: 2px solid
            ${props =>
                props.theme.orderStatusLabel.contestationDenied.borderColor};

        @media (max-width: 650px) {
            padding: 0px;
            font-size: 12px;
            max-width: 70px;
        }
    }

    &.manual-billing {
        color: ${props => props.theme.orderStatusLabel.manualBilling.textColor};
        border: 2px solid
            ${props => props.theme.orderStatusLabel.manualBilling.borderColor};

        @media (max-width: 650px) {
            padding: 0px;
            font-size: 12px;
            max-width: 70px;
        }
    }

    &.bank-dispute {
        color: ${props => props.theme.orderStatusLabel.bankDispute.textColor};
        border: 2px solid
            ${props => props.theme.orderStatusLabel.bankDispute.borderColor};

        @media (max-width: 650px) {
            padding: 0px;
            font-size: 12px;
            max-width: 70px;
        }
    }

    &.refunded {
        color: ${props => props.theme.orderStatusLabel.refunded.textColor};
        border: 2px solid
            ${props => props.theme.orderStatusLabel.refunded.borderColor};

        @media (max-width: 650px) {
            padding: 0px;
            font-size: 12px;
            max-width: 70px;
        }
    }

    &.order-label {
        padding: 10px 10px;
    }
`;

export default TableColoredLabel;
