import { useNavigate } from 'react-router-dom';
import {
    ContextLabelMap,
    ContextRouteMap,
    MenuModel,
    SubMenu,
} from '@/domain/models';
import { ICollapseState } from '@/ui/interfaces';
import { CollapseSubmenu, SubMenuListItem, SubmenuList } from './styles';
import { AuthenticationHelper, PermissionHelper } from '@/domain/helpers';

interface ISubmenuNavigation {
    collapseState: ICollapseState;
    menu: MenuModel;
    setCollapseOpen: (value: any) => void;
    activePage: string;
}

export default function SubmenuNavigation(
    props: ISubmenuNavigation,
): JSX.Element {
    const { collapseState, menu, setCollapseOpen, activePage } = props;

    const userRoles = AuthenticationHelper.getToken().auth || '';
    const permissionHelper = new PermissionHelper(userRoles);
    const isTakeAndGo = permissionHelper.isTakeAndGo();
    const isPartner = permissionHelper.isPartner();

    const navigate = useNavigate();

    const renderSubMenus = () => {
        const submenus = menu.submenus;

        const isLicensedSubmenu = (submenuItem: SubMenu) => {
            return (
                (!isTakeAndGo &&
                    submenuItem.isEnable &&
                    !submenuItem.onlyTake) ||
                (submenuItem.isPartner && isPartner)
            );
        };

        const isTakeAndGoSubmenu = (submenuItem: SubMenu) => {
            return (
                isTakeAndGo &&
                (submenuItem.onlyTake || submenuItem.takeAndGoAccess)
            );
        };

        return submenus.map((submenuItem, index) => {
            if (isTakeAndGoSubmenu(submenuItem)) {
                return (
                    <SubMenuListItem
                        isActive={activePage == submenuItem.contextType}
                        key={submenuItem.id + index}
                        to={ContextRouteMap.get(submenuItem.contextType)}
                        target={submenuItem.target}
                        onClick={() => {
                            setCollapseOpen({
                                ...collapseState,
                                activePage: submenuItem.contextType,
                            });

                            if (submenuItem.target === '_blank') {
                                window.open(
                                    ContextRouteMap.get(
                                        submenuItem.contextType,
                                    )!,
                                    '_blank',
                                );

                                return;
                            }

                            navigate(
                                ContextRouteMap.get(submenuItem.contextType)!,
                            );
                        }}
                    >
                        {ContextLabelMap.get(submenuItem.contextType)}
                    </SubMenuListItem>
                );
            } else if (isLicensedSubmenu(submenuItem)) {
                return (
                    <SubMenuListItem
                        isActive={activePage == submenuItem.contextType}
                        key={submenuItem.id}
                        to={ContextRouteMap.get(submenuItem.contextType)}
                        target={submenuItem.target}
                        onClick={() => {
                            setCollapseOpen({
                                ...collapseState,
                                activePage: submenuItem.contextType,
                            });
                            navigate(
                                ContextRouteMap.get(submenuItem.contextType)!,
                            );
                        }}
                    >
                        {ContextLabelMap.get(submenuItem.contextType)}
                    </SubMenuListItem>
                );
            }
        });
    };

    return (
        <CollapseSubmenu
            in={
                collapseState.isCollapseOpen &&
                menu.contextType == collapseState.activeMenu
            }
            timeout="auto"
            unmountOnExit
            sx={{
                minHeight: 'unset !important',
            }}
        >
            <SubmenuList
                isActive={activePage == menu.contextType}
                disablePadding
            >
                {renderSubMenus()}
            </SubmenuList>
        </CollapseSubmenu>
    );
}
