import styled from 'styled-components';
import { Column } from '../../layout';

export const TooltipContainer = styled(Column)`
    border-radius: 12px;
    color: ${props => props.theme.secondary};
    background-color: ${props => props.theme.tableBorder};
    padding: 10px;
    position: absolute;
    bottom: 49px;
`;
