import { PersistPartial } from 'redux-persist/es/persistReducer';
import {
    applyMiddleware,
    legacy_createStore as createStore,
    Reducer,
    Middleware,
} from 'redux';
import { StoreState, StoreAction } from '../models';

export default (
    reducers: Reducer<StoreState & PersistPartial, StoreAction>,
    middlewares: Middleware[],
): any => {
    const enhancer = applyMiddleware(...middlewares);
    return createStore(reducers, enhancer);
};
