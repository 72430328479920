import { IOverviewFilterParams } from '@/domain/models';

const homeRatingDefaultValues: IOverviewFilterParams = {
    licensedId: null,
    coolerId: null,
    initialDate: null,
    endDate: null,
};

export default homeRatingDefaultValues;
