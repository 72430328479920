import axios, { AxiosInstance } from 'axios';

export default class LoginAxiosHelper {
    static getInstance(): AxiosInstance {
        const axiosInstance = axios.create({
            baseURL: process.env.REACT_APP_API_AUTHENTICATION,
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return axiosInstance;
    }
}
