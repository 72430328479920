import HttpEndpoint from '../http-endpoint';

export default class ProductHttpEndpoint {
    static PRODUCT_BASE = `${HttpEndpoint.API_VERSION}/promotions/licensed/coolers/products`;
    static PRODUCT_BASE_CATEGORIES = `${HttpEndpoint.API_VERSION}/promotions/licensed/coolers/categories`;
    static PRODUCT_BASE_CATEGORIES_BY_COOLERID = (coolerId: string) =>
        `${HttpEndpoint.API_VERSION}/promotions/licensed/coolers/${coolerId}/categories`;
    static PRODUCT_BASE_PRODUCTS_BY_COOLERID = (coolerId: string) =>
        `${HttpEndpoint.API_VERSION}/promotions/licensed/coolers/${coolerId}/products`;
    static PRODUCT_BASE_BY_COOLER = `${HttpEndpoint.API_VERSION}/coolers/`;
    static PRODUCT_ALL = `${HttpEndpoint.API_VERSION}/products`;
}
