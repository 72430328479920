/* eslint-disable */

import {
    HttpMethod,
    HttpRequest,
    HttpResponse,
    ProductListHttpEndpoint,
} from '@/domain/models/services';
import { IHttpServicePort, IProductsRepository } from '@/infra/ports';

export class ProductsRepositoryAdapter implements IProductsRepository {
    constructor(private readonly httpServicePort: IHttpServicePort) {}

    async addProduct<T>(body: any): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: ProductListHttpEndpoint.PRODUCT_BASE,
            method: HttpMethod.post,
            body: body,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    addProductPartner<T>(body: any): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: ProductListHttpEndpoint.PRODUCT_BASE + '/partner',
            method: HttpMethod.post,
            body: body,
            auth: true,
        });

        return this.httpServicePort.request<T>(request);
    }

    async updateProduct<T>(
        body: any,
        productId: string,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: ProductListHttpEndpoint.PRODUCT_BASE + '/' + productId,
            method: HttpMethod.put,
            body: body,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getProductById<T>(productId: string): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: ProductListHttpEndpoint.PRODUCT_BASE + '/' + productId,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async addProductCategory<T>(body: any): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: ProductListHttpEndpoint.PRODUCT_CATEGORY,
            method: HttpMethod.post,
            body: body,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async updateProductCategory<T>(
        body: any,
        categoryId: string,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: ProductListHttpEndpoint.PRODUCT_CATEGORY + '/' + categoryId,
            method: HttpMethod.put,
            body: body,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getProductCategoryById<T>(categoryId: any): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: ProductListHttpEndpoint.PRODUCT_CATEGORY + '/' + categoryId,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async addProductMix<T>(body: any): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: ProductListHttpEndpoint.PRODUCT_MIX,
            method: HttpMethod.post,
            body: body,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async updateProductMix<T>(
        mixCode: string,
        body: any,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: ProductListHttpEndpoint.PRODUCT_MIX + '/' + mixCode,
            method: HttpMethod.put,
            body: body,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getMixes<T>(): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: ProductListHttpEndpoint.PRODUCT_MIX,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getMixesAutocomplete<T>(): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: ProductListHttpEndpoint.PRODUCT_MIX_AUTOCOMPLETE,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getMixById<T>(mixCode: string): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: ProductListHttpEndpoint.PRODUCT_MIX + '/' + mixCode,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getProviderAutocomplete<T>(): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: ProductListHttpEndpoint.PRODUCT_PROVIDER,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getProductAutocomplete<T>(): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: ProductListHttpEndpoint.PRODUCT_AUTOCOMPLETE,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getProductByOrder<T>(orderId: any): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url:
                ProductListHttpEndpoint.PRODUCT_AUTOCOMPLETE_BY_ORDER + orderId,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getCategoriesAutocomplete<T>(): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: ProductListHttpEndpoint.PRODUCT_CATEGORY_AUTOCOMPLETE,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }
}
