import styled from 'styled-components';
import { Column, Row } from '../../layout';

export const DragDropContainer = styled.div`
    width: 100%;
    border-width: 1px;
    border-radius: 8px;
    box-sizing: border-box;
    position: relative;
    z-index: 99;
    background-color: ${props => props.theme.dragDrop};

    &.error {
        label {
            background-image: repeating-linear-gradient(
                    0deg,
                    #fc4503,
                    #fc4503 30px,
                    transparent 30px,
                    transparent 60px,
                    #fc4503 60px
                ),
                repeating-linear-gradient(
                    90deg,
                    #fc4503,
                    #fc4503 30px,
                    transparent 30px,
                    transparent 60px,
                    #fc4503 60px
                ),
                repeating-linear-gradient(
                    180deg,
                    #fc4503,
                    #fc4503 30px,
                    transparent 30px,
                    transparent 60px,
                    #fc4503 60px
                ),
                repeating-linear-gradient(
                    270deg,
                    #fc4503,
                    #fc4503 30px,
                    transparent 30px,
                    transparent 60px,
                    #fc4503 60px
                );
        }
    }

    label {
        border: none;
        width: 40rem;
        height: 11.1875rem;
        margin: 0px;
        border-radius: 8px;
        background-image: repeating-linear-gradient(
                0deg,
                #bababa,
                #bababa 30px,
                transparent 30px,
                transparent 60px,
                #bababa 60px
            ),
            repeating-linear-gradient(
                90deg,
                #bababa,
                #bababa 30px,
                transparent 30px,
                transparent 60px,
                #bababa 60px
            ),
            repeating-linear-gradient(
                180deg,
                #bababa,
                #bababa 30px,
                transparent 30px,
                transparent 60px,
                #bababa 60px
            ),
            repeating-linear-gradient(
                270deg,
                #bababa,
                #bababa 30px,
                transparent 30px,
                transparent 60px,
                #bababa 60px
            );
        background-size: 1px calc(100% + 60px), calc(100% + 60px) 1px,
            1px calc(100% + 60px), calc(100% + 60px) 1px;
        background-position: 0 0, 0 0, 100% 0, 0 100%;
        background-repeat: no-repeat;
        input {
            display: hidden;
            opacity: 0;
        }

        svg {
            display: hidden;
            opacity: 0;
        }

        div {
            display: hidden;
            opacity: 0;
        }
    }

    @media (max-width: 650px) {
        label {
            width: unset;
        }
    }
`;

export const DragTextContainer = styled(Column)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    align-items: center;
`;

export const UploadIconContainer = styled(Row)`
    background: #ffe766;
    padding: 8px;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
`;

export const UploadText = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: ${props => props.theme.secondary};
    margin-top: 16px;
    margin-bottom: 16px;

    span {
        color: #007db7;
    }

    & .small {
        font-size: 10px;
    }
`;
