import { ReactNode } from 'react';
import { Card, CardTitle, IconButton } from '../..';
import { FileDownload } from '@mui/icons-material';

interface CardChartProps {
    isLoading: boolean;
    title: string;
    position?: string;
    isDarkTheme: boolean;
    children: ReactNode;
    reference?: any;
    isExport?: boolean;
    exportFunction?: any;
    isExportInProgress?: boolean;
}

export default function CardChart({
    isLoading,
    title,
    position,
    isDarkTheme,
    children,
    reference,
    isExport = false,
    exportFunction,
    isExportInProgress,
}: CardChartProps) {
    const loading = () => {
        if (isLoading && !isDarkTheme) {
            return 'default-loading';
        } else if (isLoading && isDarkTheme) {
            return 'dark-default-loading';
        }
    };
    return (
        <Card
            ref={reference}
            className={`card-position-${position} ${loading()}`}
        >
            <CardTitle className={isExport ? 'card-info' : ''}>
                <span>{title}</span>
                {isExport && (
                    <IconButton
                        onClick={exportFunction}
                        disabled={isExportInProgress}
                        color="secondary"
                    >
                        <FileDownload />
                    </IconButton>
                )}
            </CardTitle>
            {children}
        </Card>
    );
}
