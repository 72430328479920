import { Correlation, IAutocomplete } from '@/domain/models';
import {
    IBannerManagementForm,
    usePromotionAutocompleteQuery,
} from '@/presentation';
import {
    AutoCompleteField,
    InputTextField,
    MarkdownEditor,
} from '@/ui/components';
import { Column, Grid, Row } from '@/ui/components/layout';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { LinkPreview } from './styles';

export default function ApplicationTypeData({
    control,
    watch,
    setValue,
    correlationsTypeOptions,
}: {
    control: Control<IBannerManagementForm>;
    watch: UseFormWatch<IBannerManagementForm>;
    setValue: UseFormSetValue<IBannerManagementForm>;
    correlationsTypeOptions: IAutocomplete[];
}) {
    const [correlationType, promotion, link] = watch([
        'correlationType',
        'promotion',
        'link',
    ]);

    const {
        data: promotionAutocompleteData = [],
        isLoading: isPromotionAutocompleteLoading,
        isFetching: isPromotionAutocompleteFetching,
    } = usePromotionAutocompleteQuery(
        correlationType?.id === Correlation.PROMOTION,
    );

    const interceptPromotionChange = (promotionValue: IAutocomplete) => {
        setValue('promotion', promotionValue);

        if (promotionValue) {
            setValue('title', promotionValue.title);
            setValue(
                'description',
                promotionValue.description ? promotionValue.description : '',
            );
            setValue('licensed', promotionValue?.licensed);
            setValue(
                'coolers',
                promotionValue.coolers
                    ? (promotionValue.coolers as IAutocomplete[])
                    : [],
            );
            setValue(
                'categories',
                promotionValue.categories
                    ? (promotionValue.categories as IAutocomplete[])
                    : [],
            );
            setValue(
                'products',
                promotionValue.products
                    ? (promotionValue.products as IAutocomplete[])
                    : [],
            );
            setValue('dateBegin', promotionValue.dateBegin);
            setValue('dateEnd', promotionValue.dateEnd);
        }
    };

    const interceptCorrelationTypeChange = (
        correlationValue: IAutocomplete,
    ) => {
        setValue('correlationType', correlationValue);

        setValue('title', '');
        setValue('description', '');
        setValue('promotion', null);
    };

    const checkIfIsALink = (link?: string): boolean => {
        const regex = new RegExp(
            '^(https?:\\/\\/)?' +
                '((([a-zA-Z0-9\\-]+\\.)?[a-zA-Z0-9\\-]+\\.[a-zA-Z]{2,})' +
                '|' +
                'localhost' +
                '|' +
                '\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3})' +
                '(\\:\\d+)?(\\/[-a-zA-Z0-9@:%._\\+~#=]*)*' +
                '(\\?[;&a-zA-Z0-9%_\\.,~+=-]*)?' +
                '(\\#[-a-zA-Z0-9_]*)?$',
        );
        return link ? regex.test(link) : false;
    };

    return (
        <>
            <h5>Tipo de aplicação</h5>
            <Grid
                columns={
                    correlationType?.id !== Correlation.PROMOTION
                        ? '1fr 2fr'
                        : 3
                }
                gap={6}
            >
                <AutoCompleteField
                    options={correlationsTypeOptions}
                    control={control}
                    name="correlationType"
                    label="Tipo de correlação"
                    rules={{
                        required: 'Tipo de correlação é obrigatório',
                    }}
                    interceptOnChange={interceptCorrelationTypeChange}
                />
                {correlationType?.id === Correlation.PROMOTION && (
                    <AutoCompleteField
                        options={promotionAutocompleteData || []}
                        control={control}
                        name="promotion"
                        label="Promoção"
                        isLoading={
                            isPromotionAutocompleteLoading ||
                            isPromotionAutocompleteFetching
                        }
                        interceptOnChange={interceptPromotionChange}
                        rules={{
                            required: 'Promoção é obrigatória',
                        }}
                    />
                )}
                {correlationType &&
                    correlationType?.id !== Correlation.LINK && (
                        <InputTextField
                            control={control}
                            name="title"
                            label="Título"
                            rules={{
                                required: 'Título é obrigatório',
                            }}
                            readOnly={
                                correlationType?.id === Correlation.PROMOTION
                            }
                        />
                    )}
                {correlationType?.id === Correlation.LINK && (
                    <Column>
                        <InputTextField
                            control={control}
                            name="link"
                            label="Link"
                            rules={{
                                required: 'Link é obrigatório',
                                validate: {
                                    isLink: (value: string) => {
                                        return checkIfIsALink(value)
                                            ? true
                                            : 'Link inválido';
                                    },
                                },
                            }}
                        />
                        <Row>
                            {link && checkIfIsALink(link) && (
                                <LinkPreview
                                    href={link}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {link}
                                </LinkPreview>
                            )}
                        </Row>
                    </Column>
                )}
            </Grid>
            {correlationType?.id === Correlation.PROMOTION && (
                <Grid margin={{ top: 4 }}>
                    <InputTextField
                        control={control}
                        name="description"
                        label="Descrição"
                        rules={{
                            required: 'Descrição é obrigatória',
                        }}
                        type="textarea"
                        readOnly={
                            correlationType?.id === Correlation.PROMOTION &&
                            Boolean(promotion?.description)
                        }
                        maxLength={3000}
                        characterCount
                    />
                </Grid>
            )}
            {correlationType?.id === Correlation.TEXT && (
                <Grid margin={{ top: 4 }}>
                    <MarkdownEditor
                        control={control}
                        rules={{
                            required: 'Descrição é obrigatória',
                        }}
                        name="description"
                        maxLength={3000}
                        characterCount
                    />
                </Grid>
            )}
        </>
    );
}
