import { IFilterCheckbox } from '@/ui/interfaces';
import { CustomCheckbox } from './styles';

const FilterCheckbox = ({
    setStateMethod,
    toggleState,
    currentFilter,
    filterText,
    secondText,
}: IFilterCheckbox) => {
    return (
        <CustomCheckbox>
            <label
                data-testid="check-text"
                className={`${currentFilter ? 'container' : 'inactive'}`}
            >
                <p className="text">
                    {filterText}
                    <br />
                    {secondText && secondText}
                </p>
                <input
                    type="checkbox"
                    onChange={() => setStateMethod(toggleState)}
                    checked={currentFilter}
                />
                <span data-testid="checkmark" className="checkmark"></span>
            </label>
        </CustomCheckbox>
    );
};

export default FilterCheckbox;
