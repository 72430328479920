import styled from 'styled-components';
import { Row } from '../../layout';

export const SecondValueText = styled(Row)`
    padding-left: 5px;
    text-align: center;

    &.secondary {
        color: ${props => props.theme.secondary};
    }

    &.green {
        color: #36b37e;
    }

    &.red {
        color: #d61c38;
    }
`;

export const Title = styled.span`
    white-space: nowrap;
    margin-left: 8px;
`;
