import { AuthenticationHelper, PermissionHelper } from '@/domain/helpers';
import { ContextType } from './context-type';

const getRequiredFiltersByPermission = () => {
    const ContextRequiredFilters = new Map<string, string[]>();

    const userRoles = AuthenticationHelper.hasToken()
        ? AuthenticationHelper.getToken().auth
        : '';
    const permissionHelper = new PermissionHelper(userRoles);

    if (permissionHelper.isTakeAndGo()) {
        ContextRequiredFilters.set(ContextType.home_overview, [
            'initialDate',
            'endDate',
            'licensedId',
        ]);
        ContextRequiredFilters.set(ContextType.home_rating, [
            'initialDate',
            'endDate',
            'licensedId',
        ]);
        ContextRequiredFilters.set(ContextType.financial, [
            'date',
            'licensedId',
        ]);
    } else {
        ContextRequiredFilters.set(ContextType.home_overview, [
            'initialDate',
            'endDate',
        ]);
        ContextRequiredFilters.set(ContextType.home_rating, [
            'initialDate',
            'endDate',
        ]);
        ContextRequiredFilters.set(ContextType.financial, ['date']);
    }

    return ContextRequiredFilters;
};

export default getRequiredFiltersByPermission;
