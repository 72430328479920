import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import {
    CloseButton,
    FilterCheckbox,
    FilterContent,
    FilterSidebarHeader,
    StyledDivider,
} from '@/ui/components';
import { store } from '@/ui/store/config';
import { OrderColumnsAppState } from '@/ui/store/models';
import { IFilterDrawer } from '@/ui/interfaces';
import CloseIcon from '@/ui/components/illustration/icons/close-icon';
import orderColumnAction from '@/ui/store/modules/app/order-columns/actions';
import { Grid, Row } from '@/ui/components/layout';
import { AuthenticationHelper, PermissionHelper } from '@/domain/helpers';
import { useBreakpoints } from '@/ui/helpers';

type Anchor = 'right';

export default function OrderColumnFilter({
    filter,
    setFilter,
}: IFilterDrawer) {
    const currentTheme = useTheme();

    const userRoles = AuthenticationHelper.hasToken()
        ? AuthenticationHelper.getToken().auth
        : '';
    const permissionHelper = new PermissionHelper(userRoles);

    const { isXs } = useBreakpoints();

    const [activeColumnsTest, setActiveColumnsTest] = useState<any>({
        orderColumn: true,
        statusColumn: true,
        realStatusColumn: permissionHelper.isTakeAndGo() ? true : false,
        orderType: true,
        orderDateColumn: true,
        paymentDateColumn: true,
        totalValueColumn: true,
        promotionColumn: false,
        cardColumn: false,
        walletColumn: false,
        pixColumn: false,
        refrigeratorColumn: true,
        licensedColumn: false,
        userColumn: true,
    });

    const saveColumns = () => {
        const newAppState = new OrderColumnsAppState(activeColumnsTest);
        store.dispatch(orderColumnAction(newAppState));
    };

    useEffect(() => {
        saveColumns();
    }, []);

    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event &&
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }

            saveColumns();
            setFilter({ ...filter, [anchor]: open });
        };

    const list = (anchor: Anchor) => (
        <Box
            sx={{
                width: isXs ? '100vw' : 360,
                backgroundColor: currentTheme.bodyBg,
                color: currentTheme.secondary,
                flex: 1,
            }}
            role="filter"
        >
            <FilterSidebarHeader>
                Colunas
                <Row align="center" justify="center">
                    <CloseButton onClick={toggleDrawer(anchor, false)}>
                        <CloseIcon />
                    </CloseButton>
                </Row>
            </FilterSidebarHeader>
            <StyledDivider />
            <FilterContent>
                Exibir
                <Grid
                    columns={2}
                    rows={5}
                    gap={{
                        column: 1,
                    }}
                    margin={{ top: 4 }}
                >
                    <FilterCheckbox
                        setStateMethod={setActiveColumnsTest}
                        toggleState={{
                            ...activeColumnsTest,
                            orderColumn: !activeColumnsTest.orderColumn,
                        }}
                        currentFilter={activeColumnsTest.orderColumn}
                        filterText="Pedido"
                    />
                    <FilterCheckbox
                        setStateMethod={setActiveColumnsTest}
                        toggleState={{
                            ...activeColumnsTest,
                            orderType: !activeColumnsTest.orderType,
                        }}
                        currentFilter={activeColumnsTest.orderType}
                        filterText="Tipo"
                        secondText=" de pedido"
                    />
                    <FilterCheckbox
                        setStateMethod={setActiveColumnsTest}
                        filterText="Cooler"
                        toggleState={{
                            ...activeColumnsTest,
                            refrigeratorColumn:
                                !activeColumnsTest.refrigeratorColumn,
                        }}
                        currentFilter={activeColumnsTest.refrigeratorColumn}
                    />
                    <FilterCheckbox
                        setStateMethod={setActiveColumnsTest}
                        filterText="Usuário"
                        toggleState={{
                            ...activeColumnsTest,
                            userColumn: !activeColumnsTest.userColumn,
                        }}
                        currentFilter={activeColumnsTest.userColumn}
                    />
                    <FilterCheckbox
                        setStateMethod={setActiveColumnsTest}
                        filterText="Valor"
                        toggleState={{
                            ...activeColumnsTest,
                            totalValueColumn:
                                !activeColumnsTest.totalValueColumn,
                        }}
                        currentFilter={activeColumnsTest.totalValueColumn}
                    />
                    <FilterCheckbox
                        setStateMethod={setActiveColumnsTest}
                        filterText="Desconto"
                        toggleState={{
                            ...activeColumnsTest,
                            promotionColumn: !activeColumnsTest.promotionColumn,
                        }}
                        currentFilter={activeColumnsTest.promotionColumn}
                    />
                    <FilterCheckbox
                        setStateMethod={setActiveColumnsTest}
                        filterText="Cartão"
                        toggleState={{
                            ...activeColumnsTest,
                            cardColumn: !activeColumnsTest.cardColumn,
                        }}
                        currentFilter={activeColumnsTest.cardColumn}
                    />
                    <FilterCheckbox
                        setStateMethod={setActiveColumnsTest}
                        filterText="Pix"
                        toggleState={{
                            ...activeColumnsTest,
                            pixColumn: !activeColumnsTest.pixColumn,
                        }}
                        currentFilter={activeColumnsTest.pixColumn}
                    />
                    <FilterCheckbox
                        setStateMethod={setActiveColumnsTest}
                        filterText="Data"
                        secondText=" do pedido"
                        toggleState={{
                            ...activeColumnsTest,
                            orderDateColumn: !activeColumnsTest.orderDateColumn,
                        }}
                        currentFilter={activeColumnsTest.orderDateColumn}
                    />
                    <FilterCheckbox
                        setStateMethod={setActiveColumnsTest}
                        filterText="Data"
                        secondText=" do pagamento"
                        toggleState={{
                            ...activeColumnsTest,
                            paymentDateColumn:
                                !activeColumnsTest.paymentDateColumn,
                        }}
                        currentFilter={activeColumnsTest.paymentDateColumn}
                    />
                    <FilterCheckbox
                        setStateMethod={setActiveColumnsTest}
                        filterText="Status"
                        toggleState={{
                            ...activeColumnsTest,
                            statusColumn: !activeColumnsTest.statusColumn,
                        }}
                        currentFilter={activeColumnsTest.statusColumn}
                    />
                    {permissionHelper.isTakeAndGo() && (
                        <FilterCheckbox
                            setStateMethod={setActiveColumnsTest}
                            filterText="Status"
                            secondText=" interno"
                            toggleState={{
                                ...activeColumnsTest,
                                realStatusColumn:
                                    !activeColumnsTest.realStatusColumn,
                            }}
                            currentFilter={activeColumnsTest.realStatusColumn}
                        />
                    )}
                    <FilterCheckbox
                        setStateMethod={setActiveColumnsTest}
                        filterText="Wallet"
                        toggleState={{
                            ...activeColumnsTest,
                            walletColumn: !activeColumnsTest.walletColumn,
                        }}
                        currentFilter={activeColumnsTest.walletColumn}
                    />
                </Grid>
            </FilterContent>
        </Box>
    );

    return (
        <div>
            {(['right'] as const).map((anchor, index) => (
                <>
                    <SwipeableDrawer
                        key={anchor + index}
                        anchor={anchor}
                        open={filter[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {list(anchor)}
                    </SwipeableDrawer>
                </>
            ))}
        </div>
    );
}
