import { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { VisibilityOutlined } from '@mui/icons-material';

import { AuthenticationHelper, PermissionHelper } from '@/domain/helpers';
import { ContextType, ICredentials } from '@/domain/models';
import makeLoginPresenter from '@/main/factories/presenters/pages/login/login-presenter-factory';
import { NotificationContext } from '@/main';
import {
    CircularProgress,
    EmailInputIcon,
    InputTextField,
    LoginLayout,
    MaskedInputTextField,
    PasswordInputIcon,
    TakeLogoText,
} from '@/ui/components';
import { store } from '@/ui/store/config';
import { toggleThemeAction } from '@/ui/store/modules';
import { StoreState } from '@/ui/store/models';
import { useBreakpoints } from '@/ui/helpers';
import { Row, Column } from '@/ui/components/layout';
import { FilterContext } from '@/ui/context/context-filter';
import {
    ForgotPasswordText,
    LoadingContainer,
    LoginButton,
    PasswordIconContainer,
} from './styles';
import { useMutation } from 'react-query';

export default function Login(): JSX.Element {
    const [password, setPassword] = useState('password');

    const navigate = useNavigate();

    const loginPresenter = makeLoginPresenter();

    const { isXs } = useBreakpoints();

    const { token } = useParams();

    const isDarkTheme: boolean = useSelector((state: StoreState) => {
        return state.toggleTheme.darkTheme;
    });

    const { errorNotification } = useContext(NotificationContext);

    const { setActivePage } = useContext(FilterContext);

    if (isDarkTheme) {
        store.dispatch(toggleThemeAction());
    }

    const { mutateAsync: loginAsync, isLoading } = useMutation(
        (data: ICredentials) => loginPresenter.getUserTokenAsync(data),
        {
            onSuccess: res => {
                if (res.success) {
                    AuthenticationHelper.saveToken(res.data.token);
                    const permissionHelper = new PermissionHelper(
                        AuthenticationHelper.getToken().auth,
                    );

                    if (permissionHelper.isOnlyUser()) {
                        AuthenticationHelper.removeToken();
                        navigate(`/login`);
                        errorNotification('Sem permissão para acessar!');
                        return;
                    }

                    navigate('/');
                    store.dispatch(toggleThemeAction());
                } else {
                    errorNotification('Credenciais inválidas!');
                }
            },
        },
    );

    const { handleSubmit, control } = useForm<any>();
    const onSubmit: SubmitHandler<any> = data => loginAsync(data);

    const handlePasswordClick = () => {
        if (password === 'password') {
            setPassword('text');
        } else {
            setPassword('password');
        }
    };

    useEffect(() => {
        if (token) {
            AuthenticationHelper.saveToken(token);
            return navigate('/');
        }

        setActivePage(ContextType.login);
        localStorage.clear();

        if (AuthenticationHelper.isAuthenticated()) {
            const userRoles = AuthenticationHelper.getToken().auth;
            const permissionHelper = new PermissionHelper(userRoles);

            if (permissionHelper.isOnlyUser()) {
                AuthenticationHelper.removeToken();
                navigate(`/login`);
                return;
            }

            navigate('/');
            return;
        }
    }, []);

    return (
        <LoginLayout>
            {Boolean(isXs) && <TakeLogoText />}
            <Column
                style={{ position: 'relative' }}
                justify="center"
                align="center"
                as="form"
                onSubmit={handleSubmit(onSubmit)}
                gap={6}
            >
                <h5>Faça login na sua conta</h5>
                <MaskedInputTextField
                    control={control}
                    name="username"
                    label="Seu CPF"
                    mask="999.999.999-99"
                    data-testid="username"
                    icon={<EmailInputIcon />}
                    shrink
                />
                <InputTextField
                    type={password}
                    control={control}
                    name="password"
                    label="Senha"
                    data-testid="password"
                    icon={
                        <PasswordIconContainer
                            type="button"
                            onClick={() => handlePasswordClick()}
                        >
                            {password === 'password' ? (
                                <PasswordInputIcon />
                            ) : (
                                <VisibilityOutlined
                                    sx={{
                                        color: '#0C0407',
                                    }}
                                />
                            )}
                        </PasswordIconContainer>
                    }
                    shrink
                />
                <ForgotPasswordText
                    data-testid="forgot-password-button"
                    onClick={() => navigate('/password-recovery')}
                >
                    Esqueceu sua senha?
                </ForgotPasswordText>
                <LoginButton type="submit">Entrar</LoginButton>
                {Boolean(isLoading) && (
                    <LoadingContainer>
                        <CircularProgress variant="indeterminate" />
                    </LoadingContainer>
                )}
            </Column>
        </LoginLayout>
    );
}
