import RePieChart from '@/ui/components/recharts/pie-chart';
import { MobileSubtitle, MobileValue } from './mobile-rating-container';
import { PieChartProps } from '@/presentation';
import RatingPieChartTitle from '../rating-pie-chart-title';
import makeHomeOverviewPresenter from '@/main/factories/presenters/pages/home/home-overview-presenter-factory';
import { CircularProgress } from '@/ui/components';
import { Column, Row } from '@/ui/components/layout';
import { NoDataFound } from '../../../components';

interface IMobileTop {
    averageRating?: any;
    ratingQuantity?: any;
    ratingWatCanImprove: PieChartProps;
    ratingWhatCanImproveLoading: boolean;
    ratingDataLoading: boolean;
}

export default function MobileTop({
    averageRating,
    ratingQuantity,
    ratingWatCanImprove,
    ratingDataLoading,
    ratingWhatCanImproveLoading,
}: IMobileTop): JSX.Element {
    const homePresenter = makeHomeOverviewPresenter();

    return (
        <Column padding="20px">
            <Row justify="space-between">
                <Column>
                    <MobileSubtitle>Nota média</MobileSubtitle>
                    {ratingDataLoading ? (
                        <CircularProgress variant="indeterminate" />
                    ) : (
                        <MobileValue>
                            {!averageRating ||
                            isNaN(averageRating) ||
                            averageRating === 0
                                ? '-'
                                : averageRating + '/5'}
                        </MobileValue>
                    )}
                </Column>
                <Column>
                    <MobileSubtitle>Qtde. de avaliações</MobileSubtitle>
                    {ratingDataLoading ? (
                        <CircularProgress variant="indeterminate" />
                    ) : (
                        <MobileValue>
                            {ratingQuantity && ratingQuantity}
                        </MobileValue>
                    )}
                </Column>
            </Row>
            <Row justify="space-between" style={{ position: 'relative' }}>
                {ratingWhatCanImproveLoading ? (
                    <CircularProgress variant="indeterminate" />
                ) : (
                    <NoDataFound
                        data={homePresenter.checkIfChartContainData(
                            ratingWatCanImprove,
                            'pieChart',
                        )}
                    >
                        {ratingWatCanImprove && (
                            <RePieChart pieChartProps={ratingWatCanImprove} />
                        )}
                        <RatingPieChartTitle>
                            O que pode melhorar
                        </RatingPieChartTitle>
                    </NoDataFound>
                )}
            </Row>
        </Column>
    );
}
