import { useContext, useEffect } from 'react';
import { ContextType, TableIcons } from '@/domain/models';
import { TableServerSide } from '@/ui/components';
import { makeProductsMixTablePresenter } from '@/main/factories';
import { store } from '@/ui/store/config';
import selectNavigationAction from '@/ui/store/modules/app/navigation/actions';
import Default from '../../default';
import { FilterContext } from '@/ui/context/context-filter';
import { NavigationAppState } from '@/ui/store/models';

export default function ProductsMixList(): JSX.Element {
    const productMixTablePresenter = makeProductsMixTablePresenter();
    const { setActivePage } = useContext(FilterContext);

    useEffect(() => {
        setActivePage(ContextType.product_mix);
        store.dispatch(
            selectNavigationAction(
                new NavigationAppState(
                    ContextType.product_mix,
                    ContextType.products,
                    TableIcons.PRODUCTS,
                ),
            ),
        );
    }, []);

    return (
        <Default contextType={ContextType.product_mix}>
            <TableServerSide
                presenter={productMixTablePresenter}
                rowProps={{
                    enableRowActions: true,
                    identityPropertyName: 'name',
                    rowActions: productMixTablePresenter.makeRowActions(),
                }}
                paginationProps={{
                    enablePagination: true,
                    pageIndex: 0,
                    rowsPerPage: 25,
                    rowsPerPageOptions: [25, 50, 75, 100],
                }}
            />
        </Default>
    );
}
