import { ContextType } from './context-type';

export const ContextIconMap = new Map<string, string>([
    [ContextType.none, ''],
    [ContextType.home, 'home'],
    [ContextType.refrigerator, 'refrigerator'],
    [ContextType.order, 'order'],
    [ContextType.promotion_list, 'promotion'],
    [ContextType.monitoring_opening_attempts, 'monitoring'],
    [ContextType.licensed_list, 'licensed'],
    [ContextType.banner_list, 'banner'],
]);
