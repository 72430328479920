import { useContext, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateHelper } from '@/domain/helpers';
import { IBannerFilterInput } from '@/domain/models';
import { BannerFilterParams } from '@/presentation';
import { FilterContext } from '@/ui/context/context-filter';
import {
    Filter,
    AutoCompleteField,
    DateTimePickerField,
    DateTitle,
} from '@/ui/components';
import { IFilterDrawer } from '@/ui/interfaces';
import 'dayjs/locale/pt-br';

export default function BannerFilter({ filter, setFilter }: IFilterDrawer) {
    const { getFilters, resetFilter, setResetFilter, updateFilters } =
        useContext(FilterContext);

    const correlationsTypeOptions = [
        {
            id: 'PROMOTION',
            label: 'Promoção',
        },
        {
            id: 'LINK',
            label: 'Link',
        },
        {
            id: 'TEXT',
            label: 'Texto',
        },
    ];

    const filtersPage = getFilters();

    const resetValues = {
        correlationType: filtersPage?.correlationType
            ? filtersPage?.correlationType
            : '',
        dateBegin: filtersPage?.dateBegin
            ? DateHelper.formatDateHoursFromRequest(filtersPage?.dateBegin.id)
            : null,
        dateEnd: filtersPage?.dateEnd
            ? DateHelper.formatDateHoursFromRequest(filtersPage?.dateEnd.id)
            : null,
    };

    const { handleSubmit, control, reset, watch } = useForm<IBannerFilterInput>(
        {
            mode: 'all',
            defaultValues: resetValues,
        },
    );

    const submitHandler = (data: IBannerFilterInput) => {
        const bannerFilterParams = new BannerFilterParams(
            data.correlationType ? data.correlationType.id : '',
            data.correlationType ? data.correlationType.label : '',
            data.dateBegin ? DateHelper.formatDateHours(data.dateBegin) : null,
            data.dateEnd ? DateHelper.formatDateHours(data.dateEnd) : null,
        );

        updateFilters(
            {
                ...filtersPage,
                ...bannerFilterParams,
            },
            true,
        );
        setFilter({ right: false });
        reset();
    };

    const onSubmit: SubmitHandler<IBannerFilterInput> = data =>
        submitHandler(data);

    const [dateBegin, dateEnd] = watch(['dateBegin', 'dateEnd']);

    useEffect(() => {
        reset(resetValues);
    }, [filtersPage]);

    useEffect(() => {
        if (resetFilter) {
            reset(resetValues);
            setResetFilter(false);
        }
    }, [resetFilter]);

    return (
        <Filter
            filter={filter}
            setFilter={setFilter}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
        >
            <AutoCompleteField
                dataTestId="correlationType"
                control={control}
                options={correlationsTypeOptions}
                name="correlationType"
                label="Tipo de correlação"
                data-testid="correlationType"
            />

            <DateTitle>Período de vigência</DateTitle>
            <LocalizationProvider
                adapterLocale="pt-br"
                dateAdapter={AdapterDayjs}
            >
                <DateTimePickerField
                    control={control}
                    name="dateBegin"
                    label="Início"
                    rules={{
                        deps: ['dateEnd'],
                        validate: {
                            isLessThan: (value: string) => {
                                if (!value) return true;
                                return (
                                    dateEnd > value ||
                                    'A data de início deve ser menor que a data de fim'
                                );
                            },
                        },
                    }}
                />
                <DateTimePickerField
                    control={control}
                    name="dateEnd"
                    label="Fim"
                    rules={{
                        deps: ['dateBegin'],
                        validate: {
                            isGreaterThan: (value: string) => {
                                if (!value) return true;
                                return (
                                    dateBegin < value ||
                                    'A data de fim deve ser maior que a data de início'
                                );
                            },
                        },
                    }}
                />
            </LocalizationProvider>
        </Filter>
    );
}
