import styled from 'styled-components';

const RefrigeratorStockPercentage = styled.div`
    display: inline;
    margin-left: 7px;

    &.red {
        color: #ff5630;
    }

    &.green {
        color: #36b37e;
    }

    &.yellow {
        color: #ffab00;
    }

    &:after {
        border-radius: 50%;
        min-width: 20px;
        min-height: 20px;
        background-color: white;
        color: white;
        left: 2px;
        z-index: 999;
        top: 1px;
        display: inline-block;
    }
`;

export default RefrigeratorStockPercentage;
