import { useNavigate } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import { Close, LogoutRounded } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import { AuthenticationHelper, PermissionHelper } from '@/domain/helpers';
import { MenuModel } from '@/domain/models';
import makeSidebarPresenter from '@/main/factories/presenters/components/sidebar-presenter-factory';
import { AppBarProps } from '@/ui/interfaces/props/sidebar';
import TakeLogo from '@/ui/components/illustration/icons/take-logo';
import ToggleThemeButton from '@/ui/components/input/toggle-theme-button';
import Menu from '@/ui/components/navigation/menu';
import { MobileList } from './styles-mobile';
import DefaultSidebar from './default';
import { useState } from 'react';
import { ICollapseState } from '@/ui/interfaces';
import { useTheme } from 'styled-components';
import { CloseButton } from '@/ui/components/illustration/modal/styles';
import { useSelector } from 'react-redux';
import { StoreState } from '@/ui/store/models';
import { DefaultBoxStyles } from './styles';
import { Row } from '@/ui/components/layout';

export default function MobileSidebar() {
    const [open, setOpen] = useState(false);
    const [collapseState, setCollapseState] = useState<ICollapseState>({
        activePage: '',
        isCollapseOpen: false,
        activeMenu: '',
    });

    const isDarkTheme = useSelector((store: StoreState) => {
        return store.toggleTheme.darkTheme;
    });
    const styledTheme = useTheme();

    const navigate = useNavigate();

    const sidebarPresenter = makeSidebarPresenter();

    const userRoles = AuthenticationHelper.hasToken()
        ? AuthenticationHelper.getToken().auth
        : '';
    const permissionHelper = new PermissionHelper(userRoles);
    const isTakeAndGo = permissionHelper.isTakeAndGo();
    const isPartner = permissionHelper.isPartner();

    const renderMenus = () => {
        const menus = MenuModel.makeSystemMenus();
        const isLicensedMenu = (menuItem: MenuModel): boolean => {
            return (
                (!isTakeAndGo && menuItem.isEnable && !menuItem.onlyTake) ||
                (menuItem.isPartner && isPartner)
            );
        };

        const isTakeAndGoMenu = (menuItem: MenuModel): boolean | undefined => {
            return (
                isTakeAndGo && (menuItem.takeAndGoAccess || menuItem.onlyTake)
            );
        };

        return menus.map((menuItem, index) => {
            if (isTakeAndGoMenu(menuItem)) {
                return (
                    <Menu
                        key={menuItem.id + menuItem.contextType + index}
                        menu={menuItem}
                        open={open}
                        collapseState={collapseState}
                        setCollapseState={setCollapseState}
                    />
                );
            } else if (isLicensedMenu(menuItem)) {
                return (
                    <Menu
                        key={menuItem.id + menuItem.contextType + index}
                        menu={menuItem}
                        open={open}
                        collapseState={collapseState}
                        setCollapseState={setCollapseState}
                    />
                );
            }
        });
    };

    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: prop => prop !== 'open',
    })<AppBarProps>(({ theme, open }: any) => ({
        backgroundColor: styledTheme.primary,
        color: isDarkTheme ? '#f9f9f9' : '#120D0E',
        fill: isDarkTheme ? '#f9f9f9' : '#120D0E',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            width: `calc(100% - ${240}px)`,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginRight: 240,
        }),
    }));

    const quitNavigate = () => {
        AuthenticationHelper.removeToken();
        navigate(`/login`);
    };

    return (
        <>
            <AppBar className="topbar" elevation={1} open={open}>
                <Toolbar
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    color="primary"
                >
                    <TakeLogo />
                    {Boolean(!open) && (
                        <CloseButton
                            onClick={() =>
                                sidebarPresenter.handleDrawerOpen(setOpen)
                            }
                        >
                            <MenuIcon />
                        </CloseButton>
                    )}
                </Toolbar>
            </AppBar>
            <Drawer anchor="right" open={open} component={DefaultSidebar}>
                <Toolbar
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        bgcolor: isDarkTheme ? '#120D0E' : '#F7F7F7',
                        color: isDarkTheme ? '#f9f9f9' : '#120D0E',
                        fill: isDarkTheme ? '#f9f9f9' : '#120D0E',
                    }}
                    color="primary"
                >
                    <TakeLogo />
                    <Row gap={2}>
                        <ToggleThemeButton />
                        <CloseButton onClick={quitNavigate}>
                            <LogoutRounded />
                        </CloseButton>
                        <CloseButton
                            onClick={() =>
                                sidebarPresenter.handleDrawerClose(setOpen)
                            }
                        >
                            <Close />
                        </CloseButton>
                    </Row>
                </Toolbar>
                <DefaultBoxStyles>
                    <MobileList>{renderMenus()}</MobileList>
                </DefaultBoxStyles>
            </Drawer>
        </>
    );
}
