import InputTextFieldProps from '@/ui/interfaces/components/input-text-field';

export default function useInputTextFieldPresenter(
    props: Partial<InputTextFieldProps>,
) {
    const { type, isMonetary } = props;

    type inputModesTypes =
        | 'numeric'
        | 'decimal'
        | 'text'
        | 'tel'
        | 'email'
        | 'url'
        | 'search';

    const setInputMode = (): inputModesTypes => {
        if (isMonetary) return 'decimal';

        switch (type) {
            case 'number':
                return 'numeric';
            case 'text':
                return 'text';
            case 'textarea':
                return 'text';
            case 'email':
                return 'email';
            case 'url':
                return 'url';
            case 'tel':
                return 'tel';
            case 'search':
                return 'search';
            default:
                return 'text';
        }
    };

    const pattern = () => {
        if (isMonetary) return '[0-9]*([,.][0-9]*)?';

        switch (type) {
            case 'number':
                return '[0-9]*';
            case 'text':
                return '.*';
            case 'textarea':
                return '.*';
            case 'email':
                return '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
            default:
                return '.*';
        }
    };

    return {
        inputMode: setInputMode(),
        pattern: pattern(),
    };
}
