import { Pagination, PaginationItem } from '@mui/material';
import styled from 'styled-components';
import { Row } from '../../layout';

export const PaginationContainer = styled(Row)`
    justify-content: space-between;
    width: 100%;
    margin-top: 25px;
    align-items: center;

    @media (max-width: 650px) {
        flex-direction: column;
        align-items: start;
        height: 120px;
    }
`;

export const PaginationStyled = styled(Pagination)`
    &&.MuiPagination-outlined {
        &&.MuiPagination-ul {
            li {
                color: ${props => props.theme.secondary};
                border-color: #fff;
            }
        }

        &&.MuiButtonBase-root-MuiPaginationItem-root {
            color: ${props => props.theme.secondary};
            border-color: #fff;
        }
    }

    &&.MuiPaginationItem-outlinedPrimary {
        color: ${props => props.theme.secondary} !important;
    }
`;

export const PaginationItemStyled = styled(PaginationItem)`
    &&.MuiPaginationItem-outlinedPrimary-page {
        color: #fff;
        border: 1px solid ${props => props.theme.tableBorder};
        border-radius: 10px;
        background: none;
    }

    &&.MuiPaginationItem-root.Mui-selected {
        color: ${props => props.theme.secondary};
        background: none;
    }

    &&.MuiPaginationItem-icon {
        color: #fff;
    }

    &&.MuiSvgIcon-root {
        color: #fff !important;
    }

    &&.MuiPaginationItem-icon {
        color: #fff !important;
    }

    &&.MuiPaginationItem-previousNext {
        color: #fff !important;
        fill: #fff !important;
    }

    &&.MuiPaginationItem-root {
        min-height: 32px;
    }
`;

export const TotalItensSelectContainer = styled(Row)`
    min-width: 200px;
`;

export const TotalItemsLabel = styled.span`
    margin-left: 15px;
`;
