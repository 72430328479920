import { useLocation, useNavigate } from 'react-router-dom';
import { AuthenticationHelper } from '@/domain/helpers';
import useBreakpoints from '@/ui/helpers/breakpoint-helper';
import { QuitIcon } from '@/ui/components';
import ToggleThemeButton from '@/ui/components/input/toggle-theme-button';
import { Header, PageTitle } from './default.styles';
import { CloseButton } from '@/ui/components/illustration/modal/styles';
import { Row } from '@/ui/components/layout';
import { useSelector } from 'react-redux';
import { StoreState } from '@/ui/store/models';
import { ContextTitle } from '@/domain/models';
import { ChevronLeft, NavigateNext } from '@mui/icons-material';
import { Breadcrumbs } from '@mui/material';

export default function HeaderDefault(): JSX.Element {
    const { isXs } = useBreakpoints();
    const navigate = useNavigate();
    const location = useLocation();

    const quitNavigate = () => {
        AuthenticationHelper.removeToken();
        navigate(`/login`);
    };

    const { activePage, activeContextType, activeMenu, pageTitle } =
        useSelector((store: StoreState) => store.navigation);

    const contextTitle = ContextTitle();
    const activeMenuTitle = contextTitle.get(activeMenu) as string;
    const activeContextTitle = contextTitle.get(activeContextType) as string;
    const activePageTitle = contextTitle.get(activePage) as string;

    const isNotMenuSameOfContext =
        activeMenuTitle !== activeContextTitle &&
        (activeMenu as string) !== (activeContextType as string);

    const isCreatingOrEditing =
        activePage.includes('save') || activePage.includes('edit');

    const navigateToActiveContext = () => {
        if (location.state && location.state.from === 'save') {
            navigate(-3);
            return;
        }

        if (isCreatingOrEditing) {
            navigate(-2);
            return;
        }

        return navigate(-1);
    };

    return (
        <Header as="header">
            <Row align="center" gap={3}>
                {Boolean(isCreatingOrEditing) && (
                    <ChevronLeft
                        scale={20}
                        onClick={navigateToActiveContext}
                        cursor="pointer"
                    />
                )}
                <Breadcrumbs
                    color="inherit"
                    aria-label="breadcrumbs"
                    separator={<NavigateNext />}
                    sx={{
                        '& .MuiBreadcrumbs-li': {
                            maxWidth: '50ch',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        },
                    }}
                >
                    {isNotMenuSameOfContext && (
                        <h5 data-testid="active-menu-title">
                            {activeMenuTitle}
                        </h5>
                    )}
                    {activeContextTitle && (
                        <span
                            className={
                                isNotMenuSameOfContext
                                    ? 'label-extra-large'
                                    : 'heading-5'
                            }
                            data-testid="active-context-title"
                        >
                            {activeContextTitle}
                        </span>
                    )}
                    {!pageTitle && activePage !== activeContextType && (
                        <span
                            className="label-extra-large"
                            data-testid="active-page-title"
                        >
                            {activePageTitle}
                        </span>
                    )}
                    {pageTitle && (
                        <PageTitle data-testid="page-title">
                            {pageTitle}
                        </PageTitle>
                    )}
                </Breadcrumbs>
            </Row>
            {Boolean(!isXs) && (
                <Row gap={6}>
                    <ToggleThemeButton />
                    <CloseButton className="stroked" onClick={quitNavigate}>
                        <QuitIcon />
                    </CloseButton>
                </Row>
            )}
        </Header>
    );
}
