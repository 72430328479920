import { IRefrigeratorProductsUpdate } from '@/domain/models';

export default class RefrigeratorProductUpdate
    implements IRefrigeratorProductsUpdate
{
    value: number;
    expectedStock: number;
    minimalPercentage: number;

    constructor(
        value: number,
        expectedStock: number,
        minimalPercentage: number,
    ) {
        this.value = value;
        this.expectedStock = expectedStock;
        this.minimalPercentage = minimalPercentage;
    }
}
