import { DateHelper } from '@/domain/helpers';
import {
    HttpResponse,
    IAutocomplete,
    IUserChangePasswordLink,
    IUserNewPassword,
    IUserPasswordRecoveryOptions,
    IUserResponse,
    IUserSaveInputs,
    State,
} from '@/domain/models';
import Roles from '@/domain/models/helpers/roles-enum';
import { IUserRepositoryPort } from '@/infra';
import { IUserPresenter } from '@/ui/pages';
import { parsePhoneNumber } from 'react-phone-number-input';

export default class UserPresenter implements IUserPresenter {
    constructor(private readonly userRepository: IUserRepositoryPort) {}

    async getUserById(userId: string): Promise<IUserSaveInputs> {
        return await new Promise((resolve, reject) => {
            this.userRepository
                .getUserById<IUserResponse>(userId)
                .then(response => {
                    if (!response.success) {
                        reject(response.data);
                    }

                    const userData = response.data!;

                    resolve(this.formatDataToEdit(userData));
                });
        });
    }

    async updateUserById(
        userId: string,
        userFormData: IUserSaveInputs,
    ): Promise<boolean> {
        const data = this.formatDataToSave(userFormData);

        return await this.userRepository
            .updateUserById<null>(userId, data)
            .then(res => {
                if (!res.success) {
                    return false;
                }

                return true;
            })
            .catch(() => {
                return false;
            });
    }

    async createUser(userFormData: IUserSaveInputs): Promise<IUserResponse> {
        const data = this.formatDataToSave(userFormData);

        return await this.userRepository
            .createUser<IUserResponse>(data)
            .then(response => {
                if (!response.success) {
                    throw response.data;
                }

                return response.data!;
            })
            .catch(error => {
                return error;
            });
    }

    async changeActive(
        userId: string,
        isActive: any,
        data: any,
        successNotification: any,
        closeSidebar: () => void,
        refetchTable: () => void,
    ) {
        const response =
            await this.userRepository.toggleActivateUser<IUserResponse>(
                userId,
                isActive === 'INACTIVE' ? true : false,
                data,
            );

        const success = () => {
            successNotification(
                isActive === 'INACTIVE'
                    ? 'Usuário ativado com sucessos!'
                    : 'Usuário desativado com sucesso!',
            );
            closeSidebar();
            refetchTable();
        };

        response.success && success();

        return response;
    }

    async resetPasswordAsync<T>(userId: any): Promise<HttpResponse<T>> {
        const response =
            await this.userRepository.resetPasswordAsync<IUserResponse>(userId);

        const resetPasswordResponse = response.data!;

        return resetPasswordResponse;
    }

    formatDataToEdit({
        address,
        franchiseeId,
        profiles,
        gender,
        ...data
    }: IUserResponse): IUserSaveInputs {
        const phoneNumber = String(
            parsePhoneNumber(data?.telephone || '', 'BR')?.formatNational(),
        );

        return {
            ...data,
            address: address
                ? {
                      ...address,
                      city: {
                          id: address.city.id!.toString(),
                          label: address.city.name,
                      },
                      state: address.city.state
                          ? State.getStates().find(
                                state => state.id === address.city.state,
                            )
                          : null,
                  }
                : null,
            birthDate: DateHelper.formatDateFromRequest(data.birthDate),
            franchiseeId: franchiseeId
                ? {
                      id: franchiseeId.toString(),
                      label: franchiseeId.toString(),
                  }
                : null,
            profiles: profiles
                ? profiles.map(
                      profile =>
                          this.rolesOptions.find(role => role.id === profile)!,
                  )
                : this.rolesOptions.filter(role => role.id === Roles.USER)!,
            gender: gender
                ? this.genderOptions.find(g => g.id === gender)!
                : null,
            telephone: phoneNumber,
        };
    }

    formatDataToSave({ address, ...data }: IUserSaveInputs): IUserResponse {
        const phoneNumber = String(
            parsePhoneNumber(data.telephone, 'BR')?.nationalNumber,
        );

        return {
            ...data,
            address:
                address && address.city && address.state
                    ? {
                          city: {
                              id: Number(address.city.id),
                              name: address.city.label,
                              state: address.state.id,
                          },
                          complement: address?.complement || '',
                          district: address?.district || '',
                          number: address?.number || '',
                          street: address?.street || '',
                          zipCode: address?.zipCode || '',
                      }
                    : null,
            birthDate: DateHelper.formatDate(data.birthDate),
            profiles: data.profiles.map(profile => profile.id),
            gender: data.gender ? (data.gender.id as any) : null,
            telephone: phoneNumber,
            franchiseeId: data.franchiseeId
                ? Number(data.franchiseeId.id)
                : null,
        };
    }

    async passwordRecoveryOptionsAsync(
        userCpf: string,
    ): Promise<HttpResponse<IUserPasswordRecoveryOptions>> {
        return this.userRepository.getUserPasswordRecoveryOptionsAsync<IUserResponse>(
            userCpf,
        );
    }

    async sendUserChangePasswordLinkAsync(
        userChangePasswordLink: IUserChangePasswordLink,
    ): Promise<HttpResponse<any>> {
        return this.userRepository.sendUserChangePasswordLinkAsync(
            userChangePasswordLink.cpf,
            userChangePasswordLink.recoveryType,
        );
    }

    async userChangePasswordAsync<T>(
        userChangeNewPassword: IUserNewPassword,
    ): Promise<HttpResponse<T>> {
        return this.userRepository.userChangePasswordAsync(
            userChangeNewPassword.token,
            userChangeNewPassword.password,
        );
    }

    userValidateTokenAsync<T>(token: string): Promise<HttpResponse<T>> {
        return this.userRepository.userValidateToken(token);
    }

    genderOptions: Array<IAutocomplete> = [
        {
            id: 'MEN',
            label: 'Masculino',
        },
        {
            id: 'WOMEN',
            label: 'Feminino',
        },
        {
            id: 'OTHER',
            label: 'Outro',
        },
        {
            id: 'UNKNOWN',
            label: 'Não informar',
        },
    ];

    rolesOptions: Array<IAutocomplete> = [
        {
            id: Roles.USER,
            label: 'Usuário',
        },
        {
            id: Roles.ADM_FINANCIAL,
            label: 'Administrador Financeiro',
        },
        {
            id: Roles.ADMINISTRATIVE,
            label: 'Administrativo',
        },
        {
            id: Roles.ANTI_FRAUD,
            label: 'Anti Fraude',
        },
        {
            id: Roles.COMMERCIAL,
            label: 'Comercial',
        },
        {
            id: Roles.DEVELOPER,
            label: 'Desenvolvedor',
        },
        {
            id: Roles.FINANCIAL,
            label: 'Financeiro',
        },
        {
            id: Roles.FRANCHISEE,
            label: 'Licenciado',
        },
        {
            id: Roles.FRANCHISEE_EARLY_ADOPTER,
            label: 'Licenciado Early Adopter',
        },
        {
            id: Roles.FRANCHISEE_SUCCESS,
            label: 'Sucesso do Licenciado',
        },
        {
            id: Roles.OPERATIONAL,
            label: 'Operacional',
        },
        {
            id: Roles.PROMOTER,
            label: 'Promotor',
        },
        {
            id: Roles.REPOSITOR,
            label: 'Repositor',
        },
        {
            id: Roles.REVIEWER,
            label: 'Revisor',
        },
        {
            id: Roles.TAKEANDGO,
            label: 'Take and Go',
        },
        {
            id: Roles.PARTNER,
            label: 'Parceiro',
        },
    ];
}
