import styled from 'styled-components';

const ChartValueText = styled.h2`
    font-size: 24px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0px;
    text-align: left;
    margin: 0;
`;

export default ChartValueText;
