import { Container } from './styles';

interface CircularProgressProps {
    variant: 'indeterminate' | 'determinate';
    value?: number;
    size?: number;
}

export default function CircularProgress(
    props: CircularProgressProps,
): JSX.Element {
    const { variant, value, size } = props;

    return <Container variant={variant} value={value} size={size} />;
}
