import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';

import {
    Button,
    CircularProgress,
    DatePickerField,
    InputTextField,
    Modal,
    RadioGroupInput,
} from '@/ui/components';
import { store } from '@/ui/store/config';
import { StoreState } from '@/ui/store/models';
import { sidebarOpenAction } from '@/ui/store/modules';
import {
    useCreateNegotiation,
    useGetNegotiationById,
    useUpdateNegotiation,
} from '@/ui/hooks';
import { Row } from '@/ui/components/layout';
import { DateHelper } from '@/domain/helpers';

export default function NegotiationModal() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const modal = useSelector((store: StoreState) => store.sidebar);
    const licensedId = modal.elementId;

    const handleClose = () => {
        reset({
            type: 'DEBIT',
            date: null,
            description: '',
            value: null,
        });
        if (modal.sidebarName === 'financial_negotiation_modal') {
            store.dispatch(
                sidebarOpenAction({
                    ...modal,
                    sidebarState: { right: false },
                    elementId: '',
                    sidebarName: '',
                }),
            );
            return setIsModalOpen(false);
        }
        store.dispatch(
            sidebarOpenAction({
                ...modal,
                sidebarState: { right: true },
                elementId: '',
                sidebarName: 'negotiation',
            }),
        );
        setIsModalOpen(false);
    };

    const { negotiationData, isLoading: isNegotiationByIdLoading } =
        useGetNegotiationById(modal.elementData?.negotiationId || null);

    useEffect(() => {
        if (
            modal.sidebarName === 'financial_negotiation_modal' ||
            modal.sidebarName === 'financial_negotiation_modal_edit'
        ) {
            return setIsModalOpen(modal.sidebarState.right);
        }
        setIsModalOpen(false);
    }, [modal]);

    const { negotiationForm, submitNegotiation } = useCreateNegotiation(
        licensedId as string,
        handleClose,
    );

    const {
        control,
        handleSubmit,
        watch,
        formState: { isValid, isDirty },
        reset,
    } = negotiationForm;

    const { submitUpdateNegotiation } = useUpdateNegotiation(
        licensedId as string,
        handleClose,
        reset,
    );

    const isDisabled = !isValid || !isDirty;
    const [negotiationType] = watch(['type']);

    useEffect(() => {
        if (
            negotiationData &&
            modal.sidebarName == 'financial_negotiation_modal_edit'
        ) {
            const formDataToReset = {
                description: negotiationData.negotiationDescription,
                value: Math.abs(negotiationData.negotiationValue!),
                date: DateHelper.getDefaultDate(
                    negotiationData.negotiationDate,
                ),
                type:
                    negotiationData.negotiationValue! < 0 ? 'DEBIT' : 'CREDIT',
            };

            reset(formDataToReset);
        }
    }, [negotiationData]);

    return (
        <Modal
            isOpen={isModalOpen}
            onClose={handleClose}
            title={`${
                modal.sidebarName == 'financial_negotiation_modal_edit'
                    ? 'Editar negociação'
                    : 'Nova Negociação'
            }`}
            okButton={
                <Button
                    dataTestId="submit-negotiation"
                    text="Salvar"
                    onClick={
                        modal.sidebarName == 'financial_negotiation_modal_edit'
                            ? handleSubmit(submitUpdateNegotiation)
                            : handleSubmit(submitNegotiation)
                    }
                    disabled={isDisabled}
                />
            }
            cancelButton={
                <Button
                    text="Cancelar"
                    onClick={handleClose}
                    className="outlined"
                    dataTestId="cancel-button"
                />
            }
        >
            <Row align="center" justify="center">
                {modal.sidebarName != 'financial_negotiation_modal' &&
                    isNegotiationByIdLoading && (
                        <CircularProgress variant="indeterminate" />
                    )}
            </Row>
            {(!isNegotiationByIdLoading ||
                modal.sidebarName == 'financial_negotiation_modal') && (
                <>
                    <RadioGroupInput
                        required
                        control={control}
                        radios={[
                            {
                                value: 'CREDIT',
                                label: 'Crédito',
                            },
                            {
                                value: 'DEBIT',
                                label: 'Débito',
                            },
                        ]}
                        name="type"
                        flexDirection="row"
                        paddingLeft="2px"
                    />
                    <InputTextField
                        control={control}
                        rules={{
                            required: true,
                        }}
                        name="description"
                        label="Descrição"
                        dataTestId="negotiation-description"
                    />
                    <InputTextField
                        control={control}
                        rules={{
                            required: true,
                        }}
                        name="value"
                        label="Valor"
                        type="number"
                        isMonetary
                        icon={<>{negotiationType === 'CREDIT' ? '+' : '-'}</>}
                        iconPosition="start"
                        dataTestId="negotiation-value"
                    />
                    <LocalizationProvider
                        adapterLocale="pt-br"
                        dateAdapter={AdapterDayjs}
                    >
                        <DatePickerField
                            mask="MM/YYYY"
                            views={['month', 'year']}
                            control={control}
                            name="date"
                            label="Mês / Ano"
                            dataTestId="negotiation-date-picker"
                            placeholder="mm/aaaa"
                        />
                    </LocalizationProvider>
                </>
            )}
        </Modal>
    );
}
