import styled from 'styled-components';

const ValueText = styled.h2`
    font-size: 36px;
    font-weight: 400;
    line-height: 50px;
    letter-spacing: 0px;
    text-align: left;
    margin: 0px 0px 10px 0px;

    @media (max-width: 650px) {
        font-size: 26px;
    }
`;

export default ValueText;
