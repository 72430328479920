import styled from 'styled-components';

export const PasswordOrientationText = styled.p`
    color: var(--primary-dark-6, #757575);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
`;

export const PasswordRequirements = styled.ul`
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;

    li {
        color: #757575;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Helvetica;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;

        &.invalid {
            color: #ff5630;
        }

        &.valid {
            color: #36b37e;
        }
    }
`;
