import { Control, Controller, RegisterOptions } from 'react-hook-form';
import { Autocomplete, Chip, CircularProgress } from '@mui/material';
import defaultInputStyles from '../input-styles';
import StyledTextField from '../styled-text-field';

interface IMultipleValuesAutocompleteProps {
    control: Control<any, any>;
    name: string;
    options: any[];
    label: string;
    fixedValues?: any[];
    rules?: RegisterOptions;
    isDisabled?: boolean;
    defaultValue?: any[];
    isCpf?: boolean;
    isLoading?: boolean;
    placeholder?: string;
    limitTags?: number;
}

export default function MultipleValuesAutoCompleteField({
    control,
    name,
    label,
    options,
    fixedValues = [],
    rules,
    isDisabled,
    defaultValue,
    isLoading,
    placeholder,
    limitTags = 5,
}: IMultipleValuesAutocompleteProps) {
    const { inputStyles, chipStyles, textFieldStyles, componentProps } =
        defaultInputStyles(isDisabled);

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field, fieldState: { error } }) => (
                <Autocomplete
                    sx={inputStyles}
                    multiple
                    options={!isLoading ? options : []}
                    filterSelectedOptions
                    isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                    }
                    limitTags={limitTags}
                    componentsProps={componentProps}
                    {...field}
                    disabled={isDisabled || isLoading}
                    readOnly={isDisabled || isLoading}
                    loading={isLoading}
                    loadingText="Carregando..."
                    onChange={(_, data) => {
                        if (data.length < 1) {
                            field.onChange([]);
                        }

                        field.onChange([
                            ...data.filter(
                                option => fixedValues.indexOf(option) === -1,
                            ),
                        ]);
                    }}
                    renderTags={(value, getTagProps) =>
                        value.map((option: any, index: number) => {
                            return (
                                <Chip
                                    label={option.label}
                                    sx={chipStyles}
                                    variant="outlined"
                                    {...getTagProps({ index })}
                                    key={index + option.id}
                                    disabled={
                                        fixedValues
                                            .map(fixedValue => fixedValue.id)
                                            .indexOf(option.id) !== -1
                                    }
                                />
                            );
                        })
                    }
                    renderInput={params => (
                        <StyledTextField
                            sx={textFieldStyles}
                            label={label}
                            error={!!error}
                            helperText={error ? error?.message : null}
                            required={!!rules?.required}
                            {...params}
                            disabled={isDisabled || isLoading}
                            placeholder={placeholder}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {isLoading ? (
                                            <CircularProgress
                                                color="inherit"
                                                size={20}
                                            />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                />
            )}
        />
    );
}
