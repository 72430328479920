import { RowActionProps } from '@/ui/interfaces/props/table';

export interface TableRowProps {
    enableRowActions?: boolean;
    identityPropertyName?: string;
    rowActions?: RowActionProps<any>[];
    moreActionsMenu?: any[];
    hasRowSelection?: boolean;
}

export const TableRowPropsDefault = (
    options?: TableRowProps,
    hasRowSelection = false,
) => {
    return {
        identityPropertyName: options?.identityPropertyName ?? 'id',
        enableRowActions: options?.enableRowActions ?? false,
        rowActions: options?.rowActions ?? [],
        moreActionsMenu: options?.moreActionsMenu ?? [],
        hasRowSelection,
    } as TableRowProps;
};
