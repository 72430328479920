import { useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { ListItemButton } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { TableIconsMaterial } from '@/domain/models/app/context/';
import { ContextLabelMap, ContextRouteMap } from '@/domain/models';
import makeMenuPresenter from '@/main/factories/presenters/components/menu-presenter-factory';
import { StoreState } from '@/ui/store/models';
import { FilterContext } from '@/ui/context/context-filter';
import { MenuProps } from '@/ui/interfaces';
import SubmenuNavigation from './submenu-navigation';
import RefNavLink from './ref-navlink';
import { StyledListItemText, StyledListItemIcon } from './sidebar-menu-styles';
import { ExpandedArrowIcon } from './styles';

export default function Menu(props: MenuProps): JSX.Element {
    const { className, menu, open, collapseState, setCollapseState } = props;
    const [isActive, setIsActive] = useState(false);

    const activeMenu: string = useSelector(
        (state: StoreState) => state.navigation.activeMenu,
    );

    const { cleanFilter, activePage } = useContext(FilterContext);

    const theme = useTheme();

    const menuPresenter = makeMenuPresenter();

    const icons = TableIconsMaterial;

    const containSubmenu = menu.submenus.length > 0;

    const removeSpacing = {
        padding: 0,
        margin: 0,
    };

    const iconColor =
        menu.contextType == activeMenu ? theme.secondary : theme.quaternary;

    const shouldRenderButton =
        collapseState!.isCollapseOpen &&
        collapseState?.activeMenu === menu.contextType;

    const renderExpandIcon = () => {
        if (containSubmenu && open) {
            return shouldRenderButton ? (
                <ExpandedArrowIcon>
                    <ExpandLess
                        sx={{
                            color: iconColor,
                            stroke: iconColor,
                            strokeWidth: '0.5',
                            cursor: 'pointer',
                        }}
                    />
                </ExpandedArrowIcon>
            ) : (
                <ExpandedArrowIcon>
                    <ExpandMore
                        sx={{
                            color: iconColor,
                            stroke: iconColor,
                            strokeWidth: '0.5',
                            cursor: 'pointer',
                        }}
                    />
                </ExpandedArrowIcon>
            );
        }
    };

    useEffect(() => {
        setIsActive(true);
        setCollapseState!({
            ...collapseState!,
            isCollapseOpen: true,
            activeMenu: activeMenu,
        });
    }, [open]);

    return (
        <>
            <ListItemButton
                className={className}
                component={RefNavLink}
                dataTestid={menu.contextType}
                title={ContextLabelMap.get(menu.contextType)}
                onMouseOver={() => setIsActive(true)}
                to={ContextRouteMap.get(
                    menuPresenter.makeContextTypeRoute(menu),
                )}
                target={menu.target}
                onClick={(e: any) => {
                    if (containSubmenu) {
                        e.preventDefault();
                        if (
                            collapseState?.isCollapseOpen &&
                            collapseState.activeMenu != menu.contextType
                        ) {
                            setCollapseState!({
                                ...collapseState!,
                                activeMenu: menu.contextType,
                            });
                        } else {
                            setCollapseState!({
                                ...collapseState!,
                                isCollapseOpen: !collapseState!.isCollapseOpen,
                                activeMenu: menu.contextType,
                            });
                        }
                    } else if (menu.contextType === activeMenu) {
                        e.preventDefault();
                    } else {
                        cleanFilter();
                    }
                }}
                sx={removeSpacing}
                isActive={menu.contextType == activeMenu}
            >
                <StyledListItemIcon>
                    {icons.get(menu.contextType)}
                </StyledListItemIcon>
                {Boolean(open) && (
                    <>
                        <StyledListItemText
                            isActive={menu.contextType == activeMenu}
                            className={`${isActive && 'isActive'}`}
                            primary={ContextLabelMap.get(menu.contextType)}
                            sx={{ textAlign: 'start' }}
                        />
                        {renderExpandIcon()}
                    </>
                )}
            </ListItemButton>
            {containSubmenu && open && (
                <SubmenuNavigation
                    menu={menu}
                    collapseState={collapseState!}
                    setCollapseOpen={setCollapseState!}
                    activePage={activePage}
                />
            )}
        </>
    );
}
