import { Controller } from 'react-hook-form';
import { DateTimePicker } from '@mui/x-date-pickers';
import defaultInputStyles from '../input-styles';
import StyledTextField from '../styled-text-field';

export default function DateTimePickerField({
    label,
    name,
    defaultValue,
    control,
    rules,
    readOnly = false,
    min,
    max,
    placeholder,
    dataTestId,
    size = 'normal',
}: any) {
    const { inputStyles } = defaultInputStyles(readOnly);

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue ? defaultValue : undefined}
            rules={rules}
            render={({
                field: { onChange, ...field },
                fieldState: { error },
            }) => (
                <DateTimePicker
                    {...field}
                    readOnly={readOnly}
                    label={label}
                    onChange={onChange}
                    renderInput={(params: any) => (
                        <StyledTextField
                            data-testid={dataTestId}
                            inputProps={{
                                readOnly: readOnly,
                                min,
                                max,
                            }}
                            className={size}
                            sx={inputStyles}
                            placeholder={placeholder}
                            {...params}
                            error={!!error}
                            helperText={error ? error?.message : null}
                            required={!!rules?.required}
                        />
                    )}
                />
            )}
        />
    );
}
