import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { RequestQueryMaker } from '@/domain/helpers';
import makeFinancialPresenter from '@/main/factories/presenters/pages/financial/finantial-presenter-factory';
import { StoreState } from '@/ui/store/models';
import { store } from '@/ui/store/config';
import { sidebarOpenAction } from '@/ui/store/modules';

import {
    CloseButton,
    CloseIcon,
    Drawer,
    FilterSidebarHeader,
    OrderDetailContent,
    StyledDivider,
} from '@/ui/components';
import { OrderDetailTitle } from '../../order/styles';
import DetailTable from './detail-table';
import {
    contextFinancialDetailHttpEndpoint,
    contextFinancialDetailTitle,
} from '.';
import { FilterContext } from '@/ui/context/context-filter';
import { ContextPaginationFilters } from '@/domain/models';
import { Column } from '@/ui/components/layout';
import { TotalValue } from '../styles';

type Anchor = 'right';

export default function FinancialDetail() {
    const [financialDetail, setFinancialDetail] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [isFinancialDetailOpen, setIsFinancialDetailOpen] = useState<any>({
        right: false,
    });

    const { getFiltersObject } = useContext(FilterContext);

    const filterParams = getFiltersObject();

    const filtersQuery = (): string => {
        const filters: {
            [key: string]: string | number | boolean;
        } = {};

        for (const key in filterParams) {
            if (!ContextPaginationFilters.get(key)) {
                filters[key] = filterParams[key];
            }
        }

        return RequestQueryMaker(filters);
    };

    const sidebar = useSelector((store: StoreState) => store.sidebar);

    const financialPresenter = makeFinancialPresenter();

    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event &&
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }
            setIsFinancialDetailOpen({
                ...isFinancialDetailOpen,
                [anchor]: open,
            });
        };

    const closeDrawer = (anchor: Anchor) => {
        setIsFinancialDetailOpen({
            ...isFinancialDetailOpen,
            [anchor]: false,
        });

        store.dispatch(
            sidebarOpenAction({
                ...sidebar,
                elementId: '',
                sidebarName: '',
                sidebarState: {
                    right: false,
                },
            }),
        );
    };

    useEffect(() => {
        if (
            sidebar.sidebarName === 'financial_negotiation_modal' ||
            sidebar.sidebarName === 'financial_negotiation_modal_edit' ||
            sidebar.sidebarName === 'financial_delete_negotiation_modal' ||
            !sidebar.sidebarName
        ) {
            return;
        }

        setIsFinancialDetailOpen(sidebar.sidebarState);
        setLoading(true);
        financialPresenter
            .getDetailAsync(
                filtersQuery(),
                contextFinancialDetailHttpEndpoint.get(sidebar.sidebarName),
            )
            .then((result: any) => {
                setFinancialDetail(result);
                sidebarOpenAction({
                    ...sidebar,
                    elementData: result,
                });
            })
            .then(() => setLoading(false));
    }, [sidebar.sidebarState, sidebar.sidebarState.right]);

    useEffect(() => {
        store.dispatch(
            sidebarOpenAction({ ...sidebar, elementData: financialDetail }),
        );
    }, [financialDetail]);

    const list = (anchor: Anchor) => (
        <Drawer role="financial detail">
            <FilterSidebarHeader className="financial">
                <Column>
                    <OrderDetailTitle>
                        <h2>
                            {contextFinancialDetailTitle.get(
                                sidebar.sidebarName,
                            )}
                        </h2>
                    </OrderDetailTitle>
                    {sidebar.sidebarName === 'monthly-payments' && (
                        <TotalValue>
                            Total de geladeiras: {financialDetail?.length}
                        </TotalValue>
                    )}
                </Column>
                <CloseButton
                    className="sidebar-close-icon"
                    onClick={toggleDrawer(anchor, false)}
                    data-testid="close-button"
                >
                    <CloseIcon />
                </CloseButton>
            </FilterSidebarHeader>
            <StyledDivider />
            <OrderDetailContent>
                {Boolean(financialDetail) && (
                    <DetailTable
                        financialDetailData={financialDetail && financialDetail}
                        loading={loading}
                    />
                )}
            </OrderDetailContent>
        </Drawer>
    );

    return (
        <div>
            {(['right'] as const).map((anchor, index) => (
                <>
                    <SwipeableDrawer
                        key={anchor + index}
                        anchor={anchor}
                        open={isFinancialDetailOpen[anchor]}
                        onClose={() => closeDrawer(anchor)}
                        onOpen={() => toggleDrawer(anchor, true)}
                    >
                        {list(anchor)}
                    </SwipeableDrawer>
                </>
            ))}
        </div>
    );
}
