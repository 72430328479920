import {
    IFinishOrderReview,
    IOrderList,
    IOrderMedias,
    IOrderReview,
    IRefundCalculate,
} from '@/domain/models';
import { IOrderRepositoryPort } from '@/infra';
import { IOrderPresenter } from '@/ui/pages/order';

export default class OrderListPresenter implements IOrderPresenter {
    constructor(private readonly orderRepository: IOrderRepositoryPort) {}

    async getOrderListAsync(params: any): Promise<any> {
        const response = await this.orderRepository.getOrders<any>(params);
        const orderListData: IOrderList[] = response.data!.content!;
        const totalPages = response.data.totalPages;
        const totalItems = response.data.totalElements;

        return { orderListData, totalPages, totalItems };
    }

    async getOrderDetail(orderId: string): Promise<any> {
        const response =
            orderId &&
            (await this.orderRepository.getOrderDetail<any>(orderId));

        const orderDetailData: any = response ? response.data! : [];

        return orderDetailData;
    }

    async getSimpleReportXls(params: string, successNotification: any) {
        const response = await this.orderRepository.exportSimpleXls<any>(
            params,
        );

        return successNotification(response);
    }

    async getSimpleReportPdf(params: string, successNotification: any) {
        const response = await this.orderRepository.exportSimplePdf<any>(
            params,
        );

        return successNotification(response);
    }

    async getDetailedReportXls(params: string, successNotification: any) {
        const response = await this.orderRepository.exportDetailedXls<any>(
            params,
        );

        return successNotification(response);
    }

    async getNfceXls() {
        const response = await this.orderRepository.exportNfceXls<any>();

        return response.data;
    }

    async visualizeNfc(orderId: string): Promise<{ url: string }> {
        const response = await this.orderRepository.visualizeNfc<any>(orderId);

        return response.data;
    }

    async visualizeInvoice(orderId: string): Promise<{ url: string }> {
        const response = await this.orderRepository.visualizeInvoice<any>(
            orderId,
        );

        return response.data;
    }

    async getOrderImages(orderId: string): Promise<IOrderMedias> {
        const response = await this.orderRepository.getOrderMedias<any>(
            orderId,
        );

        const orderMedias: IOrderMedias = response.data;

        return orderMedias;
    }

    async getOrderReview(orderId: string): Promise<IOrderReview> {
        return this.orderRepository
            .getOrderReview<IOrderReview>(orderId)
            .then(response => response.data);
    }

    async finishOrderReview(
        orderId: string,
        data: IFinishOrderReview,
    ): Promise<any> {
        return this.orderRepository
            .finishOrderReview<any>(orderId, data)
            .then(response => response);
    }

    async getDeadbeatReport(params: string) {
        return await this.orderRepository
            .getDeadbeatReport<any>(params)
            .then(res => res.data);
    }

    async postRefundCalculate(orderIds: number[]): Promise<IRefundCalculate[]> {
        return await this.orderRepository
            .postRefundCalculate<IRefundCalculate[]>(orderIds)
            .then(res => res.data);
    }

    async postRefundApply(orderIds: number[]): Promise<any> {
        return await this.orderRepository
            .postRefundApply<any>(orderIds)
            .then(res => res.data);
    }

    async postReprocessOrder(orderId: number): Promise<any> {
        return await this.orderRepository
            .postReprocessOrder<any>(orderId)
            .then(res => res.data);
    }

    statusOptions = [
        { label: 'Cancelado', id: 'CANCELED' },
        { label: 'Fatura manual', id: 'MANUAL_BILLING' },
        { label: 'Contestação aprovada', id: 'CONTESTATION_APPROVED' },
        { label: 'Contestação bancária', id: 'BANK_DISPUTE' },
        { label: 'Contestação recusada', id: 'CONTESTATION_DENIED' },
        { label: 'Em contestação', id: 'CONTESTATION_IN_PROGRESS' },
        { label: 'Enviando', id: 'OPEN' },
        { label: 'Problema', id: 'PROBLEMS' },
        { label: 'Processado', id: 'SUCCESS' },
        { label: 'Processando', id: 'AWAITING_REVIEW' },
        { label: 'Reposição', id: 'REPLACEMENT_PRODUCT' },
        { label: 'Ressarcido', id: 'REFUNDED' },
    ];

    orderTypeOptions = [
        { label: 'Reposição', id: 'REPOSITION' },
        { label: 'Consumo', id: 'CONSUME' },
        { label: 'Reposição de SETUP', id: 'REPOSITION_SETUP' },
        { label: 'Promoter', id: 'PROMOTER' },
    ];

    getStatus(): any {
        enum orderStatus {
            SUCCESS = 'SUCCESS',
            PROBLEMS = 'PROBLEMS',
            OPEN = 'OPEN',
            AWAITING_REVIEW = 'AWAITING_REVIEW',
            CANCELED = 'CANCELED',
            REPLACEMENT_PRODUCT = 'REPLACEMENT_PRODUCT',
            CONTESTATION_IN_PROGRESS = 'CONTESTATION_IN_PROGRESS',
            CONTESTATION_APPROVED = 'CONTESTATION_APPROVED',
            CONTESTATION_DENIED = 'CONTESTATION_DENIED',
            MANUAL_BILLING = 'MANUAL_BILLING',
            BANK_DISPUTE = 'BANK_DISPUTE',
            REFUNDED = 'REFUNDED',
        }

        const statusText = new Map<string, string>([
            [orderStatus.SUCCESS, 'Processado'],
            [orderStatus.PROBLEMS, 'Problemas'],
            [orderStatus.OPEN, 'Aguardando processamento'],
            [orderStatus.AWAITING_REVIEW, 'Processando'],
            [orderStatus.CANCELED, 'Cancelado'],
            [orderStatus.REPLACEMENT_PRODUCT, 'Reposição'],
            [orderStatus.CONTESTATION_IN_PROGRESS, 'Em contestação'],
            [orderStatus.CONTESTATION_APPROVED, 'Contestação aprovada'],
            [orderStatus.CONTESTATION_DENIED, 'Contestação recusada'],
            [orderStatus.MANUAL_BILLING, 'Fatura manual'],
            [orderStatus.BANK_DISPUTE, 'Contestação bancária'],
            [orderStatus.REFUNDED, 'Ressarcido'],
        ]);

        const statusClassName = new Map<string, string>([
            [orderStatus.SUCCESS, 'success'],
            [orderStatus.PROBLEMS, 'problems'],
            [orderStatus.OPEN, 'open'],
            [orderStatus.AWAITING_REVIEW, 'processing'],
            [orderStatus.CANCELED, 'canceled'],
            [orderStatus.REPLACEMENT_PRODUCT, 'reposition'],
            [orderStatus.CONTESTATION_IN_PROGRESS, 'contestation'],
            [orderStatus.CONTESTATION_APPROVED, 'contestation-approved'],
            [orderStatus.CONTESTATION_DENIED, 'contestation-approved'],
            [orderStatus.MANUAL_BILLING, 'manual-billing'],
            [orderStatus.BANK_DISPUTE, 'bank-dispute'],
            [orderStatus.REFUNDED, 'refunded'],
        ]);

        return { statusText, statusClassName };
    }
}
