import { IFilterOption } from '@/ui/context/context-filter/type';

export default class FinancialFilterParams {
    date: IFilterOption;
    coolerId: IFilterOption;
    licensedId: IFilterOption;
    constructor(
        date: string | any,
        coolerId: string | number,
        licensedId: string | number,
        coolerLabel: string,
        licensedLabel: string,
    ) {
        this.date = {
            name: 'date',
            id: date,
            label: date,
        };
        this.coolerId = {
            name: 'coolerId',
            id: coolerId,
            label: coolerLabel,
        };
        this.licensedId = {
            name: 'licensedId',
            id: licensedId,
            label: licensedLabel,
        };
    }
}
