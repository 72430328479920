import { makeRefrigeratorStockPresenter } from '@/main/factories';
import { RefrigeratorStockPercentage, RefrigeratorTitle } from '../styles';
import { IRefrigeratorStock } from '@/domain/models';
import { Row } from '@/ui/components/layout';

interface IRefrigeratorCardHead {
    refrigerator: IRefrigeratorStock;
}

export default function RefrigeratorCardHead({
    refrigerator,
}: IRefrigeratorCardHead): JSX.Element {
    const refrigeratorStockPresenter = makeRefrigeratorStockPresenter();

    return (
        <Row justify="space-between">
            <RefrigeratorTitle>
                <h2>{refrigerator.location}</h2>
                <h4>cod.: {refrigerator.refrigeratorCode}</h4>
            </RefrigeratorTitle>
            <RefrigeratorTitle className="stock-text">
                {refrigerator.products.length > 0 && (
                    <>
                        <h2>Estoque</h2>
                        <RefrigeratorStockPercentage
                            className={refrigeratorStockPresenter.getClassColor(
                                refrigerator.stockPercentage,
                            )}
                        >
                            {refrigerator.stockPercentage}%
                        </RefrigeratorStockPercentage>
                    </>
                )}
            </RefrigeratorTitle>
        </Row>
    );
}
