import {
    makeSearchRepository,
    makeUserRepository,
} from '@/main/factories/repositories';
import { ITableServerSidePresenter } from '@/ui/components/table';
import { UserTablePresenter } from '@/presentation/presenters';

const makeUserTablePresenter = (): ITableServerSidePresenter =>
    new UserTablePresenter(makeSearchRepository(), makeUserRepository());

export default makeUserTablePresenter;
