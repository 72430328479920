import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { TableIcons } from '@/domain/models';
import { StoreState } from '@/ui/store/models';
import StyledSvgIcon from './svg-icon.styles';

export default function FinancialIcon() {
    const currentTheme = useTheme();
    const activeMenu = useSelector(
        (currentStore: StoreState) => currentStore.navigation.activeMenu,
    );

    return (
        <StyledSvgIcon
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5 13.3334C5 15.1744 6.49238 16.6667 8.33333 16.6667H11.6667C13.5076 16.6667 15 15.1744 15 13.3334C15 11.4925 13.5076 10.0001 11.6667 10.0001H8.33333C6.49238 10.0001 5 8.5077 5 6.66675C5 4.8258 6.49238 3.33341 8.33333 3.33341H11.6667C13.5076 3.33341 15 4.8258 15 6.66675M10 1.66675V18.3334"
                stroke={
                    activeMenu === TableIcons.FINANCIAL
                        ? currentTheme.secondary
                        : currentTheme.quaternary
                }
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </StyledSvgIcon>
    );
}
