import styled from 'styled-components';

const StockTable = styled.table`
    padding: 0px;
    margin: 40px 0px 15px 0px;
    width: 100%;

    tr {
        th {
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 140%;
            color: ${props => props.theme.tableText};
            text-align: start;
            margin-bottom: 27px;
            min-width: 55px;

            &.start {
                text-align: start;
                color: ${props => props.theme.secondary};
            }

            &.end {
                text-align: center;
            }

            @media (max-width: 650px) {
                font-size: 11px;
            }

            @media (max-width: 376px) {
                font-size: 10px;
            }
        }

        td {
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            text-align: center;
            color: ${props => props.theme.tableText};
            text-align: start;

            &.start {
                text-align: start;
                align-items: center;
                color: ${props => props.theme.secondary};
            }

            &.end {
                text-align: center;
            }

            &.stock {
                display: flex;
                align-items: center;
                justify-content: center;
                padding-top: 8px;
            }

            &.start {
                position: relative;
            }
            @media (max-width: 650px) {
                font-size: 12px;
            }

            @media (max-width: 376px) {
                font-size: 12px;
            }
        }
    }
`;

export default StockTable;
