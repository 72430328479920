import { IAutocomplete, IUserSaveInputs } from '@/domain/models';
import {
    AutoCompleteField,
    DatePickerField,
    InputPhoneNumber,
    InputTextField,
    MaskedInputTextField,
} from '@/ui/components';
import { Grid } from '@/ui/components/layout';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Control } from 'react-hook-form';

interface IUserData {
    control: Control<IUserSaveInputs>;
    genderOptions: IAutocomplete[];
}

export default function UserData({ control, genderOptions }: IUserData) {
    return (
        <>
            <h5>Dados do usuário</h5>
            <Grid
                columns={4}
                gap={{
                    row: 6,
                    column: 4,
                }}
            >
                <InputTextField
                    control={control}
                    name="name"
                    label="Nome"
                    rules={{
                        required: 'Nome é obrigatório',
                    }}
                />
                <InputTextField
                    control={control}
                    name="email"
                    label="E-mail"
                    rules={{
                        required: 'E-mail é obrigatório',
                    }}
                />
                <MaskedInputTextField
                    control={control}
                    name="cpf"
                    label="CPF"
                    mask="999.999.999-99"
                    rules={{
                        required: 'CPF é obrigatório',
                    }}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePickerField
                        control={control}
                        name="birthDate"
                        label="Data de nascimento"
                        rules={{
                            required: 'Data de nascimento é obrigatório',
                        }}
                    />
                </LocalizationProvider>
                <InputPhoneNumber
                    control={control}
                    name="telephone"
                    label="Telefone"
                    rules={{
                        required: 'Telefone é obrigatório',
                    }}
                />
                <AutoCompleteField
                    control={control}
                    name="gender"
                    label="Gênero"
                    options={genderOptions}
                />
            </Grid>
        </>
    );
}
