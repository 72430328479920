import { Column } from '@/ui/components/layout';
import styled from 'styled-components';

const RefrigeratorTitle = styled(Column)`
    text-align: start;

    h2 {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        margin: 0px;

        @media (max-width: 650px) {
            font-size: 14px;
        }
    }

    h4 {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;

        line-height: 140%;
        margin: 0px;
        color: ${props => props.theme.tableText};
    }

    &.stock-text {
        flex-direction: row;
    }
`;

export default RefrigeratorTitle;
