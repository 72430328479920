import { useTheme } from 'styled-components';
import StyledSvgIcon from './svg-icon.styles';

export default function CloseIcon() {
    const theme = useTheme();

    return (
        <StyledSvgIcon
            width="24"
            height="24"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ textAlign: 'center', paddingTop: 5, paddingLeft: 1.5 }}
        >
            <path
                d="M11 1L1 11M1 1L11 11"
                stroke={theme.secondary}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </StyledSvgIcon>
    );
}
