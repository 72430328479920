import { useContext, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Params, useNavigate, useParams } from 'react-router-dom';
import {
    ContextType,
    IProductsCategoryForm,
    TableIcons,
} from '@/domain/models';
import makeProductsPresenter from '@/main/factories/presenters/pages/products/product-presenter-factory';
import { NotificationContext } from '@/main';
import { InputTextField } from '@/ui/components';
import { store } from '@/ui/store/config';
import selectNavigationAction from '@/ui/store/modules/app/navigation/actions';
import Default from '../../default';
import { Column, Grid } from '@/ui/components/layout';
import { NavigationAppState } from '@/ui/store/models';
import { useMutation } from 'react-query';

export default function ProductsCategoryEdit(): JSX.Element {
    const {
        handleSubmit,
        control,
        reset,
        formState: { isDirty, isValid },
    } = useForm<IProductsCategoryForm>({
        mode: 'all',
        defaultValues: {
            description: '',
        },
    });

    const { successNotification, errorNotification } =
        useContext(NotificationContext);

    const navigate = useNavigate();

    const { categoryId }: Readonly<Params<string>> = useParams();

    const productsPresenter = makeProductsPresenter();

    const { mutate: createCategory, isLoading: isCreatingCategory } =
        useMutation(
            (description: string) =>
                productsPresenter.createCategory(description),
            {
                onSuccess: (res: any) => {
                    successNotification('Categoria criada com sucesso!');
                    navigate(
                        `/product/category/${res.data.productCategoryId}`,
                        {
                            state: { from: 'save' },
                        },
                    );
                },
                onError: (err: any) => errorNotification(err.message),
            },
        );

    const { mutate: updateCategory, isLoading: isUpdatingCategory } =
        useMutation(
            ({
                description,
                categoryId,
            }: {
                description: string;
                categoryId: string;
            }) => productsPresenter.updateCategory(description, categoryId),
            {
                onSuccess: () => {
                    successNotification('Categoria atualizada com sucesso!');
                    window.location.reload();
                },
                onError: (err: any) => errorNotification(err.message),
            },
        );

    const onSubmit: SubmitHandler<IProductsCategoryForm> = data => {
        if (categoryId) {
            return updateCategory({
                description: data.description,
                categoryId,
            });
        }
        return createCategory(data.description);
    };

    useEffect(() => {
        if (categoryId) {
            productsPresenter.getCategoryById(categoryId).then(res => {
                store.dispatch(
                    selectNavigationAction(
                        new NavigationAppState(
                            ContextType.product_category_edit,
                            ContextType.product_category,
                            TableIcons.PRODUCTS,
                            res.data.description,
                        ),
                    ),
                );

                reset({
                    description: res.data.description,
                });
            });

            return;
        }

        !categoryId &&
            store.dispatch(
                selectNavigationAction(
                    new NavigationAppState(
                        ContextType.product_category_save,
                        ContextType.product_category,
                        TableIcons.PRODUCTS,
                    ),
                ),
            );
    }, []);

    const isButtonDisabled =
        !isDirty || !isValid || isCreatingCategory || isUpdatingCategory;

    return (
        <Default
            contextType={ContextType.product_save}
            isSubmitDisabled={isButtonDisabled}
        >
            <Column
                as="form"
                id="product-category-form"
                onSubmit={handleSubmit(onSubmit)}
            >
                <h5>Dados da categoria</h5>
                <Grid columns={2} gap={6}>
                    {categoryId && (
                        <InputTextField
                            control={control}
                            name="id"
                            label="Código"
                            readOnly
                            defaultValue={categoryId}
                        />
                    )}
                    <InputTextField
                        control={control}
                        name="description"
                        label="Descrição"
                        rules={{
                            required: 'Descrição é obrigatória',
                        }}
                    />
                </Grid>
            </Column>
        </Default>
    );
}
