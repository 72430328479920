enum IncomingMessages {
    invalid_link = 'Link inválido!',
    expired_link = 'Link expirado. Por favor, tente novamente.',
}

const NewPasswordMessagesMap = new Map<string, string>([
    [IncomingMessages.invalid_link, 'Link de recuperação inválido!'],
    [IncomingMessages.expired_link, 'O link de recuperação expirou.'],
]);

export default NewPasswordMessagesMap;
