import styled from 'styled-components';

export const DeleteButton = styled.button`
    background: none;
    border: none;
`;

export const ProductsTableContainer = styled.div`
    @media (max-width: 650px) {
        max-width: 100vw;
        overflow-x: auto;
    }
`;
