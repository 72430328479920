import { IAutocomplete, IProductsForm } from '@/domain/models';
import { checkboxStyles } from '@/ui/components/input/checkbox/styles';
import { Checkbox, FormControlLabel } from '@mui/material';
import { UseFormSetValue } from 'react-hook-form';

interface IMixCheckboxProps {
    mix: IAutocomplete;
    mixData: string[];
    setValue: UseFormSetValue<IProductsForm>;
    readonly?: boolean;
}

export default function MixCheckbox(props: IMixCheckboxProps): JSX.Element {
    const { mix, mixData, setValue, readonly = false } = props;

    const styles = checkboxStyles(readonly);

    return (
        <FormControlLabel
            label={mix.label}
            sx={styles}
            control={
                <Checkbox
                    style={styles}
                    key={mix.id}
                    sx={styles}
                    checked={mixData.some(
                        (mixChecked: string) => mixChecked === mix.id,
                    )}
                    onChange={event => {
                        if (event.target.checked) {
                            const mixes = mixData.concat(mix.id);
                            setValue('mixData', mixes, {
                                shouldDirty: true,
                                shouldTouch: true,
                                shouldValidate: true,
                            });
                        } else {
                            const mixes = mixData;
                            const index = mixes.indexOf(
                                mixes.find(res => res === mix.id)!,
                            );
                            if (index > -1) {
                                mixes.splice(index, 1);
                                setValue('mixData', mixes, {
                                    shouldDirty: true,
                                    shouldTouch: true,
                                    shouldValidate: true,
                                });
                            }
                        }
                    }}
                />
            }
        />
    );
}
