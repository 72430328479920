import styled from 'styled-components';
import { Row } from '../../layout';

export const SelectedFilterContainer = styled(Row)`
    color: ${props => props.theme.secondary};
    gap: 16px;
    align-items: center;
    border: none;
    background: none;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    text-align: start;
    flex-wrap: wrap;
    padding: 0 28px;
`;

export const CleanFilter = styled.button`
    border: none;
    background: none;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    text-align: start;
    color: #007db7;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
`;

export const FilterItem = styled(Row)`
    border: 1px solid ${props => props.theme.tableBorder};
    border-radius: 40px;
    position: relative;
    color: ${props => props.theme.secondary};
    padding: 8px 12px;
    margin-left: 5px;
    align-items: center;
`;

export const RemoveButton = styled.button`
    cursor: pointer;
    margin-left: 5px;
    background: none;
    border: none;
    color: ${props => props.theme.tableBorder};
`;
