import { HeaderDefault } from '@/ui/layouts';
import { MainContainer } from './styles';
import { SubHeader } from '@/ui/components';
import { overflow } from '@/ui/components/layout/types';
import { useBreakpoints } from '@/ui/helpers';

interface IDefaultProps {
    children: React.ReactNode;
    contextType: string;
    hiddenButtons?: boolean;
    isSubmitDisabled?: boolean | boolean[];
    showSubHeader?: boolean;
    overflow?: overflow;
    height?: string;
}

export default function Default({
    children,
    contextType,
    hiddenButtons,
    isSubmitDisabled,
    showSubHeader = true,
    overflow = 'auto',
    height = undefined,
}: IDefaultProps): JSX.Element {
    const { isXs } = useBreakpoints();

    const mainPadding = isXs
        ? { left: 3, right: 3, bottom: 5 }
        : { left: 7, right: 7, bottom: 5 };

    return (
        <>
            {showSubHeader && (
                <SubHeader
                    hiddenButtons={hiddenButtons}
                    isSubmitDisabled={isSubmitDisabled}
                />
            )}
            <MainContainer
                data-testid={contextType + '-container'}
                as="main"
                padding={mainPadding}
                margin={{ top: 4 }}
                id="context-type"
                data-context-type={contextType}
                overflow={overflow}
                height={height}
            >
                {children}
            </MainContainer>
        </>
    );
}

Default.defaultProps = {
    Header: HeaderDefault,
};
