export default function TakeLogo() {
    return (
        <svg
            cursor={'pointer'}
            width="21"
            height="28"
            viewBox="0 0 21 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.4 21C7.62926 21 7 20.3707 7 19.6V8.4C7 7.62926 7.62926 7 8.4 7H12.6C13.3707 7 14 7.62926 14 8.4V19.6C14 20.3707 13.3707 21 12.6 21H8.4ZM19.6 0H8.4C7.62926 0 7 0.629255 7 1.4V5.6C7 6.37074 6.37074 7 5.6 7H1.4C0.629255 7 0 7.62926 0 8.4V26.6C0 27.3707 0.629255 28 1.4 28H12.6C13.3707 28 14 27.3707 14 26.6V22.4C14 21.6293 14.6293 21 15.4 21H19.6C20.3707 21 21 20.3707 21 19.6V1.4C21 0.629255 20.3707 0 19.6 0Z"
                fill="#FFCD00"
            />
        </svg>
    );
}
