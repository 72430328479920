import StyledSvgIcon from './svg-icon.styles';

export default function AmexCardIcon(): JSX.Element {
    return (
        <StyledSvgIcon
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="64"
            id="american-express"
        >
            <path fill="#2FABF7" d="M5.9 26.5h2.2L7 23.7z"></path>
            <path
                fill="#228FE0"
                d="M33.2 24.1c-.2-.1-.5-.1-.8-.1h-2v1.6h2c.3 0 .6 0 .8-.1.2-.1.3-.4.3-.7.1-.4-.1-.6-.3-.7z"
            ></path>
            <path
                fill="#0571C1"
                d="M54.6 21.1v1.2l-.6-1.2h-4.7v1.2l-.6-1.2h-6.4c-1.1 0-2 .2-2.8.6v-.6H35v.6c-.5-.4-1.1-.6-1.9-.6H17l-1.1 2.5-1.1-2.5H9.7v1.2l-.6-1.2H4.8l-2 4.7L.5 31h5.1l.6-1.6h1.4l.6 1.6H14v-1.2l.5 1.2h2.9l.5-1.2V31h13.9v-2.6h.2c.2 0 .2 0 .2.3v2.2h7.2v-.6c.6.3 1.5.6 2.7.6h3l.6-1.6h1.4l.6 1.6h5.8v-1.5l.9 1.5h4.7v-9.8h-4.5zm-33.8 8.4h-1.7V24l-2.4 5.5h-1.5L12.8 24v5.5H9.4L8.7 28H5.3l-.6 1.6H2.8l3-7.1h2.5l2.8 6.7v-6.7h2.7l2.2 4.8 2-4.8h2.8v7zm6.8-5.5h-3.9v1.3h3.8v1.4h-3.8v1.4h3.9v1.5H22v-7.1h5.6V24zm7.5 2.9c.2.4.3.7.3 1.3v1.4h-1.7v-.9c0-.4 0-1-.3-1.4-.3-.3-.6-.3-1.2-.3h-1.8v2.6h-1.7v-7.1h3.8c.9 0 1.5 0 2 .3s.8.8.8 1.6c0 1.1-.7 1.7-1.2 1.9.5.1.8.4 1 .6zm3 2.6h-1.7v-7.1h1.7v7.1zm19.7 0h-2.4l-3.2-5.3v5.3h-3.4l-.6-1.5h-3.5l-.6 1.6h-1.9c-.8 0-1.8-.2-2.4-.8-.6-.6-.9-1.4-.9-2.7 0-1 .2-2 .9-2.8.5-.6 1.4-.8 2.5-.8h1.6V24h-1.6c-.6 0-.9.1-1.3.4-.3.3-.5.9-.5 1.6 0 .8.1 1.3.5 1.7.3.3.7.4 1.2.4h.7l2.3-5.5h2.5l2.8 6.7v-6.7H53l2.9 4.9v-4.9h1.7v6.9z"
            ></path>
            <path
                fill="#228FE0"
                d="M45.3 26.5h2.3l-1.1-2.8zM28.3 40.9v-5.7L25.7 38z"
            ></path>
            <path
                fill="#2FABF7"
                d="M17.6 35.9v1.3h3.7v1.4h-3.7v1.5h4.1l1.9-2.1-1.8-2.1z"
            ></path>
            <path
                fill="#228FE0"
                d="M32.1 35.9H30v1.8h2.2c.6 0 1-.3 1-.9-.1-.6-.5-.9-1.1-.9z"
            ></path>
            <path
                fill="#0571C1"
                d="M63 37.6v-4.5h-4.2c-.9 0-1.6.2-2.1.6v-.6h-4.6c-.7 0-1.6.2-2 .6v-.6H42v.6c-.6-.5-1.7-.6-2.2-.6h-5.4v.6c-.5-.5-1.7-.6-2.3-.6h-6l-1.4 1.5-1.3-1.5h-9v9.8h8.8l1.4-1.5 1.3 1.5h5.4v-2.3h.7c.7 0 1.6 0 2.3-.3V43h4.5v-2.6h.2c.3 0 .3 0 .3.3V43h13.6c.9 0 1.8-.2 2.3-.6v.6h4.3c.9 0 1.8-.1 2.4-.5 1-.6 1.6-1.7 1.6-3 0-.7-.2-1.4-.5-1.9zm-31 1.6h-2v2.4h-3.2l-2-2.3-2.1 2.3h-6.6v-7.1h6.7l2 2.3 2.1-2.3h5.3c1.3 0 2.8.4 2.8 2.3-.1 2-1.5 2.4-3 2.4zm10-.4c.2.3.3.7.3 1.3v1.4h-1.7v-.9c0-.4 0-1.1-.3-1.4-.2-.3-.6-.3-1.2-.3h-1.8v2.6h-1.7v-7.1h3.8c.8 0 1.5 0 2 .3s.9.8.9 1.6c0 1.1-.7 1.7-1.2 1.9.5.2.8.4.9.6zm6.9-2.9H45v1.3h3.8v1.4H45V40h3.9v1.5h-5.6v-7.1h5.6v1.5zm4.2 5.6h-3.2V40h3.2c.3 0 .5 0 .7-.2.1-.1.2-.3.2-.5s-.1-.4-.2-.5c-.1-.1-.3-.2-.6-.2-1.6-.1-3.5 0-3.5-2.2 0-1 .6-2.1 2.4-2.1h3.3V36h-3.1c-.3 0-.5 0-.7.1-.2.1-.2.3-.2.5 0 .3.2.4.4.5.2.1.4.1.6.1h.9c.9 0 1.5.2 1.9.6.3.3.5.8.5 1.5 0 1.5-.9 2.2-2.6 2.2zm8.6-.7c-.4.4-1.1.7-2.1.7h-3.2V40h3.2c.3 0 .5 0 .7-.2.1-.1.2-.3.2-.5s-.1-.4-.2-.5c-.1-.1-.3-.2-.6-.2-1.6-.1-3.5 0-3.5-2.2 0-1 .6-2.1 2.4-2.1h3.3V36h-3c-.3 0-.5 0-.7.1-.2.1-.2.3-.2.5 0 .3.1.4.4.5.2.1.4.1.6.1h.9c.9 0 1.5.2 1.9.6.1 0 .1.1.1.1.3.4.4.9.4 1.4 0 .6-.2 1.1-.6 1.5z"
            ></path>
            <path
                fill="#228FE0"
                d="M40.2 36.1c-.2-.1-.5-.1-.8-.1h-2v1.6h2c.3 0 .6 0 .8-.1.2-.1.3-.4.3-.7.1-.4-.1-.6-.3-.7z"
            ></path>
            <path
                fill="#228FE0"
                d="M33.2 24.1c-.2-.1-.5-.1-.8-.1h-2v1.6h2c.3 0 .6 0 .8-.1.2-.1.3-.4.3-.7.1-.4-.1-.6-.3-.7zM45.3 26.5h2.3l-1.1-2.8zM28.3 40.9v-5.7L25.7 38zM32.1 35.9H30v1.8h2.2c.6 0 1-.3 1-.9-.1-.6-.5-.9-1.1-.9zM40.2 36.1c-.2-.1-.5-.1-.8-.1h-2v1.6h2c.3 0 .6 0 .8-.1.2-.1.3-.4.3-.7.1-.4-.1-.6-.3-.7z"
            ></path>
            <path
                fill="#2FABF7"
                d="M31.4 41.3 30 39.8v1.7h-3.3l-2-2.3-2.2 2.3h-6.6v-7h6.7l2.1 2.3 1-1.2-2.5-2.5h-8.8v9.8h8.8l1.5-1.5 1.3 1.5h5.4z"
            ></path>
            <g fill="#2FABF7">
                <path d="m21 30.9-1.3-1.4h-.6v-.6l-1.5-1.5-1 2.1h-1.4L12.8 24v5.5H9.4L8.7 28H5.3l-.7 1.5H2.8l3-7h2.5l2.8 6.7v-6.7h1.5l-1.4-1.4H9.7v1.2l-.5-1.2H4.8l-2 4.7-2.3 5.1h5.2l.6-1.5h1.4l.7 1.5H14v-1.2l.5 1.2h2.9l.5-1.2v1.2z"></path>
                <path d="m16.4 26.3-1.6-1.6 1.2 2.6z"></path>
            </g>
            <g fill="#228FE0">
                <path d="M61.9 42.4c.9-.6 1.5-1.6 1.6-2.7l-1.4-1.4c.1.3.2.6.2 1 0 .6-.2 1.1-.6 1.5-.4.4-1.1.7-2.1.7h-3.2V40h3.2c.3 0 .5 0 .7-.2.1-.1.2-.3.2-.5s-.1-.4-.2-.5c-.1-.1-.3-.2-.6-.2-1.6-.1-3.5 0-3.5-2.2 0-1 .6-1.9 2.1-2.1l-1.1-1.1c-.2.1-.3.2-.4.2v-.6h-4.6c-.7 0-1.6.2-2 .6v-.6H42v.6c-.6-.5-1.7-.6-2.2-.6h-5.4v.6c-.5-.5-1.7-.6-2.3-.6h-6l-1.4 1.5-1.3-1.5h-1.1l3 3 1.5-1.6h5.3c1.3 0 2.8.4 2.8 2.3 0 2-1.4 2.4-2.9 2.4h-2v1.5l1.5 1.5v-1.5h.5c.7 0 1.6 0 2.3-.3v2.7h4.5v-2.6h.2c.3 0 .3 0 .3.3v2.3h13.6c.9 0 1.8-.2 2.3-.6v.6h4.3c.8.1 1.7 0 2.4-.4zM42 38.8c.2.3.3.7.3 1.3v1.4h-1.7v-.9c0-.4 0-1.1-.3-1.4-.2-.3-.6-.3-1.2-.3h-1.8v2.6h-1.7v-7.1h3.8c.8 0 1.5 0 2 .3s.9.8.9 1.6c0 1.1-.7 1.7-1.2 1.9.5.2.8.4.9.6zm6.9-2.9H45v1.3h3.8v1.4H45V40h3.9v1.5h-5.6v-7.1h5.6v1.5zm4.2 5.6h-3.2V40h3.2c.3 0 .5 0 .7-.2.1-.1.2-.3.2-.5s-.1-.4-.2-.5c-.1-.1-.3-.2-.6-.2-1.6-.1-3.5 0-3.5-2.2 0-1 .6-2.1 2.4-2.1h3.3V36h-3.1c-.3 0-.5 0-.7.1-.2.1-.2.3-.2.5 0 .3.2.4.4.5.2.1.4.1.6.1h.9c.9 0 1.5.2 1.9.6.3.3.5.8.5 1.5 0 1.5-.9 2.2-2.6 2.2z"></path>
                <path d="M57.9 36.6c0 .3.1.4.4.5.2.1.4.1.6.1h.9c.6 0 1 .1 1.4.3L59.7 36h-.9c-.3 0-.5 0-.7.1-.1.1-.2.3-.2.5z"></path>
            </g>
            <g fill="#228FE0">
                <path d="m54.4 30.6.2.3h.1zM48.9 25.1l1.7 4.1v-2.4z"></path>
                <path d="M31.8 28.4h.2c.2 0 .2 0 .2.3v2.2h7.2v-.6c.6.3 1.5.6 2.7.6h3l.6-1.6h1.4l.6 1.6h5.8v-1l-1.4-1.4v1.1h-3.4l-.5-1.6h-3.5l-.6 1.6h-1.9c-.8 0-1.8-.2-2.4-.8-.6-.6-.9-1.4-.9-2.7 0-1 .2-2 .9-2.8.5-.6 1.4-.8 2.5-.8h1.6V24h-1.6c-.6 0-.9.1-1.3.4-.3.3-.5.9-.5 1.6 0 .8.1 1.3.5 1.7.3.3.7.4 1.2.4h.7l2.3-5.5h1l-1.4-1.4h-2.6c-1.1 0-2 .2-2.8.6v-.6H35v.6c-.5-.4-1.1-.6-1.9-.6H17l-1.1 2.5-1.1-2.5h-4.4l1.4 1.4h2l1.7 3.7.6.6 1.8-4.4h2.8v7.1H19v-5.5l-1.7 4 2.9 2.9h11.5l.1-2.6zm4.6-5.9h1.7v7.1h-1.7v-7.1zM27.6 24h-3.9v1.3h3.8v1.4h-3.8v1.4h3.9v1.5H22v-7.1h5.6V24zm2.8 5.5h-1.7v-7.1h3.8c.9 0 1.5 0 2 .3s.8.8.8 1.6c0 1.1-.7 1.7-1.2 1.9.4.1.7.4.8.6.2.4.3.7.3 1.3v1.4h-1.7v-.9c0-.4 0-1-.3-1.4-.1-.2-.4-.2-1-.2h-1.8v2.5z"></path>
            </g>
        </StyledSvgIcon>
    );
}
