import { useEffect, useState } from 'react';
import { IUseCount } from '../interfaces';

export default function useCount(props: IUseCount) {
    const { initialValue, endValue } = props;

    const [count, setCount] = useState(initialValue);

    useEffect(() => {
        setCount(initialValue);

        const step = initialValue > endValue ? -1 : 1;
        const interval = setInterval(() => {
            setCount(prevCount => {
                if (
                    (step === 1 && prevCount < endValue) ||
                    (step === -1 && prevCount > endValue)
                ) {
                    return prevCount + step;
                } else {
                    clearInterval(interval);
                    return prevCount;
                }
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [initialValue, endValue]);

    return count;
}
