import styled from 'styled-components';
import { Column } from '../../layout';

export const TooltipContainer = styled(Column)`
    background-color: ${props => props.theme.secondary};
    border-radius: 10px;
    padding: 10px;

    h3 {
        color: ${props => props.theme.primary};
        font-size: 14px;
        margin-bottom: 4px;
        margin-top: 0;
    }

    p {
        color: ${props => props.theme.primary};
        margin: 1px;
    }
`;
