import { useSelector } from 'react-redux';
import { useContext, useEffect } from 'react';
import { ContextType, TableIcons } from '@/domain/models';

import { store } from '@/ui/store/config';
import selectNavigationAction from '@/ui/store/modules/app/navigation/actions';
import { NavigationAppState, StoreState } from '@/ui/store/models';
import { useBreakpoints } from '@/ui/helpers';
import { CardChart, CircularProgress } from '@/ui/components';
import LineBarChart from '@/ui/components/recharts/line-bar-chart';
import { Grid } from '@/ui/components/layout';
import { FilterContext } from '@/ui/context/context-filter';
import FilterLicensedModal from '../licensed-modal-filter';
import { NoDataFound } from '../components';
import Default from '../../default';
import MobileTop from './styles/mobile/mobile-top';
import {
    CustomerRelationshipManagement,
    DesktopTopSection,
} from './components';
import { useRatingQueries } from '@/ui/hooks/rating';

const Rating = (): JSX.Element => {
    const { setActivePage } = useContext(FilterContext);

    const isDarkTheme = useSelector((store: StoreState) => {
        return store.toggleTheme.darkTheme;
    });

    const { isXs } = useBreakpoints();

    const {
        isModalOpen,
        setIsModalOpen,
        ratingData,
        isRatingDataLoading,
        ratingWhatCanImprove,
        isRatingWhatCanImproveLoading,
        ratingAverageTicketByPublic,
        isAverageTicketLoading,
        ratingTopUser,
        isRatingTopUserLoading,
        ratingUserStatistics,
        isUserStatisticsLoading,
        ratingByReviewQuantity,
        isRatingByReviewQuantityLoading,
        ratingByRefrigerator,
        isRatingByRefrigeratorLoading,
    } = useRatingQueries();

    useEffect(() => {
        setActivePage(ContextType.home_rating);
        store.dispatch(
            selectNavigationAction(
                new NavigationAppState(
                    ContextType.home_rating,
                    ContextType.home,
                    TableIcons.HOME,
                ),
            ),
        );
    }, []);

    return (
        <Default contextType={ContextType.home_rating}>
            <FilterLicensedModal
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
            />
            {isModalOpen ? (
                <CircularProgress variant="indeterminate" />
            ) : (
                <>
                    {!isXs ? (
                        <DesktopTopSection
                            ratingWhatCanImproveLoading={
                                isRatingWhatCanImproveLoading
                            }
                            ratingData={ratingData}
                            ratingDataLoading={isRatingDataLoading}
                            ratingWatCanImprove={ratingWhatCanImprove!}
                        />
                    ) : (
                        <MobileTop
                            ratingWhatCanImproveLoading={
                                isRatingWhatCanImproveLoading
                            }
                            ratingDataLoading={isRatingDataLoading}
                            averageRating={ratingData?.averageRating}
                            ratingQuantity={ratingData?.ratingQuantity}
                            ratingWatCanImprove={ratingWhatCanImprove!}
                        />
                    )}
                </>
            )}
            <CustomerRelationshipManagement
                isDarkTheme={isDarkTheme}
                averageTicketByPublic={ratingAverageTicketByPublic}
                isAverageTicketByPublicLoading={isAverageTicketLoading}
                topCustomers={ratingTopUser!}
                isTopCustomersLoading={isRatingTopUserLoading}
                userStatistics={ratingUserStatistics!}
                isUserStatisticsLoading={isUserStatisticsLoading}
            />
            <Grid margin={{ top: 2 }} columns={1} rows={2} gap={{ row: 2 }}>
                <CardChart
                    title="Nota média e quantidade de avaliações por mês"
                    isDarkTheme={isDarkTheme}
                    isLoading={isRatingByReviewQuantityLoading}
                >
                    <NoDataFound
                        data={
                            ratingByReviewQuantity &&
                            ratingByReviewQuantity.length > 0
                        }
                        isLoading={isRatingByReviewQuantityLoading}
                    >
                        <LineBarChart
                            lineBarChartData={ratingByReviewQuantity!}
                        />
                    </NoDataFound>
                </CardChart>
                <CardChart
                    title="Nota média e quantidade de avaliações por cooler"
                    isDarkTheme={isDarkTheme}
                    isLoading={isRatingByRefrigeratorLoading}
                >
                    <NoDataFound
                        data={
                            ratingByRefrigerator &&
                            ratingByRefrigerator.length > 0
                        }
                        isLoading={isRatingByRefrigeratorLoading}
                    >
                        <LineBarChart
                            lineBarChartData={ratingByRefrigerator!}
                        />
                    </NoDataFound>
                </CardChart>
            </Grid>
        </Default>
    );
};

export default Rating;
