import styled from 'styled-components';

export const TableContainer = styled.div`
    overflow: auto;
    max-width: 98vw;
    max-height: 73vh;
    min-height: 250px;

    @media (max-width: 1440px) and (max-height: 789px) {
        max-height: 62vh;
    }

    @media (max-width: 412px) and (max-height: 915px) {
        max-height: 59vh;
    }

    @media (max-width: 390px) and (max-height: 844px) {
        max-height: 55vh;
    }

    @media (max-width: 414px) and (max-height: 896px) {
        max-height: 57vh;
    }

    ::-webkit-scrollbar {
        border-radius: 10px;
        width: 6px;
        border: 1px;

        @media (max-width: 650px) {
            width: 5px;
        }
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #3e3a3b;
        border-radius: 10px;

        @media (max-width: 650px) {
            width: 5px;
        }

        @media (max-width: 650px) {
            width: 5px;
        }
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
        width: 10px;

        @media (max-width: 650px) {
            width: 5px;
        }
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`;
