import { IRefrigeratorStatusDashboardFilterParams } from '@/domain/models';

export default {
    licensedName: null,
    cameraProblem: null,
    lockProblem: null,
    active: null,
    version: null,
    locality: null,
    column: {
        id: 'activeStatus',
        label: 'Status',
        name: 'column',
    },
    sort: {
        id: 'ASC',
        label: 'ASC',
        name: 'sort',
    },
} as IRefrigeratorStatusDashboardFilterParams;
