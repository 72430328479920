import { useContext } from 'react';
import { FilterContext } from '@/ui/context/context-filter';
import { useFilterSelected } from '@/ui/hooks';
import {
    CleanFilter,
    FilterItem,
    RemoveButton,
    SelectedFilterContainer,
} from './styles';

export default function SelectedFilters() {
    const { getFilters, cleanFilter, deleteFilter } = useContext(FilterContext);

    const {
        hasNonNullProperty,
        isFilterRequired,
        isCleanFilterButtonVisible,
        isFilterValueNotNullAndNotEmpty,
        returnTranslationOrLabel,
        shouldRenderFilterItem,
    } = useFilterSelected();

    const filtersPage = getFilters();

    return hasNonNullProperty(filtersPage) ? (
        <SelectedFilterContainer>
            {isFilterValueNotNullAndNotEmpty(filtersPage) && (
                <span data-testid="applied-filters">Filtros aplicados</span>
            )}
            {isFilterValueNotNullAndNotEmpty(filtersPage) &&
                Object.values(filtersPage).map((filter: any, index) => {
                    return (
                        shouldRenderFilterItem(filter) && (
                            <FilterItem
                                data-testid="filter-chip"
                                key={filter.name + index}
                            >
                                {returnTranslationOrLabel(filter.label)}
                                {!isFilterRequired(filter?.name) && (
                                    <RemoveButton
                                        onClick={() =>
                                            deleteFilter(filter?.name)
                                        }
                                    >
                                        X
                                    </RemoveButton>
                                )}
                            </FilterItem>
                        )
                    );
                })}
            {isCleanFilterButtonVisible() && (
                <CleanFilter
                    data-testid="clean-filter-button"
                    onClick={() => cleanFilter()}
                >
                    Limpar filtros
                </CleanFilter>
            )}
        </SelectedFilterContainer>
    ) : null;
}
