import { IRefrigeratorEditProduct } from '@/domain/models';
import {
    CircularProgress,
    EditIcon,
    IconContainer,
    InputTextField,
    Table,
    TableHead,
    TrashIcon,
    VerifyIcon,
} from '@/ui/components';
import { useBreakpoints } from '@/ui/helpers';
import { ITableHeadColumn } from '@/ui/interfaces';
import { useForm } from 'react-hook-form';
import { UseQueryResult } from 'react-query';
import MonetaryTextField from '@/ui/components/input/monetary-text-field';
import { useContext, useState } from 'react';
import { DeleteButton, ProductsTableContainer } from './styles';
import CantDeleteModal from './cant-delete-modal';
import ConfirmDeleteModal from './confirm-delete-modal';
import { CancelOutlined } from '@mui/icons-material';
import { NotificationContext } from '@/main';

interface IProductsTableProps {
    refrigeratorProductsQuery: UseQueryResult<Array<IRefrigeratorEditProduct>>;
    updateProduct: any;
    deleteProduct: any;
}

export default function ProductsTable({
    refrigeratorProductsQuery,
    updateProduct,
    deleteProduct,
}: IProductsTableProps) {
    const [isModalOpen, setIsModalOpen] = useState({
        productName: '',
        quantity: 0,
        isOpen: false,
    });
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState({
        productName: '',
        productId: 0,
        isOpen: false,
    });
    const [isEditing, setIsEditing] = useState<number | null>(null);

    const { errorNotification } = useContext(NotificationContext);

    const {
        data: refrigeratorEditProductList,
        isLoading: isProductsLoading,
        isFetching: isProductsRefetching,
    } = refrigeratorProductsQuery;
    const isProductsLoadingOrFetching =
        isProductsLoading || isProductsRefetching;

    const { isXs } = useBreakpoints();
    const tableWidth = isXs ? 'unset' : '100%';

    const tableColumns: ITableHeadColumn[] = [
        { columnName: 'image', columnLabel: 'Imagem' },
        { columnName: 'productName', columnLabel: 'Produto' },
        { columnName: 'stockQuantity', columnLabel: 'Estoque atual' },
        { columnName: 'expectedStock', columnLabel: 'Estoque esperado' },
        { columnName: 'minimalPercentage', columnLabel: 'Estoque mínimo' },
        { columnName: 'productValue', columnLabel: 'Valor produto' },
        {
            columnName: 'options',
            columnLabel: 'Opções',
            className: 'status-label',
        },
    ];

    interface IUseFormData {
        expectedStock: number;
        minimalPercentage: number;
        productValue: number;
    }

    const {
        control,
        reset,
        handleSubmit,
        setValue,
        watch,
        formState: { isDirty, isValid },
    } = useForm<IUseFormData>({
        mode: 'all',
    });

    const [productValue] = watch(['productValue']);
    const handleSetProductValue = (value: number) => {
        setValue('productValue', value, {
            shouldValidate: true,
            shouldDirty: true,
        });
    };

    const handleEditRow = (product: IRefrigeratorEditProduct) => {
        setIsEditing(product.productId);

        reset(
            {
                productValue: Number(product.value),
                expectedStock: Number(product.expectedStock),
                minimalPercentage: Number(product.minimalPercentage),
            },
            {
                keepErrors: true,
            },
        );
    };

    const handleSubmitEditRow = (data: any) => {
        if (
            Number(data.productValue) <= 0 ||
            Number(data.expectedStock) < 0 ||
            Number(data.minimalPercentage) < 0
        ) {
            errorNotification('Os valores devem ser positivos!');
            return;
        }

        updateProduct({
            productId: isEditing,
            data: {
                value: Number(data.productValue),
                expectedStock: Number(data.expectedStock),
                minimalPercentage: Number(data.minimalPercentage),
            },
        });

        setIsEditing(null);
    };

    const handleDeleteProduct = () => {
        deleteProduct({
            productId: isDeleteModalOpen.productId,
        });

        setIsDeleteModalOpen({
            productName: '',
            productId: 0,
            isOpen: false,
        });
    };

    return !isProductsLoadingOrFetching ? (
        <ProductsTableContainer>
            <Table
                style={{
                    width: tableWidth,
                    tableLayout: isXs ? 'fixed' : 'unset',
                }}
            >
                <TableHead
                    columns={tableColumns}
                    hasCondition={false}
                    hasOrdination={false}
                />
                {refrigeratorEditProductList &&
                    refrigeratorEditProductList.map((product, index) => {
                        const isRowEditingProductId =
                            isEditing === product.productId;
                        return (
                            <tr
                                key={product.productId + index}
                                className="content-row"
                            >
                                <td>
                                    <img
                                        width={35}
                                        src={product.productImg}
                                        alt="Produto"
                                    />
                                </td>
                                <td>{product.productName}</td>
                                <td>{product.quantity}</td>
                                <td>
                                    {!isRowEditingProductId ? (
                                        product.expectedStock
                                    ) : (
                                        <InputTextField
                                            control={control}
                                            name="expectedStock"
                                            label=""
                                            size="small"
                                            type="number"
                                            rules={{
                                                min: {
                                                    value: 0,
                                                    message:
                                                        'O valor deve ser positivo!',
                                                },
                                            }}
                                        />
                                    )}
                                </td>
                                <td>
                                    {!isRowEditingProductId ? (
                                        `${(
                                            product.expectedStock *
                                            (product.minimalPercentage / 100)
                                        ).toFixed(0)} (${
                                            product.minimalPercentage
                                        }%)`
                                    ) : (
                                        <InputTextField
                                            control={control}
                                            name="minimalPercentage"
                                            label=""
                                            size="small"
                                            type="number"
                                            rules={{
                                                min: {
                                                    value: 0,
                                                    message:
                                                        'O valor deve ser positivo!',
                                                },
                                            }}
                                        />
                                    )}
                                </td>
                                <td>
                                    {!isRowEditingProductId ? (
                                        Intl.NumberFormat('pt-br', {
                                            style: 'currency',
                                            currency: 'BRL',
                                        }).format(product.value)
                                    ) : (
                                        <MonetaryTextField
                                            label=""
                                            name="productValue"
                                            size="small"
                                            setValue={handleSetProductValue}
                                            defaultValue={productValue}
                                        />
                                    )}
                                </td>
                                <td>
                                    <IconContainer>
                                        {!isRowEditingProductId ? (
                                            <>
                                                <DeleteButton
                                                    onClick={() =>
                                                        handleEditRow(product)
                                                    }
                                                >
                                                    <EditIcon />
                                                </DeleteButton>
                                                <DeleteButton
                                                    onClick={() => {
                                                        if (
                                                            product.quantity > 0
                                                        ) {
                                                            return setIsModalOpen(
                                                                {
                                                                    productName:
                                                                        product.productName,
                                                                    quantity:
                                                                        product.quantity,
                                                                    isOpen: true,
                                                                },
                                                            );
                                                        }
                                                        setIsDeleteModalOpen({
                                                            productName:
                                                                product.productName,
                                                            productId:
                                                                product.productId,
                                                            isOpen: true,
                                                        });
                                                    }}
                                                >
                                                    <TrashIcon />
                                                </DeleteButton>
                                            </>
                                        ) : (
                                            <>
                                                <DeleteButton
                                                    disabled={
                                                        !isDirty || !isValid
                                                    }
                                                    onClick={handleSubmit(
                                                        handleSubmitEditRow,
                                                    )}
                                                >
                                                    <VerifyIcon />
                                                </DeleteButton>
                                                <DeleteButton
                                                    onClick={() =>
                                                        setIsEditing(null)
                                                    }
                                                >
                                                    <CancelOutlined
                                                        sx={{
                                                            fontSize: '26px',
                                                            color: '#FF0000',
                                                            cursor: 'pointer',
                                                        }}
                                                    />
                                                </DeleteButton>
                                            </>
                                        )}
                                    </IconContainer>
                                </td>
                            </tr>
                        );
                    })}
            </Table>
            <CantDeleteModal
                productName={isModalOpen.productName}
                quantity={isModalOpen.quantity}
                isOpen={isModalOpen.isOpen}
                close={() =>
                    setIsModalOpen({
                        ...isModalOpen,
                        isOpen: false,
                    })
                }
            />
            <ConfirmDeleteModal
                close={() =>
                    setIsDeleteModalOpen({
                        productName: '',
                        productId: 0,
                        isOpen: false,
                    })
                }
                deleteProduct={handleDeleteProduct}
                isOpen={isDeleteModalOpen.isOpen}
                productId={isDeleteModalOpen.productId}
                productName={isDeleteModalOpen.productName}
            />
        </ProductsTableContainer>
    ) : (
        <CircularProgress variant="indeterminate" />
    );
}
