import { RolesType } from '../models';

export default class PermissionHelper {
    private _roles: Array<RolesType>;

    constructor(roles: string) {
        if (!roles) {
            this._roles = [];
            return;
        }
        this._roles = roles
            .split(',')
            .map(role => RolesType[role as keyof typeof RolesType]);
    }

    public get roles(): Array<RolesType> {
        return this._roles;
    }

    public hasAnyRole(rolesToCheck: Array<RolesType>): boolean {
        if (
            !rolesToCheck ||
            rolesToCheck.length === 0 ||
            this.roles.length === 0
        )
            return false;

        return (
            rolesToCheck.filter((role: RolesType) => this.roles.includes(role))
                .length > 0
        );
    }

    public isDeveloper(): boolean {
        return this.hasAnyRole([RolesType.DEVELOPER]) && this.isTakeAndGo();
    }

    public isCommercial(): boolean {
        return this.hasAnyRole([RolesType.COMMERCIAL]);
    }

    public isTakeAndGo(): boolean {
        return this.hasAnyRole([RolesType.TAKEANDGO]);
    }

    public isPartner(): boolean {
        return this.hasAnyRole([RolesType.PARTNER]);
    }

    public isOnlyUser(): boolean {
        return this.roles.length === 1 && this.roles.includes(RolesType.USER);
    }
}
