import { AuthenticationHelper, PermissionHelper } from '@/domain/helpers';
import { ContextType } from './context-type';

const getFiltersByPage = () => {
    const ContextFiltersByPage = new Map<string, string[]>();

    const userRoles = AuthenticationHelper.hasToken()
        ? AuthenticationHelper.getToken().auth
        : '';
    const permissionHelper = new PermissionHelper(userRoles);

    const pageFilters = ['page', 'limit', 'column', 'sort'];

    if (permissionHelper.isTakeAndGo()) {
        ContextFiltersByPage.set(ContextType.home_overview, [
            'initialDate',
            'endDate',
            'licensedId',
            'coolerId',
        ]);
        ContextFiltersByPage.set(ContextType.home_rating, [
            'initialDate',
            'endDate',
            'licensedId',
            'coolerId',
        ]);
        ContextFiltersByPage.set(ContextType.financial, [
            'date',
            'licensedId',
            'coolerId',
        ]);
        ContextFiltersByPage.set(ContextType.refrigerator_list, [
            'code',
            'device',
            'location',
            'situation',
            'status',
            'sortDirectionStockPercentage',
            'licensedId',
            ...pageFilters,
        ]);
        ContextFiltersByPage.set(ContextType.refrigerator_status_list, [
            'licensedName',
            'cameraProblem',
            'lockProblem',
            'active',
            'version',
            'locality',
            'column',
            'sort',
        ]);
        ContextFiltersByPage.set(ContextType.refrigerator_status_dashboard, [
            'licensedName',
            'cameraProblem',
            'lockProblem',
            'active',
            'version',
            'locality',
            'column',
            'sort',
        ]);
        ContextFiltersByPage.set(ContextType.refrigerator_stock, [
            'code',
            'device',
            'location',
            'situation',
            'status',
            'sortDirectionStockPercentage',
            'licensedId',
            'activeView',
            ...pageFilters,
        ]);
        ContextFiltersByPage.set(ContextType.user, [
            'name',
            'email',
            'telephone',
            'situation',
            'code',
            'cpf',
            'licensed',
            ...pageFilters,
        ]);
        ContextFiltersByPage.set(ContextType.promotion_list, [
            'promotionName',
            'promotionType',
            'promotionCategory',
            'promotionProduct',
            'status',
            'promotionStartDate',
            'promotionEndDate',
            'coolerId',
            'licensedId',
            'useType',
            'id',
            ...pageFilters,
        ]);
        ContextFiltersByPage.set(ContextType.order, [
            'cooler',
            'orderNumber',
            'orderStatus',
            'userName',
            'cpf',
            'licensedId',
            'orderDateStart',
            'orderDateEnd',
            'paymentDateStart',
            'paymentDateEnd',
            'orderType',
            ...pageFilters,
        ]);
        ContextFiltersByPage.set(ContextType.monitoring_opening_attempts, [
            'coolerId',
            'licensedId',
            'beginDate',
            'userName',
            'endDate',
            'userName',
            'userCPF',
            ...pageFilters,
        ]);
        ContextFiltersByPage.set(ContextType.licensed_list, [
            'licensedCnpj',
            'licensedPhone',
            'licensedName',
            ...pageFilters,
        ]);
        ContextFiltersByPage.set(ContextType.product_list, [...pageFilters]);
        ContextFiltersByPage.set(ContextType.product_category, [
            ...pageFilters,
        ]);
        ContextFiltersByPage.set(ContextType.product_mix, [...pageFilters]);
        ContextFiltersByPage.set(ContextType.banner_list, [
            'correlationType',
            'dateBegin',
            'dateEnd',
            ...pageFilters,
        ]);
    } else {
        ContextFiltersByPage.set(ContextType.home_overview, [
            'initialDate',
            'endDate',
            'coolerId',
        ]);
        ContextFiltersByPage.set(ContextType.home_rating, [
            'initialDate',
            'endDate',
            'coolerId',
        ]);
        ContextFiltersByPage.set(ContextType.financial, ['date', 'coolerId']);
        ContextFiltersByPage.set(ContextType.refrigerator_list, [
            'code',
            'device',
            'location',
            'situation',
            'status',
            'sortDirectionStockPercentage',
            'licensedId',
            ...pageFilters,
        ]);
        ContextFiltersByPage.set(ContextType.refrigerator_stock, [
            'code',
            'device',
            'location',
            'situation',
            'status',
            'sortDirectionStockPercentage',
            'licensedId',
            'activeView',
            ...pageFilters,
        ]);
        ContextFiltersByPage.set(ContextType.user, [
            'name',
            'email',
            'telephone',
            'situation',
            'code',
            'cpf',
            ...pageFilters,
        ]);
        ContextFiltersByPage.set(ContextType.promotion_list, [
            'promotionName',
            'promotionType',
            'promotionCategory',
            'promotionProduct',
            'status',
            'promotionStartDate',
            'promotionEndDate',
            'coolerId',
            'useType',
            'id',
            ...pageFilters,
        ]);
        ContextFiltersByPage.set(ContextType.order, [
            'cooler',
            'orderNumber',
            'orderStatus',
            'userName',
            'cpf',
            'licensedId',
            'orderDateStart',
            'orderDateEnd',
            'paymentDateStart',
            'paymentDateEnd',
            'orderType',
            ...pageFilters,
        ]);
        ContextFiltersByPage.set(ContextType.monitoring_opening_attempts, [
            'coolerId',
            'licensedId',
            'beginDate',
            'userName',
            'endDate',
            'userName',
            'userCPF',
            ...pageFilters,
        ]);
        ContextFiltersByPage.set(ContextType.licensed_list, [
            'licensedCnpj',
            'licensedPhone',
            'licensedName',
            ...pageFilters,
        ]);
        ContextFiltersByPage.set(ContextType.product_list, [...pageFilters]);
        ContextFiltersByPage.set(ContextType.product_category, [
            ...pageFilters,
        ]);
        ContextFiltersByPage.set(ContextType.product_mix, [...pageFilters]);
        ContextFiltersByPage.set(ContextType.banner_list, [
            'correlationType',
            'dateBegin',
            'dateEnd',
            ...pageFilters,
        ]);
    }

    return ContextFiltersByPage;
};

export default getFiltersByPage;
