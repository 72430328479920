import styled from 'styled-components';
import { Column } from '../../layout';

export const Title = styled.h3`
    font-size: 20px;
    font-weight: 700;
    margin: 0;
`;

export const CloseButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        fill: inherit;
    }

    &.stroked {
        stroke: inherit;
        fill: none;
    }
`;

interface IModalContent {
    isDarkTheme: boolean;
}

export const ModalContent = styled(Column)<IModalContent>`
    max-height: 80vh;
    min-height: 50px;
    height: auto;
    overflow-y: auto;
    .subtitle {
        font-size: 16px;
        font-weight: 600;
        color: ${({ isDarkTheme }) => (isDarkTheme ? '#FFCD00' : '#DBAB00')};
    }

    span.subtitle {
        margin-right: 6px;
    }

    ul {
        padding-left: 16px;
        li {
            color: ${({ isDarkTheme }) =>
                isDarkTheme ? '#f3f3f3' : '#0F080A'};
            margin-bottom: 8px;
            font-size: 14px;

            .subtitle {
                font-size: 14px;
            }

            &::marker {
                color: ${({ isDarkTheme }) =>
                    isDarkTheme ? '#f3f3f3' : '#0F080A'};
            }
        }
    }
`;
