import { SvgIcon } from '@mui/material';
import styled from 'styled-components';

const StyledSvgIcon = styled(SvgIcon)`
    cursor: pointer;
    color: ${props => props.theme.primary};
    fill: ${props => props.theme.quaternary};
    width: 24px;
    height: 24px;

    &.uptime-icon {
        path,
        rect {
            fill: ${props => props.theme.secondary};
        }
    }
`;

export default StyledSvgIcon;
