export default class FilterAppState {
    filterParams: any;
    isSearchAction: boolean;
    refresh?: string | number;
    selectedValues?: any;

    constructor(
        filterParams: any,
        { refresh = '' as string | number, isSearchAction = false },
        selectedValues: any,
    ) {
        this.filterParams = filterParams;
        this.refresh = refresh;
        this.isSearchAction = isSearchAction;
        this.selectedValues = selectedValues;
    }
}
