import { Button, Modal } from '@/ui/components';

export default function ConfirmModalSave({
    isOpen,
    onClose,
    type,
    confirmSave,
}: {
    isOpen: boolean;
    onClose: () => void;
    type: 'mix' | 'processing';
    confirmSave: () => void;
}) {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title="Atenção!"
            canClose={false}
            okButton={
                <Button text="Continuar e salvar" onClick={confirmSave} />
            }
            cancelButton={
                <Button className="base" text="Retornar" onClick={onClose} />
            }
        >
            {type === 'processing' ? (
                <>
                    A alteração feita irá limpar a lista de produtos cadastrados
                    e é irreversível. Deseja continuar?
                </>
            ) : (
                <>
                    Você está alterando o mix da geladeira. Por favor, revise os
                    produtos cadastrados, pois essa alteração pode causar
                    incompatibilidades com o novo mix selecionado. Deseja
                    continuar?
                </>
            )}
        </Modal>
    );
}
