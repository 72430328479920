import styled from 'styled-components';

const RatingTotalPossibleRating = styled.span`
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
`;

export default RatingTotalPossibleRating;
