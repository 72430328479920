import { AuthenticationHelper, PermissionHelper } from '@/domain/helpers';
import { ContextType, IProductsForm, TableIcons } from '@/domain/models';
import { NotificationContext } from '@/main';
import { makePromotionListPresenter } from '@/main/factories';
import makeProductsPresenter from '@/main/factories/presenters/pages/products/product-presenter-factory';
import { store } from '@/ui/store/config';
import { NavigationAppState } from '@/ui/store/models';
import selectNavigationAction from '@/ui/store/modules/app/navigation/actions';
import { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

export default function UseProductManagamentPresenter() {
    const [previewSource, setPreviewSource] = useState<string>('');
    const navigate = useNavigate();
    const { successNotification, errorNotification } =
        useContext(NotificationContext);
    const { productId } = useParams();
    const queryClient = useQueryClient();

    useEffect(() => {
        if (!productId) {
            store.dispatch(
                selectNavigationAction(
                    new NavigationAppState(
                        ContextType.product_save,
                        ContextType.product_list,
                        TableIcons.PRODUCTS,
                    ),
                ),
            );
        }
    }, []);

    const userRoles = AuthenticationHelper.hasToken()
        ? AuthenticationHelper.getToken().auth
        : '';
    const permissionHelper = new PermissionHelper(userRoles);
    const isTakeAndGo = permissionHelper.isTakeAndGo();
    const isPartner = permissionHelper.isPartner();

    const {
        handleSubmit,
        control,
        reset,
        formState: { isDirty, isValid },
        setValue,
        watch,
    } = useForm<IProductsForm>({
        mode: 'onChange',
        defaultValues: {
            mixData: [],
        },
    });

    const [productImage, mixData] = watch(['productImage', 'mixData']);

    useEffect(() => {
        const previewFile = (file: any) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                const imageSrc = reader.result as string;
                const img = new Image();
                img.src = imageSrc;

                img.onload = () => {
                    const width = img.width;
                    const height = img.height;

                    if (width !== 300 || height !== 300) {
                        errorNotification('Tamanho de imagem inválido', 6000);
                        setValue('productImage', null, {
                            shouldValidate: true,
                        });
                        setPreviewSource('');
                        return;
                    }
                };

                setPreviewSource(reader.result ? reader.result.toString() : '');
            };
        };

        if (productImage instanceof File) {
            previewFile(productImage);
        }
    }, [productImage]);

    const productsPresenter = makeProductsPresenter();

    const promotionPresenter = makePromotionListPresenter();

    const isSubmitDisabled = !isValid || !isDirty;

    const updatedSuccessfully = (res: any) => {
        if (res.success) {
            successNotification('Produto atualizado com sucesso!');
            queryClient.invalidateQueries(['product', productId]);
        } else {
            errorNotification(res.data.message);
        }
    };

    const createdSuccessfully = (res: any) => {
        if (res.success) {
            successNotification('Produto criado com sucesso!');
            navigate(`/product/${res.data.id}`, {
                state: { from: 'save' },
            });
        } else {
            errorNotification(res.data.message);
        }
    };

    const { mutateAsync: updateProduct, isLoading: isUpdatingProduct } =
        useMutation(
            (data: IProductsForm) =>
                productsPresenter.updateProduct(
                    data,
                    productId!,
                    previewSource,
                ),
            {
                onSuccess: updatedSuccessfully,
            },
        );

    const { mutateAsync: createProduct, isLoading: isCreatingProduct } =
        useMutation(
            (data: IProductsForm) =>
                productsPresenter.createProduct(data, previewSource),
            {
                onSuccess: createdSuccessfully,
            },
        );

    const { mutateAsync: createPartnerProduct } = useMutation(
        (data: IProductsForm) =>
            productsPresenter.createPartnerProduct(data, previewSource),
        {
            onSuccess: createdSuccessfully,
        },
    );

    const onSubmit: SubmitHandler<IProductsForm> = data => {
        if (productId) {
            return updateProduct(data);
        }

        return isPartner ? createPartnerProduct(data) : createProduct(data);
    };

    const handleRemoveImage = () => {
        setValue('productImage', null, {
            shouldValidate: true,
        });
        setPreviewSource('');
    };

    const [
        { data: mixes = [], isLoading: isLoadingMixes },
        { data: providers = [], isLoading: isLoadingProviders },
        { data: categories = [], isLoading: isLoadingCategories },
    ] = useQueries([
        {
            queryKey: 'mixAutocomplete',
            queryFn: () => productsPresenter.getMixesAutocomplete(),
        },
        {
            queryKey: 'providerAutocomplete',
            queryFn: () => productsPresenter.getProviderAutocomplete(),
        },
        {
            queryKey: 'productCategoryAutoComplete',
            queryFn: () => productsPresenter.getCategoriesAutocomplete(),
        },
    ]);

    const useProductQuery = () =>
        useQuery(
            ['product', productId],
            () => productsPresenter.getProductById(productId!),
            {
                enabled:
                    !!productId && !isLoadingCategories && !isLoadingProviders,
                onSuccess: data => {
                    if (data.data) {
                        const productData = data.data;

                        const categoryIdValue = categories.find(
                            (option: any) =>
                                option.id === productData.categoryId,
                        );

                        const providerIdValue = providers.find(
                            (option: any) =>
                                option.id === productData.providerId,
                        );

                        reset({
                            ...(productData as IProductsForm),
                            categoryId: categoryIdValue,
                            providerId: providerIdValue,
                            productImage: productData.image,
                            mixData: productData.mixes,
                        });

                        store.dispatch(
                            selectNavigationAction(
                                new NavigationAppState(
                                    ContextType.product_edit,
                                    ContextType.product_list,
                                    TableIcons.PRODUCTS,
                                    productData.name,
                                ),
                            ),
                        );

                        setPreviewSource(productData.image.image);
                    }
                },
            },
        );

    const isLoading =
        isLoadingMixes ||
        isLoadingProviders ||
        isLoadingCategories ||
        isCreatingProduct ||
        isUpdatingProduct;

    return {
        isSubmitDisabled,
        control,
        isLoading,
        handleSubmit,
        onSubmit,
        previewSource,
        isTakeAndGo,
        categories,
        providers,
        useProductQuery,
        handleRemoveImage,
        mixes,
        mixData,
        setValue,
    };
}
