import { SidebarAppState } from '@/ui/store/models';
import { Types, SidebarAppAction } from './types';

export default function sidebar(
    state = new SidebarAppState({
        sidebarName: '',
        elementId: '',
        sidebarState: { right: false },
    }),
    action: SidebarAppAction,
): SidebarAppState {
    switch (action.type) {
        case Types.OPEN_SIDEBAR:
            return action.payload.data;
        case Types.CLOSE_SIDEBAR:
            return action.payload.data;
        default:
            return state;
    }
}
