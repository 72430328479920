import { Control, Controller, RegisterOptions } from 'react-hook-form';
import { StyledMDEditor } from './styles';
import { useSelector } from 'react-redux';
import { StoreState } from '@/ui/store/models';
import { Row } from '../../layout';
import { HelperText } from '../text-field/style';

interface IMarkdownEditorProps {
    control: Control<any>;
    name: string;
    rules?: RegisterOptions;
    characterCount?: boolean;
    maxLength?: number;
}

export default function MarkdownEditor({
    control,
    name,
    rules,
    characterCount = false,
    maxLength,
}: IMarkdownEditorProps) {
    const isDarkTheme = useSelector((store: StoreState) => {
        return store.toggleTheme.darkTheme;
    });

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
                <div data-color-mode={isDarkTheme ? 'dark' : 'light'}>
                    <StyledMDEditor
                        {...field}
                        textareaProps={{
                            maxLength,
                        }}
                    />{' '}
                    <Row justify="space-between" margin={{ top: 2 }}>
                        <span className="body-extra-small">
                            {characterCount &&
                                field.value &&
                                field.value?.length === maxLength && (
                                    <HelperText>
                                        Limite de caracteres atingido
                                    </HelperText>
                                )}
                        </span>
                        {characterCount && (
                            <HelperText className="body-extra-small">
                                {field.value ? field.value?.length : '0'}/
                                {maxLength}
                            </HelperText>
                        )}
                    </Row>
                </div>
            )}
        />
    );
}
