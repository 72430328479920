import { useContext, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useQuery, useQueryClient } from 'react-query';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/pt-br';
import {
    AuthenticationHelper,
    DateHelper,
    PermissionHelper,
    RequestQueryMaker,
} from '@/domain/helpers';
import { IAutocomplete, IOrderFilterInput } from '@/domain/models';
import { OrderFilterParams } from '@/presentation';
import { makeRefrigeratorListPresenter } from '@/main/factories';
import makeOrderListPresenter from '@/main/factories/presenters/pages/order/order-list-presenter-factory';
import { IFilterDrawer } from '@/ui/interfaces';
import {
    InputTextField,
    DateTitle,
    DatePickerField,
    MaskedInputTextField,
    Filter,
    AutoCompleteField,
} from '@/ui/components';
import { FilterContext } from '@/ui/context/context-filter';

export default function OrderFilter({ filter, setFilter }: IFilterDrawer) {
    const queryClient = useQueryClient();

    const refrigeratorListPresenter = makeRefrigeratorListPresenter();
    const orderListPresenter = makeOrderListPresenter();

    const roles = AuthenticationHelper.getToken().auth;
    const permissionHelper = new PermissionHelper(roles);

    const isAllowed =
        permissionHelper.isTakeAndGo() || permissionHelper.isDeveloper();

    const { getFilters, resetFilter, setResetFilter, updateFilters } =
        useContext(FilterContext);
    const filtersPage = getFilters();

    const resetValues: IOrderFilterInput = {
        cooler: filtersPage?.cooler ? filtersPage?.cooler : null,
        orderNumber: filtersPage?.orderNumber
            ? filtersPage?.orderNumber.id
            : '',
        orderStatus: filtersPage?.orderStatus ? filtersPage?.orderStatus : null,
        orderType: filtersPage?.orderType ? filtersPage?.orderType : null,
        userName: filtersPage?.userName ? filtersPage?.userName.id : '',
        cpf: filtersPage?.cpf ? filtersPage?.cpf.id : '',
        licensedId: filtersPage?.licensedId ? filtersPage?.licensedId : null,
        orderDateStart: filtersPage?.orderDateStart?.id
            ? DateHelper.formatDateFromRequest(filtersPage?.orderDateStart?.id)
            : null,
        orderDateEnd: filtersPage?.orderDateEnd?.id
            ? DateHelper.formatDateFromRequest(filtersPage?.orderDateEnd?.id)
            : null,
        paymentDateStart: filtersPage?.paymentDateStart?.id
            ? DateHelper.formatDateFromRequest(
                  filtersPage?.paymentDateStart?.id,
              )
            : null,
        paymentDateEnd: filtersPage?.paymentDateEnd?.id
            ? DateHelper.formatDateFromRequest(filtersPage?.paymentDateEnd?.id)
            : null,
    };

    const { handleSubmit, control, reset, watch } = useForm<IOrderFilterInput>({
        mode: 'all',
        defaultValues: resetValues,
    });
    const onSubmit: SubmitHandler<IOrderFilterInput> = data =>
        submitHandler(data);

    const [
        licensedId,
        orderDateStart,
        orderDateEnd,
        paymentDateStart,
        paymentDateEnd,
    ] = watch([
        'licensedId',
        'orderDateStart',
        'orderDateEnd',
        'paymentDateStart',
        'paymentDateEnd',
    ]);

    const licensedAutoCompleteData = queryClient.getQueryData<
        Array<IAutocomplete>
    >('licensedAutoComplete');

    const {
        data: coolerAutoComplete = [],
        isLoading: isCoolerAutoCompleteLoading,
        isFetching: isCoolerAutoCompleteFetching,
    } = useQuery(['coolerAutoComplete', licensedId], () =>
        refrigeratorListPresenter.getCoolerFilterAutocompleteAsync(
            RequestQueryMaker({
                licensedId: licensedId?.id,
                active: '0',
            }),
        ),
    );

    const submitHandler = (data: IOrderFilterInput) => {
        const orderFilterParams = new OrderFilterParams(
            data.cooler ? data.cooler.id : '',
            data.orderNumber ? data.orderNumber : '',
            data.orderStatus ? data.orderStatus.id : '',
            data.orderType ? data.orderType.id : '',
            data.userName ? data.userName : '',
            data.cpf ? data.cpf : '',
            data.orderDateStart
                ? DateHelper.formatDate(data.orderDateStart)
                : null,
            data.orderDateEnd ? DateHelper.formatDate(data.orderDateEnd) : null,
            data.paymentDateStart
                ? DateHelper.formatDate(data.paymentDateStart)
                : null,
            data.paymentDateEnd
                ? DateHelper.formatDate(data.paymentDateEnd)
                : null,
            data.licensedId ? data.licensedId.id : '',
            data.cooler ? data.cooler.label : '',
            data.licensedId ? data.licensedId.label : '',
            data.orderType ? data.orderType.label : '',
        );

        updateFilters(
            {
                ...filtersPage,
                ...orderFilterParams,
            },
            true,
        );
        setFilter({ right: false });
        reset();
    };
    const statusOptions = orderListPresenter.statusOptions;
    const orderTypeOptions = orderListPresenter.orderTypeOptions;

    useEffect(() => {
        reset(resetValues);
    }, [filtersPage]);

    useEffect(() => {
        if (resetFilter) {
            reset(resetValues);
            setResetFilter(false);
        }
    }, [resetFilter]);

    return (
        <Filter
            filter={filter}
            setFilter={setFilter}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
        >
            {isAllowed && (
                <AutoCompleteField
                    control={control}
                    options={licensedAutoCompleteData as IAutocomplete[]}
                    name="licensedId"
                    label="Licenciado"
                    dataTestId="licensed-autocomplete"
                />
            )}
            <InputTextField
                type="number"
                control={control}
                name="orderNumber"
                label="Número"
                dataTestId="order-number"
            />
            <AutoCompleteField
                control={control}
                options={statusOptions}
                name="orderStatus"
                label="Status"
                dataTestId="order-status"
            />
            <AutoCompleteField
                control={control}
                options={orderTypeOptions}
                name="orderType"
                label="Tipo de pedido"
                dataTestId="order-type"
            />
            <AutoCompleteField
                control={control}
                options={coolerAutoComplete}
                name="cooler"
                label="Cooler"
                dataTestId="cooler-autocomplete"
                isLoading={
                    isCoolerAutoCompleteLoading || isCoolerAutoCompleteFetching
                }
            />
            <InputTextField
                control={control}
                name="userName"
                label="Nome do usuário"
                dataTestId="user-name"
            />
            <MaskedInputTextField
                control={control}
                mask="999.999.999-99"
                name="cpf"
                label="CPF"
                dataTestId="user-cpf"
            />
            <LocalizationProvider
                control={control}
                adapterLocale="pt-br"
                dateAdapter={AdapterDayjs}
            >
                <DateTitle>Data do pedido</DateTitle>
                <DatePickerField
                    control={control}
                    name="orderDateStart"
                    label="Inicio"
                    dataTestId="initialDate"
                    placeholder="dd/mm/aaaa"
                    rules={{
                        deps: ['orderDateEnd'],
                        validate: {
                            isLessThan: (value: any) => {
                                if (!value || !orderDateEnd) return true;
                                return (
                                    orderDateEnd > value ||
                                    'Data de início deve ser menor que a data de término'
                                );
                            },
                        },
                    }}
                />
                <DatePickerField
                    control={control}
                    name="orderDateEnd"
                    label="Fim"
                    dataTestId="endDate"
                    placeholder="dd/mm/aaaa"
                    rules={{
                        deps: ['orderDateStart'],
                        validate: {
                            isGreaterThan: (value: any) => {
                                if (!value || !orderDateStart) return true;
                                return (
                                    orderDateStart < value ||
                                    'Data de término deve ser maior que a data de início'
                                );
                            },
                        },
                    }}
                />
                <DateTitle>Data do pagamento</DateTitle>
                <DatePickerField
                    control={control}
                    name="paymentDateStart"
                    label="Inicio"
                    dataTestId="paymentInitialDate"
                    placeholder="dd/mm/aaaa"
                    rules={{
                        deps: ['paymentDateEnd'],
                        validate: {
                            isLessThan: (value: any) => {
                                if (!value || !paymentDateEnd) return true;
                                return (
                                    paymentDateEnd > value ||
                                    'Data de início deve ser menor que a data de término'
                                );
                            },
                        },
                    }}
                />
                <DatePickerField
                    control={control}
                    name="paymentDateEnd"
                    label="Fim"
                    dataTestId="paymentEndDate"
                    placeholder="dd/mm/aaaa"
                    rules={{
                        deps: ['paymentDateStart'],
                        validate: {
                            isGreaterThan: (value: any) => {
                                if (!value || !paymentDateStart) return true;
                                return (
                                    paymentDateStart < value ||
                                    'Data de término deve ser maior que a data de início'
                                );
                            },
                        },
                    }}
                />
            </LocalizationProvider>
        </Filter>
    );
}
