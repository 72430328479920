import { useSelector } from 'react-redux';

import {
    IBankDisputeDetails,
    ICondominiumTransferDetails,
    ICreditCardDetails,
    IMonthlyPayments,
    INegotiationDetails,
    IPromotionDetails,
    IRefundDetails,
    IWalletDetails,
} from '@/domain/models';
import { StoreState } from '@/ui/store/models';
import { CircularProgress, Table } from '@/ui/components';
import { Row } from '@/ui/components/layout';
import { NoProductsFound } from '../../order/styles';
import { TableContainer } from '../styles';
import DetailTableBody from './detail-table-body';
import DetailTableHead from './detail-table-head';

interface IDetailTable {
    financialDetailData:
        | IBankDisputeDetails[]
        | ICondominiumTransferDetails[]
        | ICreditCardDetails[]
        | IMonthlyPayments[]
        | INegotiationDetails[]
        | IPromotionDetails[]
        | IRefundDetails[]
        | IWalletDetails[]
        | any;
    loading: boolean;
}

export default function DetailTable({
    financialDetailData,
    loading,
}: IDetailTable) {
    const sidebar = useSelector((store: StoreState) => store.sidebar);

    const tableHeadMap = DetailTableHead();
    const tableBodyMap = DetailTableBody();

    return (
        <TableContainer className="detail">
            {!loading ? (
                Array.isArray(financialDetailData) &&
                financialDetailData.length > 0 ? (
                    <Table key={sidebar.sidebarName} style={{ marginTop: 25 }}>
                        <thead style={{ marginTop: 25 }}>
                            {tableHeadMap.get(sidebar.sidebarName)}
                        </thead>
                        {tableBodyMap.get(sidebar.sidebarName)}
                    </Table>
                ) : (
                    <NoProductsFound>Nenhum dado encontrado</NoProductsFound>
                )
            ) : (
                <Row align="center" justify="center">
                    {Boolean(loading) && (
                        <CircularProgress variant="indeterminate" />
                    )}
                </Row>
            )}
        </TableContainer>
    );
}
