import styled from 'styled-components';

export const Container = styled.div`
    color: ${props => props.theme.secondary};
    text-align: center;
    background-color: ${props => props.theme.bodyBg};
    margin-left: 124px;
    margin-right: 28px;
    margin-top: 95px;
    padding-bottom: 28px;

    @media (max-width: 650px) {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 28px;
        margin-top: 0px;
    }
`;
