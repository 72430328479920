import moment from 'moment';
import {
    IBarChartData,
    IBarChartProps,
    IPieChartProps,
    IActiveUsers,
    ISoldAndTotal,
    IRevenue,
    IAverageTickerByUser,
    ILineBarChart,
    ILineChart,
    ILineChartProps,
    IPieChartData,
} from '@/domain/models/pages/home';
import { IExportReport } from '@/domain/models';
import {
    AuthenticationHelper,
    PermissionHelper,
    StringHelper,
} from '@/domain/helpers';
import { IDashboardRepositoryPort } from '@/infra';
import {
    BarChart,
    DashboardOverview,
    LineBarChart,
    LineChart,
} from '@/presentation/models/dashboard';
import { makePieChartProps } from '@/main/factories/charts';
import IHomePresenter from '@/ui/pages/home/overview/home-overview-presenter';
import { PieChartColors } from '@/ui/styles/';

export default class HomeOverviewPresenter implements IHomePresenter {
    constructor(
        private readonly dashboardRepository: IDashboardRepositoryPort,
    ) {}

    getParams(filterParams: any) {
        const roles = AuthenticationHelper.getToken().auth;
        const permissionHelper = new PermissionHelper(roles);
        const isAllowed =
            permissionHelper.isTakeAndGo() || permissionHelper.isDeveloper();

        const date = new Date();
        const lastDay = moment(
            new Date(date.getFullYear(), date.getMonth() + 1, 0),
        ).format('DD/MM/YYYY');
        const firstDay = moment(
            new Date(date.getFullYear(), date.getMonth(), 1),
        ).format('DD/MM/YYYY');
        return {
            initialDate: filterParams.initialDate
                ? filterParams.initialDate
                : firstDay,
            endDate: filterParams.endDate ? filterParams.endDate : lastDay,
            coolerId: filterParams.coolerId ? filterParams.coolerId : '',
            licensedId:
                isAllowed && filterParams.licensedId
                    ? filterParams.licensedId
                    : '',
        };
    }

    async getDashboardOverviewAsync(
        params: string,
    ): Promise<DashboardOverview[]> {
        const activeUsers =
            await this.dashboardRepository.getActiveUsersAsync<IActiveUsers>(
                params,
            );

        const soldAndTotalProducts =
            await this.dashboardRepository.getSoldAndTotalProducts<ISoldAndTotal>(
                params,
            );

        const revenue = await this.dashboardRepository.getRevenue<IRevenue>(
            params,
        );

        const averageTicket =
            await this.dashboardRepository.getAverageTicketByUser<IAverageTickerByUser>(
                params,
            );

        const activeUserData = activeUsers.data!;

        const revenueData = revenue.data!;

        const averageTicketData = averageTicket.data!;

        const soldAndTotal = soldAndTotalProducts.data!;

        return [
            new DashboardOverview(
                'Faturamento',
                'Bruto',
                Intl.NumberFormat('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                }).format(+revenueData.grossValue),
                'Líquido',
                Intl.NumberFormat('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                }).format(+revenueData.netValue),
                'secondary',
            ),
            new DashboardOverview(
                'Usuários ativos',
                'Total no período',
                activeUserData.total,
                'Variação',
                activeUserData.variation,
                'variation',
            ),
            new DashboardOverview(
                'Produtos',
                'Vendidos',
                soldAndTotal.sold.toLocaleString('pt-BR', {
                    maximumFractionDigits: 0,
                }),
                'Variação',
                soldAndTotal.variation,
                'variation',
            ),
            new DashboardOverview(
                'Ticket médio',
                'Valor atual',
                Intl.NumberFormat('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                }).format(+averageTicketData.total),
                'Variação',
                averageTicketData.variation,
                'variation',
            ),
        ];
    }

    async getOverviewGrossValueVsNetValueAsync(
        params?: string,
    ): Promise<ILineBarChart[]> {
        const response = await this.dashboardRepository.getGrossValueVsNetValue<
            IBarChartData[]
        >(params);
        const chartData: IBarChartData[] = response.data!;

        return chartData.map(data => {
            return new LineBarChart(
                StringHelper.substring(data.key),
                data.value,
                data.lineData![0].value,
                'Produtos consumidos',
                'Faturamento bruto',
                '#00407A',
                '#FFCD00',
            );
        });
    }

    async getOverviewTotalGrossSalesAsync(
        params?: string,
    ): Promise<IPieChartProps> {
        const response2 = await this.dashboardRepository.getRevenueDetails(
            params,
        );

        const chartData: any = response2.data!;

        const totalGrossSalesChartData = chartData.map((item: any) => {
            return {
                id: item.key,
                name: item.key,
                value: item.value,
                percentage: item.percentage,
            } as any;
        });

        const totalGrossSalesProps = makePieChartProps(
            1,
            PieChartColors.get('shades-of-yellow')!,
            totalGrossSalesChartData,
        );

        return totalGrossSalesProps;
    }

    async getOverviewTotalUsersVsActiveAsync(
        params: string,
    ): Promise<ILineChart> {
        const response = await this.dashboardRepository.getTotalUsersVsActive(
            params,
        );

        const chartData: any = response.data!;

        return chartData[0].data.map((lineData: any, index: any) => {
            return new LineChart(
                lineData.key,
                chartData[0].label,
                lineData.value,
                chartData[1].label,
                chartData[1].data[index].value,
            );
        });
    }

    async getOverviewTotalNetSalesAsync(
        params: string,
    ): Promise<IPieChartProps> {
        const response = await this.dashboardRepository.getNetRevenueDetails(
            params,
        );

        const chartData: any = response.data! || [];

        const totalNetSalesChartData = chartData.map((item: any) => {
            return {
                id: item.key,
                name: item.key,
                value: item.value,
                percentage: item.percentage,
            } as any;
        });

        const totalNetSalesProps = makePieChartProps(
            2,
            PieChartColors.get('shades-of-blue')!,
            totalNetSalesChartData,
        );

        return totalNetSalesProps;
    }

    async getOverviewDailyTransactionsAsync(
        params: string,
    ): Promise<ILineBarChart[]> {
        const response =
            await this.dashboardRepository.getTransactionByDayOfWeek(params);

        const chartData: IBarChartData[] = response.data!;

        return chartData.map((data: any) => {
            return new LineBarChart(
                StringHelper.substring(data.key),
                data.value,
                data.lineData![0].value,
                'Pedidos por dia',
                'Faturamento bruto',
                '#00D5FF',
                '#FFCD00',
            );
        });
    }

    async getOverviewBestSellingProductsAsync(
        params: string,
    ): Promise<ILineBarChart[]> {
        const response = await this.dashboardRepository.getTopSellingProducts(
            params,
        );

        const chartData: IBarChartData[] = response.data;

        return chartData.map((data: any) => {
            return new LineBarChart(
                StringHelper.substring(data.key),
                data.value,
                data.lineData![0].value,
                'Produtos vendidos',
                'Faturamento bruto',
                '#FFCD00',
                '#00D5FF',
            );
        });
    }

    async getOverviewPosBillingAsync(params: string): Promise<IBarChartProps> {
        const response2 = await this.dashboardRepository.getGrossAmountByCooler(
            params,
        );

        const chartData: any = response2.data!;

        return chartData.map((data: any) => {
            return new BarChart(
                StringHelper.substring(data.key),
                data.value,
                '#36B37E',
                'Faturamento',
                data.key,
            );
        });
    }

    checkIfChartContainData(
        chartData: IPieChartProps | ILineChartProps | IBarChartProps,
        chartType: string,
    ): boolean {
        if (!chartData) return false;

        if (chartType === 'lineChart') {
            if (!(chartData as ILineChartProps).gridYValues) return false;

            const containValues = (
                chartData as ILineChartProps
            ).gridYValues.filter((value: any) => value != 0);

            return containValues.length > 0;
        }

        if (chartType === 'barChart' || chartType === 'pieChart') {
            if (!chartData.data) return false;

            const containData = (
                chartData as IPieChartProps | IBarChartProps
            ).data.filter(
                (barChartData: IPieChartData | IBarChartData) =>
                    barChartData.value != 0,
            );

            return containData.length > 0;
        }

        return false;
    }

    cardLoadingClassName(
        isCardLoading: boolean,
        isDarkTheme: boolean,
    ): string | void {
        if (isCardLoading && !isDarkTheme) {
            return 'default-loading';
        } else if (isCardLoading && isDarkTheme) {
            return 'dark-default-loading';
        }
    }

    async exportBestSellingProductsReport(
        params: string,
    ): Promise<IExportReport> {
        const response =
            await this.dashboardRepository.exportBestSellingProductsReport<IExportReport>(
                params,
            );

        if (!response.success) {
            throw new Error(response.data.message);
        }

        return response.data! as IExportReport;
    }

    async hasExportBestSellingProductsReportInProgress(): Promise<boolean> {
        const response =
            await this.dashboardRepository.hasExportBestSellingProductsReportInProgress<number>();

        if (response.data > 0) {
            return true;
        }

        return false;
    }
}
