export default class SearchCriteriaModel {
    Key: string;
    Value: string;

    constructor(key: string, { value = '' }) {
        this.Key = key;
        this.Value = value;
    }

    setValue(value: string): void {
        this.Value = value;
    }
}
