import { useState } from 'react';
import { IContestationOrderProduct, IOrderEdit } from '@/domain/models';
import { useOrderEdit } from '../../hooks';
import OrderRemoveProductModal from '../order-remove-modal';
import OrderAddProductModal from '../order-product-add-modal';
import OrderEditTableHead from '../order-edit-table-head';
import OrderEditTableRow from '../order-edit-table-row';
import OrderEditTotalValueRow from '../order-edit-total-value-row';
import { OrderProductTable } from './styles';

interface IOrderProductsEditTableProps {
    contestationOrderProducts: IContestationOrderProduct[];
    totalPayment: number;
    orderId: number | string;
    setOrderData: (value: IOrderEdit) => void;
    orderData: IOrderEdit;
    initialOrderData: IOrderEdit;
    setIsLoadingCalculate: (value: boolean) => void;
    isLoadingCalculate: boolean;
}

export default function OrderProductsEditTable(
    props: IOrderProductsEditTableProps,
): JSX.Element {
    const {
        contestationOrderProducts,
        totalPayment,
        orderId,
        setOrderData,
        orderData,
        initialOrderData,
        setIsLoadingCalculate,
        isLoadingCalculate,
    } = props;

    const [isEditing, setIsEditing] = useState<boolean | string | number>(
        false,
    );
    const [orderProductQuantity, setOrderProductQuantity] = useState<
        number | string
    >('');

    const {
        handleConfirmEdit,
        handleEnableEdit,
        handleRemoveProduct,
        handleAddProduct,
        handleOpenRemoveModal,
        handleOpenAddProductModal,
    } = useOrderEdit(
        orderData,
        orderId,
        orderProductQuantity,
        setOrderData,
        setIsEditing,
        setOrderProductQuantity,
        initialOrderData,
        setIsLoadingCalculate,
    );

    return (
        <>
            <OrderRemoveProductModal
                handleRemoveProduct={handleRemoveProduct}
            />
            <OrderAddProductModal
                handleAddProduct={handleAddProduct}
                products={contestationOrderProducts}
            />
            <h5 className="table">Detalhe do pedido</h5>
            <OrderProductTable>
                <OrderEditTableHead />
                <tbody>
                    {contestationOrderProducts[0] !== null &&
                        contestationOrderProducts.map((orderProduct, index) => (
                            <OrderEditTableRow
                                key={orderProduct.productId + index}
                                orderProduct={orderProduct}
                                isEditing={isEditing!}
                                orderProductQuantity={orderProductQuantity}
                                setOrderProductQuantity={
                                    setOrderProductQuantity
                                }
                                handleConfirmEdit={handleConfirmEdit}
                                handleEnableEdit={handleEnableEdit}
                                handleOpenRemoveModal={handleOpenRemoveModal}
                                isLoadingCalculate={isLoadingCalculate}
                                orderId={orderId}
                            />
                        ))}
                    <OrderEditTotalValueRow
                        totalPayment={totalPayment}
                        handleOpenAddProductModal={handleOpenAddProductModal}
                        oldTotalPayment={orderData.paymentDetails.oldTotal!}
                        isLoadingCalculate={isLoadingCalculate}
                    />
                </tbody>
            </OrderProductTable>
        </>
    );
}
