import { Control, Controller } from 'react-hook-form';
import { parsePhoneNumber } from 'react-phone-number-input';
import defaultInputStyles from '../input-styles';
import StyledTextField from '../styled-text-field';

interface InputPhoneNumberProps {
    control: Control<any>;
    name: string;
    label: string;
    rules?: any;
    size?: 'small' | 'normal';
    dataTestId?: string;
    readOnly?: boolean;
}

export default function InputPhoneNumber({
    control,
    name,
    label,
    rules,
    size = 'normal',
    dataTestId,
    readOnly = false,
}: InputPhoneNumberProps) {
    const { inputStyles } = defaultInputStyles(readOnly);

    return (
        <Controller
            name={name}
            control={control}
            rules={{
                ...rules,
                validate: {
                    isValidPhoneNumber: value => {
                        if (!value) {
                            return true;
                        }
                        if (typeof value === 'string') {
                            const phoneNumber = parsePhoneNumber(value, 'BR');
                            if (!phoneNumber?.isValid()) {
                                return 'Telefone inválido';
                            }
                        }
                    },
                },
            }}
            render={({
                field: { value, onChange, ...field },
                fieldState: { error },
            }) => {
                return (
                    <StyledTextField
                        data-testid={dataTestId}
                        inputProps={{
                            readOnly: readOnly,
                            maxLength: 15,
                        }}
                        sx={inputStyles}
                        className={size}
                        {...field}
                        value={value}
                        onChange={e => {
                            const phoneNumber = parsePhoneNumber(
                                e.target.value || '',
                                'BR',
                            );

                            if (!phoneNumber || !phoneNumber.isValid()) {
                                return onChange(e);
                            }

                            if (phoneNumber && phoneNumber.isValid()) {
                                return onChange(phoneNumber.formatNational());
                            }

                            return onChange(e);
                        }}
                        label={label}
                        error={!!error}
                        helperText={error ? error?.message : null}
                        required={!!rules?.required}
                        placeholder="(XX) XXXXX-XXXX"
                    />
                );
            }}
        />
    );
}
