import StyledSvgIcon from './svg-icon.styles';
import { useTheme } from 'styled-components';

export default function QuitIcon() {
    const currentTheme = useTheme();

    return (
        <StyledSvgIcon
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.6621 17C18.9331 19.989 15.7014 22 11.9999 22C6.47709 22 1.99994 17.5228 1.99994 12C1.99994 6.47715 6.47709 2 11.9999 2C15.7014 2 18.9331 4.01099 20.6621 7M12 8L8.00001 12M8.00001 12L12 16M8.00001 12H22"
                stroke={currentTheme.secondary}
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </StyledSvgIcon>
    );
}
