import IRefrigeratorProductStock from '@/domain/models/pages/refrigerator/stock/refrigerator-product-stock';
import { Button, CircularProgress, Modal, Switch } from '@/ui/components';
import { Label, CoolersBox } from './styles';
import { NumberHelper, RequestQueryMaker } from '@/domain/helpers';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { StoreState } from '@/ui/store/models';
import { makeRefrigeratorListPresenter } from '@/main/factories';
import { useQuery } from 'react-query';
import { Tooltip } from 'react-tooltip';
import CoolersCheckbox from './coolers-checkbox';
import MonetaryTextField from '@/ui/components/input/monetary-text-field';
import { useEffect } from 'react';
import { Grid, Row, Column } from '@/ui/components/layout';

interface EditValueModalProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    product: IRefrigeratorProductStock;
    refrigeratorCode: string;
    confirmModal: (payload: any) => void;
}

export default function EditValueModal({
    isOpen,
    setIsOpen,
    product,
    refrigeratorCode,
    confirmModal,
}: EditValueModalProps) {
    const refrigeratorPresenter = makeRefrigeratorListPresenter();
    const isDarkTheme = useSelector((store: StoreState) => {
        return store.toggleTheme.darkTheme;
    });

    const {
        control,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: { isDirty, isValid },
    } = useForm<{
        value: number;
        isAllCoolers: boolean;
        coolers: any[];
    }>({
        mode: 'all',
        defaultValues: {
            coolers: [],
        },
    });

    const [isAllCoolers, coolersValue, value] = watch([
        'isAllCoolers',
        'coolers',
        'value',
    ]);
    const isFormValid = !isDirty || !isValid;
    const hasSelectedCoolers = coolersValue.length > 0 || isAllCoolers;

    const isDisabled = isFormValid || !hasSelectedCoolers;

    const { data: coolers, isLoading: isCoolersLoading } = useQuery(
        ['coolersAutocomplete', product.productId],
        () =>
            refrigeratorPresenter.getCoolerFilterAutocompleteAsync(
                RequestQueryMaker({
                    productId: product.productId,
                }),
            ),
        {
            enabled: !!product.productId,
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            onSuccess: coolers => {
                if (refrigeratorCode) {
                    const cooler = coolers.find(
                        cooler => cooler.label === refrigeratorCode,
                    );
                    cooler && reset({ coolers: [cooler] });
                }
            },
        },
    );

    const updateValue = (data: any) => {
        const payload = {
            productId: product.productId,
            productName: product.productName,
            value: Number(data.value),
            coolers: data.coolers,
            isAllCoolers: data.isAllCoolers,
        };

        confirmModal(payload);
        setIsOpen(false);
    };

    const handleSwitchChange = (value: boolean, onChange?: any) => {
        if (value === true && onChange) {
            setValue('coolers', coolers as any);
            return onChange(value);
        } else if (
            value === false &&
            coolers?.length === coolersValue.length &&
            onChange
        ) {
            setValue('coolers', []);
            return onChange(value);
        }
    };

    useEffect(() => {
        if (coolersValue.length !== coolers?.length) {
            setValue('isAllCoolers', false);
        } else if (coolersValue.length === coolers?.length) {
            setValue('isAllCoolers', true);
        }
    }, [coolersValue]);

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            okButton={
                <Button
                    className="base"
                    text="Cancelar"
                    onClick={() => setIsOpen(false)}
                />
            }
            cancelButton={
                <Button
                    text="Salvar"
                    onClick={handleSubmit(updateValue)}
                    disabled={isDisabled}
                />
            }
            title="Edição de valor"
            content={
                <Row gap={6}>
                    <Column
                        basis="35%"
                        gap={4}
                        align="stretch"
                        justify="space-between"
                    >
                        <Column justify="center" gap={4}>
                            <Label>Produto</Label>
                            <Row gap={3} align="center">
                                <img
                                    width={30}
                                    className="stock"
                                    src={product.productImg}
                                    alt={product.productName}
                                />
                                {product.productName}
                            </Row>
                        </Column>
                        <Column gap={4}>
                            <Label>Valor médio</Label>
                            {NumberHelper.makeMoney(product.avgProductValue)}
                        </Column>
                        <Controller
                            control={control}
                            name="value"
                            defaultValue={value}
                            rules={{
                                required: true,
                                min: 0,
                            }}
                            render={({ field: { onChange, value } }) => (
                                <Column gap={4}>
                                    <MonetaryTextField
                                        label="Novo valor unitário"
                                        name="value"
                                        setValue={(value: number) =>
                                            onChange(value)
                                        }
                                        defaultValue={value}
                                    />
                                    {value <= 0 && (
                                        <div
                                            style={{
                                                color: '#d32f2f',
                                                fontSize: 14,
                                                paddingLeft: 8,
                                            }}
                                        >
                                            Informe o novo valor do produto
                                        </div>
                                    )}
                                </Column>
                            )}
                        />
                    </Column>
                    <Column gap={4} align="start" basis="65%">
                        <Switch
                            control={control}
                            label="Todos os coolers"
                            name="isAllCoolers"
                            interceptOnChange={handleSwitchChange}
                        />
                        {isCoolersLoading && !coolers ? (
                            <CircularProgress variant="indeterminate" />
                        ) : (
                            <CoolersBox
                                label={`Coolers - ${coolersValue.length} de ${
                                    coolers ? coolers?.length : '-'
                                } selecionados`}
                                isDarkTheme={isDarkTheme}
                            >
                                <Grid
                                    columns={2}
                                    gap={2}
                                    padding="16px 8px"
                                    maxHeight="250px"
                                    overflow={{ y: 'auto' }}
                                >
                                    {!isAllCoolers && (
                                        <Tooltip
                                            style={{
                                                zIndex: 9999,
                                            }}
                                            id="coolers"
                                        />
                                    )}

                                    {coolers &&
                                        coolers?.map((cooler, index) => (
                                            <CoolersCheckbox
                                                key={cooler.id + index}
                                                cooler={cooler}
                                                coolersValue={coolersValue}
                                                setValue={setValue}
                                            />
                                        ))}
                                </Grid>
                            </CoolersBox>
                        )}
                    </Column>
                </Row>
            }
        />
    );
}
