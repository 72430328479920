import { OverviewIcons } from '../context-icons';
import { IInfoModalProps } from './info-modal-type';

const OverviewInfoModals = new Map<string, IInfoModalProps>([
    [
        OverviewIcons.BILLING,
        {
            title: OverviewIcons.BILLING,
            content: (
                <>
                    Para o faturamento iremos considerar os seguintes status
                    para cada item:
                    <div>
                        <span className="subtitle">Bruto:</span> total de
                        pedidos pagos com os seguintes status:
                    </div>
                    <ul>
                        <li>Processado</li>
                        <li>Contestação Recusada</li>
                        <li>Contestação Aprovada</li>
                        <li>Em contestação</li>
                        <li>Problema</li>
                    </ul>
                    <div>
                        <span className="subtitle">Líquido:</span> total de
                        pedidos pagos com os seguintes status:
                    </div>
                    <ul>
                        <li>Processado</li>
                        <li>Contestação Recusada</li>
                        <li>Contestação Aprovada</li>
                        <li>Em contestação</li>
                    </ul>
                </>
            ),
        },
    ],
    [
        OverviewIcons.ACTIVE_USERS,
        {
            title: OverviewIcons.ACTIVE_USERS,
            content: (
                <>
                    <div>
                        <span className="subtitle">Usuários ativos:</span> são
                        usuários que compraram pelo menos uma vez naquele
                        período selecionado.
                    </div>
                    <div>
                        <span className="subtitle">Variação:</span> é a
                        diferença de usuários que compraram pelo menos uma vez
                        no período selecionado comparado ao mesmo período
                        anteriormente;
                    </div>
                    <ul>
                        <li>
                            <span className="subtitle">Exemplo:</span> no
                            período atual 10 usuário compraram, no período
                            anterior foram 15 usuário então tivemos uma variação
                            de -33,3%
                        </li>
                        <li>
                            <span className="subtitle">
                                Cálculo da variação:
                            </span>{' '}
                            [( valor no momento posterior ÷ valor no momento
                            anterior ) - 1] × 100.
                        </li>
                    </ul>
                </>
            ),
        },
    ],
    [
        OverviewIcons.PRODUCTS,
        {
            title: OverviewIcons.PRODUCTS,
            content: (
                <>
                    <div>
                        <span className="subtitle">Consumido:</span> é a
                        quantidade de produtos consumido dos pedidos com o
                        seguinte status:
                    </div>
                    <ul>
                        <li>Processado</li>
                        <li>Contestação Recusada</li>
                        <li>Contestação Aprovada</li>
                        <li>Constestação Bancária</li>
                        <li>Em contestação</li>
                        <li>Fatura Manual</li>
                        <li>Problema</li>
                    </ul>
                    <div>
                        <span className="subtitle">Variação:</span> é a
                        diferença de produtos vendidos no período selecionado
                        comparado ao mesmo período anteriormente;
                    </div>
                    <ul>
                        <li>
                            <span className="subtitle">Exemplo:</span> no
                            período atual 1000 usuário compraram, no período
                            anterior foram 1500 usuário então tivemos uma
                            variação de -33,3%
                        </li>
                        <li>
                            <span className="subtitle">
                                Cálculo da variação:
                            </span>{' '}
                            [(valor no momento posterior ÷ valor no momento
                            anterior) - 1] × 100.
                        </li>
                    </ul>
                </>
            ),
        },
    ],
    [
        OverviewIcons.AVERAGE_TICKET,
        {
            title: OverviewIcons.AVERAGE_TICKET,
            content: (
                <>
                    <div>
                        <span className="subtitle">Ticket médio:</span> é a
                        média dos valores dos pedido feitos por usuário, no
                        período selecionado com os seguintes status:
                    </div>
                    <ul>
                        <li>Processado</li>
                        <li>Contestação Recusada</li>
                        <li>Contestação Aprovada</li>
                        <li>Em contestação</li>
                        <li>Problema</li>
                    </ul>
                    <div>
                        <span className="subtitle">Variação:</span> é a
                        diferença do ticket médio do período selecionado
                        comparado ao mesmo período anteriormente;
                    </div>
                    <ul>
                        <li>
                            <span className="subtitle">Exemplo: </span>no
                            período atual o ticket é de R$15,25 e no período
                            anterior foi de R$14,50 então tivemos uma variação
                            de 5,17%
                        </li>
                        <li>
                            <span className="subtitle">
                                Cálculo da variação:
                            </span>{' '}
                            [(valor no momento posterior ÷ valor no momento
                            anterior) - 1] × 100.
                        </li>
                    </ul>
                </>
            ),
        },
    ],
]);

export default OverviewInfoModals;
