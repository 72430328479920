import { BackdropAppState } from '@/ui/store/models';
import { Types, BackdropAppAction } from './types';

export default function backdropApp(
    state = new BackdropAppState({}),
    action: BackdropAppAction,
): BackdropAppState {
    switch (action.type) {
        case Types.BACKDROP_APP_OPEN:
            return action.payload.data;
        case Types.BACKDROP_APP_CLOSE:
            return new BackdropAppState({ open: false });
        default:
            return state;
    }
}
