import { TableSortLabel } from '@mui/material';
import { HeaderGroup } from 'react-table';
import { useContext } from 'react';
import { SortingRule } from 'react-table';

import { FilterContext } from '@/ui/context/context-filter';
import { TableCell } from './styles';

interface ITableCellSortingProps {
    column: HeaderGroup<any>;
    sortBy: SortingRule<any>[];
}

export default function TableCellSorting(
    props: ITableCellSortingProps,
): JSX.Element {
    const { column, sortBy } = props;
    const { getSortColumn, updateFilters, getFilters, getSortDirection } =
        useContext(FilterContext);

    const pageFilters = getFilters();

    return (
        <TableCell
            {...(column.id === 'selection'
                ? column.getHeaderProps()
                : column.getHeaderProps(
                      column.getSortByToggleProps({
                          title: column.Header as string,
                      }),
                  ))}
            align={(column as any)['align'] ?? 'left'}
            style={{
                width: column.width ?? '1%',
                maxWidth: column.maxWidth ?? column.width,
            }}
            className={`sort-by-${column.isSorted}`}
            onClick={() => {
                column.toggleSortBy();

                const sortDirection = column.isSorted
                    ? sortBy[0]?.desc
                        ? ''
                        : 'DESC'
                    : 'ASC';

                updateFilters({
                    ...pageFilters,
                    column: {
                        id: column.id,
                        name: 'column',
                        label: column.id,
                    },
                    sort: {
                        id: sortDirection,
                        label: sortDirection,
                        name: 'sort',
                    },
                });
            }}
        >
            {column.render('Header')}
            {(column.id !== 'selection' && column.isSorted) ||
            getSortDirection() ? (
                <TableSortLabel
                    className="sort-label"
                    title="Ordenação"
                    active={getSortColumn(column && column.id)}
                    direction={getSortDirection() === 'DESC' ? 'desc' : 'asc'}
                />
            ) : null}
        </TableCell>
    );
}
