import styled from 'styled-components';
import { Row } from '../../layout';

export const CloseIconContainer2 = styled.div`
    position: relative;

    &.detail {
        svg {
            position: absolute;
            right: -550px;
            bottom: 50px;
        }
    }

    &.financial {
        svg {
            position: absolute;
            right: -550px;
            top: -21px;
        }
    }
`;

export const TableContainer = styled.main`
    min-height: 150px;
    padding-left: 10px;
    padding-right: 5px;
    margin-top: 10px;
    overflow-x: auto;

    &.modal {
        max-height: 500px;
    }
`;

export const IconContainer = styled(Row)`
    justify-content: center;
    width: 95%;
    padding: 2.7px 0px;

    &.mr {
        margin-right: 11.33px;
    }
`;

export const MainContainer = styled.main`
    color: ${props => props.theme.secondary};
    text-align: center;
    background-color: ${props => props.theme.bodyBg};
    margin-left: 124px;
    margin-right: 28px;
    margin-top: 95px;
    padding-bottom: 5px;

    @media (max-width: 650px) {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 28px;
        margin-top: 0px;
    }

    @media (max-width: 375px) {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 28px;
        margin-top: 0px;
    }
`;
