import { DevTool } from '@hookform/devtools';
import { ContextType, IAutocomplete } from '@/domain/models';
import {
    AutoCompleteField,
    CheckboxInput,
    CircularProgress,
    DragDrop,
    ImagePreview,
    InputTextField,
} from '@/ui/components';
import Default from '../../default';
import MixCheckbox from './mix-checkbox';
import { Column, Grid, Row } from '@/ui/components/layout';
import { UseProductManagamentPresenter } from '@/ui/hooks';

export default function ProductsEdit(): JSX.Element {
    const {
        isSubmitDisabled,
        control,
        isLoading,
        handleSubmit,
        onSubmit,
        previewSource,
        isTakeAndGo,
        categories,
        providers,
        useProductQuery,
        handleRemoveImage,
        mixes,
        mixData,
        setValue,
    } = UseProductManagamentPresenter();

    const { data: productData, isLoading: isProductLoading } =
        useProductQuery();

    return (
        <Default
            contextType={ContextType.product_save}
            isSubmitDisabled={isSubmitDisabled}
        >
            <DevTool control={control} placement="top-right" />
            {!isLoading || !isProductLoading ? (
                <Column
                    as="form"
                    onSubmit={handleSubmit(onSubmit)}
                    padding={{ bottom: 4 }}
                    id="product-form"
                >
                    <h5>Dados do produto</h5>
                    <Grid
                        columns={4}
                        gap={6}
                        responsiveType="input"
                        margin={{ bottom: 4 }}
                    >
                        {isTakeAndGo && (
                            <InputTextField
                                control={control}
                                name="code"
                                label="Código"
                                rules={{
                                    required: 'Código é obrigatório',
                                }}
                            />
                        )}
                        <InputTextField
                            control={control}
                            name="name"
                            label="Nome"
                            rules={{
                                required: 'Nome é obrigatório',
                            }}
                        />
                        <InputTextField
                            control={control}
                            name="description"
                            label="Descrição"
                            rules={{
                                required: 'Descrição é obrigatório',
                            }}
                        />
                        <InputTextField
                            type="number"
                            control={control}
                            name="suggestedValue"
                            label="Valor sugerido"
                            rules={{
                                required: 'Valor sugerido é obrigatório',
                            }}
                        />
                        {isTakeAndGo && (
                            <InputTextField
                                type="number"
                                control={control}
                                name="costPrice"
                                label="Valor de tabela"
                                rules={{
                                    required: 'Valor tabela é obrigatório',
                                }}
                            />
                        )}
                        <AutoCompleteField
                            control={control}
                            options={categories}
                            name="categoryId"
                            label="Categoria"
                            rules={{
                                required: 'Categoria é obrigatório',
                            }}
                        />
                        {isTakeAndGo && (
                            <>
                                <AutoCompleteField
                                    control={control}
                                    options={providers}
                                    name="providerId"
                                    label="Fornecedor"
                                    rules={{
                                        required: 'Fornecedor é obrigatório',
                                    }}
                                />
                                <InputTextField
                                    control={control}
                                    name="ncm"
                                    label="NCM"
                                    rules={{
                                        required: 'NCM é obrigatório',
                                    }}
                                />
                                <CheckboxInput
                                    readOnly={false}
                                    control={control}
                                    label="Público"
                                    name="general"
                                />
                            </>
                        )}
                    </Grid>
                    <InputTextField
                        control={control}
                        name="information"
                        label="Informações"
                        type="textarea"
                        rules={{
                            required: 'O campo informações é obrigatório',
                        }}
                    />
                    <Row gap={6} margin={{ top: 4 }}>
                        <Column align="start">
                            <h5>Imagem*</h5>
                            <DragDrop
                                fileTypes={['jpg', 'jpeg']}
                                acceptedFormatsText={
                                    'Formatos aceitos: jpeg e jpg'
                                }
                                control={control}
                                name="productImage"
                                rules={{
                                    required: 'O campo imagem é obrigatório!',
                                }}
                                acceptedSizeText="Tamanho: 300x300"
                            />
                        </Column>
                        {previewSource && (
                            <Column width="40vw" align="start">
                                <h5>Pré visualização</h5>
                                {(previewSource || productData) && (
                                    <ImagePreview
                                        imageSrc={previewSource}
                                        handleRemoveImage={handleRemoveImage}
                                        width={200}
                                        height={200}
                                    />
                                )}
                            </Column>
                        )}
                    </Row>
                    {isTakeAndGo && (
                        <>
                            <h5>Dados do mix</h5>
                            <Grid columns={4} gap={4} responsiveType="input">
                                {mixes.map((mix: IAutocomplete, index) => {
                                    return mix.label && mix.id !== 'code' ? (
                                        <MixCheckbox
                                            key={mix.id + index}
                                            mix={mix}
                                            mixData={mixData}
                                            setValue={setValue}
                                        />
                                    ) : null;
                                })}
                            </Grid>
                        </>
                    )}
                </Column>
            ) : (
                <CircularProgress variant="indeterminate" />
            )}
        </Default>
    );
}
