import { useCallback, useEffect, useState } from 'react';
import Carousel from 'react-material-ui-carousel';

interface IImageCarouselProps {
    media: string[];
}

export default function ImageCarousel(props: IImageCarouselProps) {
    const { media } = props;

    const [currentIndex, setCurrentIndex] = useState(0);

    const checkIfIsImage = (item: string) => {
        return (
            item.includes('jpg') ||
            item.includes('jpeg') ||
            item.includes('png')
        );
    };

    const changeImageByArrows = useCallback(
        ({ key }: KeyboardEvent) => {
            if (key === 'ArrowLeft') {
                setCurrentIndex(current =>
                    current - 1 < 0 ? media.length - 1 : current - 1,
                );
            } else if (key === 'ArrowRight') {
                setCurrentIndex(current =>
                    current + 1 > media.length - 1 ? 0 : current + 1,
                );
            }
        },
        [media],
    );

    useEffect(() => {
        document.addEventListener('keydown', changeImageByArrows);

        return function cleanup() {
            document.removeEventListener('keydown', changeImageByArrows);
        };
    });

    return (
        <Carousel
            autoPlay={false}
            navButtonsAlwaysVisible
            height={500}
            index={currentIndex}
        >
            {media.map(item => {
                return checkIfIsImage(item) ? (
                    <img
                        key={item}
                        alt="Imagem do produto"
                        style={{
                            maxHeight: '500px',
                        }}
                        src={item}
                    />
                ) : (
                    <video
                        key={item}
                        style={{
                            objectFit: 'cover',
                            height: '100%',
                            width: '88%',
                            zIndex: 9,
                        }}
                        controls
                        loop
                    >
                        <source src={item} type="video/mp4" />
                        <source src={item} type="video/ogg" />
                        Your browser does not support the video tag.
                    </video>
                );
            })}
        </Carousel>
    );
}
