export default class DashboardOverview {
    title: string;
    value: string | number;
    text1: string | number;
    value2: string | number;
    text2: string;
    value2Color: string;

    constructor(
        title: string,
        text1: string,
        value: string | number,
        text2: string,
        value2: string | number,
        value2Color: string,
    ) {
        this.title = title;
        this.text1 = text1;
        this.value = value;
        this.text2 = text2;
        this.value2 = value2;
        this.value2Color = value2Color;
    }
}
