import { AuthenticationHelper } from '@/domain/helpers';
import { WindowHelper } from '@/ui/helpers';
import axios, { AxiosInstance } from 'axios';

export default class AxiosHelper {
    static getInstance(auth = false): AxiosInstance {
        const axiosInstance = axios.create({
            baseURL: process.env.REACT_APP_API_GATEWAY,
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        axiosInstance.interceptors.request.use(
            config => {
                if (!auth) return config;
                else {
                    if (!AuthenticationHelper.isAuthenticated()) {
                        WindowHelper.redirect('/login');
                        throw new axios.Cancel('Token has expired');
                    }

                    const token = AuthenticationHelper.getTokenToString();
                    config.headers!.Authorization = `Bearer ${token}`;
                    return config;
                }
            },
            error => {
                Promise.reject(error);
            },
        );

        return axiosInstance;
    }
}
