enum PaginationFilters {
    page = 'page',
    limit = 'limit',
    sort = 'sort',
    column = 'column',
    active_view = 'activeView',
}

const ContextPaginationFilters = new Map<string, string>([
    [PaginationFilters.page, 'page'],
    [PaginationFilters.limit, 'limit'],
    [PaginationFilters.sort, 'sort'],
    [PaginationFilters.column, 'column'],
    [PaginationFilters.active_view, 'activeView'],
]);

export default ContextPaginationFilters;
