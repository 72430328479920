import { IProductFilterParams } from '@/presentation/models/products';

const productDefaultValues: IProductFilterParams = {
    page: {
        id: '0',
        name: 'page',
        label: '0',
    },
    limit: {
        id: 25,
        name: 'limit',
        label: '25',
    },
    column: {
        id: 'name',
        name: 'column',
        label: 'name',
    },
    sort: {
        id: 'DESC',
        name: 'sort',
        label: 'DESC',
    },
};

export default productDefaultValues;
