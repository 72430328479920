import { parsePhoneNumber } from 'react-phone-number-input';
import { DocumentHelper, StringHelper } from '@/domain/helpers';
import {
    ILicensed,
    LicensedHttpEndpoint,
    SearchCriteriaModel,
    SearchRequestModel,
    SearchResponseModel,
} from '@/domain/models';
import { ColumnModel } from '@/presentation/models/table';
import { ITableServerSidePresenter, EditIcon } from '@/ui/components';
import { WindowHelper } from '@/ui/helpers';
import { RowActionProps } from '@/ui/interfaces/props/table';
import { ILicensedPresenter } from '@/ui/pages';
import { ISearchRepositoryPort } from '@/infra';

export default class LicensedTablePresenter
    implements ITableServerSidePresenter
{
    constructor(
        private readonly searchRepository: ISearchRepositoryPort,
        private readonly licensedPresenter: ILicensedPresenter,
    ) {}

    makeColumns(): ColumnModel<any>[] {
        const formatPhone = (phone: string) => {
            const formattedPhone = parsePhoneNumber(phone, 'BR');
            if (formattedPhone?.isValid()) {
                return formattedPhone.formatNational();
            }

            return phone;
        };
        const formatCnpj = (cnpj: string) => DocumentHelper.makeMaskCnpj(cnpj);
        const formatRegimeType = (regimeType: string) =>
            this.licensedPresenter.regimeTypesList.find(
                regime => regime.id === regimeType,
            )?.label;

        return [
            new ColumnModel<any>('Código', 'licensedId', {
                width: '100px',
            }),
            new ColumnModel<any>('Licenciado', 'name', {}),
            new ColumnModel<any>('Telefone', 'phone', {})
                .withRender((phone: string) => (
                    <span>{formatPhone(phone)}</span>
                ))
                .withRenderTitleCustom(({ phone }) => formatPhone(phone)),
            new ColumnModel<any>('CNPJ', 'cnpj', {})
                .withRender((cnpj: string) => <span>{formatCnpj(cnpj)}</span>)
                .withRenderTitleCustom(({ cnpj }) => formatCnpj(cnpj)),
            new ColumnModel<any>('Regime de tributação', 'regimeType', {})
                .withRender((regimeType: string) => (
                    <span>{formatRegimeType(regimeType)!}</span>
                ))
                .withRenderTitleCustom(
                    ({ regimeType }) => formatRegimeType(regimeType)!,
                ),
            new ColumnModel<any>('Endereço', 'street', {}),
            new ColumnModel<any>('Número', 'addressNumber', {}),
            new ColumnModel<any>('Cidade', 'city', {}),
            new ColumnModel<any>('Estado', 'state', {
                width: '90px',
            }),
        ];
    }

    makeSearchRequestModel(): SearchRequestModel {
        return new SearchRequestModel(
            LicensedHttpEndpoint.LICENSED_BASE,
            'licensedId',
            {
                isOrderByDesc: true,
                criterias: [
                    new SearchCriteriaModel('licensedName', {
                        value: '',
                    }),
                ],
            },
        );
    }

    makeLabelDisplayedResult(response: SearchResponseModel<any>): string {
        if (response === undefined) return '';
        if (response.page === undefined || response.page.total === 0) return '';

        const textPlural = StringHelper.pluralize({
            count: response.page.numberOfElements,
        });

        return `Exibindo ${response.page.numberOfElements} licenciado${textPlural}`;
    }

    async search<T>(
        request: SearchRequestModel,
    ): Promise<SearchResponseModel<T>> {
        return await this.searchRepository.getDataAsync<T>(
            request,
            LicensedHttpEndpoint.LICENSED_BASE,
        );
    }

    makeRowActions(): RowActionProps<any>[] {
        return [
            {
                icon: () => <EditIcon />,
                tooltip: 'Editar',
                enableClickOnRow: false,
                onClick: (_, rowData: ILicensed) =>
                    WindowHelper.redirect(`/licensed/${rowData?.licensedId}`),
            },
        ];
    }
}
