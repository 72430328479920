import { IUserFilterParams } from '@/presentation/models/user/user-filter';

const userDefaultValues: IUserFilterParams = {
    name: null,
    email: null,
    telephone: null,
    code: null,
    cpf: null,
    licensed: null,
    page: {
        id: '0',
        name: 'page',
        label: '0',
    },
    limit: {
        id: 25,
        name: 'limit',
        label: '25',
    },
    column: {
        id: 'id',
        name: 'column',
        label: 'id',
    },
    sort: {
        id: 'DESC',
        name: 'sort',
        label: 'DESC',
    },
};

export default userDefaultValues;
