import StyledSvgIcon from './svg-icon.styles';

export default function ProductsIcon() {
    return (
        <StyledSvgIcon
            width="18"
            height="18"
            viewBox="0 0 18 18"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.3332 5.66667C12.3332 6.55072 11.982 7.39857 11.3568 8.02369C10.7317 8.64881 9.88387 9 8.99982 9C8.11576 9 7.26792 8.64881 6.6428 8.02369C6.01768 7.39857 5.66649 6.55072 5.66649 5.66667M2.0275 5.16782L1.44417 12.1678C1.31885 13.6716 1.2562 14.4235 1.51039 15.0035C1.73372 15.5131 2.12068 15.9336 2.60997 16.1985C3.16688 16.5 3.92137 16.5 5.43035 16.5H12.5693C14.0783 16.5 14.8328 16.5 15.3897 16.1985C15.879 15.9336 16.2659 15.5131 16.4892 15.0035C16.7434 14.4235 16.6808 13.6716 16.5555 12.1678L15.9721 5.16782C15.8643 3.87396 15.8104 3.22703 15.5239 2.73738C15.2716 2.3062 14.8959 1.9605 14.4452 1.74487C13.9335 1.5 13.2843 1.5 11.986 1.5L6.01369 1.5C4.71534 1.5 4.06617 1.5 3.55442 1.74487C3.10377 1.9605 2.72807 2.3062 2.47576 2.73738C2.18923 3.22703 2.13532 3.87396 2.0275 5.16782Z"
                stroke="#FFDD3F"
                fill="transparent"
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </StyledSvgIcon>
    );
}
