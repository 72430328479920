import { NumberHelper } from '@/domain/helpers';
import { TooltipContainer } from './styles';

export default function PieTooltip({ payload, isEvaluation }: any) {
    const hasValueInMoney = () => {
        if (isEvaluation) return false;

        return !(
            payload[0].payload.name == 'Temperatura' ||
            payload[0].payload.name == 'Organização' ||
            payload[0].payload.name == 'Tempo de abertura' ||
            payload[0].payload.name == 'Não encontrei um produto' ||
            payload[0].payload.name == 'Preço'
        );
    };

    return (
        <TooltipContainer>
            {payload.length > 0 && (
                <>
                    <h3>
                        {payload &&
                            payload.length > 0 &&
                            payload[0].payload.name}
                    </h3>
                    <p key={payload[0].payload.name}>
                        {hasValueInMoney() ? 'Valor' : 'Quantidade'}:{' '}
                        {payload && payload.length > 0 && hasValueInMoney()
                            ? NumberHelper.makeMoney(payload[0].payload.value)
                            : payload[0].payload.value}
                    </p>
                    <p>
                        Porcentagem:{' '}
                        {payload &&
                            payload.length > 0 &&
                            payload[0].payload.percentage}
                        %
                    </p>
                </>
            )}
        </TooltipContainer>
    );
}
