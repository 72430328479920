enum coolerStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

enum coolerSituation {
    INSTALLED = 'INSTALLED',
    INPRODUCTION = 'INPRODUCTION',
    DELIVERED = 'DELIVERED',
    MAINTENANCEPDV = 'MAINTENANCEPDV',
    MAINTENANCETAKE = 'MAINTENANCETAKE',
    INSTOCK = 'INSTOCK',
    OUTOFOPERATION = 'OUTOFOPERATION',
    INTRANSIT = 'INTRANSIT',
}

export const coolerStatusMap = new Map<string, string>([
    [coolerStatus.ACTIVE, 'Ativo'],
    [coolerStatus.INACTIVE, 'Inativo'],
]);

export const coolerSituationMap = new Map<string, string>([
    [coolerSituation.INSTALLED, 'Instalado'],
    [coolerSituation.INPRODUCTION, 'Em produção'],
    [coolerSituation.DELIVERED, 'Entregue'],
    [coolerSituation.MAINTENANCEPDV, 'Manutenção no PDV'],
    [coolerSituation.MAINTENANCETAKE, 'Manutenção na Take'],
    [coolerSituation.INSTOCK, 'Em estoque'],
    [coolerSituation.OUTOFOPERATION, 'Fora de operação'],
    [coolerSituation.INTRANSIT, 'Em transito'],
]);
