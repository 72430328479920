import {
    ContextType,
    IAutocomplete,
    IRefrigeratorAddProduct,
    IRefrigeratorEditInputs,
    TableIcons,
} from '@/domain/models';
import { NotificationContext } from '@/main';
import {
    makeLicensedPresenter,
    makeRefrigeratorListPresenter,
} from '@/main/factories';
import makeProductsPresenter from '@/main/factories/presenters/pages/products/product-presenter-factory';
import { store } from '@/ui/store/config';
import { NavigationAppState } from '@/ui/store/models';
import selectNavigationAction from '@/ui/store/modules/app/navigation/actions';
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

export default function useRefrigeratorListQueries(
    tabValue: number,
    refrigeratorId?: string,
) {
    const queryClient = useQueryClient();

    const navigate = useNavigate();
    const { errorNotification, successNotification } =
        useContext(NotificationContext);

    const isUpdate = !!refrigeratorId;
    const tabs: Array<string> = ['Informações do cooler'];

    if (isUpdate) {
        tabs.push('Produtos do cooler');
    }

    const informationForm = useForm<IRefrigeratorEditInputs>({
        mode: 'all',
        defaultValues: {
            beginDate: null,
            endDate: null,
            sendDate: null,
        },
    });

    const {
        reset,
        setValue,
        formState: { isDirty, isValid, isSubmitting },
    } = informationForm;
    const isDisabled = !isDirty || !isValid || isSubmitting;

    const licensedPresenter = makeLicensedPresenter();
    const productPresenter = makeProductsPresenter();
    const refrigeratorPresenter = makeRefrigeratorListPresenter();

    const licensedAutoCompleteData = queryClient.getQueryData<
        Array<IAutocomplete>
    >('licensedAutoComplete');
    const { data: licensedAutoComplete = [], isLoading: isLicensedLoading } =
        useQuery(
            'licensedAutoComplete',
            () => licensedPresenter.getLicensedAutoComplete(),
            {
                initialData: licensedAutoCompleteData,
                enabled: !licensedAutoCompleteData,
                onSuccess: data => {
                    queryClient.setQueryData('licensedAutoComplete', data);
                },
            },
        );

    const { mutate: getMixById, isLoading: isMixLoading } = useMutation(
        (mixCode: string) => productPresenter.getMixById(mixCode),
        {
            onSuccess: mix => {
                setValue('mix', {
                    id: mix.mix,
                    label: mix.name,
                });
            },
        },
    );

    const {
        data: refrigeratorData,
        isLoading: isRefrigeratorLoading,
        refetch: refetchRefrigerator,
    } = useQuery(
        ['getRefrigeratorById', refrigeratorId],
        () =>
            refrigeratorPresenter.getRefrigeratorAsync(
                refrigeratorId as string,
            ),
        {
            enabled:
                isUpdate && !isLicensedLoading && Boolean(licensedAutoComplete),
            onSuccess: data => {
                if (!data) {
                    navigate('../refrigerator/list');
                    errorNotification('Erro ao buscar informações do cooler');
                    return;
                }

                const { location, mix } = data as IRefrigeratorEditInputs;
                getMixById(mix.id);

                store.dispatch(
                    selectNavigationAction(
                        new NavigationAppState(
                            ContextType.refrigerator_edit,
                            ContextType.refrigerator_list,
                            TableIcons.REFRIGERATOR,
                            location,
                        ),
                    ),
                );

                const licensedId = (
                    licensedAutoComplete as Array<IAutocomplete>
                ).find(
                    licensed =>
                        Number(licensed.id) ===
                        Number((data as IRefrigeratorEditInputs).licensedId.id),
                )!;

                reset({
                    ...(data as IRefrigeratorEditInputs),
                    licensedId,
                });
            },
            onError: () => {
                navigate('../refrigerator/list');
                errorNotification('Erro ao buscar informações do cooler');
            },
        },
    );

    const { mutate: updateRefrigerator, isLoading: isUpdatingRefrigerator } =
        useMutation(
            (data: IRefrigeratorEditInputs) =>
                refrigeratorPresenter.updateRefrigerator(
                    data,
                    refrigeratorId as string,
                ),
            {
                onSuccess: res => {
                    if (res.success) {
                        refetchRefrigeratorProducts();
                        successNotification('Cooler atualizado com sucesso!');
                        return refetchRefrigerator();
                    }

                    errorNotification(
                        res.error?.message ||
                            'Erro ao atualizar o cooler! Por favor contate o suporte.',
                    );
                },
                onError: (err: string) => {
                    errorNotification(
                        err ||
                            'Erro ao atualizar o cooler! Por favor contate o suporte.',
                    );
                },
            },
        );

    const { mutate: createRefrigerator, isLoading: isCreatingRefrigerator } =
        useMutation(
            (data: IRefrigeratorEditInputs) =>
                refrigeratorPresenter.createRefrigerator(data),
            {
                onSuccess: res => {
                    if (res.success) {
                        successNotification('Cooler criado com sucesso!');
                        return navigate('../refrigerator/list/' + res.data.id);
                    }

                    errorNotification(
                        res.error?.message ||
                            'Erro ao criar o cooler! Por favor contate o suporte.',
                    );
                },
                onError: (err: string) => {
                    errorNotification(
                        err ||
                            'Erro ao criar o cooler! Por favor contate o suporte.',
                    );
                },
            },
        );

    const isLoading =
        isRefrigeratorLoading ||
        isMixLoading ||
        isLicensedLoading ||
        isUpdatingRefrigerator ||
        isCreatingRefrigerator;

    const [refrigeratorProductsQuery, allProductsQuery] = useQueries([
        {
            queryKey: ['allProducts', refrigeratorId],
            queryFn: () =>
                refrigeratorPresenter.getProductsByRefrigeratorIdAsync(
                    refrigeratorId as string,
                ),
            enabled: isUpdate,
        },
        {
            queryKey: ['productsOfRefrigerator', refrigeratorId],
            queryFn: () =>
                refrigeratorPresenter.getRefrigeratorProductEditAsync(
                    refrigeratorId as string,
                ),
            enabled: isUpdate,
        },
    ]);

    const refetchRefrigeratorProducts = () => {
        refrigeratorProductsQuery.refetch();
        allProductsQuery.refetch();
    };

    const { mutate: addRefrigeratorProduct } = useMutation(
        (data: IRefrigeratorAddProduct) =>
            refrigeratorPresenter.addRefrigeratorProduct(
                data,
                refrigeratorId as string,
            ),
        {
            onSuccess: () => {
                refetchRefrigeratorProducts();
                successNotification('Produto adicionado com sucesso!');
            },
            onError: (error: string) => {
                errorNotification(error);
            },
        },
    );

    const { mutate: updateProduct } = useMutation(
        ({ productId, data }: any) =>
            refrigeratorPresenter.updateRefrigeratorProduct(
                productId,
                refrigeratorId as string,
                data,
            ),
        {
            onSuccess: () => {
                allProductsQuery.refetch();
                successNotification('Produto atualizado com sucesso!', 3000);
            },
            onError: (error: any) => {
                if (error.message) return errorNotification(error.message);
                errorNotification('Erro ao atualizar o produto!');
            },
        },
    );

    const { mutate: deleteProduct } = useMutation(
        ({ productId }: any) =>
            refrigeratorPresenter.deleteRefrigeratorProduct(
                productId,
                refrigeratorId as string,
            ),
        {
            onSuccess: () => {
                allProductsQuery.refetch();
                successNotification('Produto removido com sucesso!');
            },
            onError: (error: any) => {
                if (error.message) return errorNotification(error.message);
                errorNotification('Erro ao remover o produto!');
            },
        },
    );

    useEffect(() => {
        if (tabValue === 0 && isUpdate && refrigeratorData) {
            store.dispatch(
                selectNavigationAction(
                    new NavigationAppState(
                        ContextType.refrigerator_edit,
                        ContextType.refrigerator_list,
                        TableIcons.REFRIGERATOR,
                        (refrigeratorData as IRefrigeratorEditInputs).location,
                    ),
                ),
            );
        } else if (tabValue === 1 && isUpdate && refrigeratorData) {
            store.dispatch(
                selectNavigationAction(
                    new NavigationAppState(
                        ContextType.refrigerator_edit_product,
                        ContextType.refrigerator_list,
                        TableIcons.REFRIGERATOR,
                        (refrigeratorData as IRefrigeratorEditInputs).location,
                    ),
                ),
            );
        }
    }, [tabValue]);

    return {
        reset,
        tabs,
        informationForm,
        isDisabled,
        isDirty,
        isLoading,
        licensedAutoComplete,
        isLicensedLoading,
        refrigeratorProductsQuery,
        allProductsQuery,
        addRefrigeratorProduct,
        updateProduct,
        deleteProduct,
        updateRefrigerator,
        createRefrigerator,
        refrigeratorData,
    };
}
