import { ContextType, TableIcons } from '@/domain/models';
import Default from '../default';
import { store } from '@/ui/store/config';
import selectNavigationAction from '@/ui/store/modules/app/navigation/actions';
import { NavigationAppState } from '@/ui/store/models';
import { useContext, useEffect } from 'react';
import { FilterContext } from '@/ui/context/context-filter';
import { Column } from '@/ui/components/layout';
import { useBannerManagementPresentation } from '@/presentation';
import { AuthenticationHelper, PermissionHelper } from '@/domain/helpers';
import {
    ApplicationTypeData,
    BannerDevices,
    BannerImage,
    ValidityPeriod,
} from './forms';
import { DevTool } from '@hookform/devtools';

export default function BannerManagement() {
    const { setActivePage } = useContext(FilterContext);

    const userRoles = AuthenticationHelper.hasToken()
        ? AuthenticationHelper.getToken().auth
        : '';

    const permissionHelper = new PermissionHelper(userRoles);

    const isTakeAndGo = permissionHelper.isTakeAndGo();

    const {
        control,
        handleRemoveImage,
        previewSource,
        handleSubmit,
        onSubmit,
        watch,
        resetField,
        setValue,
        isSubmitDisabled,
        correlationsTypeOptions,
    } = useBannerManagementPresentation();

    useEffect(() => {
        setActivePage(ContextType.banner_save);
        store.dispatch(
            selectNavigationAction(
                new NavigationAppState(
                    ContextType.banner_save,
                    ContextType.banner_list,
                    TableIcons.BANNER,
                ),
            ),
        );
    }, []);

    const formComponents = [
        ApplicationTypeData,
        ValidityPeriod,
        BannerDevices,
        BannerImage,
    ];

    return (
        <Default
            contextType={ContextType.banner_save}
            isSubmitDisabled={isSubmitDisabled}
        >
            <Column
                gap={2}
                as="form"
                id="banner-form"
                onSubmit={handleSubmit(onSubmit)}
            >
                {formComponents.map(Component => (
                    <Component
                        key={Component.name}
                        control={control}
                        setValue={setValue}
                        watch={watch}
                        resetField={resetField}
                        isTakeAndGo={isTakeAndGo}
                        previewSource={previewSource}
                        handleRemoveImage={handleRemoveImage}
                        correlationsTypeOptions={correlationsTypeOptions}
                    />
                ))}
            </Column>
            <DevTool control={control} placement="top-right" />
        </Default>
    );
}
