import styled from 'styled-components';

const IconWrapper = styled.div`
    padding: 17.67px 21px;
    border-radius: 18px;
    z-index: 3;
    background-color: rgba(122, 88, 0, 0.4);

    &.yellow-bg {
        background-color: ${props => props.theme.yellowIconBg};
        .MuiSvgIcon-root {
            color: ${props => props.theme.yellowIconBg};
        }
    }

    &.blue-bg {
        background: ${props => props.theme.blueIconBg};
        .MuiSvgIcon-root {
            color: ${props => props.theme.blueIconBg};
        }
    }
`;

export default IconWrapper;
