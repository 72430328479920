/* eslint react/prop-types: 0 */
import { useCallback, useState } from 'react';
import {
    PieChart,
    Pie,
    Cell,
    ResponsiveContainer,
    Tooltip,
    Sector,
} from 'recharts';
import PieTooltip from './pie-tooltip';
import { useBreakpoints } from '@/ui/helpers';
import { NumberHelper } from '@/domain/helpers';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { AverageTicketByPublicMap } from '@/ui/styles';

const data = [
    { name: 'Group A', value: 400 },
    { name: 'Group B', value: 300 },
    { name: 'Group C', value: 300 },
    { name: 'Group D', value: 200 },
];

function RenderActiveShape(props) {
    const RADIAN = Math.PI / 180;
    const {
        cx,
        cy,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        midAngle,
        isXs,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius - 138) * cos;
    const sy = cy + (outerRadius - 138) * sin;
    const sxMob = cx + (outerRadius - 85) * cos;
    const syMob = cy + (outerRadius - 85) * sin;

    return (
        <Sector
            cx={isXs ? sxMob : sx}
            cy={isXs ? syMob : sy}
            innerRadius={innerRadius}
            outerRadius={outerRadius + 2}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={props.fill}
        />
    );
}

export default function RePieChart({
    pieChartProps,
    isEvaluation = false,
    isCrm = false,
}) {
    const [activeIndex, setActiveIndex] = useState(null);
    const onMouseOver = useCallback((_, index) => {
        setActiveIndex(index);
    }, []);
    const onMouseLeave = useCallback(() => {
        setActiveIndex(null);
    }, []);

    const { isXs } = useBreakpoints();

    const containMonetary = itemName => {
        if (isEvaluation) return true;

        return (
            itemName == 'Não encontrou algum produto?' ||
            itemName == 'Temperatura' ||
            itemName == 'Tempo de abertura' ||
            itemName == 'Organização' ||
            itemName == 'Preço'
        );
    };

    const currentTheme = useTheme();

    const isDarkTheme = useSelector(store => {
        return store.toggleTheme.darkTheme;
    });

    const showLabel = percentage =>
        isCrm ? percentage >= 0.1 : percentage >= 5;

    return (
        <ResponsiveContainer style={{ zIndex: 55 }} height={450} width="100%">
            <PieChart key={Math.random()}>
                <Pie
                    key={Math.random()}
                    activeIndex={activeIndex}
                    data={pieChartProps.data}
                    cx="50%"
                    cy="50%"
                    innerRadius={isXs ? 35 : 80}
                    outerRadius={isXs ? 70 : 140}
                    paddingAngle={0}
                    fill="#7A5800"
                    dataKey="value"
                    stroke={null}
                    labelLine={false}
                    activeShape={<RenderActiveShape isXs={isXs} />}
                    onMouseOver={onMouseOver}
                    onMouseLeave={onMouseLeave}
                    isAnimationActive={false}
                    label={({
                        cx,
                        cy,
                        midAngle,
                        innerRadius,
                        outerRadius,
                        index,
                    }) => {
                        const RADIAN = Math.PI / 180;
                        const radius =
                            25 + innerRadius + (outerRadius - innerRadius);
                        const x = cx + radius * Math.cos(-midAngle * RADIAN);
                        const y = cy + radius * Math.sin(-midAngle * RADIAN);
                        const yMoneyValue =
                            cy + radius * Math.sin(-midAngle * RADIAN) + 15;
                        const yValue =
                            cy + radius * Math.sin(-midAngle * RADIAN) + 30;
                        return (
                            <>
                                {showLabel(
                                    pieChartProps.data[index].percentage,
                                ) && (
                                    <>
                                        <text
                                            x={x}
                                            y={y}
                                            fill={
                                                AverageTicketByPublicMap.get(
                                                    pieChartProps.data[index]
                                                        .id,
                                                ) != undefined
                                                    ? AverageTicketByPublicMap.get(
                                                          pieChartProps.data[
                                                              index
                                                          ].id,
                                                      )
                                                    : currentTheme.exportBox
                                                          .fontColor
                                            }
                                            textAnchor={
                                                x > cx ? 'start' : 'end'
                                            }
                                            dominantBaseline="central"
                                            fontSize={12}
                                        >
                                            {pieChartProps.data[index].id}
                                        </text>
                                        {!containMonetary(
                                            pieChartProps.data[index].id,
                                        ) && (
                                            <text
                                                x={x}
                                                y={yMoneyValue}
                                                fill={
                                                    isDarkTheme
                                                        ? currentTheme.secondary
                                                        : currentTheme
                                                              .notification.dark
                                                }
                                                textAnchor={
                                                    x > cx ? 'start' : 'end'
                                                }
                                                dominantBaseline="central"
                                                fontSize={12}
                                            >
                                                {NumberHelper.makeMoney(
                                                    pieChartProps.data[index]
                                                        .value,
                                                )}
                                            </text>
                                        )}
                                        <text
                                            x={x}
                                            y={
                                                !containMonetary(
                                                    pieChartProps.data[index]
                                                        .id,
                                                )
                                                    ? yValue
                                                    : yMoneyValue
                                            }
                                            fill={
                                                isDarkTheme
                                                    ? currentTheme.secondary
                                                    : currentTheme.notification
                                                          .dark
                                            }
                                            textAnchor={
                                                x > cx ? 'start' : 'end'
                                            }
                                            dominantBaseline="central"
                                            fontSize={12}
                                        >
                                            {
                                                pieChartProps.data[index]
                                                    .percentage
                                            }
                                            %
                                        </text>{' '}
                                    </>
                                )}
                            </>
                        );
                    }}
                >
                    {data.map((_, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={
                                pieChartProps.color &&
                                pieChartProps.color[index]
                            }
                        />
                    ))}
                </Pie>
                <Tooltip content={<PieTooltip isEvaluation={isEvaluation} />} />
            </PieChart>
        </ResponsiveContainer>
    );
}
