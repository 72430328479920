import { IWalletUpdateBalance } from '@/domain/models';
import { NotificationContext } from '@/main';
import { makeWalletPresenter } from '@/main/factories';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';

interface IWalletBalanceForm {
    value: number;
    description: string;
    isCredit: string;
}

export default function useWalletBallancePresenter(
    userId: string,
    walletBalance: number,
) {
    const queryClient = useQueryClient();

    const walletPresenter = makeWalletPresenter();
    const { successNotification, errorNotification } =
        useContext(NotificationContext);

    const walletForm = useForm<IWalletBalanceForm>({
        defaultValues: {
            value: 0,
            isCredit: 'add',
        },
    });

    const { mutateAsync: updateWalletBalance, isLoading: isUpdatingWallet } =
        useMutation(
            (data: IWalletUpdateBalance) =>
                walletPresenter.updateWalletBalance(data),
            {
                onSuccess: res => {
                    if (res.success) {
                        successNotification('Saldo atualizado com sucesso');
                        queryClient.invalidateQueries(['user', userId]);
                        walletForm.reset();
                        return;
                    }

                    errorNotification(
                        res.error && res.error.message
                            ? res.error.message
                            : 'Erro ao atualizar saldo',
                    );
                },
                onError: () => {
                    errorNotification('Erro ao atualizar saldo');
                },
            },
        );

    const onSubmit = () => {
        const data = walletForm.getValues();

        const walletBalance: IWalletUpdateBalance = {
            ...data,
            userId: Number(userId),
            isCredit: data.isCredit === 'add',
        };

        updateWalletBalance(walletBalance);
    };

    const radios = [
        {
            value: 'add',
            label: 'Adicionar saldo',
        },
        {
            value: 'remove',
            label: 'Remover saldo',
        },
    ];

    const [isCredit, value] = walletForm.watch(['isCredit', 'value']);

    const calculateNewBalance = () => {
        const newBalance =
            isCredit === 'add' ? walletBalance + value : walletBalance - value;
        return newBalance.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });
    };

    const isFutureValueNegative = (value: number) => {
        if (isCredit === 'remove' && value > walletBalance) {
            walletForm.setError('value', {
                type: 'manual',
                message: 'Saldo insuficiente para a operação',
            });
            return 'Saldo insuficiente para a operação';
        }

        walletForm.clearErrors('value');
        return true;
    };

    return {
        walletForm,
        onSubmit,
        radios,
        calculateNewBalance,
        isFutureValueNegative,
        isUpdatingWallet,
    };
}
