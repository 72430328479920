import {
    Control,
    Controller,
    UseFormSetValue,
    UseFormWatch,
} from 'react-hook-form';
import { makePromotionListPresenter } from '@/main/factories/presenters/pages/promotion';
import { AutoCompleteField, InputTextField } from '@/ui/components';
import { IPromotionSaveInputs } from '@/domain/models';
import { Grid } from '@/ui/components/layout';
import MonetaryTextField from '@/ui/components/input/monetary-text-field';

export default function PromotionData({
    control,
    isTakeAndGo,
    watch,
    setValue,
}: {
    control: Control<IPromotionSaveInputs, any>;
    isTakeAndGo: boolean;
    watch: UseFormWatch<IPromotionSaveInputs>;
    setValue: UseFormSetValue<IPromotionSaveInputs>;
}) {
    const promotionPresenter = makePromotionListPresenter();
    const [promotionType, discount, minimum] = watch([
        'type',
        'discount',
        'minimum',
    ]);

    const promotionTypeOptions = promotionPresenter.promotionTypeOptions;
    const useCaseOptions = promotionPresenter.useCaseOptions;

    const showTakeParticipationPercent = promotionType
        ? isTakeAndGo && promotionType
        : false;

    const interceptTypeChange = (data: any) => {
        setValue('type', data);
        Array.from(['discount', 'minimum']).forEach(field => {
            setValue(field as any, '', {
                shouldDirty: false,
                shouldTouch: false,
                shouldValidate: true,
            });
        });
    };

    return (
        <>
            <h5>Dados da promoção</h5>
            <Grid columns={4} gap={6} responsiveType="input">
                <InputTextField
                    control={control}
                    name="name"
                    label="Nome da promoção"
                    rules={{
                        required: 'Nome da promoção é obrigatório',
                    }}
                />
                <AutoCompleteField
                    control={control}
                    options={promotionTypeOptions}
                    name="type"
                    label="Tipo da promoção"
                    rules={{
                        required: 'Tipo da promoção é obrigatório',
                    }}
                    interceptOnChange={interceptTypeChange}
                />
                {promotionType && promotionType.id === 'ABOVE_AMOUNT' ? (
                    <>
                        <InputTextField
                            control={control}
                            name="minimum"
                            label="Leve"
                            type="number"
                            rules={{
                                required: 'Quantidade mínima é obrigatória',
                                min: {
                                    value: 1,
                                    message:
                                        'Valor mínimo deve ser maior ou igual a 1',
                                },
                                validate: {
                                    isMoreThanDiscount: value => {
                                        if (Number(value) <= Number(discount)) {
                                            return 'Quantidade mínima deve ser maior que a quantidade a pagar';
                                        }
                                        return true;
                                    },
                                    shouldBeANumber: value => {
                                        if (isNaN(Number(value))) {
                                            return 'Valor obrigatório!';
                                        }
                                        return true;
                                    },
                                },
                                valueAsNumber: true,
                                deps: ['discount'],
                            }}
                        />
                        <InputTextField
                            control={control}
                            name="discount"
                            label="Pague"
                            type="number"
                            rules={{
                                required: 'Quantidade a pagar é obrigatória',
                                min: {
                                    value: 1,
                                    message:
                                        'Valor mínimo deve ser maior que 1',
                                },
                                valueAsNumber: true,
                                validate: {
                                    isLessThanMinimum: value => {
                                        if (Number(value) >= Number(minimum)) {
                                            return 'Quantidade a pagar deve ser menor que a quantidade mínima';
                                        }
                                        return true;
                                    },
                                    shouldBeANumber: value => {
                                        if (isNaN(Number(value))) {
                                            return 'Valor obrigatório!';
                                        }
                                        return true;
                                    },
                                },
                                deps: ['minimum'],
                            }}
                        />
                    </>
                ) : null}
                <AutoCompleteField
                    control={control}
                    options={useCaseOptions}
                    name="useCase"
                    label="Tipo de uso"
                    rules={{
                        required: 'Tipo de uso é obrigatório',
                    }}
                />
                {promotionType &&
                Array.from(['CASHBACK', 'PERCENTAGE']).includes(
                    promotionType.id,
                ) ? (
                    <>
                        <InputTextField
                            control={control}
                            name="discount"
                            label={promotionType.label}
                            type="number"
                            rules={{
                                required: 'Porcentagem é obrigatória',
                                min: {
                                    value: 1,
                                    message:
                                        'Valor mínimo deve ser maior que 1',
                                },
                                valueAsNumber: true,
                                validate: {
                                    isLessThanMinimum: () => true,
                                    shouldBeANumber: value => {
                                        if (isNaN(Number(value))) {
                                            return 'Valor obrigatório!';
                                        }
                                        return true;
                                    },
                                },
                            }}
                        />
                        <Controller
                            control={control}
                            name="minimum"
                            rules={{
                                min: {
                                    value: 0,
                                    message:
                                        'Valor mínimo deve ser maior ou igual a 0',
                                },
                                validate: {
                                    shouldBeANumber: value => {
                                        if (!value) {
                                            return true;
                                        }

                                        if (isNaN(Number(value))) {
                                            return 'Valor obrigatório!';
                                        }
                                        return true;
                                    },
                                },
                            }}
                            render={({
                                field: { onChange, value, ...field },
                                fieldState: { error },
                            }) => (
                                <MonetaryTextField
                                    label="Valor mínimo"
                                    {...field}
                                    setValue={onChange}
                                    defaultValue={value}
                                    error={error}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="maximumDiscount"
                            rules={{
                                min: {
                                    value: 0,
                                    message:
                                        'Valor mínimo deve ser maior ou igual a 0',
                                },
                                validate: {
                                    shouldBeANumber: value => {
                                        if (!value) {
                                            return true;
                                        }

                                        if (isNaN(Number(value))) {
                                            return 'Valor obrigatório!';
                                        }
                                        return true;
                                    },
                                },
                            }}
                            render={({
                                field: { onChange, value, ...field },
                                fieldState: { error },
                            }) => (
                                <MonetaryTextField
                                    label="Valor limite"
                                    {...field}
                                    setValue={onChange}
                                    defaultValue={value}
                                    error={error}
                                />
                            )}
                        />
                    </>
                ) : null}
                {promotionType && promotionType.id === 'CASHBACK' && (
                    <InputTextField
                        control={control}
                        name="cashbackExpirationDays"
                        label="Vencimento (dias)"
                        type="number"
                        rules={{
                            required: false,
                            valueAsNumber: true,
                            validate: {
                                shouldBeANumber: value => {
                                    if (!value) {
                                        return true;
                                    }
                                    if (
                                        isNaN(Number(value)) ||
                                        /^\s+$/.test(value)
                                    ) {
                                        return 'Valor deve ser um número!';
                                    }
                                    return true;
                                },
                                shouldBeInteger: value => {
                                    if (!value) {
                                        return true;
                                    }
                                    if (!Number.isInteger(Number(value))) {
                                        return 'Valor deve ser um número inteiro!';
                                    }
                                    return true;
                                },
                                shouldBePositive: value => {
                                    if (!value) {
                                        return true;
                                    }
                                    if (Number(value) <= 0) {
                                        return 'Valor deve ser maio que 0!';
                                    }
                                    return true;
                                },
                            },
                        }}
                    />
                )}
                {promotionType && promotionType.id === 'VALUE' && (
                    <>
                        <Controller
                            control={control}
                            name="discount"
                            rules={{
                                required: 'Valor de desconto é obrigatório',
                                validate: {
                                    shouldBeANumber: value => {
                                        if (isNaN(Number(value))) {
                                            return 'Valor obrigatório!';
                                        }
                                        return true;
                                    },
                                    moreThanZero: value => {
                                        if (Number(value) <= 0) {
                                            return 'Valor deve ser maior que 0!';
                                        }
                                        return true;
                                    },
                                },
                            }}
                            render={({
                                field: { onChange, value, ...field },
                                fieldState: { error },
                            }) => (
                                <MonetaryTextField
                                    label="R$ de Desconto"
                                    {...field}
                                    setValue={onChange}
                                    defaultValue={value}
                                    error={error}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="minimum"
                            rules={{
                                min: {
                                    value: 0,
                                    message:
                                        'Valor mínimo deve ser maior ou igual a 0',
                                },
                                validate: {
                                    shouldBeANumber: value => {
                                        if (!value) {
                                            return true;
                                        }

                                        if (isNaN(Number(value))) {
                                            return 'Valor obrigatório!';
                                        }
                                        return true;
                                    },
                                },
                            }}
                            render={({
                                field: { onChange, value, ...field },
                                fieldState: { error },
                            }) => (
                                <MonetaryTextField
                                    label="Valor mínimo"
                                    {...field}
                                    setValue={onChange}
                                    defaultValue={value}
                                    error={error}
                                />
                            )}
                        />
                    </>
                )}
                {showTakeParticipationPercent && (
                    <InputTextField
                        control={control}
                        name="takeParticipationPercent"
                        label="Subsídio Take (%)"
                        type="number"
                        rules={{
                            valueAsNumber: true,
                        }}
                    />
                )}
            </Grid>
        </>
    );
}
