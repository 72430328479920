import { CardContainer, IconButton, Modal } from '@/ui/components';
import { CardHeader } from '../styles';
import { Row } from '@/ui/components/layout';
import { useState } from 'react';
import { InfoOutlined } from '@mui/icons-material';
import { RefrigeratorStatusIndicators } from '@/domain/models';

interface IIndicatorsCardsProps {
    loadingClass: string;
    headerIcon: JSX.Element;
    headerLabel: string;
    content: JSX.Element;
    hasModal?: boolean;
}

export default function IndicatorCard({
    loadingClass,
    headerIcon,
    headerLabel,
    content,
    hasModal = false,
}: IIndicatorsCardsProps) {
    const [isOpenModal, setIsOpenModal] = useState(false);

    return (
        <>
            <CardContainer
                gap={6}
                className={loadingClass}
                padding={{ bottom: 6, top: 8, right: 8, left: 8 }}
            >
                <CardHeader
                    align="center"
                    justify="space-between"
                    className="blue"
                    width="100%"
                >
                    <Row align="center" gap={2}>
                        {headerIcon}
                        {headerLabel}
                    </Row>
                    {hasModal && (
                        <IconButton
                            onClick={() => setIsOpenModal(true)}
                            className="info-yellow"
                        >
                            <InfoOutlined />
                        </IconButton>
                    )}
                </CardHeader>
                {content}
            </CardContainer>
            {hasModal && (
                <Modal
                    isOpen={isOpenModal}
                    onClose={() => setIsOpenModal(false)}
                    {...(RefrigeratorStatusIndicators.get(headerLabel) as any)}
                />
            )}
        </>
    );
}
