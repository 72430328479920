import UserClassificationType from './user-classification.type';

const ContextClassMap = new Map<number, string>([
    [0, UserClassificationType.inactive_users],
    [1, UserClassificationType.sleeping_users],
    [2, UserClassificationType.active_users],
    [3, UserClassificationType.new_users],
]);

export default ContextClassMap;
