import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import { ContextType, IOrderEdit, TableIcons } from '@/domain/models';
import makeOrderEditPresenter from '@/main/factories/presenters/pages/order/order-edit-presenter-factory';
import selectNavigationAction from '@/ui/store/modules/app/navigation/actions';
import { store } from '@/ui/store/config';
import { CircularProgress } from '@/ui/components';
import OrderEditInfo from './components/order-edit-info';
import { OrderEvaluation, OrderProductsEditTable } from './components';
import Default from '../../default';
import OrderImageModal from './components/order-image-modal';
import OrderProductPayment from './components/order-product-payment';
import OrderPromotionInfo from './components/order-promotion-info';
import OrderDeclineContestationModal from './components/order-contestation-decline-modal';
import IContestationEvaluation from '@/domain/models/pages/orders/edit/contestation-calculate/contestation-evaluation';
import { NotificationContext } from '@/main';
import { NavigationAppState } from '@/ui/store/models';

export default function OrderEdit(): JSX.Element {
    const orderEditPresenter = makeOrderEditPresenter();
    const [initialOrderData, setInitialOrderData] = useState<IOrderEdit>(
        orderEditPresenter.initialOrderDataState,
    );
    const [orderData, setOrderData] = useState<IOrderEdit>(
        orderEditPresenter.initialOrderDataState,
    );
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingCalculate, setIsLoadingCalculate] =
        useState<boolean>(false);

    const initialMount = useRef(true);
    const { orderId }: any = useParams();

    const { successNotification, errorNotification } =
        useContext(NotificationContext);

    const navigate = useNavigate();

    const isInitialMount = () => {
        if (initialMount.current) {
            initialMount.current = false;
            return true;
        }

        return false;
    };

    const handleApplyOrderEdit = (evaluationData: IContestationEvaluation) => {
        setIsLoading(true);
        orderEditPresenter
            .updateOrderPaymentContestationAsync(
                orderId,
                orderData,
                evaluationData,
            )
            .then((res: any) => {
                if (res && res.status) {
                    errorNotification(res.message);
                    navigate('/order');
                } else {
                    navigate('/order');
                    successNotification(res);
                }
            })
            .finally(() => setIsLoading(false));
    };

    const declineOrderContestation = (reason: string) => {
        orderEditPresenter
            .declineOrderPaymentContestationAsync(reason, orderId)
            .then((res: any) => {
                if (res && res.status) {
                    errorNotification(res.message);
                    navigate('/order');
                } else {
                    navigate('/order');
                    successNotification(res);
                }
            });
    };

    useEffect(() => {
        if (isInitialMount()) {
            setIsLoading(true);

            orderEditPresenter
                .getOrderPaymentContestationAsync(orderId)
                .then(res => {
                    if ((res as any).status && (res as any).status !== 200) {
                        errorNotification(
                            (res as any).message ||
                                'Erro inesperado ao buscar o pedido',
                        );
                        navigate('/order');
                    }

                    setOrderData(res);
                    setInitialOrderData(res);

                    store.dispatch(
                        selectNavigationAction(
                            new NavigationAppState(
                                ContextType.order_edit,
                                ContextType.order,
                                TableIcons.ORDER,
                                orderId,
                            ),
                        ),
                    );
                })
                .finally(() => setIsLoading(false));
        }
        return;
    }, []);

    return (
        <Default contextType={ContextType.order_edit}>
            <Tooltip id="edit" />
            <Tooltip id="exclude" />
            {isLoading ? (
                <CircularProgress variant="indeterminate" />
            ) : (
                <>
                    <OrderDeclineContestationModal />
                    <OrderImageModal />
                    <OrderEditInfo
                        orderDate={orderData!.orderDate}
                        openDoorTime={orderData!.openDoorTime}
                        paymentDate={orderData!.paymentSuccessDate}
                        contestationReason={orderData!.contestation.reason}
                        orderImages={orderData!.medias}
                        orderContestationImages={orderData!.contestation.medias}
                        orderId={orderId}
                        licensed={orderData!.licensed}
                        cooler={orderData!.cooler}
                    />
                    <OrderProductsEditTable
                        contestationOrderProducts={orderData.products}
                        totalPayment={orderData!.paymentDetails.total}
                        setOrderData={setOrderData}
                        orderData={orderData}
                        initialOrderData={initialOrderData}
                        setIsLoadingCalculate={setIsLoadingCalculate}
                        isLoadingCalculate={isLoadingCalculate}
                        orderId={orderId}
                    />
                    <OrderPromotionInfo
                        conditions={
                            orderData!.promotion
                                ? orderData!.promotion.conditions
                                : null
                        }
                        name={
                            orderData!.promotion
                                ? orderData!.promotion.name
                                : null
                        }
                        orderInitialData={initialOrderData!}
                        isLoadingCalculate={isLoadingCalculate}
                    />
                    <OrderProductPayment
                        discount={orderData!.paymentDetails.discount}
                        creditCard={
                            orderData!.paymentDetails.valuePaidCreditCard
                        }
                        wallet={orderData!.paymentDetails.valuePaidWallet}
                        pix={orderData!.paymentDetails.valuePaidPix}
                        total={orderData!.paymentDetails.total}
                        paymentToReceive={
                            orderData!.paymentDetails.valueToReceive
                        }
                        orderDetails={
                            orderData!.paymentDetails.detailedValuesToPay
                        }
                        valueToPay={orderData!.paymentDetails.valueToPay}
                        card={orderData!.card}
                        isLoadingCalculate={isLoadingCalculate}
                        initialOrderData={initialOrderData}
                    />
                    <OrderEvaluation
                        handleApplyOrderEdit={handleApplyOrderEdit}
                        initialOrderData={initialOrderData}
                        orderData={orderData}
                        declineOrderContestation={declineOrderContestation}
                    />
                </>
            )}
        </Default>
    );
}
