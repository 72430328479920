import {
    HttpResponse,
    IRefrigeratorStatusList,
    IRefrigeratorStatusListResponse,
    ITableRows,
} from '@/domain/models';
import { ColumnProps } from '@/presentation/presenters/pages/refrigerator/refrigerator-status-presenter';
import {
    CoolerIcon,
    Modal,
    ProgressBar,
    TableMui,
    TableRowMui,
    WebcamIcon,
} from '@/ui/components';
import { StoreState } from '@/ui/store/models';
import { TableHead, TableRow, TableBody, CssBaseline } from '@mui/material';
import { useSelector } from 'react-redux';
import { ContainerRoot } from '@/ui/components/table/server-side/styles';
import { Capitalized, CellLoading, Status } from './styles';
import { DateHelper } from '@/domain/helpers';
import { StatusTableBody, StatusTableHead } from './components';
import { RefObject, useState } from 'react';
import { CameraProblemModal } from '../../components';
import { useTheme } from 'styled-components';

interface IStatusTableProps {
    data: HttpResponse<IRefrigeratorStatusListResponse>[];
    isLoading: boolean;
    isFetching: boolean;
    reference?: RefObject<HTMLTableRowElement> | null;
    isDarkTheme: boolean;
}

export default function StatusTable({
    data,
    isLoading,
    isFetching,
    reference,
    isDarkTheme,
}: IStatusTableProps) {
    const currentTheme = useTheme();

    const [isModalOpen, setIsModalOpen] = useState({
        open: false,
        refrigerator: {} as IRefrigeratorStatusList,
    });

    const { activeColumns: refrigeratorStatusColumns } = useSelector(
        (state: StoreState) => state.refrigeratorStatusColumns,
    );

    const renderColumns = () => {
        if (data.length === 0) {
            return;
        }

        const initialColumns: ColumnProps<IRefrigeratorStatusList>[] = [
            {
                title: 'Cooler',
                dataIndex: 'locality',
                key: 'locality',
                render: (value: string) => <Capitalized>{value}</Capitalized>,
                width: '20%',
            },
            {
                title: 'Código',
                dataIndex: 'serial',
                key: 'serial',
                width: '15%',
            },
            {
                title: 'Status',
                dataIndex: 'active',
                key: 'active',
                render: (value: string) => (
                    <Status className={value === 'true' ? 'online' : 'offline'}>
                        {value === 'true' ? 'Online' : 'Offline'}
                    </Status>
                ),
                width: '10%',
            },
            {
                title: 'Uptime',
                dataIndex: 'upTimePercentage',
                key: 'upTimePercentage',
                render: (upTimePercentage: string) => (
                    <ProgressBar
                        progress={Math.round(
                            Number(upTimePercentage.replace(',', '.')),
                        )}
                        indicatorColor={currentTheme.tableText}
                        trackColor={currentTheme.lines}
                        labelSize={14}
                    />
                ),

                width: '10%',
            },
            {
                title: 'Câmera',
                dataIndex: 'cameraProblem',
                key: 'cameraProblem',
                render: (value: string, refrigerator) => {
                    if (value === 'true' || value === 'false') {
                        const handleCameraClick = () => {
                            if (value === 'true') {
                                setIsModalOpen({
                                    open: true,
                                    refrigerator,
                                });
                            }
                        };

                        return (
                            <Status
                                className={
                                    value === 'true'
                                        ? 'offline camera'
                                        : 'online'
                                }
                                onClick={handleCameraClick}
                            >
                                <WebcamIcon />
                            </Status>
                        );
                    }
                    return <span>-</span>;
                },
                width: '5%',
            },
            {
                title: 'Porta',
                dataIndex: 'lockProblem',
                key: 'lockProblem',
                render: (value: string) => {
                    if (value === 'true' || value === 'false') {
                        return (
                            <Status
                                className={
                                    value === 'true' ? 'offline' : 'online'
                                }
                            >
                                <CoolerIcon />
                            </Status>
                        );
                    }
                    return <span>-</span>;
                },
                width: '5%',
            },
            {
                title: 'Temperatura',
                dataIndex: 'temperature',
                key: 'temperature',
                render: (value: string) => (
                    <span>
                        {value === '-' ? 'Não disponível' : value.concat('°C')}
                    </span>
                ),
                width: '15%',
            },
            {
                title: 'Última vez disponível',
                dataIndex: 'date',
                key: 'date',
                render: (value: string) => (
                    <span>
                        {value === '-'
                            ? '-'
                            : DateHelper.formatDateHours(value)}
                    </span>
                ),
                width: '15%',
            },
            {
                title: 'Versão',
                dataIndex: 'version',
                key: 'version',
                render: (value: string) =>
                    value === '-' ? (
                        <>{value}</>
                    ) : (
                        <>{value.replaceAll('-', '.')}</>
                    ),
                width: '5%',
            },
        ];

        const columns: ColumnProps<IRefrigeratorStatusList>[] =
            initialColumns.filter(
                column => (refrigeratorStatusColumns as any)[column.key],
            ) || [];

        const rows = data.map(page => {
            const data = page.data as IRefrigeratorStatusListResponse;

            return data.coolerStatusList.map((refrigerator): ITableRows[] => {
                return columns
                    .map(
                        (column): ITableRows => ({
                            key: column.key,
                            content: !column.render
                                ? refrigerator[column.dataIndex]
                                : column.render(
                                      refrigerator[column.dataIndex],
                                      refrigerator,
                                  ),
                        }),
                    )
                    .filter(
                        cell => (refrigeratorStatusColumns as any)[cell.key],
                    );
            });
        });

        const loadingClass =
            isFetching && !isDarkTheme
                ? 'default-loading'
                : isFetching && isDarkTheme
                ? 'dark-default-loading'
                : '';

        return (
            data &&
            columns.length > 0 &&
            data.length > 0 && (
                <>
                    <TableHead
                        sx={{
                            zIndex: 3,
                            position: 'sticky',
                            top: '0px',
                        }}
                        className="material-table-head"
                    >
                        <TableRow role="row">
                            <StatusTableHead columns={columns} />
                        </TableRow>
                    </TableHead>
                    <TableBody className="material-table-body">
                        {rows.map((row, index) => {
                            const isLastRow = index === rows.length - 1;

                            return (
                                <StatusTableBody
                                    key={'status-table-body-row' + index}
                                    rows={row}
                                    reference={reference}
                                    isLastRow={isLastRow}
                                />
                            );
                        })}
                        {isFetching && (
                            <TableRowMui key="row-is-loading">
                                <CellLoading
                                    colSpan={columns.length}
                                    className={loadingClass}
                                />
                            </TableRowMui>
                        )}
                    </TableBody>
                </>
            )
        );
    };

    return (
        <ContainerRoot className="d-block">
            <CssBaseline />
            <TableMui stickyHeader className={`is-loading=${isLoading}`}>
                {renderColumns()}
            </TableMui>
            <Modal
                isOpen={isModalOpen.open}
                onClose={() =>
                    setIsModalOpen({
                        open: false,
                        refrigerator: {} as IRefrigeratorStatusList,
                    })
                }
                title="Problema da câmera"
                content={
                    <CameraProblemModal
                        refrigerator={isModalOpen.refrigerator}
                    />
                }
            />
        </ContainerRoot>
    );
}
