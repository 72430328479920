import { TextField } from '@mui/material';
import styled from 'styled-components';
import { Column, Row } from '../../layout';

export const FilterSidebarHeader = styled(Row)`
    padding: 27.5px 28px;
    position: relative;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    justify-content: space-between;

    &.financial {
        align-items: center;
        padding: 20px;
    }
    &.order {
        padding: 2px 25px;
    }
`;

export const FilterContent = styled.div`
    padding: 32px 28px;
`;

export const OrderDetailContent = styled.div`
    padding: 5px 19px;
    background-color: ${props => props.theme.bodyBg};
`;

export const CustomCheckbox = styled.div`
    .container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background: #7a5800;
        height: 80px;
        border-radius: 20px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
    }

    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 20%;
        right: 20px;
        height: 25px;
        width: 25px;
    }

    .container:hover input ~ .checkmark {
        color: ${props => props.theme.secondary};
        width: 20px;
        height: 20px;
        z-index: 999;
    }

    .container input:checked ~ .checkmark {
        color: ${props => props.theme.secondary};
        width: 20px;
        height: 20px;
        z-index: 999;
    }

    .checkmark:after {
        position: absolute;
        display: none;
    }

    .container input:checked ~ .checkmark:after {
        display: block;
        content: '';
    }

    .container .checkmark:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .text {
        position: absolute;
        top: 8px;
    }
`;

export const CloseButton = styled.div`
    background: none;
    border: none;
    width: 19px;
    height: 19px;
    position: relative;
    cursor: pointer;

    & .order-detail-btn {
        position: absolute;
        right: -15px;
        top: 20px;
    }
`;

export const StyledTextField = styled(TextField)`
    border: none;

    fieldset {
        border: 'none';
        border-radius: '5px 5px 0 0';
    }

    &&.MuiTextField-root {
        border: 'none';
        border-radius: '5px 5px 0 0';

        fieldset {
            border: 'none';
            border-radius: '5px 5px 0 0';
        }
    }
`;

export const FilterContainer = styled(Column)`
    padding: 28px 16px;
    background-color: ${props => props.theme.bodyBg};
`;

export const DateContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
`;

export const DateTitle = styled.p`
    font-size: 14px;
    color: ${props => props.theme.quaternary};
    margin-bottom: 4px;
`;
