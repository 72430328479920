import { Grid } from '@/ui/components/layout';
import IInformationInputProps from './information-inputs-props';
import { AutoCompleteField, InputTextField } from '@/ui/components';
import { makeRefrigeratorListPresenter } from '@/main/factories';

export default function RefrigeratorSimData({
    control,
}: IInformationInputProps) {
    const refrigeratorPresenter = makeRefrigeratorListPresenter();

    return (
        <>
            <h5>Dados do chip</h5>
            <Grid
                columns={4}
                gap={{
                    row: 6,
                    column: 4,
                }}
            >
                <InputTextField control={control} name="simId" label="SiD" />
                <AutoCompleteField
                    control={control}
                    options={refrigeratorPresenter.simOperatorOptions}
                    name="simOperator"
                    label="Operadora"
                />
                <InputTextField
                    control={control}
                    name="simNumber"
                    label="Número do chip"
                />
            </Grid>
        </>
    );
}
