import { IFilterOption } from '@/ui/context/context-filter/type';

export interface IPromotionFilterParams {
    promotionName: IFilterOption | null;
    promotionType: IFilterOption | null;
    promotionCategory: IFilterOption | null;
    promotionProduct: IFilterOption | null;
    status: IFilterOption | null;
    promotionStartDate: IFilterOption | null;
    promotionEndDate: IFilterOption | null;
    licensedId?: IFilterOption | null;
    coolerId?: IFilterOption | null;
    id?: IFilterOption | null;
    useType?: IFilterOption | null;
    page?: IFilterOption | null;
    limit?: IFilterOption | null;
    sort?: IFilterOption | null;
    column?: IFilterOption | null;
    promotionCategoryLabel?: any;
    promotionProductLabel?: any;
    promotionTypeLabel?: any;
}

export default class PromotionFilterParams implements IPromotionFilterParams {
    promotionName: IFilterOption;
    promotionType: IFilterOption;
    promotionCategory: IFilterOption;
    promotionProduct: IFilterOption;
    status: IFilterOption;
    promotionStartDate: IFilterOption;
    promotionEndDate: IFilterOption;
    licensedId?: IFilterOption | null;
    coolerId?: IFilterOption | null;
    id?: IFilterOption | null;
    useType?: IFilterOption | null;

    constructor(
        promotionName: string,
        promotionType: string,
        promotionCategory: string,
        promotionProduct: string,
        status: string,
        promotionStartDate: any,
        promotionEndDate: any,
        promotionStartDateLabel: any,
        promotionEndDateLabel: any,
        promotionCategoryLabel: any,
        promotionProductLabel: any,
        promotionTypeLabel: string,
        licensedId: IFilterOption | null | any,
        coolerId: IFilterOption | null | any,
        id: IFilterOption | null | any,
        useType: IFilterOption | null | any,
        licensedLabel: string,
        coolerLabel: string,
    ) {
        this.promotionName = {
            id: promotionName,
            label: promotionName,
            name: 'promotionName',
        };
        this.promotionType = {
            id: promotionType,
            label: promotionTypeLabel,
            name: 'promotionType',
        };
        this.promotionCategory = {
            id: promotionCategory,
            label: promotionCategoryLabel,
            name: 'promotionCategory',
        };
        this.promotionProduct = {
            id: promotionProduct,
            label: promotionProductLabel,
            name: 'promotionProduct',
        };
        this.status = {
            id: status,
            label: status,
            name: 'status',
        };
        this.promotionStartDate = {
            id: promotionStartDate,
            label: promotionStartDateLabel,
            name: 'promotionStartDate',
        };
        this.promotionEndDate = {
            id: promotionEndDate,
            label: promotionEndDateLabel,
            name: 'promotionEndDate',
        };
        this.licensedId = {
            id: licensedId,
            label: licensedLabel,
            name: 'licensedId',
        };
        this.coolerId = {
            id: coolerId,
            label: coolerLabel,
            name: 'coolerId',
        };
        this.id = {
            id: id,
            label: id,
            name: 'id',
        };
        this.useType = {
            id: useType,
            label: useType,
            name: 'useType',
        };
    }
}
