import styled from 'styled-components';

const RatingPieChartTitle = styled.div`
    position: absolute;
    top: 45%;
    right: 43%;
    color: '#FFFFFF';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    @media (max-width: 650px) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -53%);
        font-size: 12px;
        width: 50px;
    }
`;

export default RatingPieChartTitle;
