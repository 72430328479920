import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { usePagination, useSortBy, useTable } from 'react-table';
import { TableHelper } from '@/ui/helpers';
import {
    TablePaginationPropsDefault,
    TableRowProps,
    TableRowPropsDefault,
    TableServerSidePropsDefault,
} from '@/ui/interfaces/props/table';
import { TableBuilder } from '@/ui/components/table/common';
import {
    Pagination,
    TableBodyOperations,
    TableInfoResult,
} from '@/ui/components/table';
import ITableServerSideProps from '@/ui/interfaces/props/table/table-server-side-props';
import { ContainerPaginationHeader } from '@/ui/components/table/common';
import { TableContainer } from '@/ui/pages/promotion/styles';
import { StoreState } from '@/ui/store/models';
import { FilterContext } from '@/ui/context/context-filter';
import { ContainerRoot } from './styles';
import { SelectedTableRowsContext } from '@/ui/context/selected-table-rows';

export default function TableServerSide(
    props: ITableServerSideProps,
): JSX.Element {
    const tableServerSideProps = TableServerSidePropsDefault(props);
    const { identityPropertyName } =
        tableServerSideProps.rowProps as TableRowProps;

    const {
        getFiltersObject,
        updateFilters,
        getFilters,
        setShouldReRenderTable,
        shouldReRenderTable,
    } = useContext(FilterContext);

    const { handleSetInitialState, setSelectedRows } = useContext(
        SelectedTableRowsContext,
    );

    const pageFilters = getFiltersObject();
    const filters = getFilters();

    const { presenter, paginationProps } = tableServerSideProps;

    const currentColumns = useSelector(
        (store: StoreState) => store.orderColumns.activeColumns,
    );

    const getRowId = () =>
        useCallback((row: any): string => {
            return row[identityPropertyName as string];
        }, []);

    const page = window.location.pathname.split('/')[1].split('?')[0];

    const {
        data: tableData = [],
        isLoading: isTableDataLoading,
        isFetching: isTableDataFetching,
    } = useQuery(
        [page, pageFilters],
        () => {
            const searchModel = getFiltersObject();
            return presenter.search(searchModel);
        },
        {
            onSuccess: (data: any) => {
                setShouldReRenderTable(false);
                if (props.hasRowSelection) {
                    setSelectedRows([]);
                    handleSetInitialState(
                        data.data,
                        identityPropertyName as string,
                    );
                }
            },
            enabled: shouldReRenderTable,
        },
    );

    const isTableLoading = isTableDataLoading || isTableDataFetching;

    const memoizedData = useMemo(() => tableData, [tableData]);
    const memoizedColumns = useMemo(() => currentColumns, [currentColumns]);

    const tableBuilder = useTable(
        {
            initialState: {
                pageIndex: paginationProps?.pageIndex,
                pageSize: paginationProps?.rowsPerPage,
                sortBy: [
                    {
                        id: pageFilters?.column,
                        desc: pageFilters?.sort === 'DESC',
                    },
                ],
            },
            columns: TableHelper.makeColumn(
                presenter.makeColumns(),
                memoizedColumns,
            ),
            getRowId: getRowId(),
            data: memoizedData.data || [],
            autoResetSortBy: true,
            autoResetExpanded: false,
            autoResetPage: false,
            manualSortBy: true,
            disableResizing: false,
            stickyHeader: true,
        },
        useSortBy,
        usePagination,
    );

    const {
        gotoPage,
        setPageSize,
        state: { sortBy },
    } = tableBuilder;

    const handleOnChangePage = (_: any, newPage: number) => {
        gotoPage(newPage);
        updateFilters({
            ...filters,
            page: {
                id: newPage.toString(),
                name: 'page',
                label: newPage.toString(),
            },
        });
    };

    const handleOnChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const value = Number(`${event.target.value}`);

        setPageSize(value);
        updateFilters(
            {
                ...filters,
                limit: {
                    id: value,
                    name: 'page',
                    label: value,
                },
            },
            true,
        );
    };

    return (
        <ContainerRoot>
            <ContainerPaginationHeader>
                {!isTableLoading && (
                    <>
                        <TableInfoResult
                            labelDisplayedResult={presenter.makeLabelDisplayedResult(
                                tableData,
                            )}
                        />
                        <Pagination
                            page={+pageFilters.page}
                            rowsPerPage={pageFilters.limit as number}
                            rowsPerPageOptions={
                                paginationProps?.rowsPerPageOptions as number[]
                            }
                            count={!isTableLoading ? tableData?.page?.total : 0}
                            onPageChange={handleOnChangePage}
                            onRowsPerPageChange={handleOnChangeRowsPerPage}
                        />
                    </>
                )}
            </ContainerPaginationHeader>
            {tableData && (
                <TableContainer>
                    <TableBuilder
                        sortBy={sortBy}
                        className={`is-loading-${isTableLoading}`}
                        builder={tableBuilder}
                        rowProps={tableServerSideProps.rowProps}
                        enableCellSorting={true}
                        TableBodyCustom={
                            <TableBodyOperations
                                count={
                                    !isTableLoading ? tableData?.page?.total : 0
                                }
                                isLoading={isTableLoading}
                                rowsPerPage={pageFilters.limit as number}
                            />
                        }
                    />
                </TableContainer>
            )}
        </ContainerRoot>
    );
}

TableServerSide.defaultProps = {
    GlobalFilterComponent: undefined,
    rowProps: TableRowPropsDefault(),
    paginationProps: TablePaginationPropsDefault(),
};
