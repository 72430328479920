import { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { StoreState } from '@/ui/store/models';
import getFilterButtons from './filter-buttons';
import { FilterContext } from '@/ui/context/context-filter';
import { ContextGlobalSearchMap, ContextType } from '@/domain/models';
import {
    SearchBar,
    SelectedFilters,
    contextFilters,
} from '../../../components';
import { Column, Row } from '../../../components/layout';
import { useBreakpoints } from '@/ui/helpers';

interface ISubHeaderProps {
    isSubmitDisabled?: boolean | boolean[];
    hiddenButtons?: boolean;
}

export default function SubHeader({
    hiddenButtons,
    isSubmitDisabled,
}: ISubHeaderProps): JSX.Element {
    const { updateFilters, getFilters } = useContext(FilterContext);
    const filters = getFilters();

    const activePage = useSelector(
        (store: StoreState) => store.navigation.activePage,
    );

    const hasSearchBar = ContextGlobalSearchMap.has(activePage);
    const searchField = ContextGlobalSearchMap.get(activePage)!;

    const { control, watch } = useForm();
    const [searchInput] = watch(['search' + searchField]);

    const { isXs } = useBreakpoints();

    useEffect(() => {
        searchField &&
            updateFilters(
                {
                    ...filters,
                    [searchField]: {
                        name: searchField,
                        id: searchInput,
                        label: searchInput,
                    },
                },
                true,
            );
    }, [searchInput]);

    const onEnter = () => {
        updateFilters(
            {
                ...filters,
                [searchField]: {
                    name: searchField,
                    id: searchInput,
                    label: searchInput,
                },
            },
            true,
        );
    };

    const [showFilterColumn, setShowFilterColumn] = useState({
        right: false,
    });
    const [isHoveringButton, setIsHoveringButton] = useState(false);
    const [tableColumnsFilter, setTableColumnsFilter] = useState({
        right: false,
    });

    const isNotSaveOrEditPage =
        !activePage.includes('edit') &&
        !activePage.includes('save') &&
        !(activePage === ContextType.refrigerator_stock_by_product);

    const memoizedIsSubmitDisabled = useMemo(
        () => isSubmitDisabled,
        [isSubmitDisabled],
    );

    return (
        <>
            {isXs ? (
                <Column
                    justify="space-between"
                    gap={3}
                    align="center"
                    padding={{ left: 2, right: 2 }}
                    margin={{
                        top: isXs ? 17 : 7,
                        bottom: isNotSaveOrEditPage ? 2 : 0,
                    }}
                >
                    <Row margin={{ left: 'auto' }} gap={2} align="center">
                        {getFilterButtons({
                            activePage,
                            setFiltersColumn: setShowFilterColumn,
                            setTableColumnsFilter,
                            tableColumnsFilter,
                            setIsHoveringButton,
                            isHoveringButton,
                            isSubmitDisabled: memoizedIsSubmitDisabled,
                            hiddenButtons,
                        })}
                    </Row>
                    {hasSearchBar && (
                        <SearchBar
                            activePage={activePage}
                            control={control}
                            onEnter={onEnter}
                        />
                    )}
                </Column>
            ) : (
                <Row
                    justify="space-between"
                    gap={6}
                    align="center"
                    padding={{ left: 7, right: 7 }}
                    margin={{
                        top: isXs ? 20 : 7,
                        bottom: isNotSaveOrEditPage ? 6 : 0,
                    }}
                >
                    {hasSearchBar && (
                        <SearchBar
                            activePage={activePage}
                            control={control}
                            onEnter={onEnter}
                        />
                    )}
                    <Row margin={{ left: 'auto' }} gap={2} align="center">
                        {getFilterButtons({
                            activePage,
                            setFiltersColumn: setShowFilterColumn,
                            setTableColumnsFilter,
                            tableColumnsFilter,
                            setIsHoveringButton,
                            isHoveringButton,
                            isSubmitDisabled: memoizedIsSubmitDisabled,
                            hiddenButtons,
                        })}
                    </Row>
                </Row>
            )}
            {isNotSaveOrEditPage && <SelectedFilters />}
            {contextFilters({
                showFilterColumn,
                setFiltersColumn: setShowFilterColumn,
            })}
        </>
    );
}
