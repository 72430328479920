/* eslint-disable */
import contextFinancialDetails from './context-financial-details';

export default function DetailTableHead(): Map<string, JSX.Element> {
    const contextDetailTableHead = new Map<string, JSX.Element>([
        [
            contextFinancialDetails.bank_dispute,
            <tr className="table-head">
                <th>ID do pedido</th>
                <th>Localização</th>
                <th>Valor pedido</th>
                <th>Data do pedido</th>
            </tr>,
        ],
        [
            contextFinancialDetails.condominium_transfer,
            <tr className="table-head">
                <th>Código</th>
                <th>Localização</th>
                <th>Faturamento bruto</th>
                <th>Mês referência</th>
                <th>Repasse</th>
            </tr>,
        ],
        [
            contextFinancialDetails.credit_card,
            <tr className="table-head">
                <th>Código</th>
                <th>Localizações</th>
                <th>Valor bruto</th>
                <th>Valor líquido</th>
            </tr>,
        ],
        [
            contextFinancialDetails.monthly_payments,
            <tr className="table-head">
                <th>Código</th>
                <th>Dispositivo</th>
                <th>Localizações</th>
            </tr>,
        ],
        [
            contextFinancialDetails.negotiation,
            <tr className="table-head">
                <th>Data</th>
                <th>Descrição</th>
                <th>Valor</th>
                <th>Opções</th>
            </tr>,
        ],
        [
            contextFinancialDetails.negotiation_edit,
            <tr className="table-head">
                <th>Data</th>
                <th>Descrição</th>
                <th>Valor</th>
                <th>Opções</th>
            </tr>,
        ],
        [
            contextFinancialDetails.negotiation_delete,
            <tr className="table-head">
                <th>Data</th>
                <th>Descrição</th>
                <th>Valor</th>
                <th>Opções</th>
            </tr>,
        ],
        [
            contextFinancialDetails.pix,
            <tr className="table-head">
                <th>Código</th>
                <th>Localização</th>
                <th>Valor bruto</th>
                <th>Valor líquido</th>
            </tr>,
        ],
        [
            contextFinancialDetails.promotion_for_clients,
            <tr className="table-head">
                <th>Nome promoção</th>
                <th>Valor bruto</th>
                <th>Valor líquido</th>
            </tr>,
        ],
        [
            contextFinancialDetails.refunded,
            <tr className="table-head">
                <th>ID do pedido</th>
                <th>Valor do pedido</th>
                <th>Data de reembolso</th>
                <th>Valor reembolso</th>
            </tr>,
        ],
        [
            contextFinancialDetails.wallet,
            <tr className="table-head">
                <th>Código</th>
                <th>Localização</th>
                <th>Valor bruto</th>
                <th>Valor líquido</th>
            </tr>,
        ],
    ]);

    return contextDetailTableHead;
}
