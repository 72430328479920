import { IFilterOption } from '@/ui/context/context-filter/type';

export interface ILicensedFilterParams {
    licensedName?: IFilterOption | null;
    licensedCnpj?: IFilterOption | null;
    licensedPhone?: IFilterOption | null;
    phoneLabel?: any;
    page?: IFilterOption | null;
    limit?: IFilterOption | null;
    sort?: IFilterOption | null;
    column?: IFilterOption | null;
}

export default class LicensedFilterParams {
    licensedName?: IFilterOption;
    licensedCnpj?: IFilterOption;
    cnpjLabel?: IFilterOption;
    licensedPhone?: IFilterOption;
    phoneLabel?: any;

    constructor(
        licensedName?: any,
        cnpj?: any,
        cnpjLabel?: any,
        phone?: any,
        phoneLabel?: any,
    ) {
        this.licensedName = {
            id: licensedName,
            name: 'licensedName',
            label: licensedName,
        };
        this.licensedCnpj = {
            id: cnpj,
            name: 'licensedCnpj',
            label: cnpjLabel,
        };
        this.licensedPhone = {
            id: phone,
            name: 'licensedPhone',
            label: phoneLabel,
        };
    }
}
