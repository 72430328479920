import {
    AuthenticationHelper,
    PermissionHelper,
    StringHelper,
} from '@/domain/helpers';
import {
    ContextOrderType,
    IOrderList,
    OrderHttpEndpoint,
    OrderStatus,
    SearchCriteriaModel,
    SearchRequestModel,
    SearchResponseModel,
} from '@/domain/models';
import { ISearchRepositoryPort } from '@/infra';
import { ColumnModel } from '@/presentation/models/table';
import { RowActionProps } from '@/ui/interfaces/props/table';
import { sidebarOpenAction } from '@/ui/store/modules';
import { store } from '@/ui/store/config';
import { ITableServerSidePresenter } from '@/ui/components/table';
import { IOrderPresenter } from '@/ui/pages';
import { TableColoredLabel } from '@/ui/components';
import { WindowHelper } from '@/ui/helpers';

export default class OrderTablePresenter implements ITableServerSidePresenter {
    constructor(
        private readonly searchRepository: ISearchRepositoryPort,
        private readonly orderPresenter: IOrderPresenter,
    ) {}

    enableReprocessOrder(rowData: IOrderList) {
        const isReimbursedWithProblem =
            rowData.status === 'Ressarcido' &&
            rowData.realStatus === 'Problema';
        const userHasPermission =
            this.permissionHelper.isTakeAndGo() ||
            this.permissionHelper.isPartner();
        const orderHasProblem = rowData.status === 'Problema';

        return (
            isReimbursedWithProblem || (orderHasProblem && userHasPermission)
        );
    }

    enableShowOrderInvoice(rowData: IOrderList) {
        const invalidStatuses = ['Enviando', 'Processando', 'Cancelado'];
        const dontContainInvoice = invalidStatuses.includes(rowData.status);
        const userHasPermission = this.permissionHelper.isTakeAndGo();

        return !dontContainInvoice && userHasPermission;
    }

    makeColumns(): ColumnModel<IOrderList>[] {
        const { orderColumns } = store.getState() as any;
        const columns = [
            new ColumnModel<IOrderList>('Pedido', 'order', {}),
            new ColumnModel<IOrderList>('Status', 'status', {}).withRender(
                (value: string, rowData: IOrderList) => {
                    return (
                        <TableColoredLabel
                            isActive={value}
                            className={OrderStatus.statusClassName.get(value)}
                        >
                            {rowData.status}
                        </TableColoredLabel>
                    );
                },
            ),
            new ColumnModel<IOrderList>(
                'Status interno',
                'realStatus',
                {},
            ).withRender((value: string, rowData: IOrderList) => {
                const className = OrderStatus.internalStatusClassName.get(value)
                    ? OrderStatus.internalStatusClassName.get(value)
                    : OrderStatus.statusClassName.get(value);

                return (
                    <TableColoredLabel isActive={value} className={className}>
                        {rowData.realStatus}
                    </TableColoredLabel>
                );
            }),
            new ColumnModel<IOrderList>('Tipo de pedido', 'orderType', {})
                .withRender((value: string, rowData: IOrderList) => {
                    return <>{ContextOrderType.get(rowData.orderType)}</>;
                })
                .withRenderTitleCustom(
                    (rowData: IOrderList) =>
                        ContextOrderType.get(rowData.orderType) ||
                        rowData.orderType,
                ),
            new ColumnModel<IOrderList>('Data do pedido', 'orderDate', {}),
            new ColumnModel<IOrderList>('Data de pagamento', 'paymentDate', {}),
            new ColumnModel<IOrderList>('Valor total', 'totalValue', {})
                .withRender((value: string) => {
                    return (
                        <>
                            {Intl.NumberFormat('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                            }).format(+value)}
                        </>
                    );
                })
                .withRenderTitleCustom((rowData: IOrderList) =>
                    Intl.NumberFormat('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                    }).format(rowData.totalValue),
                ),
            new ColumnModel<IOrderList>('Desconto', 'discountValue', {})
                .withRender((value: string) => {
                    return (
                        <>
                            {Intl.NumberFormat('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                            }).format(+value)}
                        </>
                    );
                })
                .withRenderTitleCustom((rowData: IOrderList) =>
                    Intl.NumberFormat('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                    }).format(rowData.discountValue),
                ),
            new ColumnModel<IOrderList>('Valor cartão', 'cardValue', {})
                .withRender((value: string) => {
                    return (
                        <>
                            {Intl.NumberFormat('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                            }).format(+value)}
                        </>
                    );
                })
                .withRenderTitleCustom((rowData: IOrderList) =>
                    Intl.NumberFormat('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                    }).format(rowData.cardValue),
                ),
            new ColumnModel<IOrderList>('Valor Wallet', 'walletValue', {})
                .withRender((value: string) => {
                    return (
                        <>
                            {Intl.NumberFormat('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                            }).format(+value)}
                        </>
                    );
                })
                .withRenderTitleCustom((rowData: IOrderList) =>
                    Intl.NumberFormat('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                    }).format(rowData.walletValue),
                ),
            new ColumnModel<IOrderList>('Valor PIX', 'pixValue', {})
                .withRender((value: string) => {
                    return (
                        <>
                            {Intl.NumberFormat('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                            }).format(+value)}
                        </>
                    );
                })
                .withRenderTitleCustom((rowData: IOrderList) =>
                    Intl.NumberFormat('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                    }).format(rowData.pixValue),
                ),
            new ColumnModel<IOrderList>('Cooler', 'cooler', {})
                .withRender((value: string, rowData: IOrderList) => {
                    return (
                        <>{rowData?.orderRefrigeratorResponse?.pointOfSale}</>
                    );
                })
                .withRenderTitleCustom((rowData: IOrderList) => {
                    return rowData?.orderRefrigeratorResponse?.pointOfSale;
                }),
            new ColumnModel<any>('Usuário', 'user', {}),
        ];

        !orderColumns.activeColumns.orderColumn && delete columns[0];
        !orderColumns.activeColumns.statusColumn && delete columns[1];
        !orderColumns.activeColumns.realStatusColumn && delete columns[2];
        !orderColumns.activeColumns.orderType && delete columns[3];
        !orderColumns.activeColumns.orderDateColumn && delete columns[4];
        !orderColumns.activeColumns.paymentDateColumn && delete columns[5];
        !orderColumns.activeColumns.totalValueColumn && delete columns[6];
        !orderColumns.activeColumns.promotionColumn && delete columns[7];
        !orderColumns.activeColumns.cardColumn && delete columns[8];
        !orderColumns.activeColumns.walletColumn && delete columns[9];
        !orderColumns.activeColumns.pixColumn && delete columns[10];
        !orderColumns.activeColumns.refrigeratorColumn && delete columns[11];
        !orderColumns.activeColumns.userColumn && delete columns[12];

        return columns;
    }

    makeSearchRequestModel(): SearchRequestModel {
        return new SearchRequestModel(
            OrderHttpEndpoint.ORDER_BASE_API,
            'order',
            {
                isOrderByDesc: true,
                criterias: [
                    new SearchCriteriaModel('orderNumber', {
                        value: '',
                    }),
                ],
            },
        );
    }

    makeLabelDisplayedResult(response: SearchResponseModel<any>): string {
        if (response === undefined) return '';
        if (response.page === undefined || response.page.total === 0) return '';

        const textPlural = StringHelper.pluralize({
            count: response.page.numberOfElements,
        });
        return ` Exibindo ${response.page.numberOfElements} pedido${textPlural}`;
    }

    async search<T>(
        searchModel: SearchRequestModel,
    ): Promise<SearchResponseModel<T>> {
        return await this.searchRepository.getDataAsync<any>(
            searchModel,
            OrderHttpEndpoint.ORDER_BASE_API,
        );
    }

    token = AuthenticationHelper.getToken();

    permissionHelper = new PermissionHelper(this.token.auth);

    makeRowActions(): RowActionProps<any>[] {
        return [];
    }

    allowedOrderStatusToEdit = ['Em Contestação', 'Cancelado'];

    moreActionsMenu = [
        {
            enabled: true,
            label: 'Detalhes do pedido',
            onClick: (orderData: IOrderList, handleClose: any) => {
                handleClose();
                store.dispatch(
                    sidebarOpenAction({
                        sidebarName: 'order_detail',
                        sidebarState: { right: true },
                        elementId: orderData.order,
                        elementData: {
                            location:
                                orderData.orderRefrigeratorResponse.pointOfSale,
                            orderId: orderData.order,
                        },
                    }),
                );
            },
        },
        {
            enabled: (rowData: IOrderList) =>
                (this.permissionHelper.isTakeAndGo() ||
                    this.permissionHelper.isPartner()) &&
                this.allowedOrderStatusToEdit.includes(rowData.status),
            label: 'Editar pedido',
            onClick: (rowData: any) => {
                this.allowedOrderStatusToEdit.includes(rowData.status) &&
                    WindowHelper.redirect(`/order/${rowData?.order}`);
            },
        },
        {
            enabled: true,
            label: 'Visualizar nota fiscal',
            onClick: (
                orderData: IOrderList,
                handleClose: any,
                successNotification: any,
                errorNotification: any,
            ) => {
                this.orderPresenter
                    .visualizeNfc(String(orderData.order))
                    .then(({ url }) => {
                        if (url) {
                            successNotification(
                                'Redirecionando para a nota fiscal',
                            );
                            setTimeout(() => {
                                handleClose();
                                window.open(url, '_blank');
                            }, 2000);
                        } else {
                            errorNotification('Nota fiscal não encontrada');
                        }
                    });
            },
        },
        {
            enabled:
                this.permissionHelper.isTakeAndGo() ||
                this.permissionHelper.isPartner(),
            label: 'Visualizar imagens do pedido',
            onClick: (orderData: IOrderList, handleClose: any) => {
                handleClose();
                store.dispatch(
                    sidebarOpenAction({
                        sidebarName: 'order_cooler_images',
                        sidebarState: { right: true },
                        elementId: orderData.order,
                        elementData: {
                            location:
                                orderData.orderRefrigeratorResponse.pointOfSale,
                            orderId: orderData.order,
                        },
                    }),
                );
            },
        },
        {
            enabled: (orderData: IOrderList) => {
                const allowedOrderStatus = [
                    'Em Contestação',
                    'Processado',
                    'Problema',
                    'Contestação Aprovada',
                    'Contestação Bancária',
                    'Contestação Recusada',
                    'Fatura Manual',
                    'Reposição',
                ];

                if (orderData.status === 'Ressarcido') {
                    return (
                        allowedOrderStatus.includes(orderData.realStatus) &&
                        this.permissionHelper.isTakeAndGo()
                    );
                }

                return (
                    allowedOrderStatus.includes(orderData.status) &&
                    this.permissionHelper.isTakeAndGo()
                );
            },
            label: 'Relatório de IA',
            onClick: (orderData: IOrderList, handleClose: any) => {
                handleClose();
                const url = this.getUrlReportIA(String(orderData.order));
                WindowHelper.openNewTab(url);
            },
        },
        {
            enabled: (orderData: IOrderList) =>
                orderData.status === 'Processando' &&
                (this.permissionHelper.isTakeAndGo() ||
                    this.permissionHelper.isPartner()),
            label: 'Conferência manual',
            onClick: (orderData: IOrderList, handleClose: any) => {
                handleClose();
                store.dispatch(
                    sidebarOpenAction({
                        sidebarName: 'manual_order_review',
                        sidebarState: { right: true },
                        elementId: orderData.order,
                        elementData: orderData,
                    }),
                );
            },
        },
        {
            enabled: (orderData: IOrderList) =>
                orderData.status === 'Problema' &&
                (this.permissionHelper.isTakeAndGo() ||
                    this.permissionHelper.isPartner()),
            label: 'Motivo do problema',
            onClick: (orderData: IOrderList, handleClose: any) => {
                handleClose();
                store.dispatch(
                    sidebarOpenAction({
                        sidebarName: 'problem_reason',
                        sidebarState: { right: true },
                        elementId: orderData.order,
                        elementData: orderData.logPayment,
                    }),
                );
            },
        },
        {
            enabled: (orderData: IOrderList) =>
                this.enableReprocessOrder(orderData),
            label: 'Reprocessar pedido',
            onClick: (orderData: IOrderList, handleClose: any) => {
                handleClose();
                store.dispatch(
                    sidebarOpenAction({
                        sidebarName: 'reprocess_order',
                        sidebarState: { right: true },
                        elementId: orderData.order,
                        elementData: orderData,
                    }),
                );
            },
        },
        {
            enabled: (orderData: IOrderList) =>
                this.enableShowOrderInvoice(orderData),
            label: 'Visualizar fatura',
            onClick: (
                orderData: IOrderList,
                handleClose: any,
                successNotification: any,
                errorNotification: any,
            ) => {
                this.orderPresenter
                    .visualizeInvoice(String(orderData.order))
                    .then((res: any) => {
                        if (res.message || res.error) {
                            errorNotification(
                                res.error && res.error === 'Not Found'
                                    ? 'Fatura não encontrada'
                                    : res.message,
                            );
                        } else if (res) {
                            successNotification('Redirecionando para a fatura');
                            setTimeout(() => {
                                handleClose();
                                window.open(res, '_blank');
                            }, 2000);
                        } else {
                            errorNotification('Erro ao encontrar fatura');
                        }
                    });
            },
        },
    ];
    getUrlReportIA(orderId: string): string {
        const token = AuthenticationHelper.getTokenToString();

        return (
            'https://tm7m3lt5xj.execute-api.us-east-2.amazonaws.com/basic_detection_report?_o=' +
            orderId +
            '&_t=' +
            token
        );
    }
}
