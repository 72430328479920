import { useContext, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useQuery, useQueryClient } from 'react-query';
import { LocalizationProvider } from '@mui/x-date-pickers';
import {
    AuthenticationHelper,
    DateHelper,
    PermissionHelper,
    RequestQueryMaker,
} from '@/domain/helpers';
import { IAutocomplete } from '@/domain/models';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import OpeningAttemptsFilter from '@/presentation/models/monitoring/opening-attempts-filter';
import { makeRefrigeratorListPresenter } from '@/main/factories';
import {
    AutoCompleteField,
    DateTimePickerField,
    Filter,
    InputTextField,
    MaskedInputTextField,
} from '@/ui/components';
import { IFilterDrawer } from '@/ui/interfaces';
import { FilterContext } from '@/ui/context/context-filter';

export default function MonitoringFilter({
    filter,
    setFilter,
}: IFilterDrawer): JSX.Element {
    const { getFilters, resetFilter, setResetFilter, updateFilters } =
        useContext(FilterContext);
    const filtersPage = getFilters();

    const token = AuthenticationHelper.getToken();
    const permissionHelper = new PermissionHelper(token.auth);
    const isAllowed =
        permissionHelper.isTakeAndGo() || permissionHelper.isDeveloper();

    const refrigeratorListPresenter = makeRefrigeratorListPresenter();

    const resetValues = {
        coolerId: filtersPage?.coolerId ? filtersPage?.coolerId : null,
        licensedId: filtersPage?.licensedId ? filtersPage?.licensedId : null,
        beginDate: filtersPage?.beginDate
            ? DateHelper.formatDateHoursFromRequest(filtersPage?.beginDate.id)
            : null,
        endDate: filtersPage?.endDate
            ? DateHelper.formatDateHoursFromRequest(filtersPage?.endDate.id)
            : null,
        userName: filtersPage?.userName ? filtersPage?.userName.id : '',
        userCPF: filtersPage?.userCPF ? filtersPage?.userCPF.id : '',
    };

    const {
        handleSubmit,
        control,
        formState: { isValid },
        watch,
        setValue,
        reset,
    } = useForm({
        mode: 'all',
        defaultValues: resetValues,
    });

    const disableSubmit = !isValid;
    const [licensedId, beginDate, endDate] = watch([
        'licensedId',
        'beginDate',
        'endDate',
    ]);

    const queryClient = useQueryClient();
    const licensedAutoCompleteData = queryClient.getQueryData<
        Array<IAutocomplete>
    >('licensedAutoComplete');

    const {
        data: coolerAutoComplete = [],
        isLoading: isCoolerLoading,
        isFetching: isCoolerFetching,
    } = useQuery(
        ['coolerAutoComplete', licensedId],
        () =>
            refrigeratorListPresenter.getCoolerFilterAutocompleteAsync(
                RequestQueryMaker({
                    licensedId: licensedId?.id,
                    active: '0',
                }),
            ),
        {
            onSuccess: (data: IAutocomplete[]): any => {
                const cooler =
                    filtersPage?.coolerId?.id &&
                    data.find(
                        (item: any) => item.id === filtersPage.coolerId.id,
                    );
                cooler && setValue('coolerId', cooler);
            },
        },
    );

    const submitHandler = (data: any) => {
        let cooler = filtersPage.coolerId;
        if (data.coolerId !== null) {
            if (
                data?.licensedId?.id !== filtersPage?.licensedId &&
                filtersPage?.licensedId === null
            ) {
                cooler = {
                    id: '',
                    label: '',
                };
            } else {
                cooler = {
                    id: data?.coolerId?.id,
                    label: data?.coolerId?.label,
                };
            }
        } else if (!cooler) {
            cooler = {
                id: '',
                label: '',
            };
        }

        const openingAttemptsFilterParams = new OpeningAttemptsFilter(
            data.beginDate ? DateHelper.formatDateHours(data.beginDate) : null,
            data.endDate ? DateHelper.formatDateHours(data.endDate) : null,
            data.coolerId?.id,
            data.licensedId?.id,
            data.userName,
            data.userCPF,
            data.licensedId?.label,
            data.coolerId?.label,
        );

        updateFilters({ ...filtersPage, ...openingAttemptsFilterParams }, true);
        setFilter({ right: false });
        reset();
    };

    const onSubmit: SubmitHandler<any> = data => submitHandler(data);

    const handleLicensedChange = (value: any) => {
        setValue('coolerId', null);
        return setValue('licensedId', value, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
        });
    };

    useEffect(() => {
        reset(resetValues);
    }, [filtersPage]);

    useEffect(() => {
        if (resetFilter) {
            reset(resetValues);
            setResetFilter(false);
        }
    }, [resetFilter]);

    return (
        <Filter
            filter={filter}
            setFilter={setFilter}
            handleSubmit={handleSubmit as any}
            onSubmit={onSubmit}
            disabled={disableSubmit}
        >
            {isAllowed && (
                <AutoCompleteField
                    control={control}
                    options={licensedAutoCompleteData as IAutocomplete[]}
                    name="licensedId"
                    label="Licenciado"
                    dataTestId="licensed-autocomplete"
                    interceptOnChange={handleLicensedChange}
                />
            )}
            <AutoCompleteField
                control={control}
                options={coolerAutoComplete}
                isLoading={isCoolerLoading || isCoolerFetching}
                name="coolerId"
                label="Cooler"
                dataTestId="cooler-autocomplete"
            />
            <InputTextField control={control} name="userName" label="Usuário" />
            <MaskedInputTextField
                control={control}
                mask="999.999.999-99"
                name="userCPF"
                label="CPF"
                dataTestId="user-cpf"
            />
            <LocalizationProvider
                adapterLocale="pt-br"
                dateAdapter={AdapterDayjs}
            >
                <DateTimePickerField
                    control={control}
                    name="beginDate"
                    label="Início"
                    dataTestId="begin-date"
                    rules={{
                        deps: ['endDate'],
                        validate: {
                            isLessThan: (value: any) => {
                                if (!value) return true;
                                return (
                                    (endDate as any) > value ||
                                    'Data de início deve ser menor que a data de término'
                                );
                            },
                        },
                    }}
                />
                <DateTimePickerField
                    control={control}
                    name="endDate"
                    label="Fim"
                    dataTestId="end-date"
                    rules={{
                        deps: ['beginDate'],
                        validate: {
                            isGreaterThan: (value: any) => {
                                if (!value) return true;
                                return (
                                    (beginDate as any) < value ||
                                    'Data de término deve ser maior que a data de início'
                                );
                            },
                        },
                    }}
                />
            </LocalizationProvider>
        </Filter>
    );
}
