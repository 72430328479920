import { ListItemIcon } from '@mui/material';
import styled from 'styled-components';

const StyledListItemIcon = styled(ListItemIcon)`
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 650px) {
        align-self: center;
    }
`;

export default StyledListItemIcon;
