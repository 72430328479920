export enum ContextType {
    mural_take = 'mural_take',
    uptime_downtime_apis = 'uptime_downtime_apis',
    none = 'none',
    home = 'home',
    order = 'order',
    order_edit = 'order_edit',
    home_overview = 'home_overview',
    home_rating = 'home_rating',
    refrigerator = 'refrigerator',
    // refrigerator_status = 'refrigerator_status',
    refrigerator_stock = 'refrigerator_stock',
    refrigerator_stock_by_cooler = 'refrigerator_stock_by_cooler',
    refrigerator_stock_by_product = 'refrigerator_stock_by_product',
    refrigerator_list = 'refrigerator_list',
    refrigerator_status = 'refrigerator_status',
    refrigerator_status_dashboard = 'refrigerator_status_dashboard',
    refrigerator_status_list = 'refrigerator_status_list',
    refrigerator_save = 'refrigerator_save',
    refrigerator_edit = 'refrigerator_edit',
    refrigerator_edit_product = 'refrigerator_edit_product',
    promotion_list = 'promotion',
    promotion_save = 'promotion_save',
    promotion_edit = 'promotion_edit',
    user = 'user',
    user_save = 'user_save',
    user_edit = 'user_edit',
    financial = 'financial',
    products = 'products',
    product_list = 'product_list',
    product_save = 'product_save',
    product_edit = 'product_edit',
    product_mix = 'product_mix',
    product_mix_save = 'product_mix_save',
    product_mix_edit = 'product_mix_edit',
    product_category = 'product_category',
    product_category_save = 'product_category_save',
    product_category_edit = 'product_category_edit',
    monitoring = 'monitoring',
    monitoring_opening_attempts = 'monitoring_opening_attempts',
    licensed_list = 'licensed',
    licensed_save = 'licensed_save',
    licensed_edit = 'licensed_edit',
    login = 'login',
    password_recovery = 'password_recovery',
    new_password = 'new_password',
    banner_list = 'banner_list',
    banner_save = 'banner_save',
    banner_edit = 'banner_edit',
}
