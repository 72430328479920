import { OrderColumnsAppState } from '@/ui/store/models';
import { Types, OrderColumnsAppAction } from './types';

export default function orderColumns(
    state = new OrderColumnsAppState({
        orderColumn: true,
        statusColumn: true,
        realStatusColumn: true,
        orderDateColumn: true,
        paymentDateColumn: true,
        lastStatusDateColumn: false,
        totalValueColumn: true,
        promotionColumn: false,
        cardColumn: false,
        walletColumn: false,
        pixColumn: false,
        refrigeratorColumn: true,
        licensedColumn: false,
        userColumn: true,
        openRefrigeratorTimeColumn: false,
    }),
    action: OrderColumnsAppAction,
): OrderColumnsAppState {
    switch (action.type) {
        case Types.ORDER_COLUMNS:
            return action.payload.data;
        default:
            return state;
    }
}
