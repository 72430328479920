import { SidebarAppState } from '@/ui/store/models/app/sidebar';
import { action } from 'typesafe-actions';
import { Types } from './types';

export function sidebarOpenAction(data: SidebarAppState) {
    return action(Types.OPEN_SIDEBAR, {
        data,
    });
}

export function sidebarCloseAction(data: SidebarAppState) {
    return action(Types.CLOSE_SIDEBAR, {
        data,
    });
}
