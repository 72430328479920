import styled from 'styled-components';

export const LinkPreview = styled.a`
    color: ${({ theme }) => theme.blueIconColor};
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.4px;
    text-decoration: none;

    &:visited {
        color: ${({ theme }) => theme.blueIconColor};
    }
`;
