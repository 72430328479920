import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import { CircularProgress, CloseIcon, ModalContainer } from '@/ui/components';
import makeOrderListPresenter from '@/main/factories/presenters/pages/order/order-list-presenter-factory';
import NfceTable from './nfce-table';
import {
    ButtonContainer,
    TitleContainer,
    TitleText,
} from '../../user/components/styles';
import { NotificationContext } from '@/main';

export default function NfceModal() {
    const [open, setOpen] = useState(false);
    const [nfceData, setNfceData] = useState<any>(null);

    const handleClose = () => setOpen(false);

    const orderPresenter = makeOrderListPresenter();
    const sidebar = useSelector((store: any) => store.sidebar);

    const { errorNotification } = useContext(NotificationContext);

    useEffect(() => {
        if (
            sidebar.sidebarState.right &&
            sidebar.sidebarName === 'order_xml_nf'
        ) {
            setOpen(sidebar.sidebarState.right);
            orderPresenter.getNfceXls().then((result: any) => {
                if (Array.isArray(result)) {
                    return setNfceData(result);
                }

                errorNotification(result.message, 5000);
                handleClose();
            });
        }
    }, [sidebar]);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalContainer className="nfce">
                    <TitleContainer>
                        <TitleText>XML dos últimos 06 meses</TitleText>
                        <ButtonContainer onClick={() => handleClose()}>
                            <CloseIcon />
                        </ButtonContainer>
                    </TitleContainer>
                    {nfceData ? (
                        <NfceTable nfceData={nfceData} />
                    ) : (
                        <CircularProgress variant="indeterminate" />
                    )}
                </ModalContainer>
            </Modal>
        </div>
    );
}
