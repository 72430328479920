import styled from 'styled-components';
import { Row } from '../../layout';

export const ImagePreviewContainer = styled(Row)`
    position: relative;
    align-items: start;
`;

export const Image = styled.img`
    border-radius: 0.5rem;
`;

export const RemoveImageButton = styled.button`
    cursor: pointer;
    border: none;
    background: none;
    position: absolute;
    right: -10px;
    top: -10px;
    border-radius: 100%;
    background-color: #ffe766;
    width: 30px;
    height: 30px;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 0;
    display: flex;
`;
