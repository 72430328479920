import LicensedIcon from '@/ui/components/illustration/icons/licensed-icon';
import AverageTicket from '@/ui/components/illustration/icons/average-ticket';
import CipherIcon from '@/ui/components/illustration/icons/cipher-icon';
import FinancialIcon from '@/ui/components/illustration/icons/financial-icon';
import HomeIcon from '@/ui/components/illustration/icons/home-icon';
import MonitoringMenuIcon from '@/ui/components/illustration/icons/monitoring-menu-icon';
import OrderIcon from '@/ui/components/illustration/icons/order-icon';
import PeopleIcon from '@/ui/components/illustration/icons/people-icon';
import ProductsIcon from '@/ui/components/illustration/icons/products-icon';
import ProductsMenuIcon from '@/ui/components/illustration/icons/products-menu-icon';
import PromotionIcon from '@/ui/components/illustration/icons/promotion-icon';
import RefrigeratorIcon from '@/ui/components/illustration/icons/refrigerator-icon';
import UserIcon from '@/ui/components/illustration/icons/user-icon';
import MuralTakeIcon from '@/ui/components/illustration/icons/mural-take-icon';
import { SvgIcon } from '@mui/material';
import BannerIcon from '@/ui/components/illustration/icons/banner-icon';

export enum TableIcons {
    none = '',
    MURAL = 'mural_take',
    HOME = 'home',
    ORDER = 'order',
    REFRIGERATOR = 'refrigerator',
    PROMOTION = 'promotion',
    USER = 'user',
    FINANCIAL = 'financial',
    PRODUCTS = 'products',
    MONITORING = 'monitoring',
    LICENSED = 'licensed',
    BANNER = 'banner_list',
}

export type SvgIconComponent = typeof SvgIcon;

export const TableIconsMaterial = new Map<string, JSX.Element>([
    [
        TableIcons.HOME,
        <>
            <HomeIcon />
        </>,
    ],
    [
        TableIcons.MURAL,
        <>
            <MuralTakeIcon />
        </>,
    ],
    [
        TableIcons.ORDER,
        <>
            <OrderIcon />
        </>,
    ],
    [
        TableIcons.REFRIGERATOR,
        <>
            <RefrigeratorIcon />
        </>,
    ],
    [
        TableIcons.PROMOTION,
        <>
            <PromotionIcon />
        </>,
    ],
    [
        TableIcons.USER,
        <>
            <UserIcon />
        </>,
    ],
    [
        TableIcons.FINANCIAL,
        <>
            <FinancialIcon />
        </>,
    ],
    [
        TableIcons.PRODUCTS,
        <>
            <ProductsMenuIcon />
        </>,
    ],
    [
        TableIcons.MONITORING,
        <>
            <MonitoringMenuIcon />
        </>,
    ],
    [
        TableIcons.LICENSED,
        <>
            <LicensedIcon />
        </>,
    ],
    [
        TableIcons.BANNER,
        <>
            <BannerIcon />
        </>,
    ],
]);

export enum OverviewIcons {
    BILLING = 'Faturamento',
    ACTIVE_USERS = 'Usuários ativos',
    PRODUCTS = 'Produtos',
    AVERAGE_TICKET = 'Ticket médio',
}

export const OverviewIconsMaterial = new Map<string, JSX.Element>([
    [
        OverviewIcons.BILLING,
        <div key="cipher-icon" data-testid="cipher-icon">
            <CipherIcon />
        </div>,
    ],
    [
        OverviewIcons.ACTIVE_USERS,
        <div key="people-icon" data-testid="people-icon">
            <PeopleIcon />
        </div>,
    ],
    [
        OverviewIcons.PRODUCTS,
        <div key="products-icon" data-testid="products-icon">
            <ProductsIcon />
        </div>,
    ],
    [
        OverviewIcons.AVERAGE_TICKET,
        <div key="average-icon" data-testid="average-icon">
            <AverageTicket />
        </div>,
    ],
]);

export const OverviewLittleITestId = new Map<string, string>([
    [OverviewIcons.BILLING, 'billing-little-i'],
    [OverviewIcons.ACTIVE_USERS, 'active-users-little-i'],
    [OverviewIcons.PRODUCTS, 'products-little-i'],
    [OverviewIcons.AVERAGE_TICKET, 'average-ticket-little-i'],
]);
