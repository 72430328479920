import {
    IBankDisputeDetails,
    ICondominiumTransferDetails,
    ICreditCardDetails,
    IFinancial,
    IMonthlyPayments,
    INegotiationDetails,
    IPixDetails,
    IPromotionDetails,
    IRefundDetails,
    IWalletDetails,
    IExportReport,
    HttpResponse,
    INegotiationForm,
    IDefaultMonthlyPayment,
} from '@/domain/models';
import { IFinancialRepositoryPort } from '@/infra';
import { ITableHeadColumn } from '@/ui/interfaces';
import { IFinancialPresenter } from '@/ui/pages';

export default class FinancialPresenter implements IFinancialPresenter {
    constructor(
        private readonly financialRepository: IFinancialRepositoryPort,
    ) {}

    financialMock = {
        isMocked: true,
        date: '',
        financialClose: {
            deductible: 0,
            receivables: 0,
            toReceive: 0,
        },
        orderConsolidation: {
            card: {
                grossValue: 0,
                netValue: 0,
            },
            pix: {
                grossValue: 0,
                netValue: 0,
            },
            promotionForClients: {
                grossValue: 0,
                netValue: 0,
            },
            total: {
                grossValue: 0,
                netValue: 0,
            },
            wallet: {
                grossValue: 0,
                netValue: 0,
            },
        },
        other: {
            condominiumTransfer: 0,
            monthlyPayment: 0,
            negotiation: 0,
            ordersWithBankDispute: 0,
            refundedOrders: 0,
        },
    };

    async getFinancialReportAsync(params: string): Promise<IFinancial> {
        const response =
            await this.financialRepository.getFinancialReport<IFinancial>(
                params,
            );

        const financialReportData: IFinancial = response.data!;

        return financialReportData;
    }

    async getBankDisputeDetailsAsync(
        params: string,
    ): Promise<IBankDisputeDetails[]> {
        const response = await this.financialRepository.getBankDisputeDetails<
            IBankDisputeDetails[]
        >(params);

        const bankDisputeDetailsData: IBankDisputeDetails[] = response.data!;

        return bankDisputeDetailsData;
    }

    async getCondominiumTransferAsync(
        params: string,
    ): Promise<ICondominiumTransferDetails[]> {
        const response =
            await this.financialRepository.getCondominiumTransferDetails<
                ICondominiumTransferDetails[]
            >(params);

        const condominiumTransferDetailsData: ICondominiumTransferDetails[] =
            response.data!;

        return condominiumTransferDetailsData;
    }

    async getCreditCardAsync(params: string): Promise<ICreditCardDetails[]> {
        const response = await this.financialRepository.getCreditCardDetails<
            ICreditCardDetails[]
        >(params);

        const creditCardDetailsData: ICreditCardDetails[] = response.data!;

        return creditCardDetailsData;
    }

    async getMonthlyPaymentsAsync(params: string): Promise<IMonthlyPayments[]> {
        const response = await this.financialRepository.getCreditCardDetails<
            IMonthlyPayments[]
        >(params);

        const monthlyPaymentsDetailsData: IMonthlyPayments[] = response.data!;

        return monthlyPaymentsDetailsData;
    }

    async getPixDetailsAsync(params: string): Promise<IPixDetails[]> {
        const response = await this.financialRepository.getCreditCardDetails<
            IPixDetails[]
        >(params);

        const pixData: IPixDetails[] = response.data!;

        return pixData;
    }

    async getPromotionsDetailsAsync(
        params: string,
    ): Promise<IPromotionDetails[]> {
        const response = await this.financialRepository.getCreditCardDetails<
            IPromotionDetails[]
        >(params);

        const promotionDetailsData: IPromotionDetails[] = response.data!;

        return promotionDetailsData;
    }

    async getRefundDetailsAsync(params: string): Promise<IRefundDetails[]> {
        const response = await this.financialRepository.getCreditCardDetails<
            IRefundDetails[]
        >(params);

        const refundDetailsData: IRefundDetails[] = response.data!;

        return refundDetailsData;
    }

    async getWalletDetailsAsync(params: string): Promise<IWalletDetails[]> {
        const response = await this.financialRepository.getCreditCardDetails<
            IWalletDetails[]
        >(params);

        const walletDetailsData: IWalletDetails[] = response.data!;

        return walletDetailsData;
    }

    async getDetailAsync(
        params: string,
        endpoint: string,
    ): Promise<IWalletDetails[]> {
        const response = await this.financialRepository.getDetail(
            params,
            endpoint,
        );

        const detailData: any = response.data!;

        return detailData;
    }

    async exportFinancialReportAsync(params: string): Promise<IExportReport> {
        const response =
            await this.financialRepository.exportFinancialReport<IExportReport>(
                params,
            );

        if (!response.success) {
            throw new Error(response.data.message);
        }

        return response.data! as IExportReport;
    }

    async hasExportFinancialReportInProgressAsync(): Promise<boolean> {
        const response =
            await this.financialRepository.hasExportFinancialReportInProgress<number>();

        if (response.data > 0) {
            return true;
        }

        return false;
    }

    async getNegotiationDetailsAsync(
        params: string,
    ): Promise<INegotiationDetails[]> {
        const response = await this.financialRepository.getCreditCardDetails<
            INegotiationDetails[]
        >(params);

        const negotiationDetailsData: INegotiationDetails[] = response.data!;

        return negotiationDetailsData;
    }

    async getNegotiationByIdAsync(
        negotiationId: number,
    ): Promise<INegotiationDetails> {
        const response =
            await this.financialRepository.getNegotiationById<INegotiationDetails>(
                negotiationId,
            );

        if (!response.success) {
            throw new Error(response.data.message);
        }

        const negotiationDetailData: INegotiationDetails = response.data!;

        return negotiationDetailData;
    }

    async deleteNegotiationAsync(
        negotiationId: number,
    ): Promise<HttpResponse<INegotiationDetails>> {
        const response =
            await this.financialRepository.deleteNegotiation<INegotiationDetails>(
                negotiationId,
            );

        if (!response.success) {
            throw new Error(response.data.message);
        }

        const negotiationDetailData: HttpResponse<INegotiationDetails> =
            response;

        return negotiationDetailData;
    }

    async postNegotiation(
        data: INegotiationForm,
        licensedId: string,
    ): Promise<HttpResponse<INegotiationDetails>> {
        return this.financialRepository.postNegotiation(data, licensedId);
    }

    async updateNegotiation(
        data: INegotiationForm,
        negotiationId: string,
    ): Promise<HttpResponse<INegotiationDetails>> {
        return this.financialRepository.updateNegotiation(data, +negotiationId);
    }

    public async exportCondominiumTransferReportAsync(
        params: string,
    ): Promise<IExportReport> {
        const response =
            await this.financialRepository.exportCondominiumTransferReport<IExportReport>(
                params,
            );

        if (!response.success) {
            throw new Error(response.data.message);
        }

        const financialReportData: IExportReport = response.data!;

        return financialReportData;
    }

    public async getDefaultMonthlyPaymentAsync(
        licensedId: string,
    ): Promise<IDefaultMonthlyPayment> {
        const response =
            await this.financialRepository.getDefaultMonthlyPayment<IDefaultMonthlyPayment>(
                licensedId,
            );

        if (!response.success) {
            throw new Error(response.data.message);
        }

        return response.data as IDefaultMonthlyPayment;
    }

    public orderConsolidationTableColumns = (): ITableHeadColumn[] => {
        return [
            {
                columnName: 'tax',
                columnLabel: 'Taxa',
                className: 'financial-padding',
            },
            {
                columnName: 'description',
                columnLabel: 'Descrição',
            },
            {
                columnName: 'grossValue',
                columnLabel: 'Valor bruto',
            },
            {
                columnName: 'netValue',
                columnLabel: 'Valor líquido',
            },
        ];
    };

    public otherTableColumns = (): ITableHeadColumn[] => {
        return [
            {
                columnName: 'tax',
                columnLabel: 'Taxa',
                className: 'financial-padding',
            },
            {
                columnName: 'description',
                columnLabel: 'Descrição',
            },
            {
                columnName: 'value',
                columnLabel: 'Valor',
            },
        ];
    };
    public financialCloseTableColumns = (): ITableHeadColumn[] => {
        return [
            {
                columnName: 'tax',
                columnLabel: 'Taxa',
                className: 'financial-padding',
            },
            {
                columnName: 'description',
                columnLabel: 'Descrição',
            },
            {
                columnName: 'value',
                columnLabel: 'Valor',
            },
        ];
    };
}
