import { IOverviewFilterParams } from '@/domain/models';
import { IFilterOption } from '@/ui/context/context-filter/type';

export default class OverviewFilterParams implements IOverviewFilterParams {
    initialDate: IFilterOption;
    endDate: IFilterOption;
    coolerId: IFilterOption;
    licensedId: IFilterOption;

    constructor(
        initialDate: string,
        endDate: string,
        coolerId: string | number,
        licensedId: string | number,
        coolerLabel: string,
        licensedLabel: string,
    ) {
        this.initialDate = {
            id: initialDate,
            name: 'initialDate',
            label: initialDate,
        };
        this.endDate = {
            id: endDate,
            name: 'endDate',
            label: endDate,
        };
        this.coolerId = {
            id: coolerId,
            name: 'coolerId',
            label: coolerLabel,
        };
        this.licensedId = {
            id: licensedId,
            name: 'licensedId',
            label: licensedLabel,
        };
    }
}
