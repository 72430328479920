enum orderStatus {
    SUCCESS = 'Processado',
    PROBLEMS = 'Problemas',
    OPEN = 'Enviando',
    AWAITING_REVIEW = 'Processando',
    CANCELED = 'Cancelado',
    REPLACEMENT_PRODUCT = 'Reposição',
    CONTESTATION_IN_PROGRESS = 'Em Contestação',
    CONTESTATION_APPROVED = 'Contestação Aprovada',
    CONTESTATION_DENIED = 'Contestação recusada',
    MANUAL_BILLING = 'Fatura Manual',
    BANK_DISPUTE = 'Contestação Bancária',
    REFUNDED = 'Ressarcido',
    REPOSITION = 'Reposição',
    COOLER_PROCESSING = 'Processando Cooler',
}

enum internalStatus {
    PRE_PROCESS_API = 'Pré Processamento',
    PROCESSING_COOLER = 'Processando Cooler',
    PROCESSING_IA = 'Processando IA',
    POST_PROCESS_API = 'Processando Backend',
    SENT_TO_MANUAL_REVISION = 'Processado IA - Revisão Manual',
    PROCESSED = 'Processado.',
    CANCELED_BY_COOLER = 'Cancelado pelo cooler',
    CANCELED_BY_API_CARD_VALIDATION = 'Falha na verificação do cartão',
}

export const statusText = new Map<string, string>([
    [orderStatus.SUCCESS, 'Processado'],
    [orderStatus.PROBLEMS, 'Problema'],
    [orderStatus.OPEN, 'Aguardando processamento'],
    [orderStatus.AWAITING_REVIEW, 'Processando'],
    [orderStatus.CANCELED, 'Cancelado'],
    [orderStatus.REPLACEMENT_PRODUCT, 'Reposição'],
    [orderStatus.CONTESTATION_IN_PROGRESS, 'Em contestação'],
    [orderStatus.CONTESTATION_APPROVED, 'Contestação Aprovada'],
    [orderStatus.CONTESTATION_DENIED, 'Contestação recusada'],
    [orderStatus.MANUAL_BILLING, 'Fatura manual'],
    [orderStatus.BANK_DISPUTE, 'Contestação bancária'],
    [orderStatus.REFUNDED, 'Ressarcido'],
]);

export const statusClassName = new Map<string, string>([
    [orderStatus.SUCCESS, 'success'],
    [orderStatus.PROBLEMS, 'problems'],
    [orderStatus.OPEN, 'processing'],
    [orderStatus.AWAITING_REVIEW, 'processing'],
    [orderStatus.CANCELED, 'canceled'],
    [orderStatus.REPLACEMENT_PRODUCT, 'reposition'],
    [orderStatus.CONTESTATION_IN_PROGRESS, 'contestation'],
    [orderStatus.CONTESTATION_APPROVED, 'contestation-approved'],
    [orderStatus.CONTESTATION_DENIED, 'contestation-denied'],
    [orderStatus.MANUAL_BILLING, 'manual-billing'],
    [orderStatus.BANK_DISPUTE, 'bank-dispute'],
    [orderStatus.REFUNDED, 'refunded'],
]);

export const internalStatusClassName = new Map<string, string>([
    [internalStatus.PRE_PROCESS_API, 'processing'],
    [internalStatus.PROCESSING_COOLER, 'processing'],
    [internalStatus.PROCESSING_IA, 'processing'],
    [internalStatus.POST_PROCESS_API, 'processing'],
    [internalStatus.SENT_TO_MANUAL_REVISION, 'processing'],
    [internalStatus.PROCESSED, 'success'],
    [internalStatus.CANCELED_BY_COOLER, 'problems'],
    [internalStatus.CANCELED_BY_API_CARD_VALIDATION, 'problems'],
]);
