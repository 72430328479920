import { useState } from 'react';
import { useBreakpoints } from '@/ui/helpers';
import { ExportIcon } from '../icons';
import { ExportOption } from './styles';
import { FilterButton } from '../../../layouts/header/sub-header/styles';
import { Menu } from '@mui/material';

type exportBoxProps = { options: exportBoxOption[] };

interface exportBoxOption {
    name: string;
    handleExport: () => any;
    disabled?: boolean;
}

export default function ExportBox({ options }: exportBoxProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isOpen = Boolean(anchorEl);
    const { isXs } = useBreakpoints();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            <FilterButton onClick={handleClick}>
                {!isXs && 'Exportar'}
                <ExportIcon />
            </FilterButton>
            <Menu
                id="export-box-menu"
                anchorEl={anchorEl}
                open={isOpen}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                    'aria-labelledby': 'export-box-menu',
                }}
            >
                {options.map((exportOption: exportBoxOption, index) => (
                    <ExportOption
                        key={index + exportOption.name}
                        onClick={() => {
                            exportOption.handleExport();
                            setAnchorEl(null);
                        }}
                        disabled={exportOption.disabled}
                        disableRipple
                    >
                        {exportOption.name}
                    </ExportOption>
                ))}
            </Menu>
        </>
    );
}
