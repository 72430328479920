import { CircularProgress } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(CircularProgress)`
    color: #ffcd00 !important;

    &.order-list {
        margin-top: 25px;
    }
`;
