import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { ImageIcon } from '@/ui/components';
import { store } from '@/ui/store/config';
import { sidebarOpenAction } from '@/ui/store/modules';
import { Tooltip } from 'react-tooltip';
import { OrderContestationModals } from '@/domain/models';
import { InfoColumn, OrderImagesModalButton } from './styles';
import { Grid, Row } from '@/ui/components/layout';

interface IOrderEditInfo {
    orderDate: string;
    paymentDate: string;
    openDoorTime: string | null;
    contestationReason: string;
    orderImages: string[];
    orderId: string | number | any;
    orderContestationImages: string[];
    licensed: string;
    cooler: string;
}

export default function OrderEditInfo(props: IOrderEditInfo): JSX.Element {
    const {
        orderDate,
        paymentDate,
        openDoorTime,
        contestationReason,
        orderImages,
        orderId,
        orderContestationImages,
        licensed,
        cooler,
    } = props;

    const handleImageModalClick = () => {
        store.dispatch(
            sidebarOpenAction({
                sidebarName: OrderContestationModals.ORDER_IMAGES,
                sidebarState: { right: true },
                elementId: '1',
                elementData: {
                    orderImages,
                    openDoorTime,
                    contestationReason,
                    orderId,
                    orderContestationImages,
                },
            }),
        );
    };

    return (
        <>
            <Tooltip id="order-images" />
            <h5>Dados do pedido</h5>
            <Grid columns={2} gap={4} justify="space-between">
                <Grid columns={3} gap={2}>
                    <InfoColumn>
                        <p data-testid="order_date" className="title">
                            Data do pedido
                        </p>
                        <p data-testid="order_date">{orderDate}</p>
                    </InfoColumn>
                    <InfoColumn>
                        <p className="title">Licença</p>
                        <p data-testid="licensed" className="licensed">
                            {licensed}
                        </p>
                    </InfoColumn>
                    <div />
                    <InfoColumn>
                        <p className="title">Data do pagamento</p>
                        <p data-testid="payment_date">
                            {paymentDate
                                ? paymentDate
                                : 'Nenhum dado encontrado'}
                        </p>
                    </InfoColumn>
                    <InfoColumn>
                        <p className="title">Geladeira</p>
                        <p data-testid="cooler">{cooler}</p>
                    </InfoColumn>
                    <InfoColumn>
                        <p data-testid="open_door_time" className="title">
                            Porta aberta
                        </p>
                        <p>
                            {openDoorTime
                                ? openDoorTime
                                : 'Nenhum dado encontrado'}
                        </p>
                        {openDoorTime && (
                            <AccessTimeIcon
                                sx={{
                                    position: 'absolute',
                                    top: '21.5px',
                                    left: '70px',
                                }}
                            />
                        )}
                    </InfoColumn>
                </Grid>
                <Row justify="end" align="center">
                    <OrderImagesModalButton
                        data-tooltip-id="order-images"
                        data-tooltip-content="Imagens do pedido"
                        onClick={handleImageModalClick}
                        data-testid="order_images_button"
                    >
                        <ImageIcon />
                    </OrderImagesModalButton>
                </Row>
            </Grid>
            <Grid columns={2} margin={{ top: 4 }}>
                <InfoColumn>
                    <p className="title">Motivo da contestação</p>
                    <p data-testid="contestation_reason">
                        {contestationReason
                            ? contestationReason
                            : 'Nenhum dado encontrado'}
                    </p>
                </InfoColumn>
            </Grid>
        </>
    );
}
