import { IFilterOption } from '@/ui/context/context-filter/type';
export interface IOrderFilterParamsType {
    cooler: IFilterOption | null;
    orderNumber: IFilterOption | null;
    orderStatus: IFilterOption | null;
    orderType: IFilterOption | null;
    userName: IFilterOption | null;
    cpf: IFilterOption | null;
    orderDateStart: IFilterOption | null;
    orderDateEnd: IFilterOption | null;
    paymentDateStart: IFilterOption | null;
    paymentDateEnd: IFilterOption | null;
    licensedId: IFilterOption | null;
    page?: IFilterOption | null;
    limit?: IFilterOption | null;
    sort?: IFilterOption | null;
    column?: IFilterOption | null;
}

export default class OrderFilterParams implements IOrderFilterParamsType {
    cooler: IFilterOption;
    orderNumber: IFilterOption;
    orderStatus: IFilterOption;
    orderType: IFilterOption;
    userName: IFilterOption;
    cpf: IFilterOption;
    orderDateStart: IFilterOption;
    orderDateEnd: IFilterOption;
    paymentDateStart: IFilterOption;
    paymentDateEnd: IFilterOption;
    licensedId: IFilterOption;

    constructor(
        cooler: string,
        orderNumber: string | number,
        orderStatus: string,
        orderType: string,
        userName: string,
        cpf: string,
        orderDateStart: any,
        orderDateEnd: any,
        paymentDateStart: any,
        paymentDateEnd: any,
        licensedId: any,
        coolerLabel: string,
        licensedLabel: string,
        orderTypeLabel: string,
    ) {
        this.cooler = {
            id: cooler,
            name: 'cooler',
            label: coolerLabel,
        };
        this.orderNumber = {
            id: orderNumber,
            name: 'orderNumber',
            label: orderNumber,
        };
        this.orderStatus = {
            id: orderStatus,
            name: 'orderStatus',
            label: orderStatus,
        };
        this.orderType = {
            id: orderType,
            name: 'orderType',
            label: orderTypeLabel,
        };
        this.userName = {
            id: userName,
            name: 'userName',
            label: userName,
        };
        this.cpf = {
            id: cpf,
            name: 'cpf',
            label: cpf,
        };
        this.orderDateStart = {
            id: orderDateStart,
            name: 'orderDateStart',
            label: orderDateStart,
        };
        this.orderDateEnd = {
            id: orderDateEnd,
            name: 'orderDateEnd',
            label: orderDateEnd,
        };
        this.paymentDateStart = {
            id: paymentDateStart,
            name: 'paymentDateStart',
            label: paymentDateStart,
        };
        this.paymentDateEnd = {
            id: paymentDateEnd,
            name: 'paymentDateEnd',
            label: paymentDateEnd,
        };
        this.licensedId = {
            id: licensedId,
            name: 'licensedId',
            label: licensedLabel,
        };
    }
}
