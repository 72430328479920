enum contextFinancialDetails {
    promotion_for_clients = 'promotion-for-clients',
    wallet = 'wallet',
    credit_card = 'credit-card',
    pix = 'pix',
    monthly_payments = 'monthly-payments',
    bank_dispute = 'bank-dispute',
    condominium_transfer = 'condominium-transfer',
    refunded = 'refunded',
    negotiation = 'negotiation',
    negotiation_delete = 'financial_delete_negotiation_modal',
    negotiation_edit = 'financial_negotiation_modal_edit',
}

export default contextFinancialDetails;
