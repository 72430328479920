import { useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import {
    AutoCompleteField,
    Button,
    CloseIcon,
    InputTextField,
    ModalContainer,
} from '@/ui/components';
import { TitleContainer } from '@/ui/layouts/header/sub-header/styles';
import { TitleText } from '@/ui/pages/user/components/styles';
import { useForm } from 'react-hook-form';
import {
    IAutocomplete,
    IContestationOrderProduct,
    OrderContestationModals,
} from '@/domain/models';
import makeProductsPresenter from '@/main/factories/presenters/pages/products/product-presenter-factory';
import { NumberHelper } from '@/domain/helpers';
import { InfoColumn } from '../order-edit-info/styles';
import { useEffect, useState } from 'react';
import { Grid, Row } from '@/ui/components/layout';
import { useParams } from 'react-router-dom';

interface IOrderAddProductModalProps {
    handleAddProduct: (
        quantity: number,
        unitaryValue: number,
        product: IAutocomplete,
    ) => void;
    products: IContestationOrderProduct[];
}

interface IContestationAddProductForm {
    product: IAutocomplete;
    quantity: number;
}

export default function OrderAddProductModal(
    props: IOrderAddProductModalProps,
) {
    const { orderId } = useParams();
    const { handleAddProduct } = props;

    const [open, setOpen] = useState(false);
    const [isProductsLoading, setIsProductsLoading] = useState<boolean>(true);
    const [productsAutoComplete, setProductsAutoComplete] = useState<
        IAutocomplete[]
    >([]);

    const sidebar = useSelector((store: any) => store.sidebar);

    const productPresenter = makeProductsPresenter();

    const handleClose = () => {
        reset();
        setOpen(false);
    };

    const { control, setValue, watch, formState, reset } =
        useForm<IContestationAddProductForm>({
            mode: 'all',
            reValidateMode: 'onChange',
            criteriaMode: 'all',
        });
    const { isValid } = formState;

    const [selectedProduct, quantity] = watch(['product', 'quantity']);

    const getTotalValue = () => {
        if (!selectedProduct) return 'R$ --';
        return quantity && selectedProduct.price
            ? NumberHelper.makeMoney(quantity * selectedProduct.price)
            : 'R$ --';
    };

    useEffect(() => {
        if (sidebar.sidebarName === OrderContestationModals.ORDER_ADD_PRODUCT) {
            setOpen(sidebar.sidebarState.right);
        }
    }, [sidebar]);

    useEffect(() => {
        setIsProductsLoading(true);
        productPresenter
            .getProductByOrder(orderId)
            .then((res: any) => setProductsAutoComplete(res))
            .finally(() => setIsProductsLoading(false));
    }, [orderId]);

    useEffect(() => {
        setValue('quantity', '' as any);
    }, [selectedProduct]);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby={OrderContestationModals.ORDER_ADD_PRODUCT}
                aria-describedby={OrderContestationModals.ORDER_ADD_PRODUCT}
            >
                <ModalContainer
                    className={OrderContestationModals.ORDER_ADD_PRODUCT}
                >
                    <TitleContainer>
                        <TitleText style={{ marginTop: 0 }}>
                            Adicionar item
                        </TitleText>
                        <Row
                            justify="space-around"
                            onClick={() => handleClose()}
                        >
                            <CloseIcon />
                        </Row>
                    </TitleContainer>
                    <Grid columns={4} data-testid="add_product_modal" gap={4}>
                        <AutoCompleteField
                            control={control}
                            options={productsAutoComplete.filter(
                                (product: IAutocomplete) => {
                                    if (
                                        !props.products ||
                                        props.products.length === 0 ||
                                        props.products[0] === null
                                    ) {
                                        return product;
                                    }

                                    return (
                                        props.products.length > 0 &&
                                        props.products[0] !== null &&
                                        !props.products.some(
                                            productAtTable =>
                                                productAtTable !== null &&
                                                productAtTable.productId ===
                                                    Number(product.id) &&
                                                !productAtTable.removed,
                                        )
                                    );
                                },
                            )}
                            isLoading={isProductsLoading}
                            name="product"
                            label="Item"
                            dataTestId="product_input"
                            rules={{
                                required: 'Produto é obrigatório',
                            }}
                        />
                        <InputTextField
                            readOnly={!selectedProduct || isProductsLoading}
                            control={control}
                            name="quantity"
                            label="Qtde"
                            type="number"
                            placeholder="Quantidade"
                            min={1}
                            maxLength={3}
                            max={999}
                            rules={{
                                required: 'Quantidade é obrigatório',
                                maxLength: 3,
                                min: 1,
                                max: 999,
                            }}
                            dataTestId="quantity_input"
                        />
                        <InfoColumn className="add-product">
                            <p className="title">Valor unitário</p>
                            <p data-testid="add_product_modal_unitary_value">
                                {selectedProduct && selectedProduct.price
                                    ? NumberHelper.makeMoney(
                                          selectedProduct.price!,
                                      )
                                    : 'R$ --'}
                            </p>
                        </InfoColumn>
                        <InfoColumn className="add-product">
                            <p className="title">Valor total</p>
                            <p data-testid="add_product_modal_total_value">
                                {getTotalValue()}
                            </p>
                        </InfoColumn>
                    </Grid>
                    <Row justify="end" margin={{ top: '2rem' }} gap={2}>
                        <Button
                            dataTestId="add_product_modal_cancel_button"
                            onClick={handleClose}
                            className="dark"
                            text="Cancelar"
                        />
                        <Button
                            disabled={!isValid}
                            dataTestId="add_product_modal_add_button"
                            onClick={() => {
                                reset();
                                handleClose();
                                handleAddProduct(
                                    quantity,
                                    selectedProduct.price!,
                                    selectedProduct,
                                );
                            }}
                            text="Adicionar"
                        />
                    </Row>
                </ModalContainer>
            </Modal>
        </div>
    );
}
