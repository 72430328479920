import { ITableRows } from '@/domain/models';
import { TableCellMui, TableRowMui } from '@/ui/components';
import { RefObject } from 'react';

export default function StatusTableBody({
    rows,
    reference,
    isLastRow,
}: {
    rows: ITableRows[][];
    reference?: RefObject<HTMLTableRowElement> | null;
    isLastRow: boolean;
}) {
    return rows.map((row, rowIndex) => {
        const isLastItem = isLastRow && rowIndex === rows.length - 1;

        return (
            <TableRowMui key={rowIndex} ref={isLastItem ? reference : null}>
                {row.map((cell, cellIndex) => (
                    <TableCellMui
                        className={cellIndex === 0 ? 'start first' : 'end'}
                        key={cell.key + cellIndex}
                    >
                        {cell.content}
                    </TableCellMui>
                ))}
            </TableRowMui>
        );
    });
}
