import styled, { keyframes } from 'styled-components';
import { Column, Row } from '@/ui/components/layout';

const fadeIn = keyframes`
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
`;

export const FinancialCard = styled(Column)`
    background: ${props => props.theme.cardBg};
    border-radius: 16px;
    margin-bottom: 10px;
    box-shadow: ${props => props.theme.cardBoxShadow};

    &.loading {
        width: 99%;
        height: 400px;
        background: linear-gradient(
            135deg,
            rgba(32, 28, 28, 1) 20%,
            rgba(0, 0, 0, 1) 48%,
            rgba(32, 28, 28, 1) 76%
        );
        background-size: 200% 200%;
        -webkit-animation: ${fadeIn} 1s ease infinite;
        -moz-animation: ${fadeIn} 1s ease infinite;
        -o-animation: ${fadeIn} 1s ease infinite;
        animation: ${fadeIn} 1s ease infinite;
    }

    &.loading-white {
        width: 90vw;
        height: 400px;
        background: linear-gradient(
            90deg,
            #d4d4d4 2%,
            #757575 48%,
            #d4d4d4 100%
        );
        background-size: 200% 200%;
        -webkit-animation: ${fadeIn} 1s ease infinite;
        -moz-animation: ${fadeIn} 1s ease infinite;
        -o-animation: ${fadeIn} 1s ease infinite;
        animation: ${fadeIn} 1s ease infinite;
    }

    &.last {
        margin-bottom: 80px;
    }
`;

export const FinancialCardTitle = styled(Row)`
    align-items: center;
    justify-content: start;
    gap: 8px;
    padding: 20px 32px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: ${props => props.theme.secondary};

    @media (max-width: 650px) {
        padding: 14px 22px;
    }

    svg {
        fill: #ffdd3f;
    }
`;

export const TableContainer = styled.div`
    padding: 0px 32px 32px 32px;
    overflow-x: auto;

    &.financial {
        overflow-x: unset;
    }

    &.detail {
        padding: 0px 15px 15px 15px;
    }

    &.modal {
        max-height: 500px;
        td.right {
            text-align: right;
        }
    }

    @media (max-width: 650px) {
        padding: 0px 16px 16px 16px;
    }
`;

export const MobileCard = styled.div`
    background-color: ${props => props.theme.cardBg};
    border-radius: 16px;
    margin-bottom: 10px;
`;

export const MobileCardTitle = styled(Row)`
    align-items: center;
    padding: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 140%;
    color: ${props => props.theme.secondary};

    svg {
        fill: #ffdd3f;
    }
`;

export const MobileCardSection = styled.div`
    padding: 20px;

    &.financial-total {
        z-index: 2;
        background: ${props => props.theme.financialReport.financialTotal};
        border-radius: 0px 0px 16px 16px;
    }

    &.condominium-transfer {
        z-index: 2;
        background: ${props => props.theme.financialReport.condominiumTransfer};
    }

    &.financial-to-receive {
        z-index: 2;
        border-radius: 0px 0px 16px 16px;
        background: ${props => props.theme.financialReport.financialToReceive};
    }
`;

export const MobileCardSubtitle = styled.h3`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    color: ${props => props.theme.secondary};
    text-align: start;
    margin-top: 2px;
`;

export const MobileCardText = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: ${props => props.theme.tableText};
    text-align: start;
    margin: 0px;
`;

export const MobileValueTitle = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 140%;
    color: ${props => props.theme.tableText};
    text-align: start;
    margin: 0;
`;

export const TotalValue = styled.div`
    font-size: 12px;
    color: ${props => props.theme.tableText};
`;
