import { AutoCompleteField, InputTextField } from '@/ui/components';
import { Grid } from '@/ui/components/layout';
import LicensedFormComponentProps from './licensed-form-component-props';

export default function LicensedBankInformation({
    control,
    licensedPresenter,
}: LicensedFormComponentProps) {
    return (
        <>
            <h5>Dados bancários</h5>
            <Grid columns={4} gap={6} responsiveType="input">
                <InputTextField
                    control={control}
                    name="commission"
                    label="Comissão"
                    rules={{
                        required: 'Comissão é obrigatório',
                    }}
                    type="number"
                    isMonetary
                />
                <AutoCompleteField
                    control={control}
                    options={licensedPresenter.banksList}
                    name="bank"
                    label="Banco"
                    rules={{
                        required: 'Banco é obrigatório',
                    }}
                />
                <InputTextField
                    control={control}
                    name="bankAgency"
                    label="Agência"
                    rules={{
                        required: 'Agência é obrigatório',
                    }}
                />
                <InputTextField
                    control={control}
                    name="bankCC"
                    label="Número da Conta"
                    rules={{
                        required: 'Conta é obrigatório',
                    }}
                />
                <AutoCompleteField
                    control={control}
                    options={licensedPresenter.banksAccountTypesList}
                    name="bankAccountType"
                    label="Tipo de Conta"
                    rules={{
                        required: 'Tipo de conta é obrigatório',
                    }}
                />
            </Grid>
        </>
    );
}
