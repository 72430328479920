import List from '@mui/material/List';
import styled from 'styled-components';

const MobileList = styled(List)`
    z-index: 10;
    overflow-x: hidden;

    && {
        padding: 16px 32px;
    }

    .MuiSvgIcon-colorPrimary {
        transition: background-color 400ms;
        background-color: ${props => `${props.theme.primary}`};
        color: ${props => `${props.theme.primary}`};
    }

    @media (max-width: 650px) {
        && {
            padding: 0px;
        }
    }
`;

export default MobileList;
