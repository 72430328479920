import { makeRefrigeratorListPresenter } from '@/main/factories';
import IInformationInputProps from './information-inputs-props';
import { Grid } from '@/ui/components/layout';
import { AutoCompleteField, InputTextField } from '@/ui/components';

export default function RefrigeratorPdvData({
    control,
    isAllowedToEdit,
}: IInformationInputProps) {
    const refrigeratorPresenter = makeRefrigeratorListPresenter();

    return (
        <>
            <h5>Dados do pdv</h5>
            <Grid
                columns={4}
                gap={{
                    row: 6,
                    column: 4,
                }}
            >
                <AutoCompleteField
                    control={control}
                    options={refrigeratorPresenter.unityTypeOptions}
                    name="establishmentType"
                    label="Tipo de unidade"
                    isDisabled={!isAllowedToEdit}
                />
                <InputTextField
                    control={control}
                    name="unitNumberInEstablishment"
                    label="Número de unidades"
                    type="number"
                    readOnly={!isAllowedToEdit}
                />
                <AutoCompleteField
                    control={control}
                    options={refrigeratorPresenter.accessTypeOptions}
                    name="accessType"
                    label="Tipo de acesso à unidade"
                    isDisabled={!isAllowedToEdit}
                />
            </Grid>
        </>
    );
}
