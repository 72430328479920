import { DrawerStyled } from './styles';

interface IDrawer {
    role: string;
    className?: string;
    children: any;
}

export default function Drawer({ className, children }: IDrawer) {
    return <DrawerStyled className={className}>{children}</DrawerStyled>;
}
