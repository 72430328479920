import styled from 'styled-components';

const ProductImage = styled.img`
    margin-right: 10px;
    border: none;
    border-radius: 2.5px;
    position: absolute;
    left: 5px;
    top: 15px;
    width: 30px;

    & .product-detail-img {
        width: 35px;
    }

    &.stock {
        top: -8px;

        @media (max-width: 650px) {
            top: 1px;
        }
    }
`;

export default ProductImage;
