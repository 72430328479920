import { IBannerManagementForm } from '@/presentation';
import { DragDrop, ImagePreview } from '@/ui/components';
import { Column, Row } from '@/ui/components/layout';
import { Control } from 'react-hook-form';

export default function BannerImage({
    control,
    previewSource,
    handleRemoveImage,
}: {
    control: Control<IBannerManagementForm>;
    previewSource: any;
    handleRemoveImage: () => void;
}) {
    return (
        <>
            <Row gap={10}>
                <Column>
                    <h5>Imagem*</h5>
                    <DragDrop
                        fileTypes={['jpg', 'jpeg']}
                        acceptedFormatsText="Formatos aceitos: jpeg e jpg"
                        acceptedSizeText="Tamanho: 350x130"
                        control={control}
                        name="image"
                        rules={{
                            required: 'O campo imagem é obrigatório!',
                        }}
                    />
                </Column>
                <Column align="start">
                    {previewSource && <h5>Preview</h5>}
                    {previewSource && (
                        <ImagePreview
                            imageSrc={previewSource}
                            handleRemoveImage={handleRemoveImage}
                            width={532}
                            height={195}
                        />
                    )}
                </Column>
            </Row>
        </>
    );
}
