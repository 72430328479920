import {
    AutoCompleteField,
    InputPhoneNumber,
    InputTextField,
    MaskedInputTextField,
} from '@/ui/components';
import { Grid } from '@/ui/components/layout';
import LicensedFormComponentProps from './licensed-form-component-props';
import { DocumentHelper } from '@/domain/helpers';
import { IAutocomplete } from '@/domain/models';

export default function LicensedData({
    control,
    watch,
    licensedPresenter,
    franchiseeTypesAutocomplete,
}: LicensedFormComponentProps) {
    const [franchiseeType] = watch(['franchiseeType']);

    const isNotLicensed =
        (franchiseeType as IAutocomplete)?.id &&
        (franchiseeType as IAutocomplete).id !== 'LICENSED';

    return (
        <>
            <h5>Dados do licenciado</h5>
            <Grid columns={4} gap={6} responsiveType="input">
                <InputTextField
                    control={control}
                    name="name"
                    label="Nome"
                    rules={{
                        required: 'Nome do licenciado é obrigatório',
                    }}
                />
                <InputTextField
                    control={control}
                    name="companyName"
                    label="Razão Social"
                    rules={{
                        required: 'Razão social é obrigatório',
                    }}
                />
                <MaskedInputTextField
                    control={control}
                    name="cnpj"
                    label="CNPJ"
                    mask="99.999.999/9999-99"
                    rules={{
                        required: 'CNPJ é obrigatório',
                        validate: {
                            isAValidCNPJ: (cnpj: string) =>
                                DocumentHelper.isCnpjValid(cnpj) ||
                                'CNPJ inválido',
                        },
                    }}
                />
                <AutoCompleteField
                    control={control}
                    options={licensedPresenter.regimeTypesList}
                    name="regimeType"
                    label="Regime de Tributação"
                    rules={{
                        required: 'Regime de tributação é obrigatório',
                    }}
                />
                <AutoCompleteField
                    control={control}
                    options={licensedPresenter.juridicalPersonTypesList}
                    name="juridicalPerson"
                    label="Tipo de Pessoa"
                    rules={{
                        required: 'Tipo de pessoa é obrigatório',
                    }}
                />
                <InputTextField
                    control={control}
                    name="subscription"
                    label="Inscrição Estadual"
                    rules={{
                        required: 'Inscrição estadual é obrigatório',
                    }}
                />
                <InputTextField
                    control={control}
                    name="email"
                    label="Email"
                    rules={{
                        required: 'Email é obrigatório',
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: 'Email inválido',
                        },
                    }}
                />
                <InputPhoneNumber
                    control={control}
                    name="phone"
                    label="Telefone"
                    rules={{
                        required: 'Telefone é obrigatório',
                    }}
                />
                <AutoCompleteField
                    control={control}
                    options={franchiseeTypesAutocomplete.data}
                    isLoading={franchiseeTypesAutocomplete.isLoading}
                    name="franchiseeType"
                    label="Tipo de licenciado"
                    rules={{
                        required: 'Tipo de licenciado é obrigatório',
                    }}
                />
                {isNotLicensed && (
                    <InputPhoneNumber
                        control={control}
                        name="sacPhone"
                        label="Telefone SAC"
                        rules={{
                            required: 'Telefone SAC é obrigatório',
                        }}
                    />
                )}
            </Grid>
        </>
    );
}
