import { useContext, useState } from 'react';
import { useQuery } from 'react-query';

import {
    AuthenticationHelper,
    PermissionHelper,
    RequestQueryMaker,
} from '@/domain/helpers';
import makeHomeOverviewPresenter from '@/main/factories/presenters/pages/home/home-overview-presenter-factory';
import { FilterType } from '@/ui/context/context-filter/type';
import { FilterContext } from '@/ui/context/context-filter';
import { IUseOverviewQueries } from '@/ui/interfaces';

export default function useOverviewQueries(): IUseOverviewQueries {
    const { getFiltersObject } = useContext(FilterContext);
    const pageFilters: FilterType<'home_overview'> = getFiltersObject();

    const roles = AuthenticationHelper.getToken().auth;
    const permissionHelper = new PermissionHelper(roles);

    const homePresenter = makeHomeOverviewPresenter();

    const isAllowed =
        permissionHelper.isTakeAndGo() || permissionHelper.isDeveloper();
    const getLicensedIdInSessionStorage =
        window.localStorage.getItem('licensedId');

    const [isModalOpen, setIsModalOpen] = useState<boolean>(
        isAllowed && !getLicensedIdInSessionStorage,
    );

    const {
        data: overviewData = [],
        isLoading: isOverviewDataLoading,
        isFetching: isOverviewDataFetching,
    } = useQuery(
        ['overviewData', pageFilters],
        () =>
            homePresenter.getDashboardOverviewAsync(
                RequestQueryMaker(pageFilters),
            ),

        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            enabled:
                !isModalOpen &&
                'initialDate' in pageFilters &&
                'endDate' in pageFilters,
        },
    );

    return {
        isModalOpen,
        setIsModalOpen,
        overviewData,
        isOverviewDataLoading,
        isOverviewDataFetching,
        isAllowed,
    };
}
