import { IChangePasswordFormStepsProps } from '@/ui/interfaces';
import {
    ChangePasswordCpfStep,
    ChangePasswordRecoveryOptionStep,
    ChangePasswordSuccessMessageStep,
} from './steps';
import { ChangePasswordDrawing } from '../illustration';
import MessageWithDrawingStep, {
    ButtonRowTypes,
} from './steps/change-password-link-message';

export default function ChangePasswordFormSteps(
    props: IChangePasswordFormStepsProps,
): JSX.Element {
    const { control, changePasswordState, isValid } = props;

    const renderMultiStep = () => {
        switch (changePasswordState.changingPasswordStep) {
            case 1:
                return (
                    <ChangePasswordCpfStep
                        control={control}
                        isValid={isValid}
                    />
                );
            case 2:
                return (
                    <ChangePasswordRecoveryOptionStep
                        changePasswordState={changePasswordState}
                        control={control}
                        isValid={isValid}
                    />
                );
            case 3:
                return (
                    <MessageWithDrawingStep
                        drawer={<ChangePasswordDrawing />}
                        primaryMessage="O link de recuperação foi enviado."
                        secondaryMessage="Acesse a sua caixa de entrada e siga as orientações da mensagem."
                        buttonRowType={ButtonRowTypes.linkSend}
                    />
                );

            case 5:
                return <ChangePasswordSuccessMessageStep />;
            default:
                return <></>;
        }
    };

    return renderMultiStep()!;
}
