import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import { CircularProgress, CloseIcon, ModalContainer } from '@/ui/components';
import { TitleContainer } from '@/ui/layouts/header/sub-header/styles';
import { TitleText } from '@/ui/pages/user/components/styles';
import makeOrderListPresenter from '@/main/factories/presenters/pages/order/order-list-presenter-factory';
import ImageCarousel from './caorulsel';
import { Row } from '@/ui/components/layout';

export default function OrderCoolerImageModal() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [orderImages, setOrderImages] = useState<string[]>(['']);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleClose = () => setIsModalOpen(false);

    const sidebar = useSelector((store: any) => store.sidebar);

    const orderPresenter = makeOrderListPresenter();

    const getElementData = () => {
        if (sidebar && sidebar.elementData) {
            const { location, orderId } = sidebar.elementData;
            return { location, orderId };
        } else {
            return { location: '', orderId: '' };
        }
    };

    const { location, orderId } = getElementData();

    const returnOrderImages = () => {
        return orderImages && orderImages.length > 0 ? (
            <ImageCarousel media={orderImages} />
        ) : (
            <p>Nenhuma imagem encontrada</p>
        );
    };

    useEffect(() => {
        if (sidebar.sidebarName === 'order_cooler_images') {
            setIsModalOpen(sidebar.sidebarState.right);
        }
        if (orderId) {
            setIsLoading(true);
            orderPresenter.getOrderImages(orderId).then(res => {
                setOrderImages(res.medias);
                setIsLoading(false);
            });
        }
    }, [sidebar]);

    return (
        <div>
            <Modal
                open={isModalOpen}
                onClose={handleClose}
                aria-labelledby="order_cooler_images"
                aria-describedby="order_cooler_images"
                data-testid="order_cooler_images_modal"
            >
                {sidebar && sidebar.elementData ? (
                    <ModalContainer className="order-image-container">
                        <>
                            <TitleContainer>
                                <TitleText style={{ marginTop: 0 }}>
                                    #{orderId} - {location}
                                </TitleText>
                                <Row
                                    justify="space-around"
                                    data-testid="close_image_modal_button"
                                    onClick={() => handleClose()}
                                >
                                    <CloseIcon />
                                </Row>
                            </TitleContainer>
                            {isLoading ? (
                                <Row align="center" justify="center">
                                    <CircularProgress variant="indeterminate" />
                                </Row>
                            ) : (
                                returnOrderImages()
                            )}
                        </>
                    </ModalContainer>
                ) : (
                    <></>
                )}
            </Modal>
        </div>
    );
}
