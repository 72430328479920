import styled from 'styled-components';

const RatingColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-width: 292px;
`;

export default RatingColumn;
