import { IRefrigeratorFilterParams } from '@/domain/models';

const refrigeratorListDefaultValues: IRefrigeratorFilterParams = {
    code: null,
    device: null,
    location: null,
    situation: null,
    status: null,
    sortDirectionStockPercentage: null,
    licensedId: null,
    activeView: {
        id: 'stock-by-cooler',
        name: 'page',
        label: 'stock-by-cooler',
    },
    page: {
        id: '0',
        name: 'page',
        label: '0',
    },
    limit: {
        id: 25,
        name: 'limit',
        label: '25',
    },
    column: {
        id: 'id',
        name: 'column',
        label: 'id',
    },
    sort: {
        id: 'DESC',
        name: 'sort',
        label: 'DESC',
    },
};

export default refrigeratorListDefaultValues;
