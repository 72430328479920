/* eslint-disable react/prop-types */
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import ChartTooltip from './tooltip';
import { useBreakpoints } from '@/ui/helpers';

export default function ReBarChart({ barChartData }) {
    const theme = useTheme();

    function increaseArrayLength(array, desiredLength, elementToAdd) {
        while (array.length < desiredLength) {
            array.push(elementToAdd);
        }
    }

    const isDarkTheme = useSelector(store => {
        return store.toggleTheme.darkTheme;
    });

    const { isXs } = useBreakpoints();

    increaseArrayLength(
        barChartData,
        barChartData[0].barName === 'Faturamento' && !isXs ? 14 : 7,
        {},
    );

    const chartWidth = () => {
        if (isXs) {
            return barChartData.length * 47;
        }
        if (barChartData[0].barName === 'Faturamento') {
            return (barChartData.length / 14) * 1600;
        }
        return (barChartData.length / 7) * 800;
    };

    const id = Math.random().toString(36).substring(2, 15);

    return (
        <ResponsiveContainer width={chartWidth()} height="100%">
            <BarChart
                bar
                onMouseEnter={false}
                data={barChartData}
                margin={{
                    top: 5,
                    right: 5,
                    left: 5,
                    bottom: 40,
                }}
            >
                <CartesianGrid stroke={theme.chartLine} vertical={false} />
                <XAxis
                    dataKey="name"
                    tickLine={false}
                    axisLine={false}
                    style={{
                        color: '#f5f5f5',
                        fontSize: '10px',
                        fill: theme.chartLegend,
                    }}
                    angle={50}
                    tickMargin={27}
                    allowDataOverflow={true}
                    minTickGap={0}
                    interval={0}
                    padding="gap"
                    dx={20}
                />
                <YAxis
                    tickLine={false}
                    axisLine={false}
                    tickCount={5}
                    style={{
                        color: theme.chartLegend,
                        fontSize: '12px',
                        fill: theme.chartLegend,
                    }}
                />
                <Tooltip content={<ChartTooltip />} cursor={null} />
                <defs>
                    <linearGradient id={id} x1="0" y1="0" x2="0" y2="1">
                        <stop
                            offset="20%"
                            stopColor={barChartData[0].color}
                            stopOpacity={isDarkTheme ? 0.09 : 0.2}
                        />
                        <stop
                            offset="80%"
                            stopColor={barChartData[0].color}
                            stopOpacity={isDarkTheme ? 0.009 : 0.02}
                        />
                    </linearGradient>
                </defs>
                <Bar
                    radius={[5, 5, 0, 0]}
                    barSize={70}
                    dataKey="value"
                    fill={barChartData[0].color}
                    name={barChartData[0].barName}
                    background={{
                        fill: 'url(#' + id + ')',
                        radius: [5, 5, 0, 0],
                    }}
                />
            </BarChart>
        </ResponsiveContainer>
    );
}
