import { useSelector } from 'react-redux';
import StyledSvgIcon from './svg-icon.styles';
import { useTheme } from 'styled-components';
import { StoreState } from '@/ui/store/models';

export default function UserIcon() {
    const currentTheme = useTheme();
    const activeMenu = useSelector(
        (currentStore: StoreState) => currentStore.navigation.activeMenu,
    );

    return (
        <StyledSvgIcon
            width="20"
            height="18"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            color="primary"
        >
            <path
                d="M18.3334 16.5V14.8333C18.3334 13.2801 17.271 11.9751 15.8334 11.605M12.9167 1.7423C14.1383 2.23679 15 3.43443 15 4.83333C15 6.23224 14.1383 7.42988 12.9167 7.92437M14.1667 16.5C14.1667 14.9469 14.1667 14.1703 13.913 13.5577C13.5746 12.741 12.9257 12.092 12.109 11.7537C11.4964 11.5 10.7198 11.5 9.16669 11.5H6.66669C5.11355 11.5 4.33698 11.5 3.72441 11.7537C2.90765 12.092 2.25874 12.741 1.92042 13.5577C1.66669 14.1703 1.66669 14.9469 1.66669 16.5M11.25 4.83333C11.25 6.67428 9.75764 8.16667 7.91669 8.16667C6.07574 8.16667 4.58335 6.67428 4.58335 4.83333C4.58335 2.99238 6.07574 1.5 7.91669 1.5C9.75764 1.5 11.25 2.99238 11.25 4.83333Z"
                stroke={
                    activeMenu === 'user'
                        ? currentTheme.secondary
                        : currentTheme.quaternary
                }
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </StyledSvgIcon>
    );
}
