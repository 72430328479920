import { CardChart } from '@/ui/components';
import { IAverageTicketByPublic } from '@/domain/models';
import RePieChart from '@/ui/components/recharts/pie-chart';
import makeHomeOverviewPresenter from '@/main/factories/presenters/pages/home/home-overview-presenter-factory';
import { NoDataFound } from '../../components';

interface IAverageTicketByPublicProps {
    crmPieChartData: IAverageTicketByPublic[];
    isLoading: boolean;
    isDarkTheme: boolean;
}

export default function AverageTicketByPublic({
    crmPieChartData,
    isLoading,
    isDarkTheme,
}: IAverageTicketByPublicProps): JSX.Element {
    const homePresenter = makeHomeOverviewPresenter();
    return (
        <CardChart
            title="Ticket médio por público"
            isDarkTheme={isDarkTheme}
            isLoading={isLoading}
        >
            {!isLoading && crmPieChartData && (
                <NoDataFound
                    data={homePresenter.checkIfChartContainData(
                        crmPieChartData as any,
                        'pieChart',
                    )}
                >
                    <RePieChart pieChartProps={crmPieChartData} isCrm />
                </NoDataFound>
            )}
        </CardChart>
    );
}
