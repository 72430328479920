import { SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import { NotificationContext } from '@/main';
import makeUserPresenter from '@/main/factories/presenters/pages/user/user-presenter-factory';
import { CloseIcon, InputTextField, ModalContainer } from '@/ui/components';
import { IChangeActiveInputs } from './change-active-input';
import {
    ButtonContainer,
    CancelButton,
    SaveUserButton,
    TitleContainer,
    TitleText,
} from './styles';
import { Row, Column } from '@/ui/components/layout';
import { FilterContext } from '@/ui/context/context-filter';
import { useContext, useEffect, useState } from 'react';

export default function ChangeActiveModal() {
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const { handleSubmit, control } = useForm<IChangeActiveInputs>();
    const onSubmit: SubmitHandler<IChangeActiveInputs> = data =>
        handleSave(data);

    const { successNotification } = useContext(NotificationContext);

    const userPresenter = makeUserPresenter();

    const { setShouldReRenderTable } = useContext(FilterContext);

    const refetchTable = () => {
        setShouldReRenderTable(true);
    };

    const handleSave = (data: any) => {
        userPresenter.changeActive(
            sidebar.elementId,
            sidebar.elementData.status,
            data,
            successNotification,
            handleClose,
            refetchTable,
        );
    };

    const sidebar = useSelector((store: any) => store.sidebar);

    useEffect(() => {
        if (sidebar.sidebarName === 'change_activate_user') {
            setOpen(sidebar.sidebarState.right);
        }
    }, [sidebar]);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalContainer>
                    <TitleContainer>
                        <TitleText>
                            Informe o motivo para efetuar a inativação
                        </TitleText>
                        <ButtonContainer onClick={() => handleClose()}>
                            <CloseIcon />
                        </ButtonContainer>
                    </TitleContainer>
                    <Column as="form" onSubmit={handleSubmit(onSubmit)}>
                        <InputTextField
                            control={control}
                            name="reason"
                            label="Motivo"
                            type="textarea"
                            dataTestId="reason"
                        />
                        <Row justify="flex-end" margin={{ top: '80px' }}>
                            <CancelButton onClick={() => handleClose()}>
                                Cancelar
                            </CancelButton>
                            <SaveUserButton type="submit">
                                Salvar
                            </SaveUserButton>
                        </Row>
                    </Column>
                </ModalContainer>
            </Modal>
        </div>
    );
}
