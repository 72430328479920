import {
    IRefrigeratorStock,
    IRefrigeratorStockDetail,
    IRefrigeratorStockProduct,
} from '@/domain/models';
import { IRefrigeratorRepositoryPort } from '@/infra';
import { IRefrigeratorStockPresenter } from '@/ui/pages/';

export default class RefrigeratorStockPresenter
    implements IRefrigeratorStockPresenter
{
    constructor(
        private readonly refrigeratorRepository: IRefrigeratorRepositoryPort,
    ) {}

    async getRefrigeratorStockAsync(params: string): Promise<any> {
        const response: any =
            await this.refrigeratorRepository.getCoolerStockListAsync(params);

        const stockData: IRefrigeratorStock[] = response.data!.content;
        const pageable: any = response.data!.pageable;
        const totalElements: any = response.data!.totalElements;
        const totalPages: any = response.data!.totalPages;

        return { stockData, pageable, totalElements, totalPages };
    }

    async getRefrigeratorStockDetailAsync(
        coolerId: string,
    ): Promise<IRefrigeratorStockDetail> {
        const response: any =
            await this.refrigeratorRepository.getCoolerStockDetailAsync(
                coolerId,
            );

        const stockDetailData: IRefrigeratorStockDetail = response.data!;

        return stockDetailData;
    }

    async getRefrigeratorProductStockAsync(params: string): Promise<any> {
        const response: any =
            await this.refrigeratorRepository.getRefrigeratorProductStock(
                params,
            );

        if (!response.success) {
            return {
                stockData: [],
                pageable: {},
                totalElements: 0,
                totalPages: 0,
            };
        }

        const stockData: IRefrigeratorStockProduct[] = response.data!.content;
        const pageable: any = response.data!.pageable;
        const totalElements: any = response.data!.totalElements;
        const totalPages: any = response.data!.totalPages;

        return { stockData, pageable, totalElements, totalPages };
    }

    async getRefrigeratorProductStockByProductIdkAsync(
        productId: number,
    ): Promise<IRefrigeratorStockDetail> {
        const response: any =
            await this.refrigeratorRepository.getRefrigeratorProductStockDetail(
                productId,
            );

        const productStockDetailData: IRefrigeratorStockDetail = response.data!;

        return productStockDetailData;
    }

    async getRefrigeratorDetailedXlsAsync(
        params: string,
        successNotification: any,
        errorNotification: any,
    ): Promise<IRefrigeratorStockDetail> {
        const response =
            await this.refrigeratorRepository.exportDetailedXls<any>(params);

        if (response.success) {
            return successNotification(response.data);
        }

        return errorNotification(response.data.message);
    }

    getIndicatorColor = (productPercentage: number) => {
        if (productPercentage <= 49) {
            return '#ff5630';
        } else if (productPercentage >= 50 && productPercentage <= 69) {
            return '#ffab00';
        } else if (productPercentage >= 69) {
            return '#36b37e';
        } else {
            return '#ffff';
        }
    };

    getClassColor = (productPercentage: number) => {
        if (productPercentage <= 49) {
            return 'red';
        } else if (productPercentage >= 50 && productPercentage <= 69) {
            return 'yellow';
        } else if (productPercentage >= 69) {
            return 'green';
        } else {
            return '';
        }
    };
}
