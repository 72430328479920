import {
    ContextGlobalSearchMap,
    ContextSearchPlaceholder,
} from '@/domain/models';
import { SearchIcon } from '../../illustration';
import defaultInputStyles from '../input-styles';
import StyledTextField from '../styled-text-field';
import { Control, Controller } from 'react-hook-form';

interface ISearchBarProps {
    activePage: string;
    control: Control<any>;
    onEnter: () => void;
}

export default function SearchBar({
    activePage,
    control,
    onEnter,
}: ISearchBarProps): JSX.Element {
    const { inputStyles } = defaultInputStyles(false);

    return (
        <Controller
            control={control}
            name={'search' + ContextGlobalSearchMap.get(activePage)!}
            render={({ field }) => (
                <StyledTextField
                    id="search-bar"
                    {...field}
                    sx={{
                        ...inputStyles,
                        width: '100%',
                    }}
                    data-testid="search_bar"
                    InputProps={{
                        startAdornment: <SearchIcon />,
                    }}
                    placeholder={ContextSearchPlaceholder.get(activePage)}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            onEnter();
                        }
                    }}
                />
            )}
        />
    );
}
