import { useSelector } from 'react-redux';
import StyledSvgIcon from './svg-icon.styles';
import { useTheme } from 'styled-components';
import { StoreState } from '@/ui/store/models';

export default function PromotionIcon() {
    const currentTheme = useTheme();
    const activeMenu = useSelector(
        (currentStore: StoreState) => currentStore.navigation.activeMenu,
    );

    return (
        <StyledSvgIcon
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.49935 7.49935H7.50768M12.4993 12.4993H12.5077M13.3327 6.66602L6.66602 13.3327M6.11082 3.1816C6.78068 3.12814 7.41661 2.86473 7.92807 2.42887C9.12162 1.41173 10.8771 1.41173 12.0706 2.42887C12.5821 2.86473 13.218 3.12814 13.8879 3.1816C15.4511 3.30634 16.6924 4.54764 16.8171 6.11082C16.8706 6.78068 17.134 7.41661 17.5698 7.92807C18.587 9.12162 18.587 10.8771 17.5698 12.0706C17.134 12.5821 16.8706 13.218 16.8171 13.8879C16.6924 15.4511 15.4511 16.6924 13.8879 16.8171C13.218 16.8706 12.5821 17.134 12.0706 17.5698C10.8771 18.587 9.12162 18.587 7.92807 17.5698C7.41661 17.134 6.78068 16.8706 6.11082 16.8171C4.54764 16.6924 3.30634 15.4511 3.1816 13.8879C3.12814 13.218 2.86473 12.5821 2.42887 12.0706C1.41173 10.8771 1.41173 9.12162 2.42887 7.92807C2.86473 7.41661 3.12814 6.78068 3.1816 6.11082C3.30634 4.54764 4.54764 3.30634 6.11082 3.1816ZM7.91602 7.49935C7.91602 7.72947 7.72947 7.91602 7.49935 7.91602C7.26923 7.91602 7.08268 7.72947 7.08268 7.49935C7.08268 7.26923 7.26923 7.08268 7.49935 7.08268C7.72947 7.08268 7.91602 7.26923 7.91602 7.49935ZM12.916 12.4993C12.916 12.7295 12.7295 12.916 12.4993 12.916C12.2692 12.916 12.0827 12.7295 12.0827 12.4993C12.0827 12.2692 12.2692 12.0827 12.4993 12.0827C12.7295 12.0827 12.916 12.2692 12.916 12.4993Z"
                stroke={
                    activeMenu === 'promotion'
                        ? currentTheme.secondary
                        : currentTheme.quaternary
                }
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </StyledSvgIcon>
    );
}
