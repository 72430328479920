import { useInView } from 'react-intersection-observer';
import { useQueries } from 'react-query';
import { RequestQueryMaker } from '@/domain/helpers';
import { CardChart } from '@/ui/components';
import { RelineChart } from '@/ui/components/recharts';
import RePieChart from '@/ui/components/recharts/pie-chart';
import { useEffect, useState } from 'react';
import RowsProps from './row-props';
import { NoDataFound } from '@/ui/pages/home/components';

export default function SecondRow({
    homePresenter,
    isDarkTheme,
    filterParams,
}: RowsProps) {
    const [refetchTotalUserVsActive, setRefetchTotalUserVsActive] =
        useState(true);
    const [refetchNetTotalSales, setRefetchNetTotalSales] = useState(true);

    useEffect(() => {
        setRefetchNetTotalSales(true);
        setRefetchTotalUserVsActive(true);
    }, [filterParams]);

    const { ref, inView } = useInView({
        skip: !refetchTotalUserVsActive && !refetchNetTotalSales,
    });

    useEffect(() => {
        setRefetchTotalUserVsActive(true);
        setRefetchNetTotalSales(true);
    }, [filterParams]);

    const [
        {
            data: totalUserVsActive,
            isLoading: isTotalUserVsActiveLoading,
            isFetching: isTotalUserVsActiveFetching,
        },
        {
            data: netTotalSales,
            isLoading: isNetTotalSalesLoading,
            isFetching: isNetTotalSalesFetching,
        },
    ] = useQueries([
        {
            queryKey: ['totalUserVsActive', filterParams],
            queryFn: () =>
                homePresenter.getOverviewTotalUsersVsActiveAsync(
                    RequestQueryMaker(homePresenter.getParams(filterParams)),
                ),
            enabled: inView,
            onSuccess: () => setRefetchTotalUserVsActive(false),
        },
        {
            queryKey: ['netTotalSales', filterParams],
            queryFn: () =>
                homePresenter.getOverviewTotalNetSalesAsync(
                    RequestQueryMaker(homePresenter.getParams(filterParams)),
                ),
            enabled: inView,
            onSuccess: () => setRefetchNetTotalSales(false),
        },
    ]);

    const isNetTotalSalesLoadingOrFetching =
        isNetTotalSalesLoading || isNetTotalSalesFetching;
    const isTotalUserVsActiveLoadingOrFetching =
        isTotalUserVsActiveLoading || isTotalUserVsActiveFetching;

    return (
        <>
            <CardChart
                reference={ref}
                title="Total de usuários ativos e novos"
                isDarkTheme={isDarkTheme}
                isLoading={
                    isNetTotalSalesLoadingOrFetching ||
                    isTotalUserVsActiveLoadingOrFetching
                }
                position="3-1"
            >
                <NoDataFound
                    data={
                        Array.isArray(totalUserVsActive) &&
                        totalUserVsActive.length > 0
                    }
                    isLoading={isTotalUserVsActiveLoadingOrFetching}
                >
                    <RelineChart LineChartProps={totalUserVsActive} />
                </NoDataFound>
            </CardChart>
            <CardChart
                reference={ref}
                title="Detalhe faturamento líquido"
                isDarkTheme={isDarkTheme}
                isLoading={isNetTotalSalesLoadingOrFetching}
                position="3-2"
            >
                <NoDataFound
                    data={homePresenter.checkIfChartContainData(
                        netTotalSales!,
                        'pieChart',
                    )}
                    isLoading={isNetTotalSalesLoadingOrFetching}
                >
                    <RePieChart pieChartProps={netTotalSales} />
                </NoDataFound>
            </CardChart>
        </>
    );
}
