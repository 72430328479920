export default function THeadRefundTable() {
    return (
        <>
            <thead style={{ marginTop: 25 }}>
                <tr className="table-head">
                    <th>Pedido</th>
                    <th>Status</th>
                    <th>Data do pedido</th>
                    <th>Valor</th>
                    <th>Desconto</th>
                    <th>Cartão</th>
                    <th>PIX</th>
                    <th>Wallet</th>
                    <th>Reembolso</th>
                </tr>
            </thead>
        </>
    );
}
