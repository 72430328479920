import { useTheme } from 'styled-components';
import StyledSvgIcon from './svg-icon.styles';

export default function ColapseIcon(): JSX.Element {
    const theme = useTheme();

    return (
        <StyledSvgIcon
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="chevron-down">
                <path
                    id="Icon"
                    d="M5.5 7.5L10.5 12.5L15.5 7.5"
                    stroke={theme.secondary}
                    strokeWidth="1.75"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </StyledSvgIcon>
    );
}
