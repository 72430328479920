import { Box } from '@mui/material';
import styled from 'styled-components';

const DefaultStyledBox = styled(Box)`
    background-color: ${props => props.theme.primary};

    .MuiList-root {
        border-radius: 0 !important;
        background-color: ${props => props.theme.primary};
        width: 100vw;
    }

    .MuiPaper-root {
        background-color: ${props => props.theme.primary};
        width: 100vw;
    }

    .MuiDrawer-paper {
        width: 96px;
        border-right: 0.5px solid ${props => `${props.theme.lines}`};
        width: 100vw;
    }

    @media (max-width: 650px) {
        width: 100vw;
        background-color: ${props => props.theme.primary};
        height: 100vh;
    }
`;

export default DefaultStyledBox;
