import { ProductImage, ProductName } from '../styles';
import { makeRefrigeratorStockPresenter } from '@/main/factories';
import { ProgressBar } from '@/ui/components';
import { useTheme } from 'styled-components';

interface IRefrigeratorStockTableBodyProps {
    product: any;
}

export default function RefrigeratorStockTableBody({
    product,
}: IRefrigeratorStockTableBodyProps): JSX.Element {
    const refrigeratorStockPresenter = makeRefrigeratorStockPresenter();

    const currentTheme = useTheme();

    return (
        <tr key={product.product}>
            <td className="start first">
                <ProductImage src={product.productImg} alt="Cerveja" />
                <ProductName>{product.productName}</ProductName>
            </td>
            <td className="end">{product.lastRepositionQuantity}</td>
            <td className="end">{product.consumedQuantity}</td>
            <td className="end">
                {product.expectedStock != null ? product.expectedStock : '--'}
            </td>

            <td className="end">{product.quantity}</td>
            <td className="end stock first">
                <ProgressBar
                    trackColor={currentTheme.lines}
                    indicatorColor={refrigeratorStockPresenter.getIndicatorColor(
                        product.stockPercentage,
                    )}
                    progress={+product.stockPercentage}
                />
            </td>
        </tr>
    );
}
