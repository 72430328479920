import React from 'react';
import { SubmitHandler, UseFormHandleSubmit } from 'react-hook-form';
import { SwipeableDrawer } from '@mui/material';
import { Close } from '@mui/icons-material';
import {
    IBannerFilterInput,
    IOrderFilterInput,
    IPromotionFilterInput,
    IRefrigeratorFilterParams,
    IUserFilterInputs,
} from '@/domain/models';
import {
    CloseButton,
    Button,
    FilterContainer,
    FilterSidebarHeader,
    Drawer,
} from '@/ui/components';
import { StyledDivider } from '../../illustration';
import { Column, Row } from '../../layout';

interface IFilter {
    children: React.ReactNode;
    filter: ISidebarState;
    setFilter: React.Dispatch<React.SetStateAction<ISidebarState>>;
    handleSubmit: UseFormHandleSubmit<
        | IUserFilterInputs
        | IPromotionFilterInput
        | IOrderFilterInput
        | IRefrigeratorFilterParams
        | IBannerFilterInput
    >;
    onSubmit: SubmitHandler<any>;
    disabled?: boolean;
}

type ISidebarState = {
    right: boolean;
};

export default function Filter({
    children,
    filter,
    setFilter,
    handleSubmit,
    onSubmit,
    disabled,
}: IFilter): JSX.Element {
    type Anchor = 'right';

    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event &&
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }

            setFilter({ ...filter, [anchor]: open });
        };

    const list = (anchor: Anchor) => (
        <Drawer role="filter" className="filter">
            <FilterSidebarHeader>
                Filtro
                <Row align="center" justify="center">
                    <CloseButton
                        data-testid="close-button"
                        onClick={toggleDrawer(anchor, false)}
                    >
                        <Close />
                    </CloseButton>
                </Row>
            </FilterSidebarHeader>
            <StyledDivider />
            <FilterContainer
                as="form"
                onSubmit={handleSubmit(onSubmit)}
                height="90%"
                justify="space-between"
            >
                <Column
                    padding={{
                        top: 2,
                        right: 2,
                        bottom: 4,
                    }}
                    margin={{
                        bottom: 4,
                    }}
                    overflow={{
                        y: 'auto',
                    }}
                    gap={6}
                >
                    {children}
                </Column>
                <Button
                    dataTestId="filter-button"
                    type="submit"
                    text="Filtrar"
                    disabled={disabled}
                />
            </FilterContainer>
        </Drawer>
    );

    return (
        <>
            {(['right'] as const).map((anchor, index) => (
                <SwipeableDrawer
                    key={anchor + index}
                    anchor={anchor}
                    open={filter[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    onOpen={toggleDrawer(anchor, true)}
                >
                    {list(anchor)}
                </SwipeableDrawer>
            ))}
        </>
    );
}
