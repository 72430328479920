import { useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import makeUserPresenter from '@/main/factories/presenters/pages/user/user-presenter-factory';
import { NotificationContext } from '@/main';
import { CircularProgress, CloseIcon, ModalContainer } from '@/ui/components';
import { TitleContainer } from '@/ui/layouts/header/sub-header/styles';
import {
    CancelButton,
    SaveUserButton,
    TitleText,
} from '@/ui/pages/user/components/styles';
import { Column, Row } from '@/ui/components/layout';
import { FilterContext } from '@/ui/context/context-filter';

export default function ResetUserPasswordModal() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [updatedPassword, setUpdatedPassword] = useState('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { setShouldReRenderTable } = useContext(FilterContext);

    const handleClose = () => {
        setIsModalOpen(false);
        setUpdatedPassword('');
        updatedPassword && setShouldReRenderTable(true);
    };

    const { successNotification } = useContext(NotificationContext);

    const userPresenter = makeUserPresenter();

    const sidebar = useSelector((store: any) => store.sidebar);

    const getElementData = () => {
        if (sidebar && sidebar.elementData) {
            const { username, email, userId } = sidebar.elementData;
            return { username, email, userId };
        } else {
            return { username: '', email: '', userId: '' };
        }
    };

    const { username, email, userId } = getElementData();

    const renderPasswordText = () => {
        return (
            <Row>
                {updatedPassword ? (
                    <>
                        <strong>Senha temporária:</strong> {updatedPassword}
                    </>
                ) : (
                    'Deseja enviar senha temporária? Esta ação não pode ser revertida!'
                )}
            </Row>
        );
    };

    const renderButtons = () => {
        return (
            <Row width="100%" justify="end" margin={{ top: '10px' }}>
                {updatedPassword ? (
                    <>
                        <SaveUserButton
                            onClick={() => handleClose()}
                            type="submit"
                        >
                            Confirmar
                        </SaveUserButton>
                    </>
                ) : (
                    <>
                        <CancelButton onClick={() => handleClose()}>
                            Cancelar
                        </CancelButton>
                        <SaveUserButton onClick={handleSubmit} type="submit">
                            Salvar
                        </SaveUserButton>
                    </>
                )}
            </Row>
        );
    };

    const renderResetPasswordTitle = () => {
        if (updatedPassword) {
            return 'Senha temporária enviada';
        } else if (isLoading) {
            return 'Enviando senha...';
        } else {
            return 'Envio de senha temporária';
        }
    };

    const handleSubmit = () => {
        setIsLoading(true);
        userPresenter.resetPasswordAsync(userId).then((res: any) => {
            successNotification('Senha enviada com sucesso!');
            setUpdatedPassword(res.code);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        if (sidebar.sidebarName === 'reset_user_password') {
            setIsModalOpen(sidebar.sidebarState.right);
        }
    }, [sidebar]);

    return (
        <div>
            <Modal
                open={isModalOpen}
                onClose={handleClose}
                aria-labelledby="reset_user_password"
                aria-describedby="reset_user_password"
                data-testid="reset_user_password_modal"
            >
                {sidebar && sidebar.elementData ? (
                    <ModalContainer className="reset_user_password">
                        <>
                            <TitleContainer>
                                <TitleText style={{ marginTop: 0 }}>
                                    {renderResetPasswordTitle()}
                                </TitleText>
                                <Row
                                    data-testid="close_image_modal_button"
                                    onClick={() => handleClose()}
                                >
                                    <CloseIcon />
                                </Row>
                            </TitleContainer>
                            <Column align="start" justify="space-around">
                                {isLoading ? (
                                    <Row align="center" justify="center">
                                        <CircularProgress variant="indeterminate" />
                                    </Row>
                                ) : (
                                    <Column gap={4}>
                                        <Row gap={2}>
                                            <strong>Usuário:</strong>
                                            {username}
                                        </Row>
                                        <Row gap={2}>
                                            <strong>E-mail:</strong> {email}
                                        </Row>
                                        {renderPasswordText()}
                                    </Column>
                                )}
                                {!isLoading && renderButtons()}
                            </Column>
                        </>
                    </ModalContainer>
                ) : (
                    <></>
                )}
            </Modal>
        </div>
    );
}
