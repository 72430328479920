import { IAutocomplete, IUserSaveInputs } from '@/domain/models';
import {
    AutoCompleteField,
    MultipleValuesAutoCompleteField,
} from '@/ui/components';
import { Grid } from '@/ui/components/layout';
import { useMemo } from 'react';
import { Control } from 'react-hook-form';
import { UseQueryResult } from 'react-query';

interface IUserPermissions {
    control: Control<IUserSaveInputs>;
    licensedsQuery: UseQueryResult<IAutocomplete[]>;
    rolesOptions: IAutocomplete[];
}

export default function UserPermissions({
    control,
    licensedsQuery,
    rolesOptions,
}: IUserPermissions) {
    const { data: licenseds = [], isLoading: isLicensedsLoading } =
        licensedsQuery;

    const fixedUserRole = useMemo(() => {
        if (rolesOptions[0].id === 'USER') {
            return rolesOptions[0];
        }

        return rolesOptions.find(role => role.id === 'USER')!;
    }, [rolesOptions]);

    return (
        <>
            <h5>Permissionamento</h5>
            <Grid
                columns={4}
                gap={{
                    row: 6,
                    column: 4,
                }}
                margin={{ bottom: '1.67em' }}
            >
                <MultipleValuesAutoCompleteField
                    fixedValues={[fixedUserRole]}
                    control={control}
                    options={rolesOptions}
                    name="profiles"
                    label="Perfil"
                    placeholder="Selecione um perfil"
                    limitTags={3}
                />
                <AutoCompleteField
                    control={control}
                    options={licenseds}
                    isLoading={isLicensedsLoading}
                    name="franchiseeId"
                    label="Licenciado"
                />
            </Grid>
        </>
    );
}
