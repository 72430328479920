import StyledListItemTextProps from '@/ui/interfaces/props/navigation/styled-list-item-text-props';
import { ListItemText } from '@mui/material';
import styled from 'styled-components';

const StyledListItemText = styled(ListItemText)(
    ({ isActive, theme }: StyledListItemTextProps) => ({
        margin: '0px!important',
        fontSize: '14px',
        animationName: 'onOpen',
        color: isActive ? theme.secondary : theme.quaternary,
        animationDuration: '200ms',
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'start',

        '.MuiListItemText-root': {
            marginTop: '0px',
            marginBottom: '0px',
        },

        '.MuiTypography-root': {
            fontSize: '14px',
        },

        ':hover': {
            color: theme.secondary,
        },

        '@media(max-width: 650px)': {
            alignSelf: 'center',
        },
    }),
);

export default StyledListItemText;
