export interface TablePaginationProps {
    enablePagination?: boolean;
    pageIndex?: number;
    rowsPerPage?: number;
    rowsPerPageOptions?: number[];
}

export const TablePaginationPropsDefault = (options?: TablePaginationProps) => {
    return {
        pageIndex: options?.pageIndex ?? 0,
        rowsPerPage: options?.rowsPerPage ?? 10,
        enablePagination: options?.enablePagination ?? true,
        rowsPerPageOptions: options?.rowsPerPageOptions ?? [5, 10, 25, 30],
    } as TablePaginationProps;
};
