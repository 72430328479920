import { useContext, useEffect } from 'react';
import { ContextType, TableIcons } from '@/domain/models';
import { makeLicensedTablePresenter } from '@/main/factories';
import selectNavigationAction from '@/ui/store/modules/app/navigation/actions';
import { store } from '@/ui/store/config';
import { TableServerSide } from '@/ui/components/table';
import { FilterContext } from '@/ui/context/context-filter';
import Default from '../default';
import { NavigationAppState } from '@/ui/store/models';

export default function LicensedList() {
    const { setActivePage } = useContext(FilterContext);

    const licensedTablePresenter = makeLicensedTablePresenter();

    useEffect(() => {
        setActivePage(ContextType.licensed_list);
        store.dispatch(
            selectNavigationAction(
                new NavigationAppState(
                    ContextType.licensed_list,
                    ContextType.licensed_list,
                    TableIcons.LICENSED,
                ),
            ),
        );
    }, []);

    return (
        <Default contextType={ContextType.licensed_list}>
            <TableServerSide
                presenter={licensedTablePresenter}
                rowProps={{
                    enableRowActions: true,
                    identityPropertyName: 'licensedId',
                    rowActions: licensedTablePresenter.makeRowActions(),
                }}
                paginationProps={{
                    enablePagination: true,
                    pageIndex: 0,
                    rowsPerPage: 25,
                    rowsPerPageOptions: [25, 50, 75, 100],
                }}
            />
        </Default>
    );
}
