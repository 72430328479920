import { ITableHead, ITableHeadColumn } from '@/ui/interfaces';
import TableHeadItem from './table-head-item';

export default function TableHead({
    setOrdination,
    ordination,
    columns,
    hasCondition,
    hasOrdination,
}: ITableHead): JSX.Element {
    return (
        <thead>
            {Boolean(hasCondition && hasOrdination) && (
                <tr className="table-head">
                    {columns.map(
                        (column: ITableHeadColumn, index) =>
                            Boolean(column.condition) && (
                                <TableHeadItem
                                    key={column.columnName + index}
                                    setOrdination={setOrdination}
                                    ordination={ordination}
                                    columnName={column.columnName}
                                    columnLabel={column.columnLabel}
                                    hasOrdination={true}
                                    className={`${
                                        column.className ? column.className : ''
                                    }`}
                                />
                            ),
                    )}
                </tr>
            )}
            {Boolean(!hasCondition && hasOrdination) && (
                <tr className="table-head">
                    {columns.map((column: ITableHeadColumn, index) => (
                        <TableHeadItem
                            key={column.columnName + index}
                            setOrdination={setOrdination}
                            ordination={ordination}
                            columnName={column.columnName}
                            columnLabel={column.columnLabel}
                            hasOrdination={true}
                            className={`${
                                column.className ? column.className : ''
                            }`}
                        />
                    ))}
                </tr>
            )}
            {Boolean(!hasCondition && !hasOrdination) && (
                <tr className="table-head">
                    {columns.map((column: ITableHeadColumn, index) => (
                        <TableHeadItem
                            key={column.columnName + index}
                            columnName={column.columnName}
                            columnLabel={column.columnLabel}
                            className={`${
                                column.className ? column.className : ''
                            }`}
                            hasOrdination={false}
                        />
                    ))}
                </tr>
            )}
        </thead>
    );
}
