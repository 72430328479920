import { Grid } from '@/ui/components/layout';
import IInformationInputProps from './information-inputs-props';
import { InputPhoneNumber, InputTextField } from '@/ui/components';

export default function RefrigeratorContactData({
    control,
}: IInformationInputProps) {
    return (
        <>
            <h5>Contato do PDV</h5>
            <Grid
                columns={4}
                gap={{
                    row: 6,
                    column: 4,
                }}
            >
                <InputTextField
                    control={control}
                    name="contactName"
                    label="Nome do contato"
                />
                <InputPhoneNumber
                    control={control}
                    name="phone"
                    label="Telefone"
                />
            </Grid>
        </>
    );
}
