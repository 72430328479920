import styled from 'styled-components';

export const StyledNfceTable = styled.table`
    color: ${props => props.theme.secondary};
    margin-top: 5px;

    thead {
        tr {
            th {
                &.align-start {
                    text-align: start;
                }

                &.align-end {
                    text-align: end;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                border-bottom: 1px solid ${props => props.theme.tableBorder};
                padding: 10px 0px;

                &.align-start {
                    text-align: start;
                }

                &.align-end {
                    text-align: end;
                }
            }
        }
    }
`;

export const DownloadNfceButton = styled.button`
    border-radius: 100%;
    border: none;
    background-color: #ffdd3f;
    cursor: pointer;
    padding: 8px;
`;
