import { NumberHelper } from '@/domain/helpers';
import { IRefundTable } from '@/domain/models';

export default function TBodyRefundTable({ row }: { row: IRefundTable }) {
    return (
        <tr className="content-row">
            <td className="product-code">{row.order}</td>
            <td>{row.status}</td>
            <td>{row.orderDate}</td>
            <td className="right">{NumberHelper.makeMoney(row.totalValue)}</td>
            <td className="right">
                {NumberHelper.makeMoney(row.discountValue)}
            </td>
            <td className="right">{NumberHelper.makeMoney(row.cardValue)}</td>
            <td className="right">{NumberHelper.makeMoney(row.pixValue)}</td>
            <td className="right">{NumberHelper.makeMoney(row.walletValue)}</td>
            <td className="right">{NumberHelper.makeMoney(row.refundValue)}</td>
        </tr>
    );
}
