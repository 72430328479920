import { CoolerIcon, WebcamIcon, WifiIcon } from '@/ui/components';
import { Column, Grid, Row } from '@/ui/components/layout';
import { CardLabel, CardValue } from '../styles';
import IndicatorCard from '../indicator-card';
import { IRefrigeratorStatusIndicators } from '@/domain/models';

interface IIndicatorsCardsProps {
    isDarkTheme: boolean;
    indicators: IRefrigeratorStatusIndicators;
    isLoading: boolean;
}

export default function IndicatorsCards({
    isDarkTheme,
    indicators,
    isLoading,
}: IIndicatorsCardsProps) {
    const checkValues = (value: number | null) => {
        if (value === null || value === undefined) {
            return '-';
        }

        return value;
    };

    const loadingClass =
        isLoading && !isDarkTheme
            ? 'default-loading'
            : isLoading && isDarkTheme
            ? 'dark-default-loading'
            : '';

    return (
        <Grid
            columns={4}
            gap={2}
            margin={{ bottom: 4, right: '8px' }}
            responsiveType="input"
            sm={{
                columns: 2,
            }}
        >
            <IndicatorCard
                loadingClass={loadingClass}
                headerIcon={<CoolerIcon />}
                headerLabel="Geladeiras"
                content={
                    <Column justify="end" align="start" gap={1}>
                        <CardLabel>Total de geladeiras</CardLabel>
                        <CardValue className="success">
                            {checkValues(indicators.countCoolers)}
                        </CardValue>
                    </Column>
                }
                hasModal
            />
            <IndicatorCard
                loadingClass={loadingClass}
                headerIcon={<WifiIcon />}
                headerLabel="Conexão"
                content={
                    <Row justify="space-between" align="start" width="100%">
                        <Column justify="end" align="start" gap={1} grow={1}>
                            <CardLabel>Online</CardLabel>
                            <CardValue className="success">
                                {checkValues(indicators.countOnlineCoolers)}
                            </CardValue>
                        </Column>
                        <Column justify="end" align="start" gap={1} grow={1}>
                            <CardLabel>Offline</CardLabel>
                            <CardValue className="danger">
                                {checkValues(indicators.countOfflineCoolers)}
                            </CardValue>
                        </Column>
                    </Row>
                }
                hasModal
            />
            <IndicatorCard
                loadingClass={loadingClass}
                headerIcon={<WebcamIcon />}
                headerLabel="Câmera"
                content={
                    <Row justify="space-between" align="start" width="100%">
                        <Column justify="end" align="start" gap={1} grow={1}>
                            <CardLabel>Sem problema</CardLabel>
                            <CardValue className="success">
                                {checkValues(indicators.countCameras)}
                            </CardValue>
                        </Column>
                        <Column justify="end" align="start" gap={1} grow={1}>
                            <CardLabel>Com problema</CardLabel>
                            <CardValue className="danger">
                                {checkValues(indicators.countCameraProblems)}
                            </CardValue>
                        </Column>
                    </Row>
                }
            />
            <IndicatorCard
                loadingClass={loadingClass}
                headerIcon={<CoolerIcon />}
                headerLabel="Portas"
                content={
                    <Row justify="space-between" align="start" width="100%">
                        <Column justify="end" align="start" gap={1} grow={1}>
                            <CardLabel>Sem problema</CardLabel>
                            <CardValue className="success">
                                {checkValues(indicators.countLock)}
                            </CardValue>
                        </Column>
                        <Column justify="end" align="start" gap={1} grow={1}>
                            <CardLabel>Com problema</CardLabel>
                            <CardValue className="danger">
                                {checkValues(indicators.countLockProblems)}
                            </CardValue>
                        </Column>
                    </Row>
                }
            />
        </Grid>
    );
}
