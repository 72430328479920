import { useTheme } from 'styled-components';
import StyledSvgIcon from './svg-icon.styles';

export default function DarkModeIcon() {
    const currentTheme = useTheme();

    return (
        <StyledSvgIcon
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_582_149271)">
                <path
                    d="M9.99935 1.66602V3.33268M9.99935 16.666V18.3327M3.33268 9.99935H1.66602M5.26111 5.26111L4.0826 4.0826M14.7376 5.26111L15.9161 4.0826M5.26111 14.741L4.0826 15.9195M14.7376 14.741L15.9161 15.9195M18.3327 9.99935H16.666M14.166 9.99935C14.166 12.3005 12.3005 14.166 9.99935 14.166C7.69816 14.166 5.83268 12.3005 5.83268 9.99935C5.83268 7.69816 7.69816 5.83268 9.99935 5.83268C12.3005 5.83268 14.166 7.69816 14.166 9.99935Z"
                    stroke={currentTheme.secondary}
                    strokeWidth="1.75"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                />
            </g>
            <defs>
                <clipPath id="clip0_582_149271">
                    <rect width="20" height="20" fill="none" />
                </clipPath>
            </defs>
        </StyledSvgIcon>
    );
}
