import { StringHelper } from '@/domain/helpers';
import {
    DashboardHttpEndpoint,
    HttpMethod,
    HttpRequest,
    HttpResponse,
} from '@/domain/models/services';
import { IDashboardRepositoryPort, IHttpServicePort } from '@/infra/ports';

export class DashboardRepositoryAdapter implements IDashboardRepositoryPort {
    constructor(private readonly httpServicePort: IHttpServicePort) {}

    async getByTypeAsync<T>(type: string): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: StringHelper.format(
                DashboardHttpEndpoint.DASHBOARD_PATH_GET_BY_TYPE,
                type,
            ),
            method: HttpMethod.get,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getActiveUsersAsync<T>(params?: string): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url:
                DashboardHttpEndpoint.DASHBOARD_BASE +
                '/' +
                'active-users' +
                params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getSoldAndTotalProducts<T>(
        params?: string | undefined,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url:
                DashboardHttpEndpoint.DASHBOARD_BASE +
                '/' +
                'sold-products-and-variation' +
                params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getRevenue<T>(params?: string | undefined): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url:
                DashboardHttpEndpoint.DASHBOARD_BASE + '/' + 'revenue' + params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getRevenueDetails<T>(
        params?: string | undefined,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url:
                DashboardHttpEndpoint.DASHBOARD_BASE +
                '/' +
                'details-gross-amount' +
                params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getTopSellingProducts<T>(
        params?: string | undefined,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url:
                DashboardHttpEndpoint.DASHBOARD_BASE +
                '/v2/' +
                'top-selling-products' +
                params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getTransactionByDayOfWeek<T>(
        params?: string | undefined,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url:
                DashboardHttpEndpoint.DASHBOARD_BASE +
                '/v2/' +
                'transactions-by-day-of-week' +
                params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getTotalUsersVsActive<T>(
        params?: string | undefined,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url:
                DashboardHttpEndpoint.DASHBOARD_BASE +
                '/' +
                'active-and-new-users' +
                params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getGrossAmountByCooler<T>(
        params?: string | undefined,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url:
                DashboardHttpEndpoint.DASHBOARD_BASE +
                '/' +
                'gross-amount-by-cooler' +
                params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getNetRevenueDetails<T>(
        params?: string | undefined,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url:
                DashboardHttpEndpoint.DASHBOARD_BASE +
                '/' +
                'details-net-amount' +
                params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getAverageTicketByUser<T>(
        params?: string | undefined,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url:
                DashboardHttpEndpoint.DASHBOARD_BASE +
                '/' +
                'average-ticket-by-user-and-variation' +
                params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getGrossValueVsNetValue<T>(
        params?: string | undefined,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url:
                DashboardHttpEndpoint.DASHBOARD_BASE +
                '/' +
                'revenue-vs-products-last-six-months' +
                params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async exportBestSellingProductsReport<T>(
        params?: string | undefined,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: DashboardHttpEndpoint.TOP_SELLING_PRODUCTS_REPORTS + params,
            method: HttpMethod.get,
            auth: true,
        });

        return this.httpServicePort.request<T>(request);
    }

    async hasExportBestSellingProductsReportInProgress<T>(): Promise<
        HttpResponse<T>
    > {
        const request = new HttpRequest({
            url: DashboardHttpEndpoint.TOP_SELLING_PRODUCTS_REPORT_IN_PROGRESS,
            method: HttpMethod.get,
            auth: true,
        });

        return this.httpServicePort.request<T>(request);
    }
}
