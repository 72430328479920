import styled from 'styled-components';

export const IconButtonContainer = styled.button`
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    margin-right: 15px;
    margin-left: 15px;
`;
