import { Column } from '@/ui/components/layout';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
`;

const RefrigeratorCard = styled(Column)`
    padding: 32px;
    background-color: ${props => props.theme.cardBg};
    box-shadow: ${props => props.theme.cardBoxShadow};
    border-radius: 16px;
    height: fit-content !important;
    overflow-x: auto;

    &.default-loading {
        min-height: 400px !important;
        background: linear-gradient(
            90deg,
            #d4d4d4 2%,
            #757575 48%,
            #d4d4d4 100%
        );
        background-size: 300% 300%;
        -webkit-animation: ${fadeIn} 1s ease infinite;
        -moz-animation: ${fadeIn} 1s ease infinite;
        -o-animation: ${fadeIn} 1s ease infinite;
        animation: ${fadeIn} 1s ease infinite;
    }

    &.dark-default-loading {
        min-height: 400px;
        background: linear-gradient(
            135deg,
            rgba(32, 28, 28, 1) 20%,
            rgba(0, 0, 0, 1) 48%,
            rgba(32, 28, 28, 1) 76%
        );
        background-size: 200% 200%;
        -webkit-animation: ${fadeIn} 1s ease infinite;
        -moz-animation: ${fadeIn} 1s ease infinite;
        -o-animation: ${fadeIn} 1s ease infinite;
        animation: ${fadeIn} 1s ease infinite;
    }

    @media (max-width: 650px) {
        padding: 20px 10px 0px 10px;
    }
`;

export default RefrigeratorCard;
