import { Container } from './styles';

interface IconButtonProps {
    className?: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    children: React.ReactNode;
    disabled?: boolean;
    color?: 'primary' | 'secondary' | 'default' | 'inherit';
}

export default function IconButton(props: IconButtonProps): JSX.Element {
    const { onClick, className, children, disabled, color = 'primary' } = props;
    return (
        <Container
            className={className}
            onClick={event => onClick(event)}
            disabled={disabled}
            color={color}
        >
            {children}
        </Container>
    );
}
