import StyledSvgIcon from './svg-icon.styles';

export default function PasswordInputIcon() {
    return (
        <StyledSvgIcon
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.95245 3.2436C9.29113 3.19353 9.64051 3.16667 10.0003 3.16667C14.2545 3.16667 17.0461 6.9207 17.9839 8.40569C18.0974 8.58542 18.1542 8.67528 18.1859 8.81389C18.2098 8.91799 18.2098 9.08222 18.1859 9.18631C18.1541 9.32492 18.097 9.41538 17.9827 9.59631C17.7328 9.99179 17.3518 10.5476 16.8471 11.1504M5.6036 4.59586C3.80187 5.81808 2.57871 7.51615 2.01759 8.4044C1.90357 8.58489 1.84656 8.67514 1.81478 8.81373C1.79091 8.91783 1.7909 9.08203 1.81476 9.18613C1.84652 9.32473 1.90328 9.41459 2.01678 9.59432C2.95462 11.0793 5.74618 14.8333 10.0003 14.8333C11.7157 14.8333 13.1932 14.223 14.4073 13.3972M2.50035 1.5L17.5003 16.5M8.23258 7.23223C7.78017 7.68464 7.50035 8.30964 7.50035 9C7.50035 10.3807 8.61963 11.5 10.0003 11.5C10.6907 11.5 11.3157 11.2202 11.7681 10.7678"
                stroke="#0C0407"
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
                color="#ffff"
            />
        </StyledSvgIcon>
    );
}
