import { ILineBarChart } from '@/domain/models';

export default class LineBarChart implements ILineBarChart {
    name: string;
    barValue: number | string;
    lineValue: number | string;
    barName: string;
    lineName: string;
    barColor: string;
    lineColor: string;

    constructor(
        name: string,
        barValue: string | number,
        lineValue: string | number,
        barName: string,
        lineName: string,
        barColor: string,
        lineColor: string,
    ) {
        this.name = name;
        this.lineName = lineName;
        this.barName = barName;
        this.lineValue = lineValue;
        this.barValue = barValue;
        this.barColor = barColor;
        this.lineColor = lineColor;
    }
}
