import { combineReducers } from 'redux';
import { StoreState } from '../models';
import {
    navigation,
    backdropApp,
    toggleTheme,
    toggleLoading,
    orderColumns,
    refrigeratorStatusColumns,
    filter,
    sidebar,
    user,
    selectedRows,
} from '@/ui/store/modules/app';

const rootReducer = (): any =>
    combineReducers<StoreState>({
        navigation,
        backdropApp,
        toggleTheme,
        toggleLoading,
        orderColumns,
        refrigeratorStatusColumns,
        filter,
        sidebar,
        user,
        selectedRows,
    });

export default rootReducer;
