import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useTheme } from 'styled-components';
import { Typography } from '@mui/material';

interface StyledTabsProps {
    children?: React.ReactNode;
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => {
    return (
        <Tabs
            {...props}
            TabIndicatorProps={{
                children: <span className="MuiTabs-indicatorSpan" />,
            }}
        />
    );
})({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        left: '-10px',
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 180,
        width: '100%',
        backgroundColor: '#fff',
        left: '-10px',
    },
});

interface StyledTabProps {
    label: string;
}

const StyledTab = styled((props: StyledTabProps) => (
    <Tab disableRipple {...props} />
))(({ theme }) => ({
    textTransform: 'none',
    fontWeight: 400,
    fontSize: '0.875rem',
    marginRight: theme.spacing(1),
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
        color: '#fff',
    },
    '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
}));

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {Boolean(value === index) && (
                <Box sx={{ p: 1 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default function DarkNav({ value, setValue, children }: any) {
    const currentTheme = useTheme();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box
                sx={{
                    bgcolor: currentTheme.primary,
                    borderBottom: '1px solid ' + currentTheme.lines,
                    width: '100%',
                }}
            >
                <StyledTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="styled tabs example"
                >
                    <StyledTab label="Informações do cooler" />
                    <StyledTab label="Produtos do cooler" />
                    {/* <StyledTab label="Configurações da geladeira" /> */}
                </StyledTabs>
            </Box>
            <Box sx={{ p: 1 }} />
            <TabPanel value={value} index={0}>
                {children}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {children}
            </TabPanel>
            {/* <TabPanel value={value} index={2}>
                {children}
            </TabPanel> */}
        </Box>
    );
}
