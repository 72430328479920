import { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { UserRoles } from '@/domain/models';
import UserPerfilLabelStyles from './styles';

interface IUserPerfilLabel {
    roles: string[];
}

export default function UserPerfilLabel({ roles }: IUserPerfilLabel) {
    const [perfil, setPerfil] = useState('');

    const { formStyles, selectStyles, menuItemStyles } =
        UserPerfilLabelStyles();

    useEffect(() => {
        setPerfil(roles[0]);
    }, [roles]);

    return (
        <div>
            {roles.length === 1 ? (
                UserRoles.get(roles[0])
            ) : (
                <FormControl sx={formStyles}>
                    <Select
                        sx={selectStyles}
                        value={perfil}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        {roles.map((role, index) => (
                            <MenuItem
                                key={role + index}
                                sx={menuItemStyles}
                                onClick={e => e.preventDefault()}
                                value={role}
                            >
                                {UserRoles.get(role)!}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
        </div>
    );
}
