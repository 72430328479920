import {
    ContextPaginationFilters,
    TranslateFilters,
    getRequiredFiltersByPermission,
} from '@/domain/models';
import { useContext } from 'react';
import { FilterContext } from '../context/context-filter';
import { IFilterOption } from '../context/context-filter/type';

export default function useFilterSelected() {
    const { getFilters, activePage } = useContext(FilterContext);

    const filtersPage = getFilters();

    function hasNonNullProperty(obj: any) {
        for (const key in obj) {
            if (
                obj[key] !== null &&
                !String(obj[key]).includes(
                    'page' || 'limit' || 'column' || 'order' || 'sort',
                )
            ) {
                if (key in obj && obj[key] !== null) {
                    return true;
                }
            }
        }
        return false;
    }

    const isFilterRequired = (filterKey: string) => {
        const ContextRequiredFilters = getRequiredFiltersByPermission();
        const requiredFilters = ContextRequiredFilters.get(activePage);

        const isRequired = requiredFilters
            ? requiredFilters!.includes(filterKey)
            : false;

        return isRequired!;
    };

    const isFilterValueNotNullAndNotEmpty = (filters: any): boolean => {
        return (
            Object.values(filters).filter(
                (filter: any) =>
                    !ContextPaginationFilters.get(
                        Array.isArray(filter?.name)
                            ? filter?.name[0]
                            : filter?.name,
                    ) &&
                    filter != null &&
                    filter.id != null &&
                    filter.id != '' &&
                    filter.label != undefined,
            ).length > 0
        );
    };

    const isCleanFilterButtonVisible = () => {
        return (
            Object.values(filtersPage).filter(
                (filter: any) =>
                    !ContextPaginationFilters.get(
                        Array.isArray(filter?.name)
                            ? filter?.name[0]
                            : filter?.name,
                    ) &&
                    filter != null &&
                    filter.id != null &&
                    filter.id != '' &&
                    filter.label != undefined &&
                    !isFilterRequired(filter?.name),
            ).length > 0
        );
    };

    const shouldRenderFilterItem = (filter: IFilterOption) => {
        return (
            !ContextPaginationFilters.get(
                Array.isArray(filter?.name) ? filter?.name[0] : filter?.name,
            ) &&
            filter != null &&
            filter.id != null &&
            filter.id != '' &&
            filter.label != undefined
        );
    };

    const returnTranslationOrLabel = (
        filterValue: string,
    ): string | undefined => {
        return TranslateFilters.get(filterValue)
            ? TranslateFilters.get(filterValue)
            : filterValue;
    };

    return {
        hasNonNullProperty,
        isFilterRequired,
        isCleanFilterButtonVisible,
        isFilterValueNotNullAndNotEmpty,
        returnTranslationOrLabel,
        shouldRenderFilterItem,
    };
}
