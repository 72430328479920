import HttpEndpoint from '../http-endpoint';

export default class OrderHttpEndpoint {
    static ORDER_BASE = `${HttpEndpoint.API_VERSION}/order-payments`;
    static ORDER_DETAIL = `${OrderHttpEndpoint.ORDER_BASE}/`;
    static ORDER_REPORT_DETAILED = `${OrderHttpEndpoint.ORDER_BASE}/reports/detailed`;
    static ORDER_REPORT_SIMPLE = `${OrderHttpEndpoint.ORDER_BASE}/reports/simple`;
    static ORDER_EXPORT_DETAILED_XLS = `${OrderHttpEndpoint.ORDER_REPORT_DETAILED}/xls`;
    static ORDER_EXPORT_SIMPLE_XLS = `${OrderHttpEndpoint.ORDER_REPORT_SIMPLE}/xls`;
    static ORDER_EXPORT_SIMPLE_PDF = `${OrderHttpEndpoint.ORDER_REPORT_SIMPLE}/pdf`;
    static ORDER_DEADBEAT_REPORT = `${OrderHttpEndpoint.ORDER_BASE}/reports/deadbeat`;
    static ORDER_NFCE_XLS = `${HttpEndpoint.API_VERSION}/licensed/nfce/backup`;
    static ORDER_VISUALIZE_NFC = `${HttpEndpoint.API_VERSION}/nfc/`;
    static ORDER_VISUALIZE_INVOICE = `${OrderHttpEndpoint.ORDER_BASE}/invoice/`;
    static ORDER_BASE_API = `${HttpEndpoint.API_VERSION}/order-payments/`;
    static COOLER_AUTOCOMPLETE = `${HttpEndpoint.API_VERSION}/coolers/autocomplete`;
    static ORDER_REFUND = `${HttpEndpoint.API_VERSION}/order-payments/refund`;
    static ORDER_REFUND_CALCULATE = `${this.ORDER_REFUND}/calculate`;
    static ORDER_REFUND_APPLY = `${this.ORDER_REFUND}/apply`;
    static ORDER_REPROCESS = `${HttpEndpoint.API_VERSION}/order-payments/reprocess/`;
}
