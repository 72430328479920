import {
    Autocomplete,
    AutocompleteRenderGetTagProps,
    Chip,
} from '@mui/material';
import { DocumentHelper } from '@/domain/helpers';
import { IMultipleValuesInput } from '@/ui/interfaces';
import { Controller } from 'react-hook-form';
import defaultInputStyles from '../input-styles';
import StyledTextField from '../styled-text-field';

export default function MultipleValuesInput({
    control,
    label,
    name,
    isCpf = false,
    defaultValue,
    rules,
    isDisabled = false,
}: IMultipleValuesInput) {
    const { inputStyles, chipStyles, textFieldStyles } =
        defaultInputStyles(isDisabled);

    const renderChip = (
        value: any[],
        getTagProps: AutocompleteRenderGetTagProps,
    ) => {
        if (Array.isArray(value) && value.length > 0) {
            return value.map((option, index) => (
                <Chip
                    {...getTagProps({ index })}
                    key={`${option}-${index}`}
                    sx={chipStyles}
                    color={
                        DocumentHelper.checkCpf(option) ? 'default' : 'error'
                    }
                    variant="outlined"
                    label={DocumentHelper.makeMaskCpf(option)}
                />
            ));
        }

        return null;
    };

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field, fieldState: { error } }) => (
                <Autocomplete
                    sx={inputStyles}
                    multiple
                    options={[]}
                    defaultValue={defaultValue}
                    freeSolo
                    {...field}
                    onChange={(_, data) => {
                        if (data.length < 1) return field.onChange([]);
                        if (isCpf) {
                            return field.onChange(
                                data.map((item: string) =>
                                    DocumentHelper.makeMaskCpf(item),
                                ),
                            );
                        }
                        return field.onChange(data);
                    }}
                    renderTags={renderChip}
                    renderInput={params => (
                        <StyledTextField
                            sx={textFieldStyles}
                            error={!!error}
                            helperText={error ? error?.message : null}
                            {...params}
                            label={label}
                        />
                    )}
                />
            )}
        />
    );
}
