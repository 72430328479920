import { ILicensedFilterParams } from '@/presentation/models/licensed/licensed-filter-params';

const licensedDefaultValue: ILicensedFilterParams = {
    licensedCnpj: null,
    licensedPhone: null,
    licensedName: null,
    page: {
        id: '0',
        name: 'page',
        label: '0',
    },
    limit: {
        id: 25,
        name: 'limit',
        label: '25',
    },
    column: {
        id: 'licensedId',
        name: 'column',
        label: 'licensedId',
    },
    sort: {
        id: 'DESC',
        name: 'sort',
        label: 'DESC',
    },
};

export default licensedDefaultValue;
