import { FileUploader } from 'react-drag-drop-files';
import {
    DragDropContainer,
    DragTextContainer,
    UploadIconContainer,
    UploadText,
} from './styles';
import UploadIcon from '../../illustration/icons/upload-icon';
import { Controller, RegisterOptions } from 'react-hook-form';

interface IDragDropsProps {
    fileTypes: string[];
    acceptedFormatsText: string;
    control: any;
    name: string;
    rules?: RegisterOptions;
    acceptedSizeText?: string;
}

export default function DragDrop({
    fileTypes,
    acceptedFormatsText,
    control,
    name,
    rules,
    acceptedSizeText,
}: IDragDropsProps): JSX.Element {
    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field: { onChange }, fieldState: { error } }) => (
                <>
                    <DragDropContainer className={error ? 'error' : ''}>
                        <FileUploader
                            sx={{ zIndex: 1, position: 'absolute' }}
                            handleChange={(file: any) => {
                                return onChange(file);
                            }}
                            name="file"
                            types={fileTypes}
                        />
                        <DragTextContainer>
                            <UploadIconContainer>
                                <UploadIcon />
                            </UploadIconContainer>
                            <UploadText>
                                Arraste e solte ou{' '}
                                <span>busque pelo arquivo</span>
                            </UploadText>
                            <UploadText
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <div>{acceptedFormatsText}</div>
                                <div>{acceptedSizeText}</div>
                            </UploadText>
                        </DragTextContainer>
                    </DragDropContainer>
                    {error && (
                        <p
                            style={{
                                color: '#fc4503',
                                textAlign: 'start',
                            }}
                        >
                            {error?.message}
                        </p>
                    )}
                </>
            )}
        />
    );
}
