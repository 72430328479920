import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { TableIcons } from '@/domain/models';
import { StoreState } from '@/ui/store/models';
import StyledSvgIcon from './svg-icon.styles';

export default function HomeIcon() {
    const currentTheme = useTheme();
    const activeMenu = useSelector(
        (currentStore: StoreState) => currentStore.navigation.activeMenu,
    );

    return (
        <StyledSvgIcon
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="bar-chart-04">
                <path
                    id="Icon"
                    d="M2.5 9.16667L2.5 17.5M12.5 9.16667L12.5 17.5M7.5 2.5L7.5 17.5M17.5 2.5V17.5"
                    stroke={
                        activeMenu === TableIcons.HOME
                            ? currentTheme.secondary
                            : currentTheme.quaternary
                    }
                    strokeWidth="1.75"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </StyledSvgIcon>
    );
}
