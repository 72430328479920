import { AlignType } from './align-type';
import { RenderComponentType } from './render-component-type';

type RenderTypeTitle<T> = (rowData: T) => string;

export default class ColumnModel<T> {
    name: string;
    property: string;
    sorting: boolean;
    align?: AlignType;
    width?: string | number;
    renderComponent: RenderComponentType<T>;
    renderTitleCustom?: RenderTypeTitle<T>;

    constructor(
        name: string,
        property: string,
        {
            sorting = true,
            align = 'left' as AlignType,
            width = 'unset',
        }: {
            sorting?: boolean;
            align?: AlignType;
            width?: string | number;
        },
    ) {
        this.name = name;
        this.property = property;
        this.sorting = sorting;
        this.align = align;
        this.width = width;
        this.renderComponent = (value: any) => <>{value}</>;
        this.renderTitleCustom = undefined;
    }

    static build<T>(name: string, property: string): ColumnModel<T> {
        return new ColumnModel<T>(name, property, {});
    }

    withRender(render: RenderComponentType<T>): ColumnModel<T> {
        this.renderComponent = render;
        return this;
    }

    withRenderTitleCustom(
        renderTitleCustom: RenderTypeTitle<T>,
    ): ColumnModel<T> {
        this.renderTitleCustom = renderTitleCustom;
        return this;
    }
}
