import styled from 'styled-components';

export const RadioStyle = {
    color: '#FFCD00',
    '&.Mui-checked': {
        color: '#FFCD00',
    },
};

export const BpIcon = styled.span(() => ({
    borderRadius: '50%',
    width: 17,
    height: 17,
    boxShadow: '#000',
    backgroundColor: '#fff',
    backgroundImage: '#000',
    border: '3px solid #D1D1D1',
    '.Mui-focusVisible &': {
        outline: '0px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: '#fff',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background: '#000',
    },
}));

export const BpCheckedIcon = styled(BpIcon)({
    border: '3px solid #FFCD00',
    backgroundColor: '#FFCD00',
    backgroundImage:
        'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 17,
        height: 17,
        backgroundImage: 'radial-gradient(#000,#000 32%,transparent 40%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#FFCD00',
    },
});
