import { HttpRequest } from '@/domain/models/services';
import { HttpResponse, IRefrigeratorProductsUpdate } from '@/domain/models';
import { HttpMethod } from '../../../domain/models/services/http/http-method';
import RefrigeratorHttpEndpoint from '@/domain/models/services/http/http-endpoints/refrigerator-http-endpoint';
import { StringHelper } from '@/domain/helpers';
import { IHttpServicePort, IRefrigeratorRepositoryPort } from '@/infra/ports';

export class RefrigeratorRepositoryAdapter
    implements IRefrigeratorRepositoryPort
{
    constructor(private readonly httpServicePort: IHttpServicePort) {}

    async getCoolerListAsync<T>(params: string): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: RefrigeratorHttpEndpoint.REFRIGERATOR_LIST + params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getCoolerAsync<T>(coolerId: string): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: RefrigeratorHttpEndpoint.REFRIGERATOR_LIST + '/' + coolerId,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getCoolerStockListAsync<T>(params: string): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: RefrigeratorHttpEndpoint.REFRIGERATOR_LIST_STOCK + params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getCoolerStockDetailAsync<T>(
        coolerId: string,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url:
                RefrigeratorHttpEndpoint.REFRIGERATOR_LIST_STOCK +
                '/' +
                coolerId,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getRefrigeratorProductAsync<T>(
        params: string,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: RefrigeratorHttpEndpoint.REFRIGERATOR_LIST + params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getByTypeAsync<T>(type: string): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: StringHelper.format(
                RefrigeratorHttpEndpoint.USER_GET_BY_TYPE,
                type,
            ),
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getCoolerAutoComplete<T>(params?: string): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: RefrigeratorHttpEndpoint.COOLER_AUTOCOMPLETE + params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getProductsByCooler<T>(coolerId: string): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url:
                RefrigeratorHttpEndpoint.PRODUCT_BASE_BY_COOLER +
                coolerId +
                '/products',
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getAllProducts<T>(): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: RefrigeratorHttpEndpoint.PRODUCT_ALL,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getProductsByRefrigeratorId<T>(
        coolerId: string,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: RefrigeratorHttpEndpoint.PRODUCT_ALL + '/mix/' + coolerId,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async updateRefrigerator<T>(
        data: any,
        refrigeratorId: string,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url:
                RefrigeratorHttpEndpoint.REFRIGERATOR_LIST +
                '/' +
                refrigeratorId,
            method: HttpMethod.put,
            auth: true,
            body: data,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async createRefrigerator<T>(data: any): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: RefrigeratorHttpEndpoint.REFRIGERATOR_LIST,
            method: HttpMethod.post,
            auth: true,
            body: data,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async updateRefrigeratorProduct<T>(
        data: IRefrigeratorProductsUpdate | any,
        refrigeratorId: number,
        productId: number,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url:
                RefrigeratorHttpEndpoint.REFRIGERATOR_LIST +
                '/' +
                refrigeratorId +
                '/products/' +
                productId,
            method: HttpMethod.put,
            auth: true,
            body: data,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async deleteRefrigeratorProduct<T>(
        refrigeratorId: number,
        productId: number,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url:
                RefrigeratorHttpEndpoint.REFRIGERATOR_LIST +
                '/' +
                refrigeratorId +
                '/products/' +
                productId,
            method: HttpMethod.delete,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async addRefrigeratorProduct<T>(
        data: IRefrigeratorProductsUpdate | any,
        refrigeratorId: number,
        productId: number,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url:
                RefrigeratorHttpEndpoint.REFRIGERATOR_LIST +
                '/' +
                refrigeratorId +
                '/products/' +
                productId,
            method: HttpMethod.post,
            auth: true,
            body: data,
        });

        return this.httpServicePort.request<T>(request);
    }

    async exportDetailedXls<T>(params: string): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url:
                RefrigeratorHttpEndpoint.REFRIGERATOR_STOCK_DETAILED_XLS +
                params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getRefrigeratorProductStock<T>(
        params: string,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: RefrigeratorHttpEndpoint.REFRIGERATOR_PRODUCT_STOCK + params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }
    async getRefrigeratorProductStockDetail<T>(
        productId: number,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url:
                RefrigeratorHttpEndpoint.REFRIGERATOR_PRODUCT_STOCK +
                '/' +
                productId,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async updateProductValue<T>(
        productId: string,
        body: any,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url:
                RefrigeratorHttpEndpoint.PRODUCT_BASE_BY_COOLER +
                'products/' +
                productId +
                '/all',
            method: HttpMethod.put,
            auth: true,
            body,
        });

        return await this.httpServicePort.request<T>(request);
    }
}
