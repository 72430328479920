import { useContext } from 'react';
import { useQuery } from 'react-query';
import { RequestQueryMaker } from '@/domain/helpers';
import { ContextType } from '@/domain/models';
import { makeRefrigeratorStockPresenter } from '@/main/factories';
import { FilterContext } from '@/ui/context/context-filter';
import { FilterType } from '@/ui/context/context-filter/type';
import { RefrigeratorStockTable } from './components';
import Default from '../../default';
import RefrigeratorStockProduct from './components/refrigerator-stock-product';
import { RefrigeratorContainer } from './styles';

export default function RefrigeratorStock() {
    const refrigeratorStockPresenter = makeRefrigeratorStockPresenter();

    const {
        setActivePage,
        getFiltersObject,
        getFilters,
        updateFilters,
        shouldReRenderTable,
        setShouldReRenderTable,
    } = useContext(FilterContext);
    const pageFilters = getFiltersObject();
    const filtersPage: FilterType<'refrigerator_stock'> = getFilters();

    const handleChangePage = (
        _: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        updateFilters({
            ...filtersPage,
            page: {
                id: newPage.toString(),
                name: 'page',
                label: newPage.toString(),
            },
        });
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        updateFilters(
            {
                ...filtersPage,
                limit: {
                    id: parseInt(event.target.value, 10),
                    name: 'limit',
                    label: parseInt(event.target.value, 10),
                },
            },
            true,
        );
    };

    const {
        data: refrigeratorStock,
        isLoading: isRefrigeratorStockLoading,
        isFetching: isRefrigeratorStockFetching,
    } = useQuery(
        ['refrigeratorStock', pageFilters],
        () =>
            refrigeratorStockPresenter.getRefrigeratorStockAsync(
                RequestQueryMaker({
                    ...pageFilters,
                }),
            ),
        {
            enabled:
                pageFilters.activeView === 'stock-by-cooler' &&
                shouldReRenderTable,
            onSuccess: () => {
                setShouldReRenderTable(false);
            },
        },
    );

    const {
        data: refrigeratorProductStock,
        isLoading: isRefrigeratorProductStockLoading,
        isFetching: isRefrigeratorProductStockFetching,
        refetch: refetchRefrigeratorProductStock,
    } = useQuery(
        ['refrigeratorProductStock', pageFilters],
        () =>
            refrigeratorStockPresenter.getRefrigeratorProductStockAsync(
                RequestQueryMaker({
                    ...pageFilters,
                }),
            ),
        {
            enabled:
                pageFilters.activeView === 'stock-by-product' &&
                shouldReRenderTable,
            onSuccess: () => {
                setShouldReRenderTable(false);
            },
        },
    );

    return (
        <Default contextType={ContextType.refrigerator_stock}>
            <RefrigeratorContainer>
                {pageFilters.activeView === 'stock-by-cooler' ? (
                    <RefrigeratorStockTable
                        refrigeratorStock={refrigeratorStock}
                        isRefrigeratorStockLoading={
                            isRefrigeratorStockLoading ||
                            isRefrigeratorStockFetching
                        }
                        pageFilters={pageFilters}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        setActivePage={setActivePage}
                    />
                ) : (
                    <RefrigeratorStockProduct
                        data={refrigeratorProductStock}
                        loading={
                            isRefrigeratorProductStockLoading ||
                            isRefrigeratorProductStockFetching
                        }
                        refetchData={refetchRefrigeratorProductStock}
                        pageFilters={pageFilters}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        setActivePage={setActivePage}
                    />
                )}
            </RefrigeratorContainer>
        </Default>
    );
}
