import { useContext, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { SubmitHandler, useForm } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
    AuthenticationHelper,
    DateHelper,
    PermissionHelper,
    RequestQueryMaker,
} from '@/domain/helpers';
import { IAutocomplete, IPromotionFilterInput } from '@/domain/models';
import { PromotionFilterParams } from '@/presentation';
import { makePromotionListPresenter } from '@/main/factories/presenters/pages/promotion';
import { makeRefrigeratorListPresenter } from '@/main/factories';
import { FilterContext } from '@/ui/context/context-filter';
import {
    InputTextField,
    Filter,
    AutoCompleteField,
    DateTimePickerField,
} from '@/ui/components';
import { IFilterDrawer } from '@/ui/interfaces';
import 'dayjs/locale/pt-br';
import { DateTitle } from './styles';

export default function PromotionFilter({ filter, setFilter }: IFilterDrawer) {
    const { getFilters, resetFilter, setResetFilter, updateFilters } =
        useContext(FilterContext);

    const filtersPage = getFilters();

    const queryClient = useQueryClient();

    const licensedAutoCompleteData = queryClient.getQueryData<
        Array<IAutocomplete>
    >('licensedAutoComplete');

    const promotionPresenter = makePromotionListPresenter();
    const refrigeratorListPresenter = makeRefrigeratorListPresenter();

    const { data: products = [], isLoading: isProductsLoading } = useQuery(
        'productAutoComplete',
        () => promotionPresenter.getProductsAsync(),
    );

    const { data: categories = [], isLoading: isCategoriesLoading } = useQuery(
        'categoryAutoComplete',
        () => promotionPresenter.getCategoriesAsync(),
    );

    const token = AuthenticationHelper.getToken();
    const permissionHelper = new PermissionHelper(token.auth);
    const isAllowed =
        permissionHelper.isTakeAndGo() || permissionHelper.isDeveloper();

    const resetValues = {
        promotionName: filtersPage?.promotionName
            ? filtersPage?.promotionName.id
            : '',
        useType: filtersPage?.useType ? filtersPage?.useType : null,
        id: filtersPage?.id ? filtersPage?.id.id : '',
        promotionType: filtersPage?.promotionType
            ? filtersPage?.promotionType
            : null,
        promotionCategory: filtersPage?.promotionCategory
            ? filtersPage?.promotionCategory
            : null,
        promotionProduct: filtersPage?.promotionProduct
            ? filtersPage?.promotionProduct
            : null,
        licensedId: filtersPage?.licensedId ? filtersPage?.licensedId : null,
        coolerId: filtersPage?.coolerId ? filtersPage?.coolerId : null,
        status: filtersPage?.status ? filtersPage?.status : null,
        promotionStartDate: filtersPage?.promotionStartDate
            ? DateHelper.formatDateHoursFromRequest(
                  filtersPage?.promotionStartDate.id,
              )
            : null,
        promotionEndDate: filtersPage?.promotionEndDate
            ? DateHelper.formatDateHoursFromRequest(
                  filtersPage?.promotionEndDate.id,
              )
            : null,
    };

    const { handleSubmit, control, reset, watch, setValue } =
        useForm<IPromotionFilterInput>({
            mode: 'all',
            defaultValues: resetValues,
        });

    const submitHandler = (data: IPromotionFilterInput) => {
        const promotionFilterParams = new PromotionFilterParams(
            data.promotionName ? data.promotionName : '',
            data.promotionType ? data.promotionType.id : '',
            data.promotionCategory ? data.promotionCategory.id : null,
            data.promotionProduct ? data.promotionProduct.id : null,
            data.status ? data.status.id : '',
            data.promotionStartDate
                ? DateHelper.formatDateHours(data.promotionStartDate)
                : null,
            data.promotionEndDate
                ? DateHelper.formatDateHours(data.promotionEndDate)
                : null,
            data.promotionStartDate
                ? DateHelper.formatDateHours(data.promotionStartDate)
                : null,
            data.promotionEndDate
                ? DateHelper.formatDateHours(data.promotionEndDate)
                : null,
            data.promotionCategory ? data.promotionCategory.label : null,
            data.promotionProduct ? data.promotionProduct.label : null,
            data.promotionType ? data.promotionType.label : '',
            data.licensedId ? data.licensedId.id : '',
            data.coolerId ? data.coolerId.id : '',
            data.id ? data.id : '',
            data.useType ? data.useType.id : '',
            data.licensedId ? data.licensedId.label : '',
            data.coolerId ? data.coolerId.label : '',
        );

        updateFilters(
            {
                ...filtersPage,
                ...promotionFilterParams,
            },
            true,
        );
        setFilter({ right: false });
        reset();
    };

    const onSubmit: SubmitHandler<IPromotionFilterInput> = (data: any) =>
        submitHandler(data);

    const [
        promotionCategory,
        promotionProduct,
        dateBegin,
        dateEnd,
        licensedId,
    ] = watch([
        'promotionCategory',
        'promotionProduct',
        'promotionStartDate',
        'promotionEndDate',
        'licensedId',
    ]);

    const shouldDisableCategoryFilterInput = () => {
        if (promotionProduct != null && 'id' in promotionProduct) {
            return promotionProduct?.id != null;
        } else {
            return !!promotionProduct;
        }
    };

    const shouldDisableProductFilter = () => {
        if (promotionCategory != null && 'id' in promotionCategory) {
            return promotionCategory?.id != null;
        } else {
            return !!promotionCategory;
        }
    };

    const {
        data: coolerAutoComplete,
        isLoading: isCoolerAutoCompleteLoading,
        isFetching: isCoolerAutoCompleteFetching,
    } = useQuery(['coolerAutoComplete', licensedId], () =>
        refrigeratorListPresenter.getCoolerFilterAutocompleteAsync(
            RequestQueryMaker({
                licensedId: licensedId?.id,
                active: '0',
            }),
        ),
    );

    useEffect(() => {
        reset(resetValues);
    }, [filtersPage]);

    useEffect(() => {
        if (resetFilter) {
            reset(resetValues);
            setResetFilter(false);
        }
    }, [resetFilter]);

    const handleLicensedChange = (value: any) => {
        setValue('coolerId', null);
        return setValue('licensedId', value, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
        });
    };

    return (
        <Filter
            filter={filter}
            setFilter={setFilter}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
        >
            {isAllowed && (
                <AutoCompleteField
                    dataTestId="licensed-autocomplete"
                    control={control}
                    options={licensedAutoCompleteData as IAutocomplete[]}
                    name="licensedId"
                    label="Licenciado"
                    data-testid="licensed-autocomplete"
                    interceptOnChange={handleLicensedChange}
                />
            )}
            <AutoCompleteField
                dataTestId="cooler-autocomplete"
                control={control}
                options={coolerAutoComplete as IAutocomplete[]}
                isLoading={
                    isCoolerAutoCompleteLoading || isCoolerAutoCompleteFetching
                }
                name="coolerId"
                label="Cooler"
            />
            <InputTextField
                control={control}
                name="id"
                label="Código"
                dataTestId="promotion-id"
                type="number"
            />

            <InputTextField
                control={control}
                name="promotionName"
                label="Nome"
                dataTestId="promotion-name"
            />
            <AutoCompleteField
                control={control}
                options={promotionPresenter.promotionTypeOptions}
                name="promotionType"
                label="Tipo de promoção"
                dataTestId="promotion-type"
            />
            <AutoCompleteField
                control={control}
                options={promotionPresenter.useCaseOptions}
                name="useType"
                label="Tipo de uso"
                dataTestId="use-type"
            />

            <AutoCompleteField
                control={control}
                options={categories}
                name="promotionCategory"
                label="Categoria"
                dataTestId="promotion-category"
                isLoading={isCategoriesLoading}
                isDisabled={shouldDisableCategoryFilterInput()}
            />
            <AutoCompleteField
                control={control}
                options={products}
                name="promotionProduct"
                label="Produto"
                dataTestId="promotion-product"
                isLoading={isProductsLoading}
                isDisabled={shouldDisableProductFilter()}
            />
            <AutoCompleteField
                control={control}
                options={promotionPresenter.statusOptions}
                name="status"
                label="Status"
                dataTestId="promotion-status"
            />
            <DateTitle>Período de vigência</DateTitle>
            <LocalizationProvider
                adapterLocale="pt-br"
                dateAdapter={AdapterDayjs}
            >
                <DateTimePickerField
                    control={control}
                    name="promotionStartDate"
                    label="Início"
                    rules={{
                        deps: ['promotionEndDate'],
                        validate: {
                            isLessThan: (value: any) => {
                                if (!value) return true;
                                return (
                                    dateEnd > value ||
                                    'Data de início deve ser menor que a data de término'
                                );
                            },
                        },
                    }}
                />
                <DateTimePickerField
                    control={control}
                    name="promotionEndDate"
                    label="Fim"
                    rules={{
                        deps: ['promotionStartDate'],
                        validate: {
                            isGreaterThan: (value: any) => {
                                if (!value) return true;
                                return (
                                    dateBegin < value ||
                                    'Data de término deve ser maior que a data de início'
                                );
                            },
                        },
                    }}
                />
            </LocalizationProvider>
        </Filter>
    );
}
