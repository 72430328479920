import { INegotiationForm } from '@/domain/models';
import {
    FinancialHttpEndpoint,
    HttpMethod,
    HttpRequest,
    HttpResponse,
    LicensedHttpEndpoint,
} from '@/domain/models/services';
import { IFinancialRepositoryPort, IHttpServicePort } from '@/infra/ports';

export class FinancialRepositoryAdapter implements IFinancialRepositoryPort {
    constructor(private readonly httpServicePort: IHttpServicePort) {}

    async getFinancialReport<T>(params: string): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: FinancialHttpEndpoint.FINANCIAL_REPORT + params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getBankDisputeDetails<T>(params: string): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: FinancialHttpEndpoint.FINANCIAL_BANK_DISPUTE_DETAILS + params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getCondominiumTransferDetails<T>(
        params: string,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url:
                FinancialHttpEndpoint.FINANCIAL_CONDOMINIUM_TRANSFER_DETAILS +
                params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getCreditCardDetails<T>(params: string): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: FinancialHttpEndpoint.FINANCIAL_CREDIT_CARD_DETAILS + params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getMonthlyPaymentsDetails<T>(
        params: string,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: FinancialHttpEndpoint.FINANCIAL_MONTHLY_PAYMENTS + params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getPixDetails<T>(params: string): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: FinancialHttpEndpoint.FINANCIAL_PIX_DETAILS + params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getPromotionsDetails<T>(params: string): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: FinancialHttpEndpoint.FINANCIAL_PROMOTIONS_DETAILS + params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getRefundDetails<T>(params: string): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: FinancialHttpEndpoint.FINANCIAL_REFUND_DETAILS + params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getWalletDetails<T>(params: string): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: FinancialHttpEndpoint.FINANCIAL_WALLET_DETAILS + params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getDetail<T>(
        params: string,
        endpoint: string,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url:
                FinancialHttpEndpoint.FINANCIAL_DETAILS_BASE +
                endpoint +
                params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async exportFinancialReport<T>(params: string): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: FinancialHttpEndpoint.FINANCIAL_REPORT_EXPORT + params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async hasExportFinancialReportInProgress<T>(): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: FinancialHttpEndpoint.FINANCIAL_REPORT_EXPORT_IN_PROGRESS,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async exportCondominiumTransferReport<T>(
        params: string,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url:
                FinancialHttpEndpoint.FINANCIAL_CONDOMINIUM_TRANSFER_REPORT +
                params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getNegotiationDetails<T>(params: string): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: FinancialHttpEndpoint.FINANCIAL_NEGOTIATION_DETAILS + params,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async getNegotiationById<T>(
        negotiationId: number,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url:
                FinancialHttpEndpoint.FINANCIAL_NEGOTIATION +
                `/${negotiationId}`,
            method: HttpMethod.get,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async deleteNegotiation<T>(
        negotiationId: number,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url:
                FinancialHttpEndpoint.FINANCIAL_NEGOTIATION +
                `/${negotiationId}`,
            method: HttpMethod.delete,
            auth: true,
        });

        return await this.httpServicePort.request<T>(request);
    }

    async postNegotiation<T>(data: any, licensedId: string) {
        const request = new HttpRequest({
            url: `${FinancialHttpEndpoint.FINANCIAL_NEGOTIATION}/${licensedId}`,
            method: HttpMethod.post,
            auth: true,
            body: data,
        });

        return this.httpServicePort.request<T>(request);
    }

    async updateNegotiation<T>(
        data: any,
        negotiationId: number,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url: `${FinancialHttpEndpoint.FINANCIAL_NEGOTIATION}/${negotiationId}`,
            method: HttpMethod.put,
            auth: true,
            body: data,
        });

        return this.httpServicePort.request<T>(request);
    }

    async getDefaultMonthlyPayment<T>(
        licensedId: string,
    ): Promise<HttpResponse<T>> {
        const request = new HttpRequest({
            url:
                FinancialHttpEndpoint.LICENSED_DEFAULT_MONTHLY_PAYMENT +
                `?licensedId=${licensedId}`,
            method: HttpMethod.get,
            auth: true,
        });

        return this.httpServicePort.request<T>(request);
    }
}
