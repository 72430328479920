import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ContextType } from '@/domain/models';
import {
    Button,
    ChangePasswordFormSteps,
    CircularProgress,
    LoginLayout,
} from '@/ui/components';
import { Column, Row } from '@/ui/components/layout';
import { FilterContext } from '@/ui/context/context-filter';
import { StoreState } from '@/ui/store/models';
import { store } from '@/ui/store/config';
import { toggleThemeAction } from '@/ui/store/modules';
import { usePasswordRecovery } from '@/ui/hooks';
import { AlignLoadingCenter } from './styles';

export default function PasswordRecovery(): JSX.Element {
    const { token } = useParams();

    const { setActivePage } = useContext(FilterContext);

    const navigate = useNavigate();

    const {
        onSubmit,
        isLoading,
        isSubmitButtonVisible,
        changePassword,
        setChangePassword,
        control,
        handleSubmit,
        errors,
        isValid,
    } = usePasswordRecovery();

    const isDarkTheme: boolean = useSelector((state: StoreState) => {
        return state.toggleTheme.darkTheme;
    });

    const saveButtonText =
        changePassword.changingPasswordStep != 3 ? 'Enviar' : 'Salvar';

    const isBackButtonVisible = changePassword.changingPasswordStep === 1;

    const formAlignment =
        changePassword.changingPasswordStep === 1 ? 'center' : 'start';

    const buttonSize =
        changePassword.changingPasswordStep === 1 ||
        changePassword.changingPasswordStep === 4
            ? 'medium'
            : 'large';

    useEffect(() => {
        setActivePage(ContextType.password_recovery);

        if (isDarkTheme) {
            store.dispatch(toggleThemeAction());
        }

        if (token) {
            setChangePassword({ ...changePassword, changingPasswordStep: 4 });
        }
    }, []);

    return (
        <LoginLayout>
            <Column
                as="form"
                onSubmit={handleSubmit(onSubmit)}
                align={formAlignment}
            >
                <ChangePasswordFormSteps
                    control={control}
                    changePasswordState={changePassword}
                    errors={errors}
                    isValid={isValid}
                />
                <AlignLoadingCenter>
                    {isLoading && <CircularProgress variant="indeterminate" />}
                </AlignLoadingCenter>
                <Row wrap="wrap" justify="center" gap={6}>
                    {isBackButtonVisible && (
                        <Button
                            dataTestId="go-back-button"
                            onClick={() => navigate('/login')}
                            text="Voltar"
                            className="outlined white medium mt-2"
                            type="button"
                        />
                    )}
                    {isSubmitButtonVisible && (
                        <Button
                            dataTestId="send-button"
                            text={saveButtonText}
                            className={`${buttonSize} mt-2`}
                            type="submit"
                            disabled={!isValid}
                        />
                    )}
                </Row>
            </Column>
        </LoginLayout>
    );
}
