import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, CircularProgress, Modal } from '@/ui/components';
import { store } from '@/ui/store/config';
import { StoreState } from '@/ui/store/models';
import { sidebarOpenAction } from '@/ui/store/modules';
import { useDeleteNegotiation, useGetNegotiationById } from '@/ui/hooks';
import { Column, Row } from '@/ui/components/layout';
import { NumberHelper } from '@/domain/helpers';

export default function DeleteNegotiationModal() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const modal = useSelector((store: StoreState) => store.sidebar);

    const handleClose = () => {
        store.dispatch(
            sidebarOpenAction({
                sidebarName: 'negotiation',
                sidebarState: { right: true },
                elementId: '',
                elementData: {},
            }),
        );
        setIsModalOpen(false);
    };

    const { deleteNegotiation } = useDeleteNegotiation(handleClose);

    const { negotiationData, isLoading: isNegotiationByIdLoading } =
        useGetNegotiationById(modal.elementData?.negotiationId);

    useEffect(() => {
        if (
            modal.sidebarName === 'financial_delete_negotiation_modal' &&
            modal.elementId
        ) {
            setIsModalOpen(modal.sidebarState.right);
        }
    }, [modal]);

    return (
        <Modal
            isOpen={isModalOpen}
            onClose={handleClose}
            title="Excluir negociação"
            okButton={
                <Button
                    dataTestId="submit-delete-negotiation"
                    text="Excluir"
                    onClick={() => deleteNegotiation(+modal.elementId)}
                />
            }
            cancelButton={
                <Button
                    text="Cancelar"
                    onClick={handleClose}
                    className="outlined"
                    dataTestId="cancel-button"
                />
            }
        >
            <Column>
                {isNegotiationByIdLoading ? (
                    <Row
                        align="center"
                        justify="center"
                        margin={{ bottom: '15px' }}
                    >
                        <CircularProgress variant="indeterminate" />
                    </Row>
                ) : (
                    <>
                        <Row>
                            <p
                                style={{
                                    display: 'inline',
                                    margin: '0px 0px 10px 0px',
                                }}
                            >
                                <strong>Descrição:</strong>{' '}
                                {negotiationData?.negotiationDescription}
                            </p>
                        </Row>
                        <Row>
                            <p
                                style={{
                                    display: 'inline',
                                    margin: '0px 0px 10px 0px',
                                }}
                            >
                                <strong>Valor:</strong>{' '}
                                {negotiationData?.negotiationValue &&
                                    NumberHelper.makeMoney(
                                        /*eslint-disable-next-line*/
                                        negotiationData?.negotiationValue!,
                                    )}
                            </p>
                        </Row>
                        <Row>
                            <p
                                style={{
                                    display: 'inline',
                                    margin: '0px 0px 20px 0px',
                                }}
                            >
                                <strong>Data:</strong>{' '}
                                {negotiationData?.negotiationDate}
                            </p>
                        </Row>
                    </>
                )}
                Tem certeza que deseja excluir a negociação?
            </Column>
        </Modal>
    );
}
