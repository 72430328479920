import { CircularProgress, NoDataFoundMessage } from '@/ui/components';

interface INoDataFound {
    data: any;
    children: any;
    isLoading?: boolean;
    showLoading?: boolean;
}

export default function NoDataFound({
    data,
    children,
    isLoading,
    showLoading,
}: INoDataFound): JSX.Element {
    const renderNoDataFound = () => {
        if (isLoading && showLoading) {
            return <CircularProgress variant="indeterminate" />;
        }

        if (isLoading && !showLoading) {
            return <></>;
        }

        if (data && !isLoading) return children;

        return <NoDataFoundMessage />;
    };

    return renderNoDataFound();
}
