/* eslint-disable */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import { CloseIcon, ModalContainer, Tabs } from '@/ui/components';
import { TitleContainer } from '@/ui/layouts/header/sub-header/styles';
import { TitleText } from '@/ui/pages/user/components/styles';
import Example from './caorulsel';
import { OrderContestationModals } from '@/domain/models';
import { InfoColumn, OrderDetailContainer } from '../order-edit-info/styles';
import { Row } from '@/ui/components/layout';

export default function OrderImageModal() {
    const [open, setOpen] = useState(false);
    const [tabValue, setTabValue] = useState<number>(0);

    const handleClose = () => setOpen(false);

    const sidebar = useSelector((store: any) => store.sidebar);

    const {
        orderImages,
        orderContestationImages,
        openDoorTime,
        contestationReason,
        orderId,
    } = sidebar.elementData;

    const returnOrderImages = () => {
        if (tabValue === 0) {
            return orderImages && orderImages.length > 0 ? (
                <Example media={orderImages} />
            ) : (
                <p>Nenhuma imagem encontrada</p>
            );
        } else if (tabValue === 1) {
            return orderContestationImages &&
                orderContestationImages.length > 0 ? (
                <Example media={orderContestationImages} />
            ) : (
                <p>Nenhuma imagem encontrada</p>
            );
        }
    };

    useEffect(() => {
        if (sidebar.sidebarName === OrderContestationModals.ORDER_IMAGES) {
            setOpen(sidebar.sidebarState.right);
        }
    }, [sidebar]);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="order_images"
                aria-describedby="order_images"
                data-testid="order_images_modal"
                sx={{
                    maxHeight: '90vh',
                }}
            >
                <ModalContainer className="order-image-container">
                    <TitleContainer>
                        <TitleText style={{ marginTop: 0 }}>
                            Pedido {orderId}
                        </TitleText>
                        <Row
                            justify="space-around"
                            data-testid="close_image_modal_button"
                            onClick={() => handleClose()}
                        >
                            <CloseIcon />
                        </Row>
                    </TitleContainer>
                    <OrderDetailContainer style={{ marginBottom: 10 }}>
                        <div>
                            <InfoColumn>
                                <p className="title">Porta aberta</p>
                                <p data-testid="modal_open_door_time">
                                    {openDoorTime
                                        ? openDoorTime
                                        : 'Nenhum dado encontrado'}
                                </p>
                            </InfoColumn>
                        </div>
                        <div>
                            <InfoColumn>
                                <p className="title">Motivo da contestação</p>
                                <p data-testid="modal_contestation_reason">
                                    {contestationReason
                                        ? contestationReason
                                        : 'Nenhum dado econtrado'}
                                </p>
                            </InfoColumn>
                        </div>
                    </OrderDetailContainer>
                    <Tabs
                        value={tabValue}
                        setValue={setTabValue}
                        tabs={['Imagens do produto', 'Imagens da contestação']}
                    >
                        {returnOrderImages()}
                    </Tabs>
                </ModalContainer>
            </Modal>
        </div>
    );
}
