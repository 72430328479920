import { IAutocomplete } from '@/domain/models';

const regimeTypesList: IAutocomplete[] = [
    {
        id: 'SIMPLE_NATIONAL',
        label: 'Simples Nacional',
    },
    {
        id: 'SIMPLE_NATIONAL_SUBLIMIT',
        label: 'SN. excesso sublimite de receita bruta',
    },
    {
        id: 'PRESUMED_PROFIT',
        label: 'Lucro Presumido',
    },
    {
        id: 'REAL_PROFIT',
        label: 'Lucro Real',
    },
];

export default regimeTypesList;
