import { IAutocomplete } from '@/domain/models';

export const CameraOrLockProblemOptions: IAutocomplete[] = [
    {
        id: 'true',
        label: 'Com Problema',
    },
    {
        id: 'false',
        label: 'Sem Problema',
    },
];

export const RefrigeratorActiveOptions: IAutocomplete[] = [
    {
        id: 'true',
        label: 'Online',
    },
    {
        id: 'false',
        label: 'Offline',
    },
];
