import { IRefrigeratorFilterParams } from '@/domain/models';
import { IFilterOption } from '@/ui/context/context-filter/type';

export default class RefrigeratorFilterParams
    implements IRefrigeratorFilterParams
{
    code: IFilterOption;
    device: IFilterOption;
    location: IFilterOption;
    situation: IFilterOption;
    status: IFilterOption;
    sortDirectionStockPercentage?: IFilterOption;
    licensedId: IFilterOption;

    constructor(
        code: string,
        device: string,
        location: string,
        situation: string,
        status: string,
        sortDirectionStockPercentage: string,
        licensedId: any,
        licensedLabel: string,
    ) {
        this.code = {
            name: 'code',
            label: code,
            id: code,
        };
        this.device = {
            name: 'device',
            label: device,
            id: device,
        };
        this.location = {
            name: 'location',
            label: location,
            id: location,
        };
        this.situation = {
            name: 'situation',
            label: situation,
            id: situation,
        };
        this.status = {
            name: 'status',
            label: status,
            id: status,
        };
        this.sortDirectionStockPercentage = {
            name: 'sortDirectionStockPercentage',
            label: sortDirectionStockPercentage,
            id: sortDirectionStockPercentage,
        };
        this.licensedId = {
            name: 'licensedId',
            label: licensedLabel,
            id: licensedId,
        };
    }
}
