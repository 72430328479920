import { ContextType } from '../context-type';

export default function ContextTitle() {
    const ContextTitleMap = new Map<string, string>([
        [ContextType.none, ''],
        [ContextType.home, 'Dashboard'],
        [ContextType.order, 'Pedidos'],
        [ContextType.home_overview, 'Visão geral'],
        [ContextType.home_rating, 'Avaliações'],
        [ContextType.refrigerator, 'Coolers'],
        [ContextType.refrigerator_status, 'Status'],
        [ContextType.refrigerator_status_dashboard, 'Dashboard'],
        [ContextType.refrigerator_status_list, 'Lista'],
        [ContextType.refrigerator_stock, 'Estoque'],
        [ContextType.refrigerator_stock_by_cooler, 'Estoque por cooler'],
        [ContextType.refrigerator_stock_by_product, 'Estoque por produto'],
        [ContextType.refrigerator_list, 'Lista'],
        [ContextType.refrigerator_save, 'Novo cooler'],
        [ContextType.refrigerator_edit, 'Lista'],
        [ContextType.refrigerator_edit_product, 'Lista'],
        [ContextType.products, 'Produtos'],
        [ContextType.product_list, 'Produtos'],
        [ContextType.product_save, 'Novo produto'],
        [ContextType.product_edit, 'Produtos'],
        [ContextType.product_mix, 'Mix'],
        [ContextType.product_mix_save, 'Novo mix'],
        [ContextType.product_mix_edit, 'Mix'],
        [ContextType.product_category, 'Categorias'],
        [ContextType.product_category_save, 'Nova categoria'],
        [ContextType.product_category_edit, 'Categorias'],
        [ContextType.promotion_list, 'Promoções'],
        [ContextType.promotion_save, 'Nova promoção'],
        [ContextType.promotion_edit, 'Promoções'],
        [ContextType.user, 'Usuários'],
        [ContextType.user_save, 'Novo usuário'],
        [ContextType.user_edit, 'Usuários'],
        [ContextType.financial, 'Financeiro'],
        [ContextType.order, 'Pedidos'],
        [ContextType.order_edit, 'Pedidos'],
        [ContextType.monitoring, 'Monitoramento'],
        [ContextType.monitoring_opening_attempts, `Tentativas de abertura`],
        [ContextType.licensed_list, 'Licenciados'],
        [ContextType.licensed_save, 'Novo licenciado'],
        [ContextType.licensed_edit, 'Licenciados'],
        [ContextType.banner_list, 'Banners'],
        [ContextType.banner_save, 'Novo banner'],
        [ContextType.banner_edit, 'Banners'],
    ]);

    return ContextTitleMap;
}
