import styled from 'styled-components';

export const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const TitleText = styled.h2`
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: -0.025em;
    color: ${props => props.theme.secondary};

    &.reset-password {
        flex-direction: column;
    }
`;

export const ButtonContainer = styled.button`
    border: none;
    padding: 0;
    margin: 0;
    background: none;
`;

export const SaveUserButton = styled.button`
    background-color: ${props => props.theme.tertiary};
    color: #000;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
    border-radius: 10px;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    border: none;
    padding: 7px 22px;
    cursor: pointer;
`;

export const CancelButton = styled.button`
    background-color: '#bababa';
    color: #000;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
    border-radius: 10px;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    border: none;
    padding: 7px 22px;
    margin-right: 16px;
    cursor: pointer;
`;
