import { TablePagination } from '@mui/material';
import { Container } from './styles';
import { IPaginationComponent } from '@/ui/interfaces/components/table';
import PaginationActions from './actions';

export default function Pagination(props: IPaginationComponent): JSX.Element {
    const { page, rowsPerPage, count, rowsPerPageOptions } = props;
    const { onPageChange, onRowsPerPageChange } = props;

    function makeLabelDisplayedRows({
        from,
        to,
        count,
    }: {
        from: number;
        to: number;
        count: number;
    }) {
        return `${from}-${to} de ${count !== -1 ? count : `more than ${to}`}`;
    }

    return (
        <Container className="pagination">
            <TablePagination
                page={Number(page)}
                rowsPerPage={rowsPerPage}
                count={Number(count)}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                rowsPerPageOptions={rowsPerPageOptions}
                labelRowsPerPage="Linhas por página"
                labelDisplayedRows={makeLabelDisplayedRows}
                align="right"
                ActionsComponent={PaginationActions}
            />
        </Container>
    );
}
