import {
    CalendarIcon,
    CardContainer,
    CoolerIcon,
    ThermometerIcon,
    UptimeIcon,
    WebcamIcon,
    WifiIcon,
} from '@/ui/components';
import { CardHeader } from '../../components/styles';
import { IRefrigeratorStatusList } from '@/domain/models';
import { ConnectionContainer, CoolerLocality, StatusRows } from './styles';
import { Grid } from '@/ui/components/layout';
import {
    AuthenticationHelper,
    DateHelper,
    PermissionHelper,
} from '@/domain/helpers';
import { InfoOutlined, Inventory2Outlined } from '@mui/icons-material';
import { Tooltip } from 'react-tooltip';
import { RefObject } from 'react';
import { Capitalized } from '../../list/status-table/styles';

export default function DashboardCard({
    refrigerator,
    isLoading,
    reference,
    onCameraClick,
}: {
    refrigerator: IRefrigeratorStatusList;
    isLoading: string;
    reference: RefObject<HTMLDivElement>;
    onCameraClick: (refrigerator: IRefrigeratorStatusList) => void;
}) {
    const userRoles = AuthenticationHelper.hasToken()
        ? AuthenticationHelper.getToken().auth
        : '';
    const permissionHelper = new PermissionHelper(userRoles);

    const isAllowed =
        permissionHelper.isTakeAndGo() || permissionHelper.isDeveloper();

    const cardClassName = `${isLoading} ${
        refrigerator.active === 'false' || !refrigerator.active ? 'danger' : ''
    }`;

    const checkCameraOrDoorStatusHasProblem = (value: string) => {
        if (value === 'true') return 'Com problema';
        if (value === 'false') return 'Sem problema';

        return '-';
    };

    const handleCameraClick = () => {
        if (refrigerator.cameraProblem === 'true') {
            onCameraClick(refrigerator);
        }
    };

    const stockClassName = (stock: string) => {
        if (stock === '-' || isNaN(Number(stock))) return '';

        if (Number(stock) <= 49) return 'offline';
        if (Number(stock) >= 50 && Number(stock) <= 69) return 'warning';
        if (Number(stock) > 69) return 'online';

        return '';
    };

    return (
        <CardContainer
            ref={reference}
            className={cardClassName}
            gap={4}
            padding={5}
            style={{
                overflow: 'unset',
            }}
        >
            {refrigerator.locality && (
                <Tooltip id={refrigerator.locality + refrigerator.date} />
            )}
            {refrigerator.serial && (
                <Tooltip id={refrigerator.serial + refrigerator.date} />
            )}
            <CardHeader justify="space-between" width="100%" align="flex-start">
                <div>
                    <CoolerLocality
                        className="body-medium font-weight-regular"
                        data-tooltip-content={
                            refrigerator?.locality &&
                            refrigerator?.locality.length > 20
                                ? refrigerator.locality
                                : ''
                        }
                        data-tooltip-id={
                            refrigerator.locality + refrigerator.date
                        }
                    >
                        <Capitalized>{refrigerator.locality}</Capitalized>
                    </CoolerLocality>
                    <CoolerLocality
                        className="body-medium font-weight-regular"
                        data-tooltip-content={
                            refrigerator?.serial &&
                            refrigerator?.serial.length > 20
                                ? refrigerator.serial
                                : ''
                        }
                        data-tooltip-id={
                            refrigerator.serial + refrigerator.date
                        }
                    >
                        {refrigerator.serial}
                    </CoolerLocality>
                </div>
                <ConnectionContainer
                    className={`${
                        refrigerator.active === 'true' ? 'online' : 'offline'
                    } font-weight-bold`}
                    align="center"
                    fontSize={3}
                >
                    {refrigerator.active === 'true' ? 'ONLINE' : 'OFFLINE'}
                    <WifiIcon />
                </ConnectionContainer>
            </CardHeader>
            <Grid columns={2} width="100%" gap={4}>
                <StatusRows
                    className={
                        'stroke' +
                        (refrigerator.cameraProblem === 'true'
                            ? ' cameraProblem'
                            : '')
                    }
                    onClick={handleCameraClick}
                >
                    <WebcamIcon />
                    <ConnectionContainer
                        className={
                            refrigerator.cameraProblem === 'true'
                                ? 'offline camera'
                                : '' + 'font-weight-regular'
                        }
                    >
                        {checkCameraOrDoorStatusHasProblem(
                            refrigerator.cameraProblem,
                        )}
                    </ConnectionContainer>
                </StatusRows>
                <StatusRows className="stroke">
                    <CalendarIcon />
                    <span>
                        {refrigerator.date === '-'
                            ? '-'
                            : DateHelper.formatDateHours2(refrigerator.date)}
                    </span>
                </StatusRows>
                <StatusRows className="stroke">
                    <CoolerIcon />
                    <ConnectionContainer
                        className={
                            refrigerator.lockProblem === 'true'
                                ? 'offline'
                                : '' + 'font-weight-regular'
                        }
                    >
                        {checkCameraOrDoorStatusHasProblem(
                            refrigerator.lockProblem,
                        )}
                    </ConnectionContainer>
                </StatusRows>
                <StatusRows>
                    <Inventory2Outlined
                        sx={{
                            fontSize: '20px',
                        }}
                    />
                    <ConnectionContainer>Estoque:</ConnectionContainer>
                    <ConnectionContainer
                        className={stockClassName(refrigerator.stockPercentage)}
                    >
                        {refrigerator.stockPercentage === '-'
                            ? '-'
                            : `${Math.round(
                                  Number(
                                      refrigerator.stockPercentage.replace(
                                          ',',
                                          '.',
                                      ),
                                  ),
                              )}%`}
                    </ConnectionContainer>
                </StatusRows>
                <StatusRows className="stroke">
                    <ThermometerIcon />
                    <span>
                        {refrigerator.temperature === '-'
                            ? 'Não disponível'
                            : refrigerator.temperature.concat('°C')}
                    </span>
                </StatusRows>
                <StatusRows>
                    <InfoOutlined />
                    <span>
                        Versão{' '}
                        {refrigerator.version === '-'
                            ? '-'
                            : refrigerator.version.replaceAll('-', '.')}
                    </span>
                </StatusRows>
                {isAllowed && (
                    <StatusRows>
                        <UptimeIcon />
                        <ConnectionContainer>Uptime:</ConnectionContainer>
                        <ConnectionContainer>
                            <strong>
                                {Math.round(
                                    Number(
                                        refrigerator.upTimePercentage.replace(
                                            ',',
                                            '.',
                                        ),
                                    ),
                                )}
                                %{' '}
                            </strong>
                        </ConnectionContainer>
                    </StatusRows>
                )}
            </Grid>
        </CardContainer>
    );
}
