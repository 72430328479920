import { useContext, useEffect } from 'react';
import { ContextType, TableIcons } from '@/domain/models';
import { makeProductsTablePresenter } from '@/main/factories';
import { store } from '@/ui/store/config';
import selectNavigationAction from '@/ui/store/modules/app/navigation/actions';
import { MainContainer, TableServerSide } from '@/ui/components';
import Default from '../../default';
import { ChangeActiveModal } from '../../user/components';
import { FilterContext } from '@/ui/context/context-filter';
import { NavigationAppState } from '@/ui/store/models';

export default function ProductsList(): JSX.Element {
    const productTablePresenter = makeProductsTablePresenter();

    const { setActivePage } = useContext(FilterContext);

    useEffect(() => {
        setActivePage(ContextType.product_list);
        store.dispatch(
            selectNavigationAction(
                new NavigationAppState(
                    ContextType.product_list,
                    ContextType.products,
                    TableIcons.PRODUCTS,
                ),
            ),
        );
    }, []);

    return (
        <Default contextType={ContextType.products}>
            <ChangeActiveModal />
            <TableServerSide
                presenter={productTablePresenter}
                rowProps={{
                    enableRowActions: true,
                    identityPropertyName: 'id',
                    rowActions: productTablePresenter.makeRowActions(),
                }}
                paginationProps={{
                    enablePagination: true,
                    pageIndex: 0,
                    rowsPerPage: 25,
                    rowsPerPageOptions: [25, 50, 75, 100],
                }}
            />
        </Default>
    );
}
