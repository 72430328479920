/*eslint-disable no-extra-boolean-cast*/

import { createContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { parsePhoneNumber } from 'react-phone-number-input';
import {
    ContextDefaultValidColumns,
    IAutocomplete,
    getFiltersByPage,
    getRequiredFiltersByPermission,
} from '@/domain/models';
import {
    AuthenticationHelper,
    DocumentHelper,
    PermissionHelper,
    RequestQueryMaker,
} from '@/domain/helpers';
import {
    makeLicensedPresenter,
    makePromotionListPresenter,
    makeRefrigeratorListPresenter,
} from '@/main/factories';
import {
    getDefaultRequiredValues,
    defaultFilterValues,
} from '../default-values';
import { IFilterProvider, IFilters } from './type';

export const FilterContext = createContext({} as IFilterProvider);

export function FilterProvider({ children }: any) {
    const [filters, setFilters] = useState<IFilters>(defaultFilterValues);
    const [resetFilter, setResetFilter] = useState<boolean>(false);
    const [activePage, setActivePage] = useState('');
    const [shouldReRenderTable, setShouldReRenderTable] =
        useState<boolean>(false);

    const queryClient = useQueryClient();
    const [searchParams, setSearchParams] = useSearchParams();

    const licensedPresenter = makeLicensedPresenter();
    const refrigeratorListPresenter = makeRefrigeratorListPresenter();
    const promotionPresenter = makePromotionListPresenter();

    const licensedAutoCompleteData = queryClient.getQueryData<
        Array<IAutocomplete>
    >('licensedAutoComplete');

    const coolerAutoCompleteData =
        queryClient.getQueryData<Array<IAutocomplete>>('coolerAutoComplete');

    const categoriesData = queryClient.getQueryData<Array<IAutocomplete>>(
        'categoryAutoComplete',
    );

    const roles = AuthenticationHelper.getToken().auth;
    const permissionHelper = new PermissionHelper(roles ? roles : '');

    const { data: licensedAutoComplete = [] } = useQuery(
        'licensedAutoComplete',
        () => licensedPresenter.getLicensedAutoComplete(),
        {
            initialData: licensedAutoCompleteData,
            enabled:
                !licensedAutoCompleteData &&
                AuthenticationHelper.isAuthenticated(),
            onSuccess: data => {
                queryClient.setQueryData('licensedAutoComplete', data);
            },
        },
    );

    const { data: coolerAutoComplete = [] } = useQuery(
        'coolerAutoComplete',
        () =>
            refrigeratorListPresenter.getCoolerFilterAutocompleteAsync(
                RequestQueryMaker({
                    active: '0',
                }),
            ),
        {
            enabled:
                !coolerAutoCompleteData &&
                AuthenticationHelper.isAuthenticated(),
            initialData: coolerAutoCompleteData,
            onSuccess: data => {
                queryClient.setQueryData('coolerAutoComplete', data);
            },
        },
    );

    const { data: categoriesAutoComplete } = useQuery(
        'categoryAutoComplete',
        () => promotionPresenter.getCategoriesAsync(),
        {
            onSuccess: data => {
                queryClient.setQueryData('categoryAutoComplete', data);
            },
            enabled:
                !categoriesData &&
                activePage === 'promotion' &&
                AuthenticationHelper.isAuthenticated(),
            initialData: categoriesData,
        },
    );

    const { data: productsAutoComplete = [] } = useQuery(
        'productAutoComplete',
        () => promotionPresenter.getProductsAsync(),
        {
            enabled: AuthenticationHelper.isAuthenticated(),
        },
    );

    const orderTypeOptions = [
        { label: 'Reposição', id: 'REPOSITION' },
        { label: 'Consumo', id: 'CONSUME' },
        { label: 'Reposição de SETUP', id: 'REPOSITION_SETUP' },
        { label: 'Promoter', id: 'PROMOTER' },
    ];

    interface IKeyMapping {
        licensedId: IAutocomplete[];
        licensed: IAutocomplete[];
        coolerId: IAutocomplete[];
        cooler: IAutocomplete[];
    }

    const getUrlFilters = () => {
        let urlFilters: any = filters[activePage as keyof IFilters];
        const keyMappings: IKeyMapping = {
            licensedId: licensedAutoComplete,
            licensed: licensedAutoComplete,
            coolerId: coolerAutoComplete,
            cooler: coolerAutoComplete,
        };

        searchParams.forEach((value, key) => {
            const defaultItem: any =
                keyMappings[key as keyof IKeyMapping]?.find(
                    item => item.id == value,
                ) || {};
            const label = defaultItem.label || value;

            urlFilters = {
                ...urlFilters,
                [key]: {
                    id: value,
                    label: label,
                    name: key,
                },
            };

            if (
                urlFilters &&
                urlFilters?.column &&
                filters[activePage as keyof IFilters]?.column
            ) {
                const validColumns = ContextDefaultValidColumns.get(activePage);
                if (!validColumns?.includes(urlFilters.column.id)) {
                    delete urlFilters.column;
                }
            }

            if (
                urlFilters &&
                !urlFilters?.column &&
                filters[activePage as keyof IFilters]?.column
            ) {
                urlFilters.column = {
                    id: filters[activePage as keyof IFilters]?.column?.id,
                    label: filters[activePage as keyof IFilters]?.column?.label,
                    name: 'column',
                };
            }

            if (
                urlFilters &&
                urlFilters?.sort &&
                !filters[activePage as keyof IFilters] &&
                !filters[activePage as keyof IFilters].sort
            ) {
                urlFilters.sort = {
                    id: filters[activePage as keyof IFilters].sort?.id,
                    label: filters[activePage as keyof IFilters].sort?.label,
                    name: 'sort',
                };
            }

            if (key === 'promotionCategory' && activePage === 'promotion') {
                const category = categoriesAutoComplete?.find(
                    item => item.id == value,
                );

                if (category) {
                    urlFilters.promotionCategory = {
                        id: category.id,
                        label: category.label,
                        name: 'promotionCategory',
                    };
                }
            }

            if (key === 'promotionProduct' && activePage === 'promotion') {
                const product = productsAutoComplete?.find(
                    item => item.id == value,
                );

                if (product) {
                    urlFilters.promotionProduct = {
                        id: product.id,
                        label: product.label,
                        name: 'promotionProduct',
                    };
                }
            }
            if (key === 'licensedCnpj' && value) {
                urlFilters.licensedCnpj = {
                    id: value,
                    label: DocumentHelper.makeMaskCnpj(value),
                    name: 'licensedCnpj',
                };
            }

            if (key === 'licensedPhone' && value) {
                urlFilters.licensedPhone = {
                    id: value,
                    label: parsePhoneNumber(value, 'BR')?.formatNational(),
                    name: 'licensedPhone',
                };
            }

            if (key === 'cameraProblem' && value) {
                urlFilters.cameraProblem = {
                    id: value,
                    label: Boolean(value === 'true')
                        ? 'Status da câmera: Com problema'
                        : 'Status da porta: Sem problema',
                    name: 'cameraProblem',
                };
            }

            if (key === 'active' && value) {
                urlFilters.active = {
                    id: value,
                    label: Boolean(value === 'true') ? 'Online' : 'Offline',
                    name: 'active',
                };
            }

            if (key === 'lockProblem' && value) {
                urlFilters.lockProblem = {
                    id: value,
                    label: Boolean(value === 'true')
                        ? 'Status da porta: Com problema'
                        : 'Status da porta: Sem problema',
                    name: 'lockProblem',
                };
            }

            if (key === 'version' && value) {
                urlFilters.version = {
                    id: value,
                    label: `Versão: ${value.replace(/-/g, '.')}`,
                    name: 'version',
                };
            }

            if (key === 'licensedName' && value) {
                urlFilters.licensedName = {
                    id: value,
                    label: decodeURIComponent(value),
                    name: 'licensedName',
                };
            }

            if (!permissionHelper.isTakeAndGo()) {
                delete urlFilters.licensedId;
            }

            if (key === 'orderType' && value) {
                const orderType = orderTypeOptions.find(
                    item => (item.id = value),
                );

                urlFilters.orderType = {
                    id: value,
                    label: orderType!.label,
                    name: 'orderType',
                };
            }
        });

        return urlFilters;
    };

    const handleRequiredFilters = (filters: IFilters): IFilters => {
        const requiredFilters =
            getRequiredFiltersByPermission().get(activePage);

        const filtersByPage = getFiltersByPage().get(activePage);

        const defaultRequiredValues = getDefaultRequiredValues();

        const filtersWithRequiredValues: IFilters = { ...filters };

        if (!permissionHelper.isTakeAndGo()) {
            delete defaultRequiredValues.licensedId;
        }

        requiredFilters?.forEach(filterKey => {
            const filterAction = defaultRequiredValues[filterKey as any];
            if (filterAction) {
                Object.keys(filterAction).forEach(page => {
                    filtersWithRequiredValues[page as keyof IFilters] = {
                        ...filtersWithRequiredValues[page as keyof IFilters],
                        ...filterAction[page as any],
                    };
                });
            }
        });

        const removeProperties = (obj: any, allowedKeys: string[]) => {
            if (allowedKeys) {
                return Object.keys(obj)
                    .filter(key => allowedKeys.includes(key))
                    .reduce((result: any, key) => {
                        result[key] = obj[key];
                        return result;
                    }, {});
            }
        };

        const filtersWithoutOtherPageFilters =
            filtersWithRequiredValues[activePage as keyof IFilters] &&
            removeProperties(
                filtersWithRequiredValues[activePage as keyof IFilters],
                filtersByPage!,
            );

        filtersWithRequiredValues[activePage as keyof IFilters] =
            filtersWithoutOtherPageFilters;

        return filtersWithRequiredValues;
    };

    const setPageFilters = (): void => {
        const filtersCopy = Object.assign({}, filters);

        filtersCopy[activePage as keyof IFilters] = getUrlFilters();

        const filtersCopyWithRequired = handleRequiredFilters(filtersCopy);

        if (filtersCopy === filtersCopyWithRequired) {
            return;
        }

        updateUrlFilters(filtersCopyWithRequired[activePage as keyof IFilters]);
        setFilters(filtersCopyWithRequired);
        setResetFilter(true);
        setShouldReRenderTable(true);
    };

    const getFilters = (): any => {
        return filters[activePage as keyof IFilters];
    };

    const cleanFilter = (): void => {
        const requiredFilters = handleRequiredFilters(defaultFilterValues);
        updateFilters(requiredFilters[activePage as keyof IFilters], true);
        setResetFilter(true);
    };

    const convertFilterToObject = (filtersObject: any) => {
        const transformedObject: any = {};
        for (const key in filtersObject) {
            if (
                filtersObject[key] != null &&
                key in filtersObject &&
                filtersObject[key].id
            ) {
                transformedObject[key] = filtersObject[key].id;
            }
        }

        return transformedObject;
    };

    const updateUrlFilters = (filters: any) => {
        setSearchParams(convertFilterToObject(filters));
    };

    const updateFilters = (newFilter: any, resetPage?: boolean) => {
        const resetPageValue = { id: '0', label: '0', name: 'page' };

        resetPage
            ? setFilters({
                  ...filters,
                  [activePage as keyof any]: {
                      ...newFilter,
                      page: resetPageValue,
                  },
              })
            : setFilters({
                  ...filters,
                  [activePage as keyof any]: { ...newFilter },
              });

        resetPage
            ? updateUrlFilters({ ...newFilter, page: resetPageValue })
            : updateUrlFilters(newFilter);

        setShouldReRenderTable(true);
    };

    const deleteFilter = (filterName: string) => {
        const emptyValue = { name: null, id: '', label: null };

        updateFilters({
            ...filters[activePage as keyof IFilters],
            [filterName]: emptyValue,
        });
        updateUrlFilters({
            ...filters[activePage as keyof IFilters],
            [filterName]: emptyValue,
        });
        setResetFilter(true);
    };

    const getFiltersObject = () => {
        return convertFilterToObject(filters[activePage as keyof IFilters]);
    };

    const getSortColumn = (columnName = '') => {
        return filters[activePage as keyof IFilters]?.column?.id == columnName;
    };

    const getSortDirection = () => {
        return filters[activePage as keyof IFilters]?.sort?.id;
    };

    useEffect(() => {
        if (activePage) {
            setPageFilters();
        }
    }, [
        licensedAutoCompleteData,
        coolerAutoCompleteData,
        categoriesAutoComplete,
        activePage,
    ]);

    return (
        <FilterContext.Provider
            value={{
                getFilters,
                cleanFilter,
                resetFilter,
                setResetFilter,
                deleteFilter,
                setActivePage,
                updateFilters,
                convertFilterToObject,
                getFiltersObject,
                shouldReRenderTable,
                setShouldReRenderTable,
                getSortColumn,
                activePage,
                getSortDirection,
            }}
        >
            {children}
        </FilterContext.Provider>
    );
}
